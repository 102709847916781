import React from "react";
import * as S from "./checkbox.styled";

function Checkbox(props) {
  const {
    value = "",
    primaryColor,
    checked,
    onChange,
    padding = true,
    ...rest
  } = props;

  return (
    <S.Checkbox
      primaryColor={primaryColor}
      noPadding={padding}
    >
      <S.Input
        type="checkbox"
        checked={checked}
        value={value}
        onChange={onChange}
        readOnly={!!!onChange}
        {...rest}
      />
    </S.Checkbox>
  )
}

export default Checkbox;