import React, { Component } from 'react';
import './remove-modal.css';
import { getTheme } from '../../utils/getTheme';

export default class RemoveModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "value": ""
    };

    this.onTitleChange = this.onTitleChange.bind(this);
  }

  onTitleChange(e) {
    this.setState({
      "value": e.currentTarget.value
    })
  }

  onRemoveConfirm() {
    this.props.onRemoveConfirm(); 
  }

  render() {
    var inactive = this.state.value !== this.props.value ? "inactive" : "";
    return (
      <div className={`rdp-component-remove-modal rdp-component-remove-modal-theme-${getTheme()}`}>
        <div className="rdp-component-remove-modal-popup">
          <span className="rdp-component-remove-modal-close" onClick={() => this.props.onClose()}></span>
          <span className="rdp-component-remove-modal-icon"></span>
          <span className="rdp-component-remove-modal-title">{this.props.title}</span>
          <span className="rdp-component-remove-modal-message">{this.props.message}</span>
          <button className="rdp-component-remove-modal-submit" onClick={() => this.onRemoveConfirm()}>{this.props.removeLabel}</button>
        </div>
      </div>
    );
  }
}
