import React from "react";
import * as S from "./quiz-video.styled";
import {UserService} from "../../services/user";

function QuizVideo(props) {
  const {
    data = null,
    mediaMaxWidth,
  } = props;
  const style = mediaMaxWidth ? {maxWidth: mediaMaxWidth.toString() + 'px'} : {};

  const getAssetUrl = (asset) => {
    if (asset.slice(0, '/static/media/'.length) === '/static/media/') {
      return asset;
    } else {
      const accessToken = UserService.getUser().access_token;
      return asset + "&access_token=" + accessToken;
    }
  }

  if (data !== null) {
    return <S.QuizVideo controls src={getAssetUrl(data)} style={style}/>
  }

  return null;
}

export default QuizVideo;