import React from "react";
import * as S from "./quiz-match-line.styled";
import dragIcon from "./drag.svg";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

function QuizMatchLine(props) {
  const {
    primaryColor,
    data = [],
    disabled,
    onChange = (connectedItems) => {},
  } = props;
  const choices = data;
  const matches = data;
  const [items, setItems] = React.useState(choices || []);
  const [connectedItems, setConnectedItems] = React.useState([]);
  const lines = React.useMemo(() => {
    let lines = 1;

    choices.forEach(choice => {
      let titleLines = choice.title.length / 40;
      let matchTitleLines = choice.matchTitle.length / 40;
      lines = Math.max(lines, titleLines, matchTitleLines);
    });

    return lines > 3 ? lines : 3;
  });

  React.useEffect(() => { 
    setItems(choices || []);
  }, [choices]);

  React.useEffect(() => {
    if (typeof onChange === "function") {
      if (connectedItems.length === choices.length) {
        onChange(connectedItems);
      } else {
        onChange(null);
      }
    }
  }, [connectedItems]);

  const handleDndOperation = (result) => {
    if (result.destination) {
      if (isMatchOperation(result)) {
        saveConnection(result);

        const operationIndexes = getOperationIndexes(result);

        if (isReorderOperation(result)) {
          setItems(swapItems(operationIndexes.source, operationIndexes.destination));
        }
      } else if (isReorderOperation(result)) {
        setItems(reorderItems(result.source.index, result.destination.index));
      }
    }
  }

  const saveConnection = (result) => {
    const choice = items[result.source.index];
    const match = matches.find(m => m.matchTitle === result.destination.droppableId);

    let connectedItemsCopy = [...connectedItems];

    connectedItemsCopy = connectedItemsCopy.filter(item => item.destinationId !== match.id && item.sourceId !== choice.id);

    let connectedItem = {
      sourceId: choice.id,
      destinationId: match.id,
      title: choice.title,
      matchTitle: result.destination.droppableId,
      correct:  choice.id === match.id,
    }

    connectedItemsCopy.push(connectedItem);

    setConnectedItems(connectedItemsCopy);
  }

  const getOperationIndexes = (result) => {
    const sourceItem = items.find(item => item.title === result.draggableId);
    const destinationItem = matches.find(item => item.matchTitle === result.destination.droppableId)

    const destination = matches.indexOf(destinationItem);
    const source = items.indexOf(sourceItem);

    return {source, destination};
  }

  const isReorderOperation = (result) => {
    return result.source.droppableId === "choices";
  }

  const isMatchOperation = (result) => {
    return result.source.droppableId === "choices" && result.destination.droppableId !== "choices";
  }

  const reorderItems = (sourceIndex, destinationIndex) => {
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);

    return items;
  }

  const swapItems = (sourceIndex, destinationIndex) => {
    let itemsCopy = [...items];
    const tmp = itemsCopy[destinationIndex];
    itemsCopy[destinationIndex] = itemsCopy[sourceIndex];
    itemsCopy[sourceIndex] = tmp;
    return itemsCopy;
  }

  return (
    <S.QuizMatchLine>
      <DragDropContext
        onDragEnd={handleDndOperation}
      >
        <S.LeftColumn>
          <Droppable droppableId="choices">
            {(provided) => (
              <ol
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {items.map((choice, index) => (
                  <Draggable
                    key={choice.title}
                    isDragDisabled={disabled}
                    draggableId={choice.title}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <S.Choice
                          className="quiz-match-line__choice"
                          lines={lines}
                          primaryColor={primaryColor}
                        >
                          <S.IconImage
                            lines={lines}
                            src={dragIcon}
                          />
                          <S.Text lines={lines}>
                            {choice.title}
                          </S.Text>

                          <S.ArrowLeft
                            lines={lines}
                            connected={connectedItems.find(item => item.title === choice.title)}
                          />
                        </S.Choice>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ol>
            )}
          </Droppable>
        </S.LeftColumn>

        <S.MatchList>
          {
            matches.map((match, index) => (
              <li key={match.matchTitle}>
                <Droppable droppableId={match.matchTitle}>
                  {(provided) => (
                    <span ref={provided.innerRef} {...provided.droppableProps}>
                      <S.MatchLine
                        className={connectedItems.find(item => item.matchTitle === match.matchTitle) ? "quiz-match-line__match-line-connected" : "quiz-match-line__match-line"}
                        key={match.matchTitle}
                        connected={connectedItems.find(item => item.matchTitle === match.matchTitle)}
                      >
                        <S.MatchConnector lines={lines}/>

                        <S.Match
                          className="quiz-match-line__match"
                          lines={lines}
                        >
                          <S.Text lines={lines}>
                            {match.matchTitle}
                          </S.Text>
                          <S.ArrowRight
                            lines={lines}
                            connected={connectedItems.find(item => item.matchTitle === match.matchTitle)}
                          />
                        </S.Match>
                      </S.MatchLine>
                      {provided.placeholder}
                    </span>
                  )}
                </Droppable>
              </li>
            ))
          }
        </S.MatchList>
      </DragDropContext>
    </S.QuizMatchLine>
  )
}

export default QuizMatchLine;