import styled from 'styled-components';


export const ElementCodeSnippet1 = styled.div`
  background-color: ${props => props.backgroundColor || "#fff"};
  padding-top: ${props => props.paddingTop || "0"}px;
  padding-bottom: ${props => props.paddingBottom || "0"}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  
  :root {
    --primary-color: ${props => props.primaryColor || "#2d7b45"};
  }

  /*region element max-width*/
  /*SM*/
  &.max-w-sm .element-snippet-1__snippet-item  {
    max-width: 760px;
  }
  /*MD*/
  &.max-w-md .element-snippet-1__snippet-item  {
    max-width: 1160px;
  }
  /*LG*/
  &.max-w-lg .element-snippet-1__snippet-item  {
    max-width: 1600px;
  }
  /*FULL*/
  &.max-w-full .element-snippet-1__snippet-item  {
    max-width: calc(100% - 60px);
  }
  /*endregion*/
`;


export const SnippetList = styled.ul`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SnippetItem = styled.li`
  width: 100%;
  min-width: 600px;
  position: relative;
`;

export const Figure = styled.figure`
  min-height: 300px;
  border-radius: 8px;
  background-color: ${props => props.backgroundColor || "whitesmoke"};
  border: 1px solid #e6e6e6;
  padding: 20px;
  display: flex;
  align-items: center;
  overflow-x: auto;
`;

export const Pre = styled.pre``;

export const FigCaption = styled.figcaption`
  margin-top: 10px;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 5px;
`;

export const CopyButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  
  &:hover {
    img {
      filter: brightness(0.8);
    }
  }
`;

export const CopyButtonIcon = styled.img``;
