import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'animate.css/animate.min.css';

import { UserService } from '../../services/user';
import * as S from './element-images-gallery.styled';

const ElementImagesGallery = ({
  headerActionsTemplate,
  footerActionsTemplate,
  content,
  className
}) => {
  const sliderRef = useRef(null);

  const [isModalActivated, setIsModalActivated] = useState(content.images.map(() => false));
  const [currentImageOnModal, setCurrentImageOnModal] = useState(0);

  const handleClickOnImage = (i) => {
    isModalActivated[i] = true;
    setIsModalActivated([...isModalActivated]);
    setCurrentImageOnModal(i);
  };

  const handleCloseModal = () => {
    setIsModalActivated(isModalActivated.map(() => false));
  };

  const getImageUrl = (image) => {
    let accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg" &&
      image.slice(0, '/static/media/'.length) !== '/static/media/') {
      image += "&access_token=" + accessToken;
    };
    return image;
  };

  const handleClickOnNext = () => {
    const newCurrentImage = 
      currentImageOnModal === content.images.length - 1 
        ? 0 
        : currentImageOnModal + 1;

    sliderRef.current.slickGoTo(newCurrentImage);
  };

  const handleClickOnPrev = () => {
    const newCurrentImage = 
      currentImageOnModal === 0 
        ? content.images.length - 1 
        : currentImageOnModal - 1;

    sliderRef.current.slickGoTo(newCurrentImage);
  };

  const handleClickOnDot = (index) => {
    setCurrentImageOnModal(index);
    sliderRef.current.slickGoTo(index);
  };

  const imagesGalleryStyle = {
    background: content.background_color,
    paddingTop: content.padding_top + "px",
    paddingBottom: content.padding_bottom + "px",
    color: content.text_color
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentImageOnModal
  };

  return (
    <S.ImagesGalleryContainer className={className} style={imagesGalleryStyle}>
      {headerActionsTemplate}
      <S.ImagesContainer className="element-images-gallery__images-container">
        {content.images.map((image, i) =>
            <S.ImageContainer
              className="element-images-gallery__image-container"
              numberOfImages={content.images.length} 
              key={i}
            >
                <img
                  onError={e => {
                    e.target.classList.add('broken-img-absolute');
                    e.target.style.position = "relative"
                  }}
                  onLoad={e => {
                    e.target.classList.remove('broken-img-absolute');

                  }}
                  // className="broken-img-absolute"
                  src={getImageUrl(image.src)} 
                  alt={image.caption}
                  // onError={e => e.target.style.position = "relative"}
                  onClick={() => handleClickOnImage(i)}
                />
              <span>{image.caption}</span>
            </S.ImageContainer>
          )}
      </S.ImagesContainer>
        {isModalActivated.includes(true) && 
          <S.Modal>
            <div>
              <S.ArrowButton onClick={handleClickOnPrev}>
                <S.ArrowLeft />
              </S.ArrowButton>
              <Slider 
                ref={sliderRef} 
                beforeChange={(currentSlide, nextSlide) => setCurrentImageOnModal(nextSlide)}
                {...sliderSettings}
              >
                {content.images.map((image, index) =>
                  <div key={index}>
                    <S.ModalImage src={getImageUrl(image.src)} alt="" />
                    {image.caption && <S.Caption>{image.caption}</S.Caption>}
                  </div>
                )}
              </Slider>
              <S.Dots>
                {content.images.map((image, index) => 
                  <S.Dot 
                    onClick={() => handleClickOnDot(index)} 
                    isSelected={index === currentImageOnModal}
                    key={index}
                  />
                )}
              </S.Dots>
              <S.ArrowButton onClick={handleClickOnNext}>
                <S.ArrowRight />
              </S.ArrowButton>
              <S.Close onClick={handleCloseModal}>&times;</S.Close>
            </div>
          </S.Modal>
        }
      {footerActionsTemplate}
    </S.ImagesGalleryContainer>
  )
};

export default ElementImagesGallery;