import React, { Component } from 'react'
import './video-interactive.css';

export default class VideoInteractive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "width": 0,
      "height": 0
    }

    // this.getElementHeader = this.getElementHeader.bind(this);
  }

  componentDidMount() {
    var self = this;
    var vi = document.querySelector('[data-index="'+self.props.index+'"]')
    var interval = setInterval(function() {
      if (vi.videoWidth > 0) {
        self.setState({
          "width": vi.videoWidth,
          "height": vi.videoHeight
        });
        clearInterval(interval);
      }
    }, 1000);
  }

  render() {
    var style = {
      "width": this.state.width,
      "height": this.state.height
    };
    return (
      <div className="rdp-element-video-interactive">
        {this.props.children}
        <video data-index={this.props.index} controls src={this.props.element.url}></video>
        <div className="rdp-element-video-interactive-layer" style={style}></div>
      </div>
    );
  }
}
