var messagesService = function () {
  this.getObjectMessages = function (objectUid) {
    return new Promise(function (resolve, reject) {
      fetch(`https://rdpparseplataformapi.azurewebsites.net/rdpcomposer/objects/messages/${objectUid}`, {
        method: "GET",
        headers: {
          // "session_token": AuthenticationService.getUser() && AuthenticationService.getUser().session_token,
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        resolve(data)
      }, function (error) {
        reject(error)
      });
    })
  }

  this.postObjectMessage = function (model) {
    return new Promise(function (resolve, reject) {
      fetch(`https://rdpparseplataformapi.azurewebsites.net/rdpcomposer/objects/messages`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "user_uid": model.user_uid,
          "composition_uid": model.composition_uid,
          "object_uid": model.object_uid,
          "message": model.message
        })
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        resolve(data)
      }, function (error) {
        reject(error)
      });
    })
  }

  this.putObjectMessage = function (id, model) {
    return new Promise(function (resolve, reject) {
      fetch(`https://rdpparseplataformapi.azurewebsites.net/rdpcomposer/objects/messages/` + id, {
        method: "PUT",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "message": model.message
        })
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        resolve(data)
      }, function (error) {
        reject(error)
      });
    })
  }

  this.deleteObjectMessage = function (id) {
    return new Promise(function (resolve, reject) {
      fetch(`https://rdpparseplataformapi.azurewebsites.net/rdpcomposer/objects/messages/` + id, {
        method: "DELETE",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        resolve(data)
      }, function (error) {
        reject(error)
      });
    })
  }

  return this;
}

var MessagesService = new messagesService();

export {
  MessagesService
};