import styled from "styled-components";

export const ElementQuizContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;

  /*SM*/
  &.max-w-sm .rdp-element-quiz__quiz-card  {
    max-width: 760px;
  }
  /*MD*/
  &.max-w-md .rdp-element-quiz__quiz-card  {
    max-width: 1160px;
  }
  /*LG*/
  &.max-w-lg .rdp-element-quiz__quiz-card  {
    max-width: 1600px;
  }
  /*FULL*/
  &.max-w-full .rdp-element-quiz__quiz-card  {
    max-width: calc(100% - 60px);
  }
  /*endregion*/
`;