import styled, {css} from 'styled-components';

export const Spot = styled.button`
  border-radius: 100%;
  position: absolute;
  top: calc(${props => `${props.posY}%`} - 21px);
  left: calc(${props => `${props.posX}%`} - 21px);
  background: white;
  border: 2px solid transparent;
  box-shadow: 1px 1px 1px 1px rgba(204, 204, 204, 0.47);
  margin: 4px;
  cursor: pointer;
  outline: 8px solid rgba(255, 255, 255, 0.56) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  animation: pulse 3s ease-in-out infinite;
  color: ${props => props.color || "#2d7b45"};
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;

  @media only screen and (max-width: 700px) {
    transform: scale(0.7);
  }

  @media only screen and (max-width: 600px) {
    transform: scale(0.6);
  }

  @media only screen and (max-width: 400px) {
    transform: scale(0.4);
  }

  @media only screen and (max-width: 300px) {
    transform: scale(0.3);
  }

  &:hover {
    border: 2px solid ${props => props.color || "#2d7b45"};
  }

  @keyframes pulse {
    0% {
      outline-offset: 4px;
    }
    50% {
      outline-offset: 16px;
    }
    100% {
      outline-offset: 4px;
    }
  }

  &:before {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    content: '';
  }

  &:focus, &.expanded {
    background: ${props => props.color || "#2d7b45"};
    color: white;
    animation: none;
    outline: none !important;
    border-color: white;

    &:hover {
      border: 2px solid #bbb;
    }

    .hotspot-remove-btn, .hotspot-cancel-btn {
      opacity: 1;
      top: -30px;
      pointer-events: auto;
      height: 28px;
      width: 28px;
      ${props => !props.flipped && css`
        left: -30px;
      `}
      ${props => props.flipped && css`
        right: -30px;
        left: auto;
      `}
    }

    .hotspot-move-btn, .hotspot-confirm-btn {
      opacity: 1;
      top: 30px;
      
      pointer-events: auto;
      height: 28px;
      width: 28px;
      ${props => !props.flipped && css`
        left: -30px;
      `}
      ${props => props.flipped && css`
        right: -30px;
        left: auto;
      `}
    }
  }

  ${props => props.isMoving && css`
    &:focus, &.expanded {
      opacity: 0.8;
    }
  `}

  ${props => !props.isMoving && css`
    &:focus, &.expanded {
      &::before {
        content: "";
        position: absolute;
        top: 7px;
        left: ${props => props.flipped ? "-20px" : "40px"};
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid white;
        z-index: 2;
        opacity: 1;
        ${props => props.flipped && css`
          transform: rotate(180deg);
        `}
      }

      @media only screen and (max-width: 700px) {
        &::before {
          left: ${props => props.flipped ? "-36px" : "55px"};
        }
      }

      @media only screen and (max-width: 600px) {
        &::before {
          left: ${props => props.flipped ? "-45px" : "63px"};
        }
      }
      
      .hotspot-tooltip {
        opacity: 1;
        height: auto;
      }
    }
  `}
`;

export const Tooltip = styled.div`
  pointer-events: inherit;
  opacity: 0;
  display: none;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  width: auto;
  height: 0;
  background: white;
  top: calc(${props => `${props.posY}%`} - 40px);
  ${props => props.flippedY && css`
    top: calc(${props => `${props.posY}%`} - 140px);
  `}
  left: calc(${props => `${props.posX}%`} + 35px);
  ${props => props.flippedX && css`
    left: calc(${props => `${props.posX}%`} - 335px);
  `}
  bottom: 0;
  min-height: 100px;
  max-width: 300px;
  max-height: 400px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 14px 3px 14px 1px rgba(0, 0, 0, 0.47);
  ${props => props.flippedX && css`
    box-shadow: -14px 3px 14px 1px rgba(0, 0, 0, 0.47);
  `}
  color: black;
  z-index: 1;
  padding: 20px;
  cursor: initial;
  
  ${props => props.expanded === "true" && css`
    opacity: 1;
    height: auto;
    overflow: auto;
    display: initial;

    @media only screen and (max-width: 468px) {
      top: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      right: 0;
      width: 100%;
      bottom: 0;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
      max-width: 100%;
    }
  `}
`;

export const TooltipHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  
  p {
    font-weight: bold;
  }
`;

export const NavigationButton = styled.span`
  cursor: pointer;
  pointer-events: auto;
  background: transparent;
  font-weight: bold;
  border-radius: 4px;
  border: none;
  padding: 2px;
  margin: 2px;

  @media only screen and (max-width: 468px) {
    display: none;
  }

  &:hover {
    background: rgba(187, 187, 187, 0.1);
    color: ${props => props.color || "#2d7b45"};
  }
`;

export const CloseButton = styled.span`
  display: none;
  cursor: pointer;
  pointer-events: auto;
  background: transparent;
  font-weight: bold;
  border-radius: 4px;
  border: 0;
  padding: 2px;

  @media only screen and (max-width: 468px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &:hover {
    background: rgba(187, 187, 187, 0.1);
    color: ${props => props.color || "#2d7b45"};
  }
`;

export const TooltipImage = styled.img`
  width: 100%;
  height: auto;
  margin: 10px 0;
`;

export const TooltipAudio = styled.audio`
  width: 100%;
  margin: 10px 0;
`;

export const TooltipDescription = styled.div`
  margin-top: 10px;
  text-align: justify;
`;

export const RemoveButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  background: #5d5d5d;
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  width: 0;
  overflow: hidden;
  color: white;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
`;

export const CancelButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  background: #5d5d5d;
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  width: 0;
  overflow: hidden;
  color: white;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
`;

export const ConfirmButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  background: #5d5d5d;
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  overflow: hidden;
  color: white;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
  pointer-events: none;
`;

export const MoveButton = styled.div`
  position: absolute;
  cursor: grab;
  top: 0;
  left: 0;
  background: #5d5d5d;
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  overflow: hidden;
  color: white;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
  pointer-events: none;
  
  &:active {
    cursor: grabbing;
  }
`;