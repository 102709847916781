import styled from "styled-components";

export const Root = styled.div`
  background-color: ${props => props.backgroundColor || "#fff"};
  padding-top: ${props => props.paddingTop || "0"}px;
  padding-bottom: ${props => props.paddingBottom || "0"}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &.max-w-sm {
    .element-quote-6-grid, 
    .element-quote-6-controls {
      max-width: 760px;
    }
    
    .element-quote-6-grid {
      grid-template-columns: 50px calc(760px - 100px) 50px;
    }
    
    .element-quote-6-carrousel,
    .element-quote-6-slide,
    .element-quote-6-slide-quote {
      max-width: 100%;
    }
  }

  &.max-w-md {
    .element-quote-6-grid,
    .element-quote-6-controls {
      max-width: 1160px;
    }

    .element-quote-6-grid {
      grid-template-columns: 50px calc(1160px - 100px) 50px;
    }

    .element-quote-6-carrousel,
    .element-quote-6-slide,
    .element-quote-6-slide-quote {
      max-width: 100%;
    }
  }

  &.max-w-lg {
    .element-quote-6-grid,
    .element-quote-6-controls {
      max-width: 1600px;
    }

    .element-quote-6-grid {
      grid-template-columns: 50px calc(100% - 100px) 50px;
    }

    .element-quote-6-carrousel,
    .element-quote-6-slide,
    .element-quote-6-slide-quote {
      max-width: 100%;
    }
  }

  &.max-w-full {
    .element-quote-6-grid,
    .element-quote-6-controls {
      max-width: calc(100% - 60px);
    }

    .element-quote-6-grid {
      grid-template-columns: 50px calc(100% - 100px) 50px;
    }

    .element-quote-6-carrousel,
    .element-quote-6-slide,
    .element-quote-6-slide-quote {
      max-width: 100%;
    }
  }
`;

export const Carousel = styled.div`
  text-align: center;
  overflow: hidden;
  position: relative;
  pointer-events: none;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Slides = styled.ul`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
  
  @media (max-width: 800px) {
    pointer-events: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
`;

export const Slide = styled.li`
  scroll-snap-align: start;
  flex-shrink: 0;
  margin-right: 50px;
  width: 100%;
  border-radius: 10px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Quote = styled.div`
  width: 100%;
  display: block;
  padding: 50px 0;
  text-align: center;
  border-top: 1px solid #e9eaeb;
  border-bottom: 1px solid #e9eaeb;
  margin: 30px auto;
  
  &.mobile,
  &.tablet {
    padding: 30px;
  }
`;

export const Avatar = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 500px;
  overflow: hidden;
  display: block;
  margin: auto;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.4);
`;

export const AvatarImage = styled.img`
  margin-left: 50%;
  margin-top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const AuthorInfo = styled.div`
  display: block;
  margin-top: 10px;
`;

export const AuthorName = styled.div`
  color: ${props => props.fontColor || "#000"};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  display: inline-block;
  vertical-align: middle;
`;

export const TextContainer = styled.div`
  position: relative;
  
  .element-quote-6-text {
    color: #fff;
    margin-top: 30px;
    width: 100%;
    display: block;

    @media (max-width: 600px) {
      padding-left: 5px;
      padding-right: 5px;
    }

    .ql-editor {
      padding: 0;
      line-height: inherit;
      overflow: hidden;
    }

    .ql-editor p:before,
    .ql-editor p:after {
      content: '"';
      color: #313537;
      font-size: 16px;
    }

    .ql-editor * {
      line-height: 150%;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 50px calc(760px - 100px) 50px;
  align-items: center;
`;

export const ControlsLeft = styled.div`
  color: black;
  width: 50px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ControlsLeftArrow = styled.svg``;

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Page = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  pointer-events: auto;
  border-radius: 100%;
  background-color: ${props => props.primaryColor || "#2d7b45"};
  color: white;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  opacity: 0.5;
  
  &.active {
    opacity: 1;
  }
`;

export const ControlsRight = styled.div`
  color: black;
  width: 50px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ControlsRightArrow = styled.svg``;