import useIsChoiceSelected from "./useIsChoiceSelected";

function useQuestionIsCorrect(question, activeChoices) {
  const {choices = []} = question;
  let totalCorrects = 0;
  let correctsAnswered = 0;

  if (choices.length) {
    choices.forEach(choice => {
      if ("correct" in choice && choice.correct === true) {
        const selected = useIsChoiceSelected(activeChoices, choice, question);
        totalCorrects++;
        if (selected) {
          correctsAnswered++;
        }
      } else if (!("correct" in choice)) {
        const selected = useIsChoiceSelected(activeChoices, choice, question);
        if (selected) {
          totalCorrects++;
          correctsAnswered++;
        }
      }
    });

    // Questionários do tipo 1 e 5 possuem o activeChoices do tipo string
    // Questionários do tipo 2 possuem o activeChoices do tipo array pois podem conter mais de uma resposta correta
    return (totalCorrects > 0 && totalCorrects === correctsAnswered)
      && (typeof activeChoices[question.id] === "string" || correctsAnswered === activeChoices[question.id].length);
  } else {
    // Caso possua apenas o texto da pergunta
    return true;
  }
}

export default useQuestionIsCorrect;