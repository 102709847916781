import styled from "styled-components";

export const QuizQuestionFeedback = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  transition: all 0.3s ease-out;
  width: 100%;
`;

export const ConfirmButton = styled.button`
  transition: all 0.3s ease-out;
  display: block;
  margin: 0 auto;
  background: #2d7b45;
  color: #fff;
  border: 0;
  padding: 8px 30px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
`;

export const EditButton = styled.button`
  margin: 20px 10px;
  transition: all 0.3s ease-out;
  display: block;
  background: #2d7b45;
  color: #fff;
  border: 0;
  padding: 8px 30px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
`;

export const QuizQuestionFeedbackMessage = styled.div`
  transition: all 0.3s ease-out;
  text-align: center;
  line-height: 1.42;

  p {
    margin: 10px auto;
    font-weight: 500;
    color: #333;
  }
`;