import uuidv1 from "uuid/v1";
import replaceObjectKeyValue from "./replaceObjectKeyValue";

export default function replaceCompositionObjectsIds(obj) {
  const replacedValues = [];

  const replaced = replaceObjectKeyValue(obj, /^id$/, (key, value) => {
    const uuid = uuidv1();
    replacedValues.push({
      original: value,
      replaced: uuid,
    });
    return uuid;
  });

  // Garante que propriedade que façam referência aos valores substituídos sejam atualizadas
  // Exemplo: "sorted-activity-1"
  obj = replaceObjectKeyValue(replaced, /^(?!.*(id)).*/, (key, value) => {
    const replacedValue = replacedValues.find(replacedValue => replacedValue.original === value);
    if (replacedValue) {
      return replacedValue.replaced;
    }
    return value;
  });

  return obj;
}