import React from "react";
import {Quill} from "../index";
import * as S from "./quiz-question-quill.styled";

function QuizQuestionQuill(props) {
  const {
    editable = false,
    question,
    onEditQuestionText = (question, text) => {
    },
  } = props;

  return (
    <S.QuizQuestionQuill>
      {
        editable ? (
          <Quill
            key={question.id}
            value={question.question}
            onChange={(text) => onEditQuestionText(question, text)}
          />
        ) : (
          <>
            <div className="ql-editor" dangerouslySetInnerHTML={{__html: question.question}}></div>
          </>
        )
      }
    </S.QuizQuestionQuill>
  );
}

export default QuizQuestionQuill;