import React, { Component } from 'react';
import './element-statement-1-properties.css';
import { ColorPicker, FontPicker } from '../../components';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

export default class ElementStatement1Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content
    }

    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.onBorderColorChange = this.onBorderColorChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
  }

  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange(color) {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBorderColorChange(color) {
    var content = this.state.content;
    content.border_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onFontChange(font) {
    var content = this.state.content;
    content.font_family = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  renderFont() {
    var content = this.state.content;
    var labels = this.props.labels;

    if (content.font_family) {
      return <FontPicker font={content.font_family} onFontChange={(font) => this.onFontChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontChange(font)} noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;

    return (<div className="rdp-element-text-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className="rdp-composer-element-edit-tabs-item active">{labels.elements.common_settings.label_tab_settings}</li>
      </ul>
      <div className="rdp-composer-element-edit-body">
      <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_statement_1.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)} defaultValue={content.content_title}></input>
        
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_statement_1.settings_label_background_color}</span>
        <ColorPicker labels={this.props.labels} color={content.background_color} onChange={this.onBackgroundColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_statement_1.settings_label_border_color}</span>
        <ColorPicker labels={this.props.labels} color={content.border_color} onChange={this.onBorderColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_statement_1.settings_label_margin_top}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_statement_1.settings_label_margin_bottom}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_statement_1.settings_label_label_font}:</span>
        {this.renderFont()}

        <PreferenceRequiredInteraction
          title={labels.elements.settings.preference_required_interaction.title}
          label={labels.elements.settings.preference_required_interaction.label}
          labels={labels}
          content={content}
          onContentChange={c => {
            this.setState({ content: c });
            this.props.onContentChange(c);
          }}
        />
      </div>
    </div>);
  }
}
