import styled, {css} from "styled-components";

export const ElementSortedActivity1 = styled.div`
  padding-top: ${props => props.paddingTop || "30"}px;
  padding-bottom: ${props => props.paddingBottom || "30"}px;
  overflow: hidden;
  position: relative;
  background-color: ${props => props.backgroundColor || "transparent"};
`;

export const Container = styled.div`
  max-width: 750px;
  margin: 0 auto;
`;

export const ItemCardList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: 200px;
`;

export const ItemCard = styled.li`
  position: absolute;
  padding: 20px;
  left: calc(50% - 150px);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  background-color: ${props => props.backgroundColor || "#FFFFFF"};
  border: 1px solid #ededed;
  color: ${props => props.fontColor || "#000"};
  width: 300px;
  height: 200px;
  border-top: 3px solid ${props => props.borderColor || "#00643e"};
  cursor: ${props => props.cursorType || "grab"};
  pointer-events: ${props => props.draggable !== "false" ? "all" : "none"}!important;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  
  p {
    max-height: 130px;
    overflow: hidden;
  }

  &[draggable=true]:after {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 12px;
    left: calc(50% - 12px);
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZmlsbD0iIzAwMDAwMCIgZD0iTTMsMTVWMTNINVYxNUgzTTMsMTFWOUg1VjExSDNNNywxNVYxM0g5VjE1SDdNNywxMVY5SDlWMTFIN00xMSwxNVYxM0gxM1YxNUgxMU0xMSwxMVY5SDEzVjExSDExTTE1LDE1VjEzSDE3VjE1SDE1TTE1LDExVjlIMTdWMTFIMTVNMTksMTVWMTNIMjFWMTVIMTlNMTksMTFWOUgyMVYxMUgxOVoiIC8+Cjwvc3ZnPg==");
  }
  
  ${props => props.isCorrect === false && css`
    opacity: 0;
    animation: shake 0.5s, disappear 0s;
    animation-delay: 0s, 0.5s;
  `}

  ${props => props.isCorrect === true && css`
    opacity: 0;
    transform: scale(0.8);
    animation: correct 1s, slide 0.5s;
    animation-delay: 0s, 1s;
    
    &::after {
      content: "";
      width: 34px;
      height: 34px;
      position: absolute;
      animation: grow 1s;
      top: -12px;
      left: -12px;
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTAsMTdMNSwxMkw2LjQxLDEwLjU4TDEwLDE0LjE3TDE3LjU5LDYuNThMMTksOE0xMiwyQTEwLDEwIDAgMCwwIDIsMTJBMTAsMTAgMCAwLDAgMTIsMjJBMTAsMTAgMCAwLDAgMjIsMTJBMTAsMTAgMCAwLDAgMTIsMloiIC8+Cjwvc3ZnPg==");
    }
  `}
  
  @keyframes grow {
    0% {
      width: 0;
      height: 0;
      top: 12px;
      left: 12px;
    }
    100% {
      width: 34px;
      height: 34px;
      top: -12px;
      left: -12px;
    }
  };

  @keyframes slide {
    0% {
      opacity: 1;
      top: 0;
    }
    100% {
      opacity: 0;
      top: 200px;
    }
  };
  
  @keyframes correct {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(0.8);
    }
  };
  
  @keyframes shake {
    0% {
      opacity: 1;
      transform: translate(10px, 0);
    }
    25% {
      opacity: 1;
      transform: translate(-10px, 0);
    }
    50% {
      opacity: 1;
      transform: translate(10px, 0);
    }
    75% {
      opacity: 1;
      transform: translate(-10px, 0);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  };

  @keyframes disappear {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  };
`;

export const DropPileList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  min-height: 220px;
  gap: 30px;
  margin-top: 30px;
`;

export const DropPileItemCardList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 300px;
  height: 200px;
  pointer-events: none;
  background: ${props => props.backgroundColor || "#e1e1e1"};
  color: ${props => props.color || "#000"};
  border: 1px dashed ${props => props.borderColor || "#00643e"};
  box-shadow: inset 0 2px 5px rgb(0 0 0 / 60%);
`;

export const DropPile = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 320px;
  height: 220px;
  overflow: hidden;
  padding: 20px;
  
  p {
    margin: 10px;
  }
`;

export const ResultItem = styled.li`
  position: absolute;
  left: calc(50% - 150px);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 200px;
`;

export const ResultPlacar = styled.p`
  font-weight: bold;
  font-size: 1.3em;
`;

export const ReloadButton = styled.button`
  border: none;
  background: none;
  height: auto;
  width: 100px;
  margin: 20px;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #bbb;
  padding-top: 20px;
  text-transform: uppercase;
`;