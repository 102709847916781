import $ from "jquery";
import jwtDecode from 'jwt-decode';
import RDP_CONFIG from '../config';

var userService = function() {
  this.getUserDecoded = function() {
    if (window.localStorage.getItem("rdp-composer-user-data") !== null) {
      return jwtDecode(JSON.parse(window.localStorage.getItem("rdp-composer-user-data")).access_token);
    } else {
      return null;
    }
  }

  this.getUser = function() {
    return JSON.parse(window.localStorage.getItem("rdp-composer-user-data"));
  }

  this.setUser = function(data) {
    window.localStorage.setItem("rdp-composer-user-data", JSON.stringify(data));
  }

  this.setUuid = function(uuid) {
    window.localStorage.setItem("rdp-composer-user-uuid", uuid);
  }

  this.getUUid = function(uuid) {
    return window.localStorage.getItem("rdp-composer-user-uuid");
  }

  this.setUserInfo = function(data) {
    window.localStorage.setItem("rdp-composer-user-info", JSON.stringify(data));  
  }

  this.getUserInfo = function(data) {
    return JSON.parse(window.localStorage.getItem("rdp-composer-user-info"));
  }

  this.getProjects = function() {
    return JSON.parse(window.localStorage.getItem("rdp-composer-projects"));
  }

  this.setProjects = function(data) {
    window.localStorage.setItem("rdp-composer-projects", JSON.stringify(data));
  }

  this.getActiveProject = function() {
    return JSON.parse(window.localStorage.getItem("rdp-composer-active-project"));
  }

  this.getUserPermissions = function() {
    return JSON.parse(window.localStorage.getItem("rdp-composer-permissions"));
  }

  this.setUserPermissions = async function(project, accessToken) {
    const ComposerConnectorUid = project.connectors.find(connector => connector.type === "Composer_1").uid;
    var Permissions = await $.ajax({
      url: `https://${RDP_CONFIG.domain}/storage/${RDP_CONFIG.apiVersion}/bridge/connector/uid/${ComposerConnectorUid}/restrictions/user`,
      type: "GET",
      dataType: "json",
      headers: {
        "Authorization": "Bearer " + accessToken,
        "Project-Key": RDP_CONFIG.projectKey,
        "Api-Env": RDP_CONFIG.apiEnv
      }
    });

    window.localStorage.setItem("rdp-composer-permissions", JSON.stringify(Permissions));
  }

  this.setActiveProjectLocal = function(data, accessToken) {
    window.localStorage.setItem("rdp-composer-active-project", JSON.stringify(data));
    this.setUserPermissions(data, accessToken);
  }

  this.loadUserInfo = async function(uid, type, accessToken) {
    var _this = this;
    var data = await new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/${RDP_CONFIG.apiVersion}/user/me`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": RDP_CONFIG.projectKey,
          "Api-Env": RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
      
        resolve(data)
      }, function(error) {
        reject(error)
      });
    });

    _this.setUserInfo(data);
  }

  this.loadProjects = async function(uid, type, accessToken) {
    var _this = this;
    var data = await new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/${RDP_CONFIG.apiVersion}/projects`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": RDP_CONFIG.projectKey,
          "Api-Env": RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
      
        resolve(data)
      }, function(error) {
        reject(error)
      });
    });

    _this.setProjects(data);
  }

  this.setActiveProject = function(uid, type, accessToken) {
    var _this = this;
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/${RDP_CONFIG.apiVersion}/project/uid/${uid}`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": RDP_CONFIG.projectKey,
          "Api-Env": RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
        _this.setActiveProjectLocal(data, accessToken);
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getProjectUsers = function(accessToken) {
    var currentProject = this.getActiveProject();
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/${RDP_CONFIG.apiVersion}/project/uid/${currentProject.uid}/users`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Project-Key": RDP_CONFIG.projectKey,
          "Api-Env": RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  return this;
}

var UserService = new userService();

export {
  UserService
};
