import styled from "styled-components";

export const QuizCard = styled.div`
  transition: all 0.3s ease-out;
  width: 100%;
  margin: auto;
  position: relative;
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: 0 0.4rem 1.2rem 0.2rem rgba(0, 0, 0, 0.05);
  padding: 35px 35px 58px 35px;
`;