import React from 'react';
import InputGroup from "../element-input-group/input-group";
import QuizHeader from "../element-quiz-header/quiz-header";
import QuizChoiceQuill from "../element-quiz-choice-quill/quiz-choice-quill";
import QuizImage from "../element-quiz-image/quiz-image";
import QuizVideo from "../element-quiz-video/quiz-video";
import QuizQuestionQuill from "../element-quiz-question-quill/quiz-question-quill";
import QuizQuestionConfirmFeedback from "../element-quiz-question-confirm-feedback/quiz-question-confirm-feedback";
import QuizTextarea from "../element-quiz-textarea/quiz-textarea";
import QuizTextInput from "../element-quiz-text-input/quiz-text-input";
import QuizMatchLine from "../element-quiz-match-line/quiz-match-line";


function ElementQuizQuestion(props) {
  const {
    questionNumber,
    questionTotal,
    labels,
    activeChoices,
    editable,
    content,
    data,
    answered = false,
    onAnswering = (questionId, choice) => {
    },
    onConfirmAnswer = (questionId) => {
    },
    onResetAnswer = (questionId) => {
    },
    onChange = (content) => {
    },
  } = props;

  const updateQuestionTextAndNotify = (questionChanged, text) => {
    content.questions = content.questions.map(question => {
      if (question.id === questionChanged.id) {
        question.question = text;
      }
      return question;
    });

    try {
      onChange(content);
    } catch (e) {
      console.error(e);
    }
  }

  const notifyChoiceSelection = (question, choice) => {
    if (onAnswering) {
      try {
        onAnswering(question.id, choice);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const updateQuestionChoiceTextAndNotify = (question, choice, text) => {
    content.questions = content.questions.map(qtion => {
      if (qtion.id === question.id)
        qtion.choices = qtion.choices.map(ch => {
          if (ch.id === choice.id) {
            ch.text = text;
          }
          return ch;
        })
      return qtion;
    });

    try {
      onChange(content);
    } catch (e) {
      console.error(e);
    }
  }

  const notifyAnswerConfirmation = (idQuestion) => {
    if (onConfirmAnswer) {
      try {
        onConfirmAnswer(idQuestion);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const notifyAnswerReset = (idQuestion) => {
    if (onResetAnswer) {
      try {
        onResetAnswer(idQuestion);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const renderAsnwer = (type) => {
    const choices = data.choices || [];

    switch (type) {
      case "quiz-1":
      case "quiz-2":
        return (
          <InputGroup type="radiogroup">
            {choices.map((choice, index) => (
              <QuizChoiceQuill
                key={index}
                disabled={answered}
                type={data.type}
                content={content}
                question={data}
                choice={choice}
                editable={editable}
                activeChoices={activeChoices}
                onChoiceClick={() => notifyChoiceSelection(data, choice)}
                onEditChoiceText={(text) => updateQuestionChoiceTextAndNotify(data, choice, text)}
              />
            ))}
          </InputGroup>
        );

      case "quiz-3":
        return (
          <QuizTextInput
            value={activeChoices[data.id] || ""}
            primaryColor={content.primary_color}
            onChange={(text) => notifyChoiceSelection(data, text)}
            disabled={answered}
          />
        )

      case "quiz-4":
        return (
          <QuizMatchLine
            primaryColor={content.primary_color}
            data={choices}
            disabled={answered}
            onChange={(matches) => notifyChoiceSelection(data, matches)}
          />
        )

      case "quiz-5":
        const {primary_color} = content;

        return (
          <QuizTextarea
            defaultValue={activeChoices[data.id] || ""}
            primaryColor={primary_color}
            onChange={(text) => notifyChoiceSelection(data, text)}
            disabled={answered}
          />
        )
      default:
        return null;
    }
  }

  if (data) {
    const {primary_color,} = content;
    const image = data.image || null;
    const video = data.video || null;
    const media_max_width = data.media_max_width || null;


    return (
      <>
        {data && (
          <>
            <QuizHeader
              primaryColor={primary_color}
              current={questionNumber}
              total={questionTotal}
              labels={labels}
            />

            <QuizQuestionQuill
              editable={editable}
              question={data}
              onEditQuestionText={updateQuestionTextAndNotify}
            />

            {(image && <QuizImage data={image} mediaMaxWidth={media_max_width}/>)
              || (video && <QuizVideo data={video} mediaMaxWidth={media_max_width}/>)
            }

            {renderAsnwer(data.type)}

            <QuizQuestionConfirmFeedback
              question={data}
              answered={answered}
              content={content}
              labels={labels}
              activeChoices={activeChoices}
              onClickConfirm={notifyAnswerConfirmation}
              onClickEdit={notifyAnswerReset}
            />
          </>
        )}
      </>
    )
  }

  return null;
}

export default ElementQuizQuestion;
