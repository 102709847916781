import React, { Component } from 'react';
import { Quill } from '../../components';
import {UserService} from '../../services/user';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import 'animate.css/animate.min.css';
import './element-image-and-text.css';

export default class ElementImageAndText extends Component {
  constructor(props) {
    super(props);
    this.zoomImgRef = React.createRef();

    this.state = {
      "zoom_enabled": false,
      "inner_width": window.innerWidth,
      "inner_height": window.innerHeight - 60,
      "img_zoom_width": "100%",
      "img_zoom_height": "auto"
    }

    this.handleChange = this.handleChange.bind(this);
    this.onResizeWindow = this.onResizeWindow.bind(this);
    this.refreshZoomOnResize = this.refreshZoomOnResize.bind(this);
  }

  componentDidMount() {
    // this.resizeWebview();
    this.onResizeIframe();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow);
  }

  onResizeIframe() {
    window.addEventListener("resize", this.onResizeWindow);
  }

  onResizeWindow() {
    this.refreshZoomOnResize();
    this.resizeImage();
  }

  resizeImage() {
    // "zoom_enabled": false,
    this.setState({
      "width": window.innerWidth,
      "height": window.innerHeight - 60
    })
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image.search("https://pocs.digitalpages.com.br/") === -1 &&
    image.search("https://images.articulate.com/") === -1 &&
    image.search("https://articulateusercontent.com/") === -1) {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  getImgStyle() {
    var content = this.props.content;
    var style = {
      "width": content.image_width
    };

    if (content.zoom) {
      style.cursor = "zoom-in";
    };

    if (content.image_border_radius) {
      style.borderRadius = content.image_border_radius + "px";
    };

    return style;
  }

  toggleZoomImage() {
    this.setState({
      "zoom_enabled": !this.state.zoom_enabled
    })
  }

  getWrapperStyle() {
    var obj = {
      width: `${this.state.inner_width}px`,
      height: `${this.state.inner_height}px`
    }
    return obj
  }

  getImgDynamicStyle() {
    return {
      "width": this.state.img_zoom_width,
      "height": this.state.img_zoom_height
    }
  }

  onLoad(e) {
    var width = "100%";
    var height = "auto";
    var calcHeight = e.target.naturalHeight / (window.innerHeight - 60);
    var calcWidth = e.target.naturalWidth / window.innerWidth;

    if (calcHeight > calcWidth) {
      width = "auto";
      height = "100%";
    };

    this.setState({
      "img_zoom_width": width,
      "img_zoom_height": height
    });
  }

  renderIconZoomIn() {
    var color = this.props.content.zoom_icons_color || "#000";

    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="640" height="640" viewBox="0 0 640 640">
      <path fill={color} d="M412.8 458.24c-42.889 33.57-97.603 53.832-157.048 53.832-141.385 0-256-114.615-256-256s114.615-256 256-256c141.385 0 256 114.615 256 256 0 59.446-20.262 114.159-54.256 157.611l0.424-0.562 171.2 170.56-45.44 45.44-170.56-170.88zM256 448c106.039 0 192-85.961 192-192s-85.961-192-192-192v0c-106.039 0-192 85.961-192 192s85.961 192 192 192v0zM224 224v-64h64v64h64v64h-64v64h-64v-64h-64v-64h64z"></path>
    </svg>
  }

  renderIconZoomOut() {
    var color = this.props.content.zoom_icons_color || "#000";

    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="640" height="640" viewBox="0 0 640 640">
      <path fill={color} d="M412.8 458.24c-42.889 33.57-97.603 53.832-157.048 53.832-141.385 0-256-114.615-256-256s114.615-256 256-256c141.385 0 256 114.615 256 256 0 59.446-20.262 114.159-54.256 157.611l0.424-0.562 171.2 170.56-45.44 45.44-170.56-170.88zM256 448c106.039 0 192-85.961 192-192s-85.961-192-192-192v0c-106.039 0-192 85.961-192 192s85.961 192 192 192v0zM160 224h192v64h-192v-64z"></path>
    </svg>
  }

  renderIconClose() {
    var color = this.props.content.zoom_icons_color || "#000";

    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="640" height="640" viewBox="0 0 640 640">
      <path fill={color} d="M320 274.752l-226.272-226.272-45.248 45.248 226.272 226.272-226.272 226.272 45.248 45.248 226.272-226.272 226.272 226.272 45.248-45.248-226.272-226.272 226.272-226.272-45.248-45.248-226.272 226.272z"></path>
    </svg>
  }

  refreshZoomOnResize() {
    const {zoom_enabled} = this.state;

    let zoom_enabled_new = zoom_enabled;

    this.setState({
      "zoom_enabled": false
    }, () => {
      if (zoom_enabled_new) {
        this.setState({
          "zoom_enabled": true
        })
      }
    })
  }

  handleTextChange(text) {
    var content = this.props.content;
    content.text = text;
    this.props.onChange(content);
  }

  getImgStyle() {
    var {
      image_width,
      image_position,
      zoom,
      image_border_radius
    } = this.props.content;

    var style = {
      "width": `calc(${image_width} - 20px)`
    };

    if (image_position === "left") {
      style.marginRight = "20px";
    } else {
      style.marginLeft = "20px";
    }

    if (zoom) {
      style.cursor = "zoom-in";
    };

    if (image_border_radius) {
      style.borderRadius = image_border_radius + "px";
    };

    return style;
  }

  getTextStyle() {
    var { image_width, image_position } = this.props.content;
    var style = {};

    if (image_position === "left") {
      style.marginLeft = "20px";
    } else {
      style.marginRight = "20px";
    }

    var width = style.width = 100 - parseInt(image_width.split("%")[0]);
    style.width = `calc(${width}% - 20px)`;

    return style;
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  getLegendStyle() {
    const { content } = this.props;
    const { legend_color = 'white', image_width, image_position } = content;

    const positionStyle = image_position === "right" ? { right: 0 } : { left: 0 };
    const widthCalc = `calc(${image_width} - 20px)`;

    return {
      position: "absolute",
      bottom: 0,
      color: legend_color,
      padding: "5px",
      fontSize: "12px",
      width: widthCalc,
      textAlign: "center",
      ...positionStyle,
    };
  }


  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;
    var containerStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }

    return <div className={`${className} ${this.getFont(content.font_family) || ""}`} style={containerStyle}>
      {headerActionsTemplate}

      {content.image_position === "left" &&
        <div className="element-image-and-text-container left">
        <figure
          aria-labelledby={`caption-${content.id}`}
          style={{ position: 'relative' }}
        >
          {content.zoom &&
            <img onClick={() => this.toggleZoomImage()} style={this.getImgStyle()} src={this.getImageUrl(content.image)}/>
          }
          {!content.zoom &&
            <img style={this.getImgStyle()} src={this.getImageUrl(content.image)}/>
          }
          {content.legend && (
            <div style={this.getLegendStyle()}>
              {content.legend}
            </div>
          )}

          <div className="element-image-and-text-container-text" style={this.getTextStyle()}>
            {this.props.editable ? (
              <Quill
                value={content.text}
                onChange={(text) => this.handleTextChange(text)}
              />
            ) : (
              <div className="editor" dangerouslySetInnerHTML={{__html: content.text}}></div>
            )}
          </div>
          </figure>
        </div>
      }

      {content.image_position === "right" &&
        <div className="element-image-and-text-container right">
        <figure
          aria-labelledby={`caption-${content.id}`}
          style={{ position: 'relative' }}
        >
          <div className="element-image-and-text-container-text" style={this.getTextStyle()}>
            {this.props.editable ? (
              <Quill
                value={content.text}
                onChange={(text) => this.handleTextChange(text)}
              />
            ) : (
              <div className="editor" dangerouslySetInnerHTML={{__html: content.text}}></div>
            )}
          </div>

          {content.zoom &&
            <img onClick={() => this.toggleZoomImage()} style={this.getImgStyle()} src={this.getImageUrl(content.image)}/>
          }
          {!content.zoom &&
            <img style={this.getImgStyle()} src={this.getImageUrl(content.image)}/>
          }
          {content.legend && (
            <div style={this.getLegendStyle()}>
              {content.legend}
            </div>
          )}
          </figure>
        </div>
      }

      {this.state.zoom_enabled &&
        <TransformWrapper
          maxScale={content.zoom_max_level || 3}
          centerZoomedOut={true}
          centerOnInit={true}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div className="element-image-and-text-modal">
                <div className="element-image-and-text-modal-header" style={{background: content.zoom_header_background_color || "#FFFFFF"}}>
                  <div className="element-image-and-text-modal-header-zoom-btns">
                    <span className="element-image-and-text-modal-header-zoom-btn" onClick={() => zoomIn()}>{this.renderIconZoomIn()}</span>
                    <span className="element-image-and-text-modal-header-zoom-btn" onClick={() => zoomOut()}>{this.renderIconZoomOut()}</span>
                  </div>
                  <span className="element-image-and-text-modal-header-close-btn" onClick={() => this.toggleZoomImage()}>{this.renderIconClose()}</span>
                </div>
                <div className="element-image-and-text-modal-body">
                  <TransformComponent wrapperStyle={this.getWrapperStyle()} contentStyle={this.getWrapperStyle()}>
                    <img ref={this.zoomImgRef} style={this.getImgDynamicStyle()} src={this.getImageUrl(content.image)} onLoad={(e) => this.onLoad(e)}/>
                  </TransformComponent>
                </div>
              </div>
            </React.Fragment>
          )}
        </TransformWrapper>
      }
      {footerActionsTemplate}
    </div>
  }
}
