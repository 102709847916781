import React, {Component} from 'react';
import Navigation from "../../components/element-navigation/navigation";
import classNameFn from "../../utils/classNameFn";
import QuizCard from "../../components/element-quiz-card/quiz-card";
import ElementQuizQuestion from "../../components/element-quiz-question/element-quiz-question";
import QuizFinishResult from "../../components/element-quiz-finish-result/quiz-finish-result";
import useQuestionIsCorrect from "../../hooks/useQuestionIsCorrect";
import * as S from "./element-quiz-3.styled";

export default class ElementQuiz3 extends Component {
  state = {
    content: this.props.content,
    contentBkp: this.props.content,
    activeChoices: [],
    answeredQuestionsIds: [],
    currentQuestionIndex: 0,
    showResult: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.content !== prevProps.content) {
      this.sortQuestions();
    }
  };

  getLabels = () => {
    const {
      content: {
        questions = []
      }
    } = this.state;
    const {
      contentLanguage = "pt_br"
    } = this.props;

    const labels = {
      en: {
        confirm: "Confirm",
        question: "Question",
        back: "Back",
        retry: "Try again",
        previous: "Previous",
        next: "Next",
        finish: questions.length > 1 ? "Send answers" : "Send answer",
        incorrect: "Incorrect",
        right: "Right!",
        finish_quiz_message: "You have completed this quiz.",
        edit: "Edit",
        answer_saved: "Answer saved!",
      },
      pt_br: {
        confirm: "Confirmar",
        question: "Questão",
        retry: "Refazer atividade",
        previous: "Anterior",
        next: "Próximo",
        finish: questions.length > 1 ? "Enviar respostas" : "Enviar resposta",
        incorrect: "Incorreto",
        right: "Correto!",
        finish_quiz_message: "Você finalizou este questionário.",
        edit: "Editar resposta",
        answer_saved: "Resposta salva!",
      }
    }

    return labels[contentLanguage] || labels["en"];
  }

  isFinishButtonVisible = () => {
    const {currentQuestionIndex, content} = this.state;
    const {questions} = content;
    let current = currentQuestionIndex + 1;
    let questions_count = questions.length;

    return current === questions_count;
  }

  isFinishButtonDisabled = () => {
    const {content, answeredQuestionsIds} = this.state;
    const {questions} = content;
    let answeredQuestions = questions.filter(q => answeredQuestionsIds.includes(q.id));

    return questions.length !== answeredQuestions.length;
  }

  updateContentAndNotifyChange = (content) => {
    this.setState({
      content
    }, () => {
      this.props.onChange(this.state.content)
    });
  }

  setAnswer = (questionId, choice) => {
    let {activeChoices} = this.state;

    this.setState({activeChoices: {...activeChoices, [questionId]: choice}});
  }

  setAnswerConfirmation = (questionId) => {
    const {answeredQuestionsIds} = this.state;
    this.setState({
      answeredQuestionsIds: [...answeredQuestionsIds, questionId]
    })
  }

  deleteAnswerConfirmation = (questionId) => {
    const {answeredQuestionsIds} = this.state;
    this.setState({
      answeredQuestionsIds: answeredQuestionsIds.filter(id => id !== questionId)
    })
  }

  getContentOnlyWithQuestion = (question) => {
    const {
      content,
    } = this.state;

    let contentCopy = {...content};
    contentCopy.questions = [question];

    return contentCopy;
  }

  goToPreviusQuestion = () => {
    const {currentQuestionIndex} = this.state;
    this.setState({
      currentQuestionIndex: currentQuestionIndex - 1
    });
  }

  goToNextQuestion = () => {
    const {currentQuestionIndex} = this.state;
    this.setState({
      currentQuestionIndex: currentQuestionIndex + 1
    });
  }

  renderContentFor = (currentQuestionIndex) => {
    const {
      editable,
      contentLanguage,
      headerActionsTemplate,
      footerActionsTemplate,
    } = this.props;
    const {
      content,
      showResult,
      activeChoices,
      answeredQuestionsIds,
      grades,
    } = this.state;
    const {
      questions,
      primary_color
    } = content;

    if (showResult) {
      return (
        <QuizFinishResult
          grades={grades}
          labels={this.getLabels()}
          onClickRemake={this.resetState}
          content={content}
          contentLanguage={contentLanguage}
        />
      )
    } else if (questions) {
      let question = questions[currentQuestionIndex];
      question.type = "quiz-3";

      return (
        <>
          <ElementQuizQuestion
            data={question}
            answered={answeredQuestionsIds.includes(question.id)}
            labels={this.getLabels()}
            editable={editable}
            content={this.getContentOnlyWithQuestion(question)}
            questionNumber={currentQuestionIndex}
            questionTotal={questions.length}
            contentLanguage={contentLanguage}
            headerActionsTemplate={headerActionsTemplate}
            footerActionsTemplate={footerActionsTemplate}
            activeChoices={activeChoices}
            onAnswering={this.setAnswer}
            onConfirmAnswer={this.setAnswerConfirmation}
            onResetAnswer={this.deleteAnswerConfirmation}
            onUpdateContent={this.updateContentAndNotifyChange}
          />
          <Navigation
            showFinishButton={this.isFinishButtonVisible()}
            disableFinishButton={this.isFinishButtonDisabled()}
            currentProgress={currentQuestionIndex + 1}
            totalProgress={questions.length}
            primaryColor={primary_color}
            labels={this.getLabels()}
            activeChoices={activeChoices}
            onClickPrevius={this.goToPreviusQuestion}
            onClickNext={this.goToNextQuestion}
            onClickFinish={this.onFinishClick}
          />
        </>
      )
    }

    return null;
  }

  onFinishClick = () => {
    const {content, activeChoices, answeredQuestionsIds} = this.state;
    const {questions} = content;
    let hits = 0;
    let total = questions.length;

    questions
      .forEach(question => {
        if (answeredQuestionsIds.includes(question.id)) {
          if (useQuestionIsCorrect(question, activeChoices)) {
            hits++;
          }
        }
      });

    const resume = {
      rdp_html_content: {
        questions: questions,
        hits: hits,
        total: total,
        percentage_average: (100 / total * hits)
      }
    };

    this.setState({
      grades: resume,
      showResult: true,
    })
  }

  resetState = () => {
    this.setState({
      answered: false,
      grades: null,
      showResult: false,
      currentQuestionIndex: 0,
      activeChoices: [],
      answeredQuestionsIds: [],
    })

    this.sortQuestions(true);
  }

  sortQuestions(isRemake = false) {
    const contentCopy = { ...this.props.content };
    var splitQuestions = contentCopy.number_of_questions_to_display !== undefined && contentCopy.number_of_questions_to_display > 0;
    var randomizeOnRemake = contentCopy.random_on_remake !== undefined && contentCopy.random_on_remake.enabled === true;
    var sortQuestions = contentCopy.random_question_order !== undefined && contentCopy.random_question_order.enabled === true;

    if ((randomizeOnRemake && isRemake) || !isRemake) {
      const backup = this.props.content.questions;
      var newQuestions = this.props.content.questions;

      if (sortQuestions) {
        newQuestions.sort((a, b) => {
          return Math.floor(Math.random() * (1 - -1)) + -1;
        });
      }

      if (splitQuestions) {
        newQuestions = newQuestions.slice(0, contentCopy.number_of_questions_to_display);
      }

      contentCopy.questions = newQuestions;

      this.props.content.questions = backup;
      this.setState({
        content: contentCopy
      })
    }

    return contentCopy;
  }

  getFont = (font) => {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    const {
      headerActionsTemplate,
      footerActionsTemplate,
      content,
      className
    } = this.props;
    const {
      currentQuestionIndex,
    } = this.state;

    return (
      <S.ElementQuizContainer
        className={classNameFn([
          className,
          this.getFont(content.font_family) || ""
        ])}
        style={{
          background: content.background_color,
          paddingTop: content.padding_top + "px",
          paddingBottom: content.padding_bottom + "px"
        }}
      >
        {headerActionsTemplate}

        <QuizCard>
          {this.renderContentFor(currentQuestionIndex)}
        </QuizCard>

        {footerActionsTemplate}
      </S.ElementQuizContainer>
    )
  }

}
