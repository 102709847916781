import builder from 'xmlbuilder'

const xmlBuilder = function(composition) {

  console.log(composition)

  const xml = builder.create('manifest')
    .att('xmlns', 'http://www.imsproject.org/xsd/imscp_rootv1p1p2')
    .att('xmlns:adlcp', 'http://www.adlnet.org/xsd/adlcp_rootv1p2')
    .att('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance')
    .att('identifier', '')
    .att('version', '1.2')
    .att('xsi:schemaLocation', 'http://www.imsproject.org/xsd/imscp_rootv1p1p2 imscp_rootv1p1p2.xsd  http://www.imsglobal.org/xsd/imsmd_rootv1p2p1 imsmd_rootv1p2p1.xsd  http://www.adlnet.org/xsd/adlcp_rootv1p2 adlcp_rootv1p2.xsd')
    .ele('metadata')
      .ele('schema', 'ADL SCORM').up()
      .ele('schemaversion', '1.2').up().up()
    .ele('organizations', { default: 'https://www.digitalpages.com.br' })
      .ele('organization', { identifier: 'https://www.digitalpages.com.br' })
        .ele('title', composition.metadata.title).up()
        .ele('item', { identifier: 'CourseItem01', identifierref: 'SCO_Resource_01', isvisible: 'true' })
          .ele('title', composition.metadata.title).up().up().up().up()
    .ele('resources')
    .ele('resource', { identifier: 'SCO_Resource_01', type: 'webcontent', 'adlcp:scormType': 'sco', href: 'index.html' })
    
    composition.structure.map(structure => {
      resolveByStructureType(structure, xml);
    })
    
    xml.ele('file', { href: 'index.html'}).up()
  return xml.end({ pretty: true});
}

const resolveByStructureType = function(structure, xmlBuilder) {
  if (structure.type === 'head-1') {
    if (structure.background_image) {
      xmlBuilder.ele('file', { href: structure.background_image}).up()
    }

    if (structure.avatar) {
      xmlBuilder.ele('file', { href: structure.avatar}).up()
    }

  } else if (structure.type === 'image-1' || structure.type === 'image-2'|| structure.type === 'image-3' || structure.type === 'image-4') { 
    if (structure.image) {
      xmlBuilder.ele('file', { href: structure.image}).up()
    }

  } else if (structure.type === 'audio-1') {
    if (structure.audio) {
      xmlBuilder.ele('file', { href: structure.audio}).up()
    }

  } else if (structure.type === 'video-1') {
    if (structure.video) {
      xmlBuilder.ele('file', { href: structure.video}).up()
    }
    
  } else if (structure.type === 'accordion' || structure.type === 'tabs') {
    structure.items.map(file => {
      if (file.video) {
        xmlBuilder.ele('file', { href: file.video}).up()
      }
      if (file.image) {
        xmlBuilder.ele('file', { href: file.image}).up()
      }
    })
  } else if (structure.type === 'flashcards-1') {
    structure.items.map(file => {
      if (file.front_card) {
        if (file.front_card) {
          if (file.front_card.centered_image) {
            xmlBuilder.ele('file', { href: file.front_card.centered_image}).up()
          }
          if (file.front_card.fullscreen_image) {
            xmlBuilder.ele('file', { href: file.front_card.fullscreen_image}).up()
          }
        }
        if (file.front_card) {
          if (file.back_card.centered_image) {
            xmlBuilder.ele('file', { href: file.back_card.centered_image}).up()
          }
          if (file.back_card.fullscreen_image) {
            xmlBuilder.ele('file', { href: file.back_card.fullscreen_image}).up()
          }
        }
      }
    })
  } else if (structure.type === 'quiz-1' || structure.type === 'quiz-2') {
    structure.questions.map(file => {
      if (file.video) {
        xmlBuilder.ele('file', { href: file.video}).up()
      }
      if (file.image) {
        xmlBuilder.ele('file', { href: file.image}).up()
      }
    })
  } else if (structure.type === 'multimedia-2') {
    if (structure.url) {
      xmlBuilder.ele('file', { href: structure.url}).up()
    }
  } else if (structure.type === 'gallery-2') {
    if (structure.first_image) {
      xmlBuilder.ele('file', { href: structure.first_image}).up()
    }

    if (structure.second_image) {
      xmlBuilder.ele('file', { href: structure.second_image}).up()
    }
  }
}

export { xmlBuilder }