import React, { Component } from 'react';
import {UserService} from '../../services/user';
import { Quill } from '../../components';
import './element-quote-2.css';

export default class ElementQuote1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "avatar_size": "width"
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeAuthorName = this.handleChangeAuthorName.bind(this);
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  handleAvatarChange(img) {
    this.setState({
      "avatar_size": img.target.width >= img.target.height ? "height" : "width"
    });
  }

  handleChangeText(text) {
    var content = this.props.content;
    content.text = text;
    this.props.onChange(content);
  }

  renderText() {
    let { content } = this.props;

    return <div className="rdp-element-quote-2-text-container">
      <Quill
        className="rdp-element-quote-2-text"
        value={content.text}
        onChange={this.handleChangeText}
      />
    </div>;
  }

  renderAvatar() {
    let { content } = this.props;

    if (content.avatar !== null) {
      var avatarSize = this.state.avatar_size;
      return <div className="rdp-element-quote-2-avatar">
        <img
          style={avatarSize === "width" ? { width: "102%" } : { height: "102%" }}
          src={this.getAvatarUrl(content.avatar)}
          onLoad={this.handleAvatarChange.bind(this)}
        />
      </div>;
    } else {
      return null;
    }
  }

  getAvatarUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  handleChangeAuthorName(name) {
    var content = this.props.content;
    content.author_name = name;
    this.props.onChange(content);
  }

  renderAuthorInfo() {
    let { content } = this.props;

    return <div className="rdp-element-quote-2-author-info">
      <Quill
        className="rdp-element-quote-2-author-name"
        value={content.author_name}
        onChange={this.handleChangeAuthorName}
      />
    </div>
  }

  getBackgroundUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/background.png") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;
    
    return <div className={`${className} rdp-element-quote-2 ${this.getFont(content.font_family) || ""}`} style={{ background: content.background_color }}>
      {headerActionsTemplate}
      <div className="rdp-element-quote-2-container">
        {this.renderAvatar()}
        {this.renderText()}
        {this.renderAuthorInfo()}
      </div>
      {footerActionsTemplate}
    </div>;
  }
}
