import React, { Component } from 'react';
import './element-divider-1.css';

export default class ElementDivider1 extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;
    var dividerContainerStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }

    var lineStyle = {
      background: content.divider_color,
      height: content.height
    }

    return (
      <div className={`${className}`} style={dividerContainerStyle}>
        {headerActionsTemplate}
        <div className="element-divider-1-content">
          <div className="element-divider-1-line" style={lineStyle}></div>
        </div>
        {footerActionsTemplate}
      </div>
    )
  }
}
