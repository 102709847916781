import React, { Component } from 'react';
import {UserService} from '../../services/user';
import { Quill } from '../../components';
import * as S from './element-quote-6.styled';

class ElementQuote6 extends Component {
  state = {
    avatar_size: "width",
    visibleSlideIndex: 0,
  }

  componentDidMount() {
    const { items } = this.props.content;
    if (items.length > 0) {
      this.setActivePageMarker(items[0].id);
    }
  }

  handleAvatarChange = (img) => {
    this.setState({
      avatar_size: img.target.width >= img.target.height ? "height" : "width"
    });
  }

  handleChangeText = text => this.props.onChange({...this.props.content, text});

  handleChangeAuthorName = author_name => this.props.onChange({...this.props.content, author_name});

  getAssetUrl = (assetUrl) => {
    const publicAssetUrlArr = [
      "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
      "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/background.png"
    ];
    if (false === publicAssetUrlArr.includes(assetUrl)) {
      assetUrl = `${assetUrl}&access_token=${UserService.getUser().access_token}`;
    }
    return assetUrl;
  }

  getFont = font => font ? font.replace(/\s/g, '-').toLowerCase() : "";

  renderText = (text) => {
    return (
      <S.TextContainer>
        <Quill
          className="rdp-element-quote-6-text"
          value={text}
          onChange={this.handleChangeText}
        />
      </S.TextContainer>
    );
  }

  renderAvatar = (avatar) => {
    if (avatar !== null) {
      const { avatar_size } = this.state;

      return (
        <S.Avatar>
          <S.AvatarImage
            style={{ [avatar_size]: "102%" }}
            src={this.getAssetUrl(avatar)}
            onLoad={this.handleAvatarChange}
            alt="avatar"
          />
        </S.Avatar>
      );
    } else {
      return null;
    }
  }

  renderAuthorInfo = (author_name) => {
    const {
      content,
    } = this.props;
    const {
      primary_color,
    } = content;
    return (
      <S.AuthorInfo>
        <S.AuthorName
          fontColor={primary_color}
          dangerouslySetInnerHTML={{__html: author_name}}
        />
      </S.AuthorInfo>
    )
  }

  handlePageChange = (page) => (event) => {
    const { content } = this.props;
    const { items } = content;

    event.preventDefault();
    const slideElement = document.getElementById(page);

    let visibleSlideId = page.split("slide-")[1];
    let visibleItem = items.find(item => item.id === visibleSlideId);
    let visibleSlideIndex = items.indexOf(visibleItem);
    this.setState({visibleSlideIndex}, () => {
      slideElement.scrollIntoView();
      slideElement.focus();
    });
  }

  handlePrevClick = (event) => {
    const {
      visibleSlideIndex
    } = this.state;

    if (visibleSlideIndex > 0) {
      const prevIndex = visibleSlideIndex - 1;
      let prevItem = this.props.content.items[prevIndex];
      const itemElement = document.getElementById(`slide-${prevItem.id}`);

      if (itemElement) {
        this.setState({visibleSlideIndex: prevIndex}, () => {
          itemElement.scrollIntoView();
          itemElement.focus({
            preventScroll: true
          });
        });
      }
    }
  }

  handleNextClick = (event) => {
    const {
      visibleSlideIndex
    } = this.state;

    if (visibleSlideIndex < this.props.content.items.length - 1) {
      const nextIndex = visibleSlideIndex + 1;
      let prevItem = this.props.content.items[nextIndex];
      const itemElement = document.getElementById(`slide-${prevItem.id}`);

      if (itemElement) {
        this.setState({visibleSlideIndex: nextIndex}, () => {
          itemElement.scrollIntoView();
          itemElement.focus({
            preventScroll: true
          });
        });
      }
    }
  }

  isInViewport = (event, target) => {
    const element = target || event.target;
    const rect = element.getBoundingClientRect();
    const isVisivle = rect.top >= 0 && rect.bottom <= window.innerHeight;

    if (isVisivle) {
     this.setActivePageMarker(element.id.split("slide-")[1]);
    }

    return isVisivle;
  }

  setActivePageMarker = (slideId) => {
    const pageMarker = document.getElementById(this.getSlidePageId(slideId));
    if (pageMarker) {
      this.resetActivePageMarkers();
      pageMarker.classList.add("active");
    }
  }

  resetActivePageMarkers = () => {
    const { items } = this.props.content;

    items.forEach(item => {
      const pageMarker = document.getElementById(this.getSlidePageId(item.id));
      if (pageMarker) {
        pageMarker.classList.remove("active")
      }
    });
  }

  getSlideId = id => `slide-${id}`;

  getSlidePageId = id => `page-${id}`;

  render() {
    const {
      headerActionsTemplate,
      footerActionsTemplate,
      content,
      className,
    } = this.props;
    const {
      background_color,
      primary_color,
      padding_top,
      padding_bottom,
      font_family,
      items = [],
    } = content;

    return (
      <S.Root
        className={`${className} ${this.getFont(font_family)}`}
        backgroundColor={background_color}
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
      >
        {headerActionsTemplate}

        <S.Grid className="element-quote-6-grid">
          <S.ControlsLeft
            onClick={this.handlePrevClick}
          >
            <S.ControlsLeftArrow
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
            </S.ControlsLeftArrow>
          </S.ControlsLeft>

          <S.Carousel
            className="element-quote-6-carrousel"
          >
            <S.Slides
              className="element-quote-6-slides"
            >
              {
                items.map((item) =>
                  (
                    <S.Slide
                      className="element-quote-6-slide"
                      key={item.id}
                      tabIndex="1"
                      id={this.getSlideId(item.id)}
                      onFocus={this.isInViewport}
                    >
                      <S.Quote
                        style={{borderColor: primary_color}}
                        className="element-quote-6-slide-quote"
                      >
                        {this.renderAvatar(item.avatar)}
                        {this.renderText(item.text)}
                        {this.renderAuthorInfo(item.author_name)}
                      </S.Quote>
                    </S.Slide>
                  )
                )}
            </S.Slides>
          </S.Carousel>

          <S.ControlsRight
            onClick={this.handleNextClick}
          >
            <S.ControlsRightArrow
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </S.ControlsRightArrow>
          </S.ControlsRight>
        </S.Grid>

        <S.Controls
          className="element-quote-6-controls"
        >
          <S.Pages role="list">
            {
              items.map((item, index) =>
                (
                  <S.Page
                    id={this.getSlidePageId(item.id)}
                    key={item.id}
                    role="listitem"
                    onClick={this.handlePageChange(this.getSlideId(item.id))}
                    primaryColor={primary_color}
                  >
                    {index+1}
                  </S.Page>
                )
              )}
          </S.Pages>
        </S.Controls>

        {footerActionsTemplate}
      </S.Root>
    );
  }
}

export default ElementQuote6;
