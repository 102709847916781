import React, {Component} from 'react';
import {Quill} from '../../components';
import './element-quiz-5.css';
import {
  ContainedButton,
  ContainerFinishButton,
  FeedbackContainer,
  NextButtonContainer,
  PrevButtonContainer,
  RemakeButtonContainer,
  RemakeButtonIcon,
  RemakeButtonText,
  TextArea,
  TextAreaContainer,
  TextButton
} from "./element-quiz-5.styled.";

export default class ElementQuiz5 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.content,
      currentQuestionPos: 0,
      answeredQuestionsQtd: 0,
      questionAnswer: "",
    }

    this.handlePreviousQuestion = this.handlePreviousQuestion.bind(this);
    this.renderEditAnswerButton = this.renderEditAnswerButton.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.content !== prevProps.content) {
      this.manipulateItems();
    }
  };

  manipulateItems() {
    this.setState({
      content: this.sortQuestions(),
      contentBkp: this.props.content,
      currentQuestionPos: 0,
      answeredQuestionsQtd: 0,
      questionAnswer: "",
    }, () => this.defineAnswered(0))
  }

  handleChangeQuestion(questionChanged, answer) {
    let contentClone = this.state.content;

    contentClone.questions = contentClone.questions.map(question => {
      if (question.id === questionChanged.id) {
        question.question = answer;
      }
      return question;
    });

    this.setState({
      content: contentClone
    }, () => {
      this.props.onChange(contentClone);
    });
  }

  getLabels() {
    const {
      content: {
        questions = []
      }
    } = this.state;

    let language = this.props.contentLanguage;
    if (language === undefined) language = "pt_br";
    if (language === "en") {
      return {
        confirm: "Confirm",
        edit: "Edit answer",
        question: "Question",
        back: "Back",
        retry: "Try again",
        previous: "Previous",
        next: "Next",
        finish: questions.length > 1 ? "Send answers" : "Send answer",
        incorrect: "Incorrect",
        right: "Right!",
        finish_quiz_message: "You have completed this quiz.",
        answer_saved: "Answer saved!",
      }
    } else if (language === "pt_br") {
      return {
        confirm: "Confirmar",
        edit: "Editar resposta",
        question: "Questão",
        retry: "Refazer atividade",
        previous: "Anterior",
        next: "Próximo",
        finish: questions.length > 1 ? "Enviar respostas" : "Enviar resposta",
        incorrect: "Incorreto",
        right: "Correto!",
        finish_quiz_message: "Você finalizou este questionário.",
        answer_saved: "Resposta salva!",
      }
    }
  }

  renderQuestionQuill(question) {
    if (this.props.editable) {
      return (
        <div className="element-quiz-5-question">
          <Quill
            key={question.id}
            value={question.question}
            onChange={(text) => this.handleChangeQuestion(question, text)}
          />
        </div>
      );
    } else {
      return (
        <div className="element-quiz-5-question">
          <div className="ql-editor" dangerouslySetInnerHTML={{__html: question.question}}/>
        </div>
      );
    }
  }

  handleChangeAnswer(question, questionAnswer) {
    let contentClone = this.state.content;

    contentClone.questions = contentClone.questions.map(qtion => {
      if (qtion.id === question.id) {
        qtion.answer = questionAnswer;
      }
      return qtion;
    });

    this.setState({
      content: contentClone,
      questionAnswer
    }, () => {
      this.props.onChange(contentClone);
    });
  }

  renderDissertationArea(question) {
    const {
      content: {
        primary_color
      },
    } = this.state;
    const {
      answer = ""
    } = question;

    return (
      <TextAreaContainer>
        <TextArea
          tabIndex={1}
          value={answer}
          onChange={event => this.handleChangeAnswer(question, event.target.value)}
          disabled={question.answered}
          primaryColor={primary_color}
          rows={10}
        />
      </TextAreaContainer>
    )
  }

  handlePreviousQuestion() {
    const {
      currentQuestionPos
    } = this.state;

    this.setState({currentQuestionPos: currentQuestionPos - 1})
  }

  renderPreviousQuestionBtn() {
    const {
      content: {
        primary_color
      },
      currentQuestionPos
    } = this.state;

    if (currentQuestionPos > 0) {
      return (
        <PrevButtonContainer>
          <TextButton
            tabIndex={1}
            type="button"
            color={primary_color}
            onClick={this.handlePreviousQuestion}
          >
            {this.getLabels().previous}
          </TextButton>
        </PrevButtonContainer>
      )
    }
  }

  renderNextQuestionBtn() {
    const {
      currentQuestionPos,
      content: {
        questions,
        primary_color
      }
    } = this.state;
    const nextQuestionPos = currentQuestionPos + 1;

    if (nextQuestionPos < questions.length) {
      return (
        <NextButtonContainer>
          <TextButton
            tabIndex={1}
            type="button"
            color={primary_color}
            onClick={() => this.setState({currentQuestionPos: nextQuestionPos})}
          >
            {this.getLabels().next}
          </TextButton>
        </NextButtonContainer>
      )
    }
  }

  renderFinishBtn() {
    const {currentQuestionPos, content} = this.state;
    const {questions, primary_color} = content;
    let current = currentQuestionPos + 1;
    let questions_count = questions.length;
    let answeredQuestions = 0;
    questions.map(q => {
      if (q.answered) {
        answeredQuestions++;
      }
    })
    let disabledBtn = questions_count !== answeredQuestions;
    if (current === questions_count) {
      return (
        <ContainerFinishButton
          color={!disabledBtn ? primary_color : "#A1A1A1"}
        >
          <ContainedButton
            tabIndex={1}
            type="button"
            background={!disabledBtn ? primary_color : "#A1A1A1"}
            disabled={disabledBtn}
            onClick={() => this.onFinishClick()}
          >
            {this.getLabels().finish}
          </ContainedButton>
        </ContainerFinishButton>
      )
    }
  }

  renderProgress() {
    const {currentQuestionPos, content} = this.state;
    const {questions, primary_color} = content;
    const current = currentQuestionPos + 1;
    const total = questions.length;
    const percentage = (100 / total * current) + "%";
    return (
      <div className="rdp-react-quiz-5-navigation-progress">
        <div className="rdp-react-quiz-5-navigation-progress-bar-placeholder">
          <div
            style={{width: percentage, background: primary_color}}
            className="rdp-react-quiz-5-navigation-progress-bar"
          />
        </div>
        <span
          style={{color: primary_color}}
          className="rdp-react-quiz-5-navigation-progress-count"
        >
          {current}/{total}
        </span>
      </div>
    )
  }

  renderNavigation() {
    return (
      <div className="rdp-react-quiz-5-navigation">
        {this.renderPreviousQuestionBtn()}
        {this.renderProgress()}
        {this.renderNextQuestionBtn()}
        {this.renderFinishBtn()}
      </div>
    );
  }

  resetAllQuestions() {
    let cloneContent = this.state.content;
    cloneContent.questions = this.state.content.questions.map(question => {
      question.answered = false;
      question.answer = "";
      return question;
    });
    this.setState({
      content: cloneContent
    }, () => this.defineAnswered(0));
  }

  defineAnswered = (idQuestion, isAnswered = true) => {
    let cloneContent = this.state.content;
    cloneContent.questions = this.state.content.questions.map(question => {
      if (question.id === idQuestion) {
        question.answered = isAnswered;
      }
      return question;
    });
    this.setState({
      content: cloneContent
    });
  }

  renderCustomMsg = (question) => {
    const msg = question.feedback_default.text;
    const image = question.feedback_default.image;
    const video = question.feedback_default.video;
    const media_max_width = question.feedback_default.media_max_width;

    return (
      <div>
        {(image && this.renderImage({image, media_max_width})) || (video && this.renderVideo({
          video,
          media_max_width
        }))}
        {msg && <p>{msg}</p>}
      </div>
    )
  }

  verifyAnswer = (question) => {
    const {feedback} = this.state.content;

    if (feedback.type !== "disabled" && !question.no_feedback) {
      return (
        <div className="element-quiz-5-feedback-msg">
          {this.getLabels().answer_saved}
        </div>
      );
    }
  }

  renderFeedback(question) {
    return (
      <div className="element-quiz-5-feedback-container">
        {this.verifyAnswer(question)}
        {this.renderEditAnswerButton(question)}
      </div>
    );
  }

  renderConfirmAnswerButton(question) {
    const {content} = this.state;
    const {primary_color} = content;

    const currentQuestion = content.questions.find(row => row.id === question.id);
    let currentAnswer = "";
    if (currentQuestion && currentQuestion.answer) {
      currentAnswer = currentQuestion.answer;
    }

    const enabled = currentAnswer.length > 0;

    return (
      <FeedbackContainer>
        <ContainedButton
          tabIndex={1}
          type="button"
          onClick={() => this.defineAnswered(question.id)}
          background={enabled ? primary_color : "#F1F1F1"}
          disabled={!enabled}
        >
          {this.getLabels().confirm}
        </ContainedButton>
      </FeedbackContainer>
    )
  }

  renderEditAnswerButton(question) {
    const {content} = this.state;
    const {primary_color} = content;

    return (
      <FeedbackContainer>
        <ContainedButton
          tabIndex={1}
          type="button"
          onClick={() => this.defineAnswered(question.id, false)}
          background={primary_color}
        >
          {this.getLabels().edit}
        </ContainedButton>
      </FeedbackContainer>
    )
  }

  renderQuestionare(question) {
    const {
      currentQuestionPos,
      content: {
        questions,
        primary_color
      }
    } = this.state;

    return (
      <div>
        <div className="element-quiz-5-header">
          <span style={{color: primary_color}}>
            {this.getLabels().question} {currentQuestionPos + 1}
          </span>
          <span style={{color: primary_color}}>
            {currentQuestionPos + 1}/{questions.length}
          </span>
        </div>
        {this.renderQuestionQuill(question)}
        {this.renderDissertationArea(question)}
        {
          question.answered
            ? this.renderFeedback(question)
            : this.renderConfirmAnswerButton(question)
        }
        {this.renderNavigation()}
      </div>
    );
  }

  renderContent() {
    const {
      currentQuestionPos,
      content: {
        questions
      },
      show_result
    } = this.state;

    if (questions && questions.length) {
      let question = questions[currentQuestionPos];
      return (
        <div className="element-quiz-5-container">
          {question && !show_result ? this.renderQuestionare(question) : this.renderResult()}
        </div>
      )
    }
  }


  onFinishClick() {
    this.setState({
      show_result: true,
    })
  }

  onRemakeQuestionnaire() {
    this.resetAllQuestions();
    this.setState({
      answered: false,
      grades: null,
      show_result: false,
      currentQuestionPos: 0,
      answeredQuestion: 0,
      questionAnswer: ""
    })

    this.sortQuestions(true);
  }

  sortQuestions(isRemake = false) {
    const contentCopy = { ...this.props.content };
    var splitQuestions = contentCopy.number_of_questions_to_display !== undefined && contentCopy.number_of_questions_to_display > 0;
    var randomizeOnRemake = contentCopy.random_on_remake !== undefined && contentCopy.random_on_remake.enabled === true;
    var sortQuestions = contentCopy.random_question_order !== undefined && contentCopy.random_question_order.enabled === true;

    if ((randomizeOnRemake && isRemake) || !isRemake) {
      const backup = this.props.content.questions;
      var newQuestions = this.props.content.questions;

      if (sortQuestions) {
        newQuestions.sort((a, b) => {
          return Math.floor(Math.random() * (1 - -1)) + -1;
        });
      }

      if (splitQuestions) {
        newQuestions = newQuestions.slice(0, contentCopy.number_of_questions_to_display);
      }

      contentCopy.questions = newQuestions;

      this.props.content.questions = backup;
      this.setState({
        content: contentCopy
      })
    }

    return contentCopy;
  }

  getResultLabel() {
    let language = this.props.contentLanguage;
    if (language === "en") {
      return "Your responses have been sent for review.";
    } else if (language === "pt_br") {
      return "Suas respostas foram enviadas para análise.";
    }
  }


  renderFinalMessage() {
    const feedback = this.state.content.feedback.type;

    if (feedback === "disabled") {
      return <span className="rdp-react-quiz-5-result-message">{this.getLabels().finish_quiz_message}</span>
    } else {
      return (
        <span className="rdp-react-quiz-5-result-message">
          {this.getResultLabel()}
        </span>
      )
    }
  }

  renderResult() {
    const {content, show_result} = this.state;

    if (show_result) {
      return (
        <div className="rdp-react-quiz-5-result">
          <span className="rdp-react-quiz-5-result-icon"/>
          {this.renderFinalMessage()}
          {content.remake === "enable" &&
            <RemakeButtonContainer>
              <TextButton
                tabIndex={1}
                type="button"
                onClick={() => this.onRemakeQuestionnaire()}
                color={content.primary_color}
              >
                <RemakeButtonIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="32px"
                    height="32px"
                    viewBox="0 0 512 512"
                    enableBackground="new 0 0 512 512"
                    space="preserve"
                  >
                    <g>
                      <path
                        fill={content.primary_color ? content.primary_color : "#111111"}
                        d="M126.97,328.84c-5.84,0-11.63-2.42-15.78-7.15L5.17,200.31c-6.89-7.88-6.89-19.64,0-27.53L111.19,51.41   c7.61-8.7,20.83-9.59,29.53-1.99c8.71,7.6,9.59,20.82,2,29.52l-94,107.61l94,107.6c7.6,8.7,6.71,21.92-2,29.52   C136.75,327.14,131.85,328.84,126.97,328.84z"
                      />
                      <path
                        fill={content.primary_color ? content.primary_color : "#111111"}
                        d="M359.93,469.75H48.83c-11.56,0-20.93-9.37-20.93-20.93s9.37-20.93,20.93-20.93h311.1   c60.78,0,110.22-49.44,110.22-110.21s-49.44-110.21-110.22-110.21h-339C9.37,207.47,0,198.1,0,186.54   c0-11.55,9.37-20.92,20.93-20.92h339c83.85,0,152.07,68.22,152.07,152.06C512,401.53,443.77,469.75,359.93,469.75z"
                      />
                    </g>
                  </svg>
                </RemakeButtonIcon>
                <RemakeButtonText>{this.getLabels().retry}</RemakeButtonText>
              </TextButton>
            </RemakeButtonContainer>
          }
        </div>
      );
    }
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
    return "";
  }

  render() {
    const {
      headerActionsTemplate,
      footerActionsTemplate,
      content: {
        background_color,
        padding_bottom,
        padding_top,
        font_family
      },
      className
    } = this.props;

    return (
      <div
        className={`${className} ${this.getFont(font_family)}`}
        style={{
          background: background_color,
          paddingTop: padding_top + "px",
          paddingBottom: padding_bottom + "px"
        }}>
        {headerActionsTemplate}
        {this.renderContent()}
        {footerActionsTemplate}
      </div>
    )
  }
}
