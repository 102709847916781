import React from "react";
import * as S from "./quiz-question-confirm-feedback.styled";
import QuizImage from "../element-quiz-image/quiz-image";
import QuizVideo from "../element-quiz-video/quiz-video";
import useQuestionIsCorrect from "../../hooks/useQuestionIsCorrect";


function QuizQuestionConfirmFeedback(props) {
  const {
    question,
    activeChoices,
    content,
    labels,
    answered = false,
    onClickConfirm = (questionId) => {
    },
    onClickEdit = (questionId) => {},
  } = props;
  const {primary_color, feedback} = content;

  const renderCustomMsg = (question) => {
    const msg = question.feedback_default.text;
    const image = question.feedback_default.image;
    const video = question.feedback_default.video;
    const media_max_width = question.feedback_default.media_max_width;

    return (
      <div>
        {(image && <QuizImage data={image} mediaMaxWidth={media_max_width}/>)
          || (video && <QuizVideo data={video} mediaMaxWidth={media_max_width} />)
        }
        {msg && <p>{msg}</p>}
      </div>
    )
  }

  const renderCorrectMsg = (question) => {
    let msg = labels.right;
    let image = null, video = null, media_max_width = null;
    if (content.feedback.type === "correct_incorrect") {
      msg = question.feedback_correct.text ? question.feedback_correct.text : msg;
      image = question.feedback_correct.image;
      video = question.feedback_correct.video;
      media_max_width = question.feedback_correct.media_max_width;
    } else {
      msg = question.feedback_default.text ? question.feedback_default.text : msg;
      image = question.feedback_default.image;
      video = question.feedback_default.video;
      media_max_width = question.feedback_default.media_max_width;
    }

    return (
      <div>
        {!image && !video && (
          <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
               x="0px" y="0px" width="56px" height="56px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512"
               space="preserve">
            <path fill={content.correct_color ? content.correct_color : content.primary_color}
                  d="M221.41,346.64c-5.42,0-10.81-2.11-14.87-6.28l-77.5-79.56c-7.99-8.21-7.82-21.35,0.39-29.35  c8.22-8,21.35-7.82,29.35,0.39l63.21,64.89L354.5,171.06c8.32-7.9,21.46-7.53,29.34,0.78c7.9,8.31,7.54,21.45-0.77,29.34  L235.69,340.94C231.67,344.74,226.54,346.64,221.41,346.64z"/>
            <path fill={content.correct_color ? content.correct_color : content.primary_color} fillRule="evenodd"
                  clipRule="evenodd"
                  d="M256,41.51C137.73,41.51,41.51,137.73,41.51,256  S137.73,470.49,256,470.49S470.49,374.27,470.49,256S374.27,41.51,256,41.51z M256,512C114.84,512,0,397.16,0,256S114.84,0,256,0  s256,114.84,256,256S397.16,512,256,512z"/>
          </svg>
        )}
        {(image && <QuizImage data={image} mediaMaxWidth={media_max_width}/>)
          || (video && <QuizVideo data={video} mediaMaxWidth={media_max_width} />)
        }
        <p>{msg}</p>
      </div>
    );
  }

  const renderIncorrectMsg = (question) => {
    let msg = labels.incorrect;
    let image = null, video = null, media_max_width = null;
    if (content.feedback.type === "correct_incorrect") {
      msg = question.feedback_incorrect.text ? question.feedback_incorrect.text : msg;
      image = question.feedback_incorrect.image;
      video = question.feedback_incorrect.video;
      media_max_width = question.feedback_incorrect.media_max_width;
    } else {
      msg = question.feedback_default.text ? question.feedback_default.text : msg;
      image = question.feedback_default.image;
      video = question.feedback_default.video;
      media_max_width = question.feedback_default.media_max_width;
    }

    return (
      <div>
        {!image && !video && (
          <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
               x="0px" y="0px" width="56px" height="56px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512"
               space="preserve">
            <path fill={content.incorrect_color ? content.incorrect_color : content.primary_color} fillRule="evenodd"
                  clipRule="evenodd"
                  d="M256,36.57C135,36.57,36.57,135,36.57,256S135,475.43,256,475.43  S475.43,377,475.43,256S377,36.57,256,36.57z M256,512C114.84,512,0,397.16,0,256S114.84,0,256,0s256,114.84,256,256  S397.16,512,256,512z"/>
            <path fill={content.incorrect_color ? content.incorrect_color : content.primary_color} fillRule="evenodd"
                  clipRule="evenodd"
                  d="M170,342c7.13,7.13,18.68,7.13,25.8,0l60.2-60.2l60.2,60.2  c7.13,7.13,18.68,7.12,25.8,0c7.13-7.13,7.13-18.67,0-25.8L281.8,256l60.2-60.2c7.13-7.12,7.13-18.67,0-25.8  c-7.12-7.12-18.67-7.12-25.8,0L256,230.2L195.8,170c-7.12-7.13-18.67-7.13-25.8,0c-7.12,7.12-7.13,18.68,0,25.8l60.2,60.2L170,316.2  C162.88,323.32,162.88,334.88,170,342z"/>
          </svg>
        )}
        {(image && <QuizImage data={image} mediaMaxWidth={media_max_width}/>)
          || (video && <QuizVideo data={video} mediaMaxWidth={media_max_width} />)
        }
        <p>{msg}</p>
      </div>
    );
  }

  const verifyAnswer = (question) => {
    // Caso seja dissertativa permite a edição da resposta
    if (question.type === "quiz-5") {
      return (
        <>
          <S.QuizQuestionFeedbackMessage>
            {labels.answer_saved}
          </S.QuizQuestionFeedbackMessage>

          <S.EditButton
            onClick={() => activeChoices[question.id] && onClickEdit(question.id)}
            style={{background: activeChoices[question.id] ? primary_color : "#F1F1F1"}}
          >
            {labels.edit || "Editar"}
          </S.EditButton>
        </>
      )
    } else {
      let correctAnswer = useQuestionIsCorrect(question, activeChoices);

      const no_correct_answer = question.no_correct_answer;

      if (feedback.type !== "disabled" && !question.no_feedback) {
        return (
          <S.QuizQuestionFeedbackMessage>
            {(correctAnswer && !no_correct_answer)
              ? renderCorrectMsg(question)
              : (!no_correct_answer)
                ? renderIncorrectMsg(question)
                : renderCustomMsg(question)
            }
          </S.QuizQuestionFeedbackMessage>
        );
      }
    }

    return null;
  }

  return (
    <S.QuizQuestionFeedback>
      {answered ? (
        <>{verifyAnswer(question)}</>
      ) : (
        <S.ConfirmButton
          onClick={() => activeChoices[question.id] && onClickConfirm(question.id)}
          style={{background: activeChoices[question.id] ? primary_color : "#F1F1F1"}}
        >
          {labels.confirm}
        </S.ConfirmButton>
      )
      }
    </S.QuizQuestionFeedback>
  );
}

export default QuizQuestionConfirmFeedback;