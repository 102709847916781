import $ from 'jquery';
import React, {Component} from 'react';
import uuidv1 from 'uuid/v1';
import JSZip, { forEach } from 'jszip';
import JSZipUtils from 'jszip-utils';
import {DamService} from '../../services/dam';
import {UserService} from '../../services/user';
import {xmlBuilder} from '../../services/xml';
import './composer.css';
import {Compositions} from '../../containers';
import {RDP_TRANSLATE} from '../../translate/translate';
import RDP_CONFIG from '../../config';
import {Configurations, ElementEdit, ElementMessages, Popup, Sidebar} from '../../components';
import replaceCompositionObjectsIds from "../../utils/replaceCompositionObjectsIds";
import Swal from 'sweetalert2';
import Url from "../../utils/url";
import getConnectorFromArray from "../../utils/getConnectorFromArray";
import { getTheme } from '../../utils/getTheme';
import { StructureProvider } from '../../hooks/context/structure';
import { CompositionProvider } from '../../hooks/context/composition';
import { ActionsMenu } from '../../components/actions-menu';

export default class ViewComposer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "is_embed": this.props.match.path.search("/embed/") !== -1,
      "downloading": false,
      "opened_configurations": false,
      "opened_menu": false,
      "displayed_preloader": this.props.match.params.content_uid !== undefined,
      "structure": [],
      "enabled_element_edit_props": false,
      "enabled_element_message_object_uid": null,
      "redirect_to_preview": false,
      "redirect_to_home": false,
      "mirror_composition": null,
      "composition_backup": {},
      "composition": {
        "version": "1.1",
        "metadata": {
          "title": "Sem título",
          "description": "",
          "thumb": null,
          "tags": []
        },
        "interface": {
          "content_language": RDP_CONFIG.language,
          "index_option": "buttons",
          "font_family": "Lato",
          "show_summary": "disabled",
          "finish_btn": "disabled"
        },
        "structure": []
      },
      "labels": RDP_TRANSLATE[RDP_CONFIG.language]().composer,
      "google_fonts_render_modal": false,
      "google_fonts": null,
      "project_users": null,
      "render_versions_history": false,
      "first_version": null,
      "render_first_version_items": false,
      "second_version": null,
      "render_second_version_items": false,
      "versions": [],
      "can_send_object_messages": false,
      "structure_before_removed": null,
    }

    this.onClickSubitem = this.onClickSubitem.bind(this);
    this.autoSize = this.autoSize.bind(this);
    this.onElementEditClose = this.onElementEditClose.bind(this);
    this.onElementEdit = this.onElementEdit.bind(this);
    this.onElementRemove = this.onElementRemove.bind(this);
    this.onElementChange = this.onElementChange.bind(this);
    this.onElementMoveDown = this.onElementMoveDown.bind(this);
    this.onElementMoveUp = this.onElementMoveUp.bind(this);
    this.onElementAdd = this.onElementAdd.bind(this);
    this.setUserEditorUid = this.setUserEditorUid.bind(this);
  }

  componentWillMount() {
    if (this.props.match.params.content_uid !== undefined) {
      this.getProjectUsers();
      this.getCompositionContent(this.props.match.params.content_uid)
      this.getCompositionVersions();
      // .then(this.setUserEditorUid);
      this.getGoogleFonts();
    }
    this.redirectToLogin();
  }

  async getCompositionVersions() {
    const userData = UserService.getUser();
    const accessToken = userData.access_token;
    const original = await DamService.getFileOriginalUid(this.props.match.params.content_uid, accessToken);
    const versions = await DamService.getFileVersions(original.original_uid, accessToken);
    console.log(versions);

    for (var i = 0; i < versions.length; i++) {
      versions[i].label = `Versão ${i + 1}`;
    }

    this.setState({
      "versions": versions,
      "first_version": versions[0].content_uid,
      "second_version": versions[versions.length - 1].content_uid
    })
  }

  getIframeSrc(id) {
    var userData = UserService.getUser();
    var accessToken = userData.access_token;

    var options = {
      "activity":{
        "mime_type":"digitalpages/composer",
        "url": `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${id}`
      },
      "access_token": accessToken
    }

    let domain = RDP_CONFIG.domain;
    if (domain === "api-euconquisto.azure-api.net") {
      domain = "content-euconquisto.azureedge.net";
    }

    var keyType = RDP_CONFIG.projectUid.length > 0 ? "p" : "d";
    var keyValue = keyType == "p" ? RDP_CONFIG.projectUid : RDP_CONFIG.directoryUid;

    var composerPlayer = `https://${domain}/product/learning_player/current/${RDP_CONFIG.projectKey}/${keyType}/${keyValue}/${RDP_CONFIG.apiEnv}/init?custom_options=${btoa(JSON.stringify(options))}`;

    return composerPlayer;
  }

  async getProjectUsers() {
    const userData = UserService.getUser();
    const accessToken = userData.access_token;
    var users = await UserService.getProjectUsers(accessToken);
    this.setState({
      "project_users": users
    })
  }

  getGoogleFonts() {
    var _this = this;

    DamService.getGoogleFonts().then(function (fonts) {
      _this.setState({
        "google_fonts": fonts
      })
    });
  }

  async getCompositionContent(id) {
    const userData = UserService.getUser();
    const context = { api_env : RDP_CONFIG.apiEnv, project_key : RDP_CONFIG.projectKey};
    const accessToken = userData.access_token;
    const canSendObjectMessages = await this.canSendObjectMessages()

    return new Promise( (resolve) => {
      DamService.getContent(id, accessToken)
        .then((composition) => {
          composition = this.adjustStructure(context, JSON.parse(composition), userData);

          this.setState({
            "composition": composition,
            "composition_backup": JSON.parse(JSON.stringify(composition)),
            "can_download": this.canDownloadComposition(composition),
            "can_send_object_messages": canSendObjectMessages,
            "displayed_preloader": false
          }, () => resolve())
        })
        .catch(function (error) {
          if (error && "status" in error) {
            window.location = window.location.origin + "/#/status/"+error.status;
          }
        });
    });
  }

  componentDidUpdate() {
    console.log(this.state.composition)
  }

  setUserEditorUid() {
    const {
      composition = {}
    } = this.state;
    const {
      metadata: {
        editor_uuid
      }
    } = composition;

    const userUuid = UserService.getUUid();

    if (editor_uuid === undefined) {
      composition.metadata.editor_uuid = userUuid;

      this.setState({
        "composition": composition
      }, () => {
        this.onSaveClick();
      });
    } else if (editor_uuid !== userUuid) {
      Swal.fire({
        title: this.state.labels.attention,
        text: this.state.labels.composition_being_edited,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2d7b45',
        confirmButtonText: this.state.labels.yes,
        cancelButtonText: this.state.labels.no,
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          composition.metadata.editor_uuid = userUuid;

          this.setState({
            "composition": composition
          }, () => {
            this.onSaveClick();
          });
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          this.redirectToHome();
        }
      });
    } else {
      // verifica se possui alterações locais e se sim, pergunta se deseja salvar
      const localComposition = DamService.getLocalContent(this.props.match.params.content_uid);
      if (localComposition) {
        Swal.fire({
          title: this.state.labels.local_changes,
          text: this.state.labels.save_changes_message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2d7b45',
          confirmButtonText: this.state.labels.save_changes,
          cancelButtonText: this.state.labels.discard_changes,
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            const content_uid = this.props.match.params.content_uid;

            this.setState({
              "composition": localComposition
            }, () => {
              this.onSaveClick().then(() => {
                DamService.removeLocalContent(content_uid);
                Swal.fire(
                  this.state.labels.saved,
                  this.state.labels.saved_message,
                  'success'
                );
              });
            });
          } else if (
            result.dismiss === Swal.DismissReason.cancel
          ) {
            DamService.removeLocalContent(this.props.match.params.content_uid);
          }
        })
      }
    }
  }

  async canDownloadComposition(composition) 
  {
    let sameOrigin = false;
    let downloadEnabled = false;

    const project = UserService.getActiveProject();
    let connectors = project.connectors.filter(p => p.type === "Customization_1");

    const userData = JSON.parse(window.localStorage.getItem("rdp-composer-user-data"));
    const type = userData.type;
    const accessToken = userData.access_token;
    let authorization = type + " " + accessToken;

    if (RDP_CONFIG.directToken) {
      authorization = "Bearer " + RDP_CONFIG.directToken;
    }

    for(var connector of connectors)
    {
      try
      {
        const resp = await fetch(`https://${RDP_CONFIG.domain}/auth/v1.1/connector/uid/${connector.uid}`, {
          method: "GET",
          headers: {
            "Authorization": authorization,
            "Project-Key": RDP_CONFIG.projectKey,
            "Api-Env": RDP_CONFIG.apiEnv,
            "Content-Type": "application/json"
          }
        });

        var connectorInfo = await resp.json();
        let configuration = typeof connectorInfo.configuration === "string" ? JSON.parse(connectorInfo.configuration) : connectorInfo.configuration;
        let configs = configuration.configs;
        let composerConfigs = configs.find(c => c.uid === "composer") || {};

        if (composerConfigs && "value" in composerConfigs) {
          let values = JSON.parse(composerConfigs.value);

          let downloadConfig = values.find(c => c.uid === "enable_download") || [];

          if (downloadConfig && "value" in downloadConfig) {
            downloadEnabled = Boolean(downloadConfig.value);
            console.log("downloadEnabled", downloadEnabled);
          }
        }

        if (downloadEnabled) break;
      }catch(e)
      {
        console.log(e);
      } 
    }

    if ("origin" in composition) {
      sameOrigin = composition.origin.directory_uid === project.directory.uid &&
        composition.origin.project_uid === project.uid;
    }

    return sameOrigin || downloadEnabled;
  }

  async canSendObjectMessages() {
    let sendMessagesEnabled = false;

    const project = UserService.getActiveProject();
    let connectors = project.connectors.filter(p => p.type === "Customization_1");

    const userData = JSON.parse(window.localStorage.getItem("rdp-composer-user-data"));
    const type = userData.type;
    const accessToken = userData.access_token;
    let authorization = type + " " + accessToken;

    if (RDP_CONFIG.directToken) {
      authorization = "Bearer " + RDP_CONFIG.directToken;
    }

    for(var connector of connectors) {
      try {
        const resp = await fetch(`https://${RDP_CONFIG.domain}/auth/v1.1/connector/uid/${connector.uid}`, {
          method: "GET",
          headers: {
            "Authorization": authorization,
            "Project-Key": RDP_CONFIG.projectKey,
            "Api-Env": RDP_CONFIG.apiEnv,
            "Content-Type": "application/json"
          }
        });

        var connectorInfo = await resp.json();
        let configuration = typeof connectorInfo.configuration === "string" ? JSON.parse(connectorInfo.configuration) : connectorInfo.configuration;
        let configs = configuration.configs;
        let composerConfigs = configs.find(c => c.uid === "composer") || {};

        if (composerConfigs && "value" in composerConfigs) {
          let values = JSON.parse(composerConfigs.value);

          let sendMessagesConfig = values.find(c => c.uid === "enable_object_messages") || [];

          if (sendMessagesConfig && "value" in sendMessagesConfig) {
            sendMessagesEnabled = Boolean(sendMessagesConfig.value);
            console.log("sendMessagesEnabled", sendMessagesEnabled);
          }
        }

        if (sendMessagesEnabled) break;
      } catch(e) {
        console.log(e);
      } 
    }

    return sendMessagesEnabled;
  }

  adjustStructure(context, structure, userData)
  {
    if (!structure.assets) return structure;

    var originalJson = JSON.stringify(structure);
    var accessToken = userData ? userData.access_token : "";
    var mapReplaced = [];

    for (const index in structure.assets) {
      const url = structure.assets[index];

      if (url) {
        if (url.indexOf("/content") == -1 || url.indexOf("/storage/v") == -1) continue;

        if (mapReplaced.find(p=> p == url)) continue;

        var newUrl = url;
        if (newUrl.indexOf("api_env") == -1) newUrl += `&api_env=${context.api_env}`;
        if (newUrl.indexOf("project_key") == -1) newUrl += `&project_key=${context.project_key}`;

        originalJson = originalJson.replaceAll(url, newUrl);
        mapReplaced.push(url);
      }
    }

    structure = JSON.parse(originalJson);
    return structure;
  }

  redirectToLogin() {
    if (window.localStorage.getItem("rdp-composer-user-data") === null) {
      // window.location = window.location.origin + "/#/login/";
      window.location = window.location.origin + window.location.pathname + "#/login";
    };
  }

  onElementCopy(element, index) {
    var composition = this.state.composition;
    var structure = composition.structure;

    var obj = JSON.parse(JSON.stringify(element));

    obj = replaceCompositionObjectsIds({...obj});

    structure.splice(index + 1, 0, obj);

    composition.structure = structure;
    this.setState({
      "composition": composition
    })

    DamService.setLocalContent(composition, this.props.match.params.content_uid);
  }

  onElementOpenMessages(element) {
    this.setState({
      "enabled_element_message_object_uid": element.id
    })
  }

  onElementCloseMessages() {
    this.setState({
      "enabled_element_message_object_uid": null
    })
  }

  onElementEdit(element) {
    this.setState({
      "enabled_element_edit_props": true,
      "element_edit_props": element
    })
  }

  onElementRemove(element) {
    var composition = this.state.composition;
    var structure = composition.structure;
    const structureCopy = [...structure];

    structure = structure.filter(function (obj) {
      return element.id !== obj.id;
    });
    composition.structure = structure
    this.setState({
      "composition": composition,
      "structure_before_removed": JSON.stringify(structureCopy),
    });

    DamService.setLocalContent(composition, this.props.match.params.content_uid);
  }

  onElementMoveUp(index) {
    this.onElementMove(index, index - 1);
  }

  onElementMoveDown(index) {
    this.onElementMove(index, index + 1);
  }

  onElementMove(old_index, new_index) {
    var composition = this.state.composition;
    var structure = composition.structure;
    if (new_index >= structure.length) {
      var k = new_index - structure.length + 1;
      while (k--) {
        structure.push(undefined);
      }
    }
    structure.splice(new_index, 0, structure.splice(old_index, 1)[0]);

    composition.structure = structure;
    this.setState({
      "composition": composition
    })
  }

  onDispatchCompositionChange(newComposition) {
    const event = new CustomEvent('compositionChange', {
        detail: {
            newComposition
        },
    });
    
    document.dispatchEvent(event);
}

  onElementChange(element) {
    var composition = this.state.composition;
    var structure = composition.structure;
    for (var i = 0; i < structure.length; i++) {
      if (structure[i].id === element.id) {
        structure[i] = element;
      };
    };

    composition.structure = structure;
    this.setState({
      "composition": composition,
      "enabled_element_edit_props": false,
      "element_edit_props": null
    }, () => {
      this.onDispatchCompositionChange(composition)
    })
  }

  onElementEditClose() {
    this.setState({
      "enabled_element_edit_props": false,
      "element_edit_props": null
    })
  }

  onContentChange(index, content) {
    var composition = this.state.composition;
    var structure = composition.structure;
    structure[index] = content;
    composition.structure = structure;

    this.setState({
      "composition": composition
    })
    DamService.setLocalContent(composition, this.props.match.params.content_uid);
  }

  onMetadataChange(composition) {
    this.setState({
      "composition": composition
    })
    DamService.setLocalContent(composition, this.props.match.params.content_uid);
  }

  onElementAdd(index) {
    var composition = this.state.composition;
    var structure = composition.structure;

    structure = structure.filter(function(element) { return element.type != "temp-slot"; });

    structure.splice(index + 1, 0, {
      "id": uuidv1(),
      "type": "temp-slot",
      "text": this.state.labels.stage.choose_element_to_insert_above
    });

    composition.structure = structure;
    this.setState({
      "composition": composition
    })

    DamService.setLocalContent(composition, this.props.match.params.content_uid);
  }

  onClickSubitem(type) {
    var composition = this.state.composition;
    var structure = composition.structure;
    var obj;
    var hasTempSlot = null;

    for (var i = 0; i < structure.length; i++) {
      if (structure[i].type === "temp-slot") {
        hasTempSlot = i;
      };
    };

    if (type === "head-1") {
      obj = {
        "id": uuidv1(),
        "type": "head-1",
        "content_title": null,
        "primary_color": "#FFFFFF",
        "secondary_color": "#aa2c23",
        "category": '<p>Lorem ipsum dolor</p>',
        "background_image": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/background.png",
        "avatar": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
        "avatar_border_color": "#00643e",
        "author_name": "H. Rackham",
        "author_office": "At vero eos",
        "show_category": true,
        "show_author_name": true,
        "show_divider": true,
      };
    } else if (type === "text-1") {
      obj = {
        "id": uuidv1(),
        "type": "text-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "text": '<p><span style="font-size: 18px;' + `color: #313537;` + 'line-height: 26px;">Lorem ipsum <b>dolor sit amet</b>, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin, sagittis nulla eget, blandit arcu. Etiam fermentum nunc nisl, in porttitor dui viverra quis. Etiam fringilla dolor id lobortis congue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec faucibus eu felis id suscipit.</span></p>'
      };
    } else if (type === "text-2") {
      obj = {
        "id": uuidv1(),
        "type": "text-2",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "text": '<h1><span style="font-size: 30px; ' + `color: #313537;` + '">Lorem ipsum</span></h1><p><span style="font-size: 18px; ' + `color: #313537;` + ' line-height: 26px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin, sagittis nulla eget, blandit arcu. Etiam fermentum nunc nisl, in porttitor dui viverra quis. Etiam fringilla dolor id lobortis congue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec faucibus eu felis id suscipit.</span></p>'
      };
    }
    else if (type === "interactive-video") {
      obj = {
        "id": uuidv1(),
        "type": "interactive-video",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "url": "https://www.youtube.com/watch?v=JgZrJATtN-w",
        "pausing_minute": 0,
        "pausing_second": 5,
        "questions": [
          {
            "id": uuidv1(),
            "title": "Lorem ipsum set dollor?",
            "seconds": 5,
            "minutes": 0,
            "hours": 0,
            "anchor_time": {
              "correct": 75,
              "error": 5
            },
            "choices": [
              {
                "id": uuidv1(),
                "correct": true,
                "text": 'Sed ut perspiciatis unde omnis iste natus',
                "skills": [
                  {
                    id: uuidv1(),
                    value: '',
                    reference: null,
                  }
                ]
              },
              {
                "id": uuidv1(),
                "correct": false,
                "text": 'Sed ut perspiciatis unde omnis iste natus',
                "value": 10,
                "skills": [
                  {
                    id: uuidv1(),
                    value: '',
                    reference: null,
                  }
                ]
              },
            ],
          }
        ]
      }
    }
    else if (type === "text-3") {
      obj = {
        "id": uuidv1(),
        "type": "text-3",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "text": '<h2><span style="' + `color: #313537;` + ' font-size: 20px;">Sed ut perspiciatis</span></h2><p><span style="' + `color: #313537;` + ' font-size: 18px; line-height: 26px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin, sagittis nulla eget, blandit arcu. Etiam fermentum nunc nisl, in porttitor dui viverra quis. Etiam fringilla dolor id lobortis congue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec faucibus eu felis id suscipit.</span></p>'
      };
    } else if (type === "text-4") {
      obj = {
        "id": uuidv1(),
        "type": "text-4",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "text": '<h1><span style=" ' + `color: #313537;` + ' font-size: 30px;">Lorem ipsum</span></h1>'
      };
    } else if (type === "text-5") {
      obj = {
        "id": uuidv1(),
        "type": "text-5",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "text": '<h2><span style=" ' + `color: #313537;` + ' font-size: 20px;">Sed ut perspiciatis</span></h2>'
      };
    } else if (type === "text-two-column") {
      obj = {
        "id": uuidv1(),
        "type": "text-two-column",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "items": [
          '<p><span style=" ' + `color: #313537;` + ' font-size: 18px; line-height: 26px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin, sagittis nulla eget, blandit arcu. Etiam fermentum nunc nisl, in porttitor dui viverra quis. Etiam fringilla dolor id lobortis congue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec faucibus eu felis id suscipit.</span></p>',
          '<p><span style=" ' + `color: #313537;` + ' font-size: 18px; line-height: 26px;">But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain.</span></p>'
        ]
      };
    } else if (type === "list-1" || type === "list-2" || type === "list-3") {
      obj = {
        "id": uuidv1(),
        "type": type,
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": "#297C43",
        "secondary_color": "#FFFFFF",
        "items": [
          {
            "id": uuidv1(),
            "text": '<p><span style=" ' + `color: #313537;` + ' font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula.</span></p>',
            "image": null,
            "video": null
          },
          {
            "id": uuidv1(),
            "text": '<p><span style=" ' + `color: #313537;` + ' font-size: 18px;">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.</span></p>',
            "image": null,
            "video": null
          },
          {
            "id": uuidv1(),
            "text": '<p><span style=" ' + `color: #313537;` + ' font-size: 18px;">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint.</span></p>',
            "image": null,
            "video": null
          }
        ]
      };
    } else if (type === "text-6") {
      obj = {
        "id": uuidv1(),
        "type": "text-6",
        "content_title": null,
        "background_color": "#FFFFFF",
        "left": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "right": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
      };
    } else if (type === "image-1") {
      obj = {
        "id": uuidv1(),
        "type": "image-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "width": null,
        "background_color": "#FFFFFF",
        "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
        "caption": null
      };
    } else if (type === "image-2") {
      obj = {
        "id": uuidv1(),
        "type": "image-2",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg",
        "caption": null
      };
    } else if (type === "image-3") {
      obj = {
        "id": uuidv1(),
        "type": "image-3",
        "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-3/image-3.jpg",
        "text": "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.",
        "caption": null
      };
    } else if (type === "image-4") {
      obj = {
        "id": uuidv1(),
        "type": "image-4",
        "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-4/image-4.jpg",
        "text": "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system."
      };
    } else if (type === "images-gallery") {
      obj = {
        "id": uuidv1(),
        "type": "images-gallery",
        "images": [
          {
            src: "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
            caption: "",
            id: uuidv1()
          },
          {
            src: "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg",
            caption: "",
            id: uuidv1()
          },
        ],
        "text": "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system."
      }
    } else if (type === "image-and-text") {
      obj = {
        "id": uuidv1(),
        "type": "image-and-text",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "image_width": "50%",
        "image_position": "left",
        "image_border_radius": 0,
        "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg",
        "text": '<p><span style="font-size: 18px;">But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.</span></p>',
        "subtitle": null,
      };
    } else if (type === "image-with-text") {
      obj = {
        "id": uuidv1(),
        "type": "image-with-text",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "opacity": "50%",
        "opacity_color": "#000000",
        "divider_color": "#FFFFFF",
        "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
        "text": '<p><span style="color: #FFFFFF; font-size: 30px;">But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account.</span></p>',
      };
    } else if (type === "audio-1") {
      obj = {
        "id": uuidv1(),
        "type": "audio-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "audio": "https://pocs.digitalpages.com.br/rdpcomposer/media/audio-1/audio-1.mp3"
      };
    } else if (type === "video-1") {
      obj = {
        "id": uuidv1(),
        "type": "video-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "video": "https://pocs.digitalpages.com.br/rdpcomposer/media/video-1/video-1.mp4"
      };
    } else if (type === "iframe") {
      obj = {
        "id": uuidv1(),
        "type": "iframe",
        "content_title": null,
        "width": 800,
        "height": 560,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "url": "https://player.vimeo.com/video/411911652"
      };
    } else if (type === "accordion") {
      obj = {
        "id": uuidv1(),
        "type": "accordion",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "border_color": "#ff9900",
        "behavior": "keep-open",
        "items": [
          {
            "id": uuidv1(),
            "title": "Lorem ipsum",
            "text": '<p><span style="font-size: 18px;">Lorem ipsum dolor sit amet consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin, sagittis nulla eget, blandit arcu. Etiam fermentum nunc nisl, in porttitor dui viverra quis. Etiam fringilla dolor id lobortis congue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec faucibus eu felis id suscipit.</span></p>',
            "image": null,
            "video": null
          },
          {
            "id": uuidv1(),
            "title": "Sed ut",
            "text": '<p><span style="font-size: 18px;">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</span></p>',
            "image": null,
            "video": null
          },
          {
            "id": uuidv1(),
            "title": "At vero",
            "text": '<p><span style="font-size: 18px;">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</span></p>',
            "image": null,
            "video": null
          }
        ]
      };
    } else if (type === "tabs") {
      obj = {
        "id": uuidv1(),
        "type": "tabs",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "active_color": "#00643e",
        "items": [
          {
            "id": uuidv1(),
            "title": "Lorem ipsum",
            "text": '<p><span style="font-size: 18px;">Lorem ipsum </span><strong style="font-size: 18px;">dolor sit amet</strong><span style="font-size: 18px;">, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin, sagittis nulla eget, blandit arcu. Etiam fermentum nunc nisl, in porttitor dui viverra quis. Etiam fringilla dolor id lobortis congue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec faucibus eu felis id suscipit.</span></p>',
            "image": null,
            "video": null
          },
          {
            "id": uuidv1(),
            "title": "Sed ut",
            "text": '<p><span style="font-size: 18px;">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</span></p>',
            "image": null,
            "video": null
          },
          {
            "id": uuidv1(),
            "title": "At vero",
            "text": '<p><span style="font-size: 18px;">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</span></p>',
            "image": null,
            "video": null
          }
        ]
      };
    } else if (type === "flashcards-1") {
      obj = {
        "id": uuidv1(),
        "type": "flashcards-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "card_height": 240,
        "card_width": 240,
        "background_color": "#FFFFFF",
        "border_color": "#00643e",
        "items": [
          {
            "id": uuidv1(),
            "front_card": {
              "text": "Lorem Ipsum",
              "centered_image": null,
              "fullscreen_image": null
            },
            "back_card": {
              "text": "Excepteur sint",
              "centered_image": null,
              "fullscreen_image": null
            }
          },
          {
            "id": uuidv1(),
            "front_card": {
              "text": "Sed ut perspiciatis",
              "centered_image": null,
              "fullscreen_image": null
            },
            "back_card": {
              "text": "Quis autem",
              "centered_image": null,
              "fullscreen_image": null
            }
          },
          {
            "id": uuidv1(),
            "front_card": {
              "text": "At vero eos",
              "centered_image": null,
              "fullscreen_image": null
            },
            "back_card": {
              "text": "Itaque earum",
              "centered_image": null,
              "fullscreen_image": null
            }
          }
        ]
      };
    } else if (type === "flashcards-2") {
      obj = {
        "id": uuidv1(),
        "type": "flashcards-2",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "card_height": 360,
        "card_width": 360,
        "background_color": "#FFFFFF",
        "border_color": "#00643e",
        "items": [
          {
            "id": uuidv1(),
            "front_card": {
              "text": "Lorem Ipsum",
              "centered_image": null,
              "fullscreen_image": null
            },
            "back_card": {
              "text": "Excepteur sint",
              "centered_image": null,
              "fullscreen_image": null
            }
          },
          {
            "id": uuidv1(),
            "front_card": {
              "text": "Sed ut perspiciatis",
              "centered_image": null,
              "fullscreen_image": null
            },
            "back_card": {
              "text": "Quis autem",
              "centered_image": null,
              "fullscreen_image": null
            }
          },
          {
            "id": uuidv1(),
            "front_card": {
              "text": "At vero eos",
              "centered_image": null,
              "fullscreen_image": null
            },
            "back_card": {
              "text": "Itaque earum",
              "centered_image": null,
              "fullscreen_image": null
            }
          }
        ]
      };
    } else if (type === "quiz-1") {
      obj = {
        "id": uuidv1(),
        "type": "quiz-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": "#2d7b45",
        "remake": "enable",
        "max_attempts": 1,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "feedback": {
          "type": "default", //correct_incorrect e default
        },
        "questions": [{
          "id": uuidv1(),
          "question": '<p><span style="font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
          "image": null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": [
            {
              "id": uuidv1(),
              "correct": true,
              "text": '<p><span style="font-size: 15px;">Sed ut perspiciatis unde omnis iste natus</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Sit voluptatem accusantium doloremque laudantium</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Nemo enim ipsam voluptatem quia voluptas sit</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Ut enim ad minima veniam, quis nostrum</span></p>'
            }
          ]
        }],
      };
    } else if (type === "quiz-2") {
      obj = {
        "id": uuidv1(),
        "type": "quiz-2",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": "#2d7b45",
        "remake": "enable",
        "max_attempts": 1,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "feedback": {
          "type": "default", //correct_incorrect e default
        },
        "questions": [{
          "id": uuidv1(),
          "question": '<p><span style="font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
          "image": null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": [
            {
              "id": uuidv1(),
              "correct": true,
              "text": '<p><span style="font-size: 15px;">Sed ut perspiciatis unde omnis iste natus</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": true,
              "text": '<p><span style="font-size: 15px;">Sit voluptatem accusantium doloremque laudantium</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Nemo enim ipsam voluptatem quia voluptas sit</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Ut enim ad minima veniam, quis nostrum</span></p>'
            }
          ]
        }],
      };
    } else if (type === "quiz-3") {
      obj = {
        "id": uuidv1(),
        "type": "quiz-3",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": "#2d7b45",
        "remake": "enable",
        "max_attempts": 1,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "feedback": {
          "type": "default", //correct_incorrect e default
        },
        "questions": [{
          "id": uuidv1(),
          "question": '<p><span style="font-size: 18px;">____ ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
          "image": null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": [
            {
              "id": uuidv1(),
              "text": 'Lorem'
            }
          ]
        }],
      };
    } else if (type === "quiz-4") {
      obj = {
        "id": uuidv1(),
        "type": "quiz-4",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": "#2d7b45",
        "remake": "enable",
        "max_attempts": 1,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "feedback": {
          "type": "default", //correct_incorrect e default
        },
        "questions": [{
          "id": uuidv1(),
          "question": '<p><span style="font-size: 18px;">____ ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
          "image": null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": [
            {
              "id": uuidv1(),
              "title": '1',
              "correct": true,
              "matchTitle": '1'
            },
            {
              "id": uuidv1(),
              "title": '2',
              "correct": true,
              "matchTitle": '2'
            },
            {
              "id": uuidv1(),
              "title": '3',
              "correct": true,
              "matchTitle": '3'
            }
          ]
        }],
      };
    } else if (type === "quiz-5") {
      obj = {
        "id": uuidv1(),
        "type": "quiz-5",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": "#2d7b45",
        "remake": "enable",
        "max_attempts": 1,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "grade_type": "numeral",
        "grade_numeral": {
          "min": 0,
          "max": 100,
          "steps": 10
        },
        "feedback": {
          "type": "default", //correct_incorrect e default
        },
        "questions": [{
          "id": uuidv1(),
          "question": '<p><span style="font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
          "image": null,
          "video": null,
          "answered": false,
          "answer": "",
          "feedback_default": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
        }],
      };
    } else if (type === "quiz-6") {
      obj = {
        "id": uuidv1(),
        "type": "quiz-6",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": "#2d7b45",
        "remake": "enable",
        "max_attempts": 1,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "grade_type": "numeral",
        "grade_numeral": {
          "min": 0,
          "max": 100,
          "steps": 10
        },
        "feedback": {
          "type": "default", //correct_incorrect e default
        },
        "questions": [
          {
          "id": uuidv1(),
          "type": "quiz-1",
          "question": '<p><span style="font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
          "image": null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": [
            {
              "id": uuidv1(),
              "correct": true,
              "text": '<p><span style="font-size: 15px;">Sed ut perspiciatis unde omnis iste natus</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Sit voluptatem accusantium doloremque laudantium</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Nemo enim ipsam voluptatem quia voluptas sit</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Ut enim ad minima veniam, quis nostrum</span></p>'
            }
          ]
        },
          {
          "id": uuidv1(),
          "type": "quiz-2",
          "question": '<p><span style="font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
          "image": null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": [
            {
              "id": uuidv1(),
              "correct": true,
              "text": '<p><span style="font-size: 15px;">Sed ut perspiciatis unde omnis iste natus</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": true,
              "text": '<p><span style="font-size: 15px;">Sit voluptatem accusantium doloremque laudantium</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Nemo enim ipsam voluptatem quia voluptas sit</span></p>'
            },
            {
              "id": uuidv1(),
              "correct": false,
              "text": '<p><span style="font-size: 15px;">Ut enim ad minima veniam, quis nostrum</span></p>'
            }
          ]
        },
          {
            "id": uuidv1(),
            "type": "quiz-3",
            "question": '<p><span style="font-size: 18px;">____ ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
            "image": null,
            "video": null,
            "answered": false,
            "feedback_default": {
              "text": null,
              "image": null,
              "video": null,
              "media_max_width": null
            },
            "feedback_correct": {
              "text": null,
              "image": null,
              "video": null,
              "media_max_width": null
            },
            "feedback_incorrect": {
              "text": null,
              "image": null,
              "video": null,
              "media_max_width": null
            },
            "no_correct_answer": false,
            "no_feedback": false,
            "choices": [
              {
                "id": uuidv1(),
                "text": 'lorem'
              },
            ]
          },
          {
            "id": uuidv1(),
            "type": "quiz-5",
            "question": '<p><span style="font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
            "image": null,
            "video": null,
            "answered": false,
            "answer": "",
            "feedback_default": {
              "text": null,
              "image": null,
              "video": null,
              "media_max_width": null
            },
            "feedback_correct": {
              "text": null,
              "image": null,
              "video": null,
              "media_max_width": null
            },
            "feedback_incorrect": {
              "text": null,
              "image": null,
              "video": null,
              "media_max_width": null
            },
            "no_correct_answer": false,
            "no_feedback": false,
          }
        ],
      };
    } else if (type === "quiz-7") {
      obj = {
        "id": uuidv1(),
        "type": "quiz-7",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": "#2d7b45",
        "remake": "enable",
        "max_attempts": 1,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "feedback": {
          "type": "default", //correct_incorrect e default
        },
        "questions": [{
          "id": uuidv1(),
          "question": '<p><span style="font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?</span></p>',
          "image": null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": null,
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_feedback": false,
          "choices": [
            {
              "id": uuidv1(),
              "text": '1'
            },
            {
              "id": uuidv1(),
              "text": '2'
            },
            {
              "id": uuidv1(),
              "text": '3'
            },
            {
              "id": uuidv1(),
              "text": '4'
            },
            {
              "id": uuidv1(),
              "text": '5'
            },
            {
              "id": uuidv1(),
              "text": '6'
            },
            {
              "id": uuidv1(),
              "text": '7'
            },
            {
              "id": uuidv1(),
              "text": '8'
            },
            {
              "id": uuidv1(),
              "text": '9'
            },
            {
              "id": uuidv1(),
              "text": '10'
            }
          ]
        }],
      };
    } else if (type === "forum-1") {
      obj = {
        "id": uuidv1(),
        "type": "forum-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "confirm_button_text_color": "#FFFFFF",
        "confirm_button_background_color": "#00643e",
        "cancel_button_text_color": "#FFFFFF",
        "cancel_button_background_color": "#8f8f8f",
        "topic_button_color": "#00643e",
        "primary_topic_background_color": "#e1f1eb",
        "primary_topic_author_color": "#A8A8A8",
        "primary_topic_text_color": "#727272",
        "secondary_topic_background_color": "#f1f1f1",
        "secondary_topic_author_color": "#A8A8A8",
        "secondary_topic_text_color": "#727272",
        "grade_type": "numeral",
        "grade_numeral": {
          "min": 0,
          "max": 100,
          "steps": 10
        },
        "enable_new_topics": ["reviewer", "reviewadmin"],
      }
    } else if (type === "multimedia-2") {
      obj = {
        "id": uuidv1(),
        "type": "multimedia-2",
        "url": "https://pocs.digitalpages.com.br/rdpcomposer/media/video-1/video-1.mp4"
      };
    } else if (type === "gallery-2") {
      obj = {
        "id": uuidv1(),
        "type": "gallery-2",
        "first_image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
        "second_image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg"
      }
    } else if (type === "virtual-index-1") {
      obj = {
        "id": uuidv1(),
        "type": "virtual-index-1",
        "title": "Título do índice",
        "content_title": null
      }
    } else if (type === "tex-1") {
      obj = {
        "id": uuidv1(),
        "type": "tex-1",
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "formula_color": "#000000",
        "formula_font_size": "20px"
      }
    } else if (type === "divider-1") {
      obj = {
        "id": uuidv1(),
        "type": "divider-1",
        "padding_top": 35,
        "padding_bottom": 35,
        "height": 1,
        "background_color": "#FFFFFF",
        "divider_color": "#e6e6e6"
      }
    } else if (type === "divider-4") {
      obj = {
        "id": uuidv1(),
        "type": "divider-4",
        "padding_top": 35,
        "padding_bottom": 35,
        "height": 50,
        "background_color": "#FFFFFF",
        "divider_color": "#e6e6e6"
      }
    } else if (type === "divider-numbered") {
      obj = {
        "id": uuidv1(),
        "type": "divider-numbered",
        "padding_top": 35,
        "padding_bottom": 35,
        "height": 1,
        "background_color": "#FFFFFF",
        "divider_primary_color": "#297C43",
        "divider_secondary_color": "#FFFFFF"
      }
    } else if (type === "hotspots-1") {
      obj = {
        "id": uuidv1(),
        "type": "hotspots-1",
        "padding_top": 35,
        "padding_bottom": 35,
        "width": null,
        "background_color": "#FFFFFF",
        "spot_color": "#00643e",
        "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
        "image_alt": "",
        "spots": [
          {
            "id": uuidv1(),
            "x": "50",
            "y": "50",
            "icon": "01",
            "title": "Lorem ipsum dolor sit amet",
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            "hasMedia": false,
            "isActive": false,
            "image": null,
            "audio": null,
          }
        ]
      };
    } else if (type === "statement-1") {
      obj = {
        "id": uuidv1(),
        "type": "statement-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "border_color": "#eaeaeb",
        "text": '<p class="ql-align-center"><span style=" ' + `color: #313537;` + ' font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin, sagittis nulla eget, blandit arcu.</span></p>',
        "font_family": "Grueber"
      };
    } else if (type === "statement-2") {
      obj = {
        "id": uuidv1(),
        "type": "statement-2",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "border_color": "#d5d5d5",
        "text": '<p class="ql-align-center"><span style=" ' + `color: #313537;` + ' font-size: 26px;' + ` color: #313537;"` + '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin.</span></p>',
        "font_family": "Helvetica Neue"
      };
    } else if (type === "statement-3") {
      obj = {
        "id": uuidv1(),
        "type": "statement-3",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "border_color": "#d5d5d5",
        "text": '<p><span style="font-size: 40px; ' + ` color: #313537;"` + '><b>Lorem ipsum dolor</b> sit amet, consectetur adipiscing elit. Curabitur <b>tempor massa</b> ut luctus dictum. <b>Suspendisse</b> consectetur ex cursus, <b>tincidunt orci sit</b> amet, cursus ligula.</span></p>',
        "font_family": "Helvetica Neue"
      };
    } else if (type === "statement-4") {
      obj = {
        "id": uuidv1(),
        "type": "statement-4",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "border_color": "#d5d5d5",
        "text": '<p><span style="font-size: 30px; ' + ` color: #313537;"` + '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin.</span></p>',
        "font_family": "Grueber"
      };
    } else if (type === "note-1") {
      obj = {
        "id": uuidv1(),
        "type": "note-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "icon": "info",
        "primary_color": "#ff631e",
        "secondary_color": "#ffefe9",
        "text": '<p class="ql-align-center"><span style="font-size: 14px; ' + ` color: ${getTheme() === 'dark' ? "#313537" : "#313537"};"` + '><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin.</b></span></p>'
      };
    } else if (type === "quote-1") {
      obj = {
        "id": uuidv1(),
        "type": "quote-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#e9eaeb",
        "background_color": "#FFFFFF",
        "avatar": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
        "text": '<p class="ql-align-center"><span style="font-size: 18px; ' + ` color: #313537;"` + '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet.</span></p>',
        "author_name": '<p class="ql-align-center"><span style="font-size: 14px; ' + ` color: #313537;"` + '>H. Rackham</span></p>',
        "font_family": "Merriweather"
      };
    } else if (type === "quote-2") {
      obj = {
        "id": uuidv1(),
        "type": "quote-2",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#e9eaeb",
        "background_color": "#FFFFFF",
        "avatar": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
        "text": '<p class="ql-align-center"><span style="font-size: 30px; ' + ` color: #313537;"` + '">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet.</span></p>',
        "author_name": '<p class="ql-align-center"><span style="font-size: 14px; ' + ` color: #313537;"` + '">H. Rackham</span></p>',
        "font_family": "Helvetica Neue"
      };
    } else if (type === "quote-3") {
      obj = {
        "id": uuidv1(),
        "type": "quote-3",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#f8f8f8",
        "background_color": "#FFFFFF",
        "avatar": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
        "text": '<p><span style="font-size: 18px; ' + ` color: #313537;"` + '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet.</span></p>',
        "author_name": '<p><span style="font-size: 14px; ' + ` color: #313537;"` + '>H. Rackham</span></p>',
        "font_family": "Merriweather"
      };
    } else if (type === "quote-4") {
      obj = {
        "id": uuidv1(),
        "type": "quote-4",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#FFFFFF",
        "background_color": "#f5f5f5",
        "avatar": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
        "text": '<p><span style="font-size: 18px; ' + ` color: #313537;"` + '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet.</span></p>',
        "author_name": '<p><span style="font-size: 14px; ' + ` color: #313537;"` + '>H. Rackham</span></p>',
        "font_family": "Merriweather"
      };
    } else if (type === "quote-5") {
      obj = {
        "id": uuidv1(),
        "type": "quote-5",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#FFFFFF",
        "background_image": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/background.png",
        "avatar": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
        "text": '<p><span style="font-size: 26px; color: #FFFFFF;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet.</span></p>',
        "author_name": "H. Rackham",
        "font_family": "Merriweather"
      };
    } else if (type === "quote-6") {
      obj = {
        "id": uuidv1(),
        "type": "quote-6",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#2d7b45",
        "background_color": "#FFFFFF",
        "items": [
          {
            "id": uuidv1(),
            "avatar": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
            "text": '<p class="ql-align-center"><span style="font-size: 18px; ' + ` color: #313537;"` + '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet.</span></p>',
            "author_name": '<p class="ql-align-center"><span style="font-size: 14px; ' + ` color: #313537;"` + '>H. Rackham 1</span></p>',
          },
          {
            "id": uuidv1(),
            "avatar": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
            "text": '<p class="ql-align-center"><span style="font-size: 18px; ' + ` color: #313537;"` + '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet.</span></p>',
            "author_name": '<p class="ql-align-center"><span style="font-size: 14px; ' + ` color: #313537;"` + '>H. Rackham 2</span></p>',
          },
          {
            "id": uuidv1(),
            "avatar": "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
            "text": '<p class="ql-align-center"><span style="font-size: 18px; ' + ` color: #313537;"` + '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet.</span></p>',
            "author_name": '<p class="ql-align-center"><span style="font-size: 14px; ' + ` color: #313537;"` + '>H. Rackham 3</span></p>',
          }
        ],
        "font_family": "Merriweather"
      };
    } else if (type === "sorted-activity-1") {
      const pile1Id = uuidv1();
      const pile2Id = uuidv1();
      obj = {
        "id": uuidv1(),
        "type": "sorted-activity-1",
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#297C43",
        "background_color": "#FFFFFF",
        "pile_color": "#e1e1e1",
        "pile_text_color": "#000",
        "item_color": "#FFFFFF",
        "item_text_color": "#000",
        "items": [
          {
            "id": uuidv1(),
            "title": "Item 1",
            "pileId": pile1Id,
          },
          {
            "id": uuidv1(),
            "title": "Item 2",
            "pileId": pile2Id,
          }
        ],
        "piles": [
          {
            "id": pile1Id,
            "title": "Pile 1",
          },
          {
            "id": pile2Id,
            "title": "Pile 2",
          }
        ]
      };
    } else if (type === "timeline-1") {
      obj = {
        "id": uuidv1(),
        "type": "timeline-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": getTheme() === 'dark' ?  "#673ab7" : "#00643e",
        "items": [
          {
            "id": uuidv1(),
            "date": "Date 1",
            "title": "Lorem ipsum dolor sit amet",
            "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed. Accusantium amet cum cumque deleniti doloribus earum eligendi, eum exercitationem harum hic illum ipsa itaque labore libero magni minus neque nesciunt nihil non odio quam repellendus tempore velit, veniam veritatis? Aspernatur aut beatae blanditiis corporis dolorem eius harum incidunt ipsa, itaque iure labore laboriosam laborum magni maiores molestiae natus omnis quae reiciendis reprehenderit sed similique sit tempora tenetur velit veniam! Aliquid autem debitis dolores ducimus eaque est facilis incidunt ipsa ipsum laboriosam modi non perspiciatis placeat, quaerat quas quis totam vel velit? Deleniti deserunt expedita laudantium molestias porro, quas veritatis? Architecto dolor, provident. A accusantium architecto consequatur illum itaque necessitatibus perspiciatis reprehenderit sapiente veritatis vero. Consequatur doloremque ducimus ex molestias non. Dolore, harum ipsa ipsam laudantium nostrum tenetur ut voluptates!",
          },
          {
            "id": uuidv1(),
            "date": "Date 2",
            "title": "Lorem ipsum dolor sit amet",
            "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed. Accusantium amet cum cumque deleniti doloribus earum eligendi, eum exercitationem harum hic illum ipsa itaque labore libero magni minus neque nesciunt nihil non odio quam repellendus tempore velit, veniam veritatis? Aspernatur aut beatae blanditiis corporis dolorem eius harum incidunt ipsa, itaque iure labore laboriosam laborum magni maiores molestiae natus omnis quae reiciendis reprehenderit sed similique sit tempora tenetur velit veniam! Aliquid autem debitis dolores ducimus eaque est facilis incidunt ipsa ipsum laboriosam modi non perspiciatis placeat, quaerat quas quis totam vel velit? Deleniti deserunt expedita laudantium molestias porro, quas veritatis? Architecto dolor, provident. A accusantium architecto consequatur illum itaque necessitatibus perspiciatis reprehenderit sapiente veritatis vero. Consequatur doloremque ducimus ex molestias non. Dolore, harum ipsa ipsam laudantium nostrum tenetur ut voluptates!",
          }
        ],
        "font_family": "Merriweather"
      };
    } else if (type === "steps-1") {
      obj = {
        "id": uuidv1(),
        "type": "steps-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "card_height": 360,
        "card_width": 360,
        "background_color": "#FFFFFF",
        "primary_color": getTheme() === 'dark' ?  "#673ab7" : "#00643e",
        "items": [
          {
            "id": uuidv1(),
            "type": "intro",
            "title": "Intro",
            "isHidden": false,
            "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed. Accusantium amet cum cumque deleniti doloribus earum eligendi, eum exercitationem harum hic illum ipsa itaque labore libero magni minus neque nesciunt nihil non odio quam repellendus tempore velit, veniam veritatis? Aspernatur aut beatae blanditiis corporis dolorem eius harum incidunt ipsa, itaque iure labore laboriosam laborum magni maiores molestiae natus omnis quae reiciendis reprehenderit sed similique sit tempora tenetur velit veniam! Aliquid autem debitis dolores ducimus eaque est facilis incidunt ipsa ipsum laboriosam modi non perspiciatis placeat, quaerat quas quis totam vel velit? Deleniti deserunt expedita laudantium molestias porro, quas veritatis? Architecto dolor, provident. A accusantium architecto consequatur illum itaque necessitatibus perspiciatis reprehenderit sapiente veritatis vero. Consequatur doloremque ducimus ex molestias non. Dolore, harum ipsa ipsam laudantium nostrum tenetur ut voluptates!",
          },
          {
            "id": uuidv1(),
            "type": "step",
            "title": "Lorem ipsum dolor sit amet",
            "isHidden": false,
            "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed. Accusantium amet cum cumque deleniti doloribus earum eligendi, eum exercitationem harum hic illum ipsa itaque labore libero magni minus neque nesciunt nihil non odio quam repellendus tempore velit, veniam veritatis? Aspernatur aut beatae blanditiis corporis dolorem eius harum incidunt ipsa, itaque iure labore laboriosam laborum magni maiores molestiae natus omnis quae reiciendis reprehenderit sed similique sit tempora tenetur velit veniam! Aliquid autem debitis dolores ducimus eaque est facilis incidunt ipsa ipsum laboriosam modi non perspiciatis placeat, quaerat quas quis totam vel velit? Deleniti deserunt expedita laudantium molestias porro, quas veritatis? Architecto dolor, provident. A accusantium architecto consequatur illum itaque necessitatibus perspiciatis reprehenderit sapiente veritatis vero. Consequatur doloremque ducimus ex molestias non. Dolore, harum ipsa ipsam laudantium nostrum tenetur ut voluptates!",
          },
          {
            "id": uuidv1(),
            "type": "step",
            "title": "Lorem ipsum dolor sit amet",
            "isHidden": false,
            "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed. Accusantium amet cum cumque deleniti doloribus earum eligendi, eum exercitationem harum hic illum ipsa itaque labore libero magni minus neque nesciunt nihil non odio quam repellendus tempore velit, veniam veritatis? Aspernatur aut beatae blanditiis corporis dolorem eius harum incidunt ipsa, itaque iure labore laboriosam laborum magni maiores molestiae natus omnis quae reiciendis reprehenderit sed similique sit tempora tenetur velit veniam! Aliquid autem debitis dolores ducimus eaque est facilis incidunt ipsa ipsum laboriosam modi non perspiciatis placeat, quaerat quas quis totam vel velit? Deleniti deserunt expedita laudantium molestias porro, quas veritatis? Architecto dolor, provident. A accusantium architecto consequatur illum itaque necessitatibus perspiciatis reprehenderit sapiente veritatis vero. Consequatur doloremque ducimus ex molestias non. Dolore, harum ipsa ipsam laudantium nostrum tenetur ut voluptates!",
          },
          {
            "id": uuidv1(),
            "type": "summary",
            "title": "Summary",
            "isHidden": false,
            "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed. Accusantium amet cum cumque deleniti doloribus earum eligendi, eum exercitationem harum hic illum ipsa itaque labore libero magni minus neque nesciunt nihil non odio quam repellendus tempore velit, veniam veritatis? Aspernatur aut beatae blanditiis corporis dolorem eius harum incidunt ipsa, itaque iure labore laboriosam laborum magni maiores molestiae natus omnis quae reiciendis reprehenderit sed similique sit tempora tenetur velit veniam! Aliquid autem debitis dolores ducimus eaque est facilis incidunt ipsa ipsum laboriosam modi non perspiciatis placeat, quaerat quas quis totam vel velit? Deleniti deserunt expedita laudantium molestias porro, quas veritatis? Architecto dolor, provident. A accusantium architecto consequatur illum itaque necessitatibus perspiciatis reprehenderit sapiente veritatis vero. Consequatur doloremque ducimus ex molestias non. Dolore, harum ipsa ipsam laudantium nostrum tenetur ut voluptates!",
          },
        ]
      };
    } else if (type === "attachment-1") {
      obj = {
        "id": uuidv1(),
        "type": "attachment-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "primary_color": "#00643e",
        "items": [
          {
            "id": uuidv1(),
            "type": "attachment",
            "size": 546677,
            "filename": "image-1.jpg",
            "mime_type": "image/jpeg",
            "original_url": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
          },
        ],
        "font_family": "Merriweather"
      };
    } else if (type === "gallery-1") {
      obj = {
        "id": uuidv1(),
        "type": "gallery-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#2d7b45",
        "background_color": "#FFFFFF",
        "items": [
          {
            "id": uuidv1(),
            "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
          },
          {
            "id": uuidv1(),
            "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg",
          },
          {
            "id": uuidv1(),
            "image": "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
          }
        ],
        "font_family": "Merriweather"
      };
    } else if (type === "snippet-1") {
      obj = {
        "id": uuidv1(),
        "type": "snippet-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "snippet_background_color": "#e9eaeb",
        "items": [
          {
            "id": uuidv1(),
            "code": '&lt;!DOCTYPE html&gt;\n' +
              '&lt;html lang=&quot;en&quot;&gt;\n' +
              '\t&lt;head&gt;\n' +
              '\t\t&lt;meta charset=&quot;utf-8&quot;&gt;\n' +
              '\t\t&lt;title&gt;title&lt;/title&gt;\n' +
              '\t\t&lt;link rel=&quot;stylesheet&quot; href=&quot;style.css&quot;&gt;\n' +
              '\t\t&lt;script src=&quot;script.js&quot;&gt;&lt;/script&gt;\n' +
              '\t&lt;/head&gt;\n' +
              '\t&lt;body&gt;\n' +
              '\t\t&lt;!-- page content --&gt;\n' +
              '\t&lt;/body&gt;\n' +
              '&lt;/html&gt;',
            "caption": "",
          },
        ],
        "font_family": "Merriweather"
      };
    } else if (type === "button-1") {
      obj = {
        "id": uuidv1(),
        "type": "button-1",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#2d7b45",
        "background_color": "#FFFFFF",
        "items": [
          {
            "id": uuidv1(),
            "type": "link",
            "label": "Button 1",
            "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed.",
            "destination": "",
          },
        ],
        "font_family": "Merriweather"
      };
    } else if (type === "button-2") {
      obj = {
        "id": uuidv1(),
        "type": "button-2",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "primary_color": "#2d7b45",
        "background_color": "#FFFFFF",
        "items": [
          {
            "id": uuidv1(),
            "type": "link",
            "label": "Button 1",
            "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed.",
            "destination": "",
          },
          {
            "id": uuidv1(),
            "type": "email",
            "label": "Button 2",
            "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed.",
            "destination": "",
          },
        ],
        "font_family": "Merriweather"
      };
    } else if (type === "3d") {
      obj = {
        "id": uuidv1(),
        "type": "3d",
        "content_title": null,
        "padding_top": 35,
        "padding_bottom": 35,
        "background_color": "#FFFFFF",
        "file": "https://pocs.digitalpages.com.br/rdpcomposer/media/3d/3d.glb"
      };
    }

    if (hasTempSlot) {
      structure[hasTempSlot] = obj;
    } else {
      structure.push(obj);
    };

    composition.structure = structure;
    this.setState({
      "composition": composition
    })

    DamService.setLocalContent(composition, this.props.match.params.content_uid);
  }

  autoSize(element) {
    var target = element.target;
    setTimeout(function () {
      target.style.cssText = 'height:auto; padding:0';
      target.style.cssText = 'height:' + (target.scrollHeight + 8) + 'px';
    }, 0);
  }


  async onZipExport(needUpdateComposition) {
    var _this = this;

    const startExport = (uid) => {
      var contentUid = uid || this.props.match.params.content_uid;
      var accessToken = UserService.getUser().access_token;
      var title = this.generateSlug(this.state.composition.metadata.title);
  
      this.setState({
        "downloading": true,
        "opened_menu": false
      });
  
      DamService.downloadScormPackage(contentUid, accessToken, title).then(function (blob) {
        _this.downloadBlob(blob, title);
      });
    };

    if (needUpdateComposition) {
      await this.onSaveClick().then((response) => {
        const uid = response[0].uid;
        startExport(uid);
      })

      return;
    };
    startExport();
  }

  downloadBlob(blob, name) {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = blobUrl;
    link.download = name;

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    document.body.removeChild(link);

    this.setState({
      "downloading": false
    });
  }

  getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  downloadPublicStructure() {
    var _this = this;
    var composition = this.state.composition
    var structure = composition.structure;
    var accessToken = UserService.getUser().access_token;
    var assets = [];
    var publicAssets = [];
    composition.metadata.content_uid = this.props.match.params.content_uid;

    for (var i = 0; i < structure.length; i++) {
      var item = structure[i];
      var types = ["background_image", "avatar", "image", "video", "audio"];

      for (var j = 0; j < types.length; j++) {
        if (item[types[j]] !== undefined && _this.getParameterByName("uid", item[types[j]]) !== null) {
          assets.push(item[types[j]]);
        };
      };

      var items = item.items || item.questions;
      var itemsTypes = ["image", "video", "fullscreen_image", "centered_image"];

      if (items !== undefined) {

        for (var k = 0; k < items.length; k++) {
          for (var j = 0; j < itemsTypes.length; j++) {
            if (items[k][itemsTypes[j]] !== undefined && _this.getParameterByName("uid", items[k][itemsTypes[j]]) !== null) {
              assets.push(items[k][itemsTypes[j]])
            }
          }

          if (items[k].front_card !== undefined && _this.getParameterByName("uid", items[k].front_card.centered_image) !== null) {
            assets.push(items[k].front_card.centered_image)
          }

          if (items[k].front_card !== undefined && _this.getParameterByName("uid", items[k].front_card.fullscreen_image) !== null) {
            assets.push(items[k].front_card.fullscreen_image)
          }

          if (items[k].back_card !== undefined && _this.getParameterByName("uid", items[k].back_card.centered_image) !== null) {
            assets.push(items[k].back_card.centered_image)
          }

          if (items[k].back_card !== undefined && _this.getParameterByName("uid", items[k].back_card.fullscreen_image) !== null) {
            assets.push(items[k].back_card.fullscreen_image)
          }
        }
      }
    };

    if (assets.length === 0) {
      _this.downloadPublicZip(composition, structure, publicAssets);
      return;
    }

    for (var i = 0; i < assets.length; i++) {
      var uid = _this.getParameterByName("uid", assets[i]);
      DamService.getPubliContent(uid, accessToken, i + 1).then(function (data) {
        publicAssets.push(data);
        if (data.index === assets.length) {
          _this.downloadPublicZip(composition, structure, publicAssets);
        };
      });
    };
  }

  downloadPublicZip(composition, structure, publicAssets) {
    var _this = this;
    for (var i = 0; i < structure.length; i++) {
      var item = structure[i];
      var types = ["background_image", "avatar", "image", "video", "audio"];

      for (var j = 0; j < types.length; j++) {
        if (item[types[j]] !== undefined && _this.getParameterByName("uid", item[types[j]]) !== null) {
          for (var k = 0; k < publicAssets.length; k++) {
            if (publicAssets[k].original_uid === _this.getParameterByName("uid", item[types[j]])) {
              item[types[j]] = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${publicAssets[k].share_uid}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
            };
          };
        };
      };

      var items = item.items || item.questions;
      var itemsTypes = ["image", "video"];

      if (items !== undefined) {
        for (var j = 0; j < itemsTypes.length; j++) {
          for (var k = 0; k < items.length; k++) {
            if (items[k][itemsTypes[j]] !== undefined && _this.getParameterByName("uid", items[k][itemsTypes[j]]) !== null) {
              for (var l = 0; l < publicAssets.length; l++) {
                if (publicAssets[l].original_uid === _this.getParameterByName("uid", items[k][itemsTypes[j]])) {
                  items[k][itemsTypes[j]] = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${publicAssets[l].share_uid}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
                };
              };
            }

            for (var l = 0; l < publicAssets.length; l++) {
              if (items[k].front_card !== undefined && _this.getParameterByName("uid", items[k].front_card.centered_image) !== null) {
                if (publicAssets[l].original_uid === _this.getParameterByName("uid", items[k].front_card.centered_image)) {
                  items[k].front_card.centered_image = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${publicAssets[l].share_uid}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
                };
              }

              if (items[k].front_card !== undefined && _this.getParameterByName("uid", items[k].front_card.fullscreen_image) !== null) {
                if (publicAssets[l].original_uid === _this.getParameterByName("uid", items[k].front_card.fullscreen_image)) {
                  items[k].front_card.fullscreen_image = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${publicAssets[l].share_uid}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
                };
              }

              if (items[k].back_card !== undefined && _this.getParameterByName("uid", items[k].back_card.centered_image) !== null) {
                if (publicAssets[l].original_uid === _this.getParameterByName("uid", items[k].back_card.centered_image)) {
                  items[k].back_card.centered_image = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${publicAssets[l].share_uid}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
                };
              }

              if (items[k].back_card !== undefined && _this.getParameterByName("uid", items[k].back_card.fullscreen_image) !== null) {
                if (publicAssets[l].original_uid === _this.getParameterByName("uid", items[k].back_card.fullscreen_image)) {
                  items[k].back_card.fullscreen_image = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${publicAssets[l].share_uid}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
                };
              }
            };

          }
        }
      }
    };

    composition.structure = structure;

    JSZipUtils.getBinaryContent(composition.version + ".zip", function (err, data) {
      if (err) {
        throw err;
      };
      const manifest = xmlBuilder(composition);

      JSZip.loadAsync(data).then(function (zip) {
        zip.file("structure.js", 'window.structure = ' + JSON.stringify(composition));
        zip.file("imsmanifest.xml", manifest);
        zip.generateAsync({ type: "base64" }).then(function (base64) {
          var pack = "data:application/zip;base64," + base64;
          var downloadLink = document.createElement("a");
          downloadLink.setAttribute("href", pack);
          downloadLink.setAttribute("download", _this.generateSlug(composition.metadata.title) + ".zip");
          downloadLink.click();
          _this.setState({
            "opened_menu": false
          })
        });
      });
    });
  }

  setAssetsUidsAndPushAssets(composition) {
    var _this = this;
    var structure = composition.structure;
    composition.assets = [];

    structure = structure.filter(function(element) { return element.type != "temp-slot"; });

    for (var i = 0; i < structure.length; i++) {
      var element = structure[i];
      element.dam_assets = [];

      if (element.background_image !== null && element.background_image !== undefined) {
        if (_this.getParameterByName("uid", element.background_image) !== null) {
          element.dam_assets.push({
            "type": "image",
            "id": _this.getParameterByName("uid", element.background_image)
          });
        };
        composition.assets.push(element.background_image);
      };

      if (element.avatar !== null && element.avatar !== undefined) {
        if (_this.getParameterByName("uid", element.avatar) !== null) {
          element.dam_assets.push({
            "type": "image",
            "id": _this.getParameterByName("uid", element.avatar)
          });
        };
        composition.assets.push(element.avatar);
      };

      if (element.image !== null && element.image !== undefined) {
        if (_this.getParameterByName("uid", element.image) !== null) {
          element.dam_assets.push({
            "type": "image",
            "id": _this.getParameterByName("uid", element.image)
          });
        };
        composition.assets.push(element.image);
      };

      if (element.video !== null && element.video !== undefined) {
        if (_this.getParameterByName("uid", element.video) !== null) {
          element.dam_assets.push({
            "type": "video",
            "id": _this.getParameterByName("uid", element.video)
          });
        };
        composition.assets.push(element.video);
      };

      if (element.audio !== null && element.audio !== undefined) {
        if (_this.getParameterByName("uid", element.audio) !== null) {
          element.dam_assets.push({
            "type": "audio",
            "id": _this.getParameterByName("uid", element.audio)
          });
        };
        composition.assets.push(element.audio);
      };

      if (element.file !== null && element.file !== undefined) {
        if (_this.getParameterByName("uid", element.file) !== null) {
          element.dam_assets.push({
            "type": "file",
            "id": _this.getParameterByName("uid", element.file)
          });
        };
        composition.assets.push(element.file);
      };

      if (element.type === "accordion" || element.type === "tabs" || element.type === "quiz-1" || element.type === "quiz-2" || element.type === "quiz-3" || element.type === "quiz-4" ) {
        var items = (element.type === "quiz-1" || element.type === "quiz-2" || element.type === "quiz-3" || element.type === "quiz-4") ? element.questions : element.items;
        for (var j = 0; j < items.length; j++) {
          var item = items[j];

          if (item.image !== null && item.image !== undefined) {
            if (_this.getParameterByName("uid", item.image) !== null) {
              element.dam_assets.push({
                "type": "image",
                "id": _this.getParameterByName("uid", item.image)
              });
            };
            composition.assets.push(item.image);
          };

          if (item.video !== null && item.video !== undefined) {
            if (_this.getParameterByName("uid", item.video) !== null) {
              element.dam_assets.push({
                "type": "video",
                "id": _this.getParameterByName("uid", item.video)
              });
            };
            composition.assets.push(item.video);
          };

          if (element.type === "quiz-1" || element.type === "quiz-2" || element.type === "quiz-3" || element.type === "quiz-3") {
            item.answered = false;

            var choices = item.choices;
            for (var k = 0; k < choices.length; k++) {
              var choice = choices[k];
              if (choice.image && _this.getParameterByName("uid", choice.image) !== null) {
                element.dam_assets.push({
                  "type": "image",
                  "id": _this.getParameterByName("uid", choice.image)
                });
                composition.assets.push(choice.image);

              }

              if (choice.video && _this.getParameterByName("uid", choice.video) !== null) {
                element.dam_assets.push({
                  "type": "video",
                  "id": _this.getParameterByName("uid", choice.video)
                });
                composition.assets.push(choice.video);
              }
            }

            if (item.feedback_correct) {
              if (item.feedback_correct.image && _this.getParameterByName("uid", item.feedback_correct.image) !== null) {
                element.dam_assets.push({
                  "type": "image",
                  "id": _this.getParameterByName("uid", item.feedback_correct.image)
                });
                composition.assets.push(item.feedback_correct.image);
              }
              if (item.feedback_correct.video && _this.getParameterByName("uid", item.feedback_correct.video) !== null) {
                element.dam_assets.push({
                  "type": "video",
                  "id": _this.getParameterByName("uid", item.feedback_correct.video)
                });
                composition.assets.push(item.feedback_correct.video);
              }
            }
            if (item.feedback_default) {
              if (item.feedback_default.image && _this.getParameterByName("uid", item.feedback_default.image) !== null) {
                element.dam_assets.push({
                  "type": "image",
                  "id": _this.getParameterByName("uid", item.feedback_default.image)
                });
                composition.assets.push(item.feedback_default.image);
              }
              if (item.feedback_default.video && _this.getParameterByName("uid", item.feedback_default.video) !== null) {
                element.dam_assets.push({
                  "type": "video",
                  "id": _this.getParameterByName("uid", item.feedback_default.video)
                });
                composition.assets.push(item.feedback_default.video);
              }
            }
            if (item.feedback_incorrect) {
              if (item.feedback_incorrect.image && _this.getParameterByName("uid", item.feedback_incorrect.image) !== null) {
                element.dam_assets.push({
                  "type": "image",
                  "id": _this.getParameterByName("uid", item.feedback_incorrect.image)
                });
                composition.assets.push(item.feedback_incorrect.image);
              }
              if (item.feedback_incorrect.video && _this.getParameterByName("uid", item.feedback_incorrect.video) !== null) {
                element.dam_assets.push({
                  "type": "video",
                  "id": _this.getParameterByName("uid", item.feedback_incorrect.video)
                });
                composition.assets.push(item.feedback_incorrect.video);
              }
            }
          }
        };
      };

      if (element.type === "images-gallery") {
        var items = element.images;

        for (var j = 0; j < items.length; j++) {
          var item = items[j];

          element.dam_assets.push({
            "type": "image",
            "id": _this.getParameterByName("uid", item.src)
          })
          composition.assets.push(item.src);
        }
      }

      if (element.type === "quote-6") {
        const items = element.items;

        for (let j = 0; j < items.length; j++) {
          const item = items[j];

          element.dam_assets.push({
            "type": "image",
            "id": _this.getParameterByName("uid", item.avatar)
          })
          composition.assets.push(item.avatar);
        }
      }

      if (element.type === "gallery-1") {
        const items = element.items;

        for (let j = 0; j < items.length; j++) {
          const item = items[j];

          element.dam_assets.push({
            "type": "image",
            "id": _this.getParameterByName("uid", item.image)
          })
          composition.assets.push(item.image);
        }
      }

      if (element.type === "flashcards-1" || element.type === "flashcards-2") {
        var items = element.items;
        for (var j = 0; j < items.length; j++) {
          var item = items[j];

          if (item.back_card.centered_image !== null) {
            element.dam_assets.push({
              "type": "image",
              "id": _this.getParameterByName("uid", item.back_card.centered_image)
            });
            composition.assets.push(item.back_card.centered_image);
          };

          if (item.back_card.fullscreen_image !== null) {
            element.dam_assets.push({
              "type": "image",
              "id": _this.getParameterByName("uid", item.back_card.fullscreen_image)
            });
            composition.assets.push(item.back_card.fullscreen_image);
          };

          if (item.front_card.centered_image !== null) {
            element.dam_assets.push({
              "type": "image",
              "id": _this.getParameterByName("uid", item.front_card.centered_image)
            });
            composition.assets.push(item.front_card.centered_image);
          };

          if (item.front_card.fullscreen_image !== null) {
            element.dam_assets.push({
              "type": "image",
              "id": _this.getParameterByName("uid", item.front_card.fullscreen_image)
            });
            composition.assets.push(item.front_card.fullscreen_image);
          };
        };
      };

      if (element.type === "hotspots-1") {
        const {spots = []} = element;

        for (let spotCounter = 0; spotCounter < spots.length; spotCounter++) {
          const spot = spots[spotCounter];
          const {image = null, audio = null} = spot;

          if (image !== null) {
            element.dam_assets.push({
              "type": "image",
              "id": _this.getParameterByName("uid", image)
            });
            composition.assets.push(image);
          }

          if (audio !== null) {
            element.dam_assets.push({
              "type": "audio",
              "id": _this.getParameterByName("uid", audio)
            });
            composition.assets.push(audio);
          }
        }
      }

      if (element.type === "attachment-1") {
        const {items = []} = element;

        for (let counter = 0; counter < items.length; counter++) {
          const item = items[counter];
          const {original_url} = item;

          if (original_url !== null) {
            element.dam_assets.push({
              "type": "file",
              "id": _this.getParameterByName("uid", original_url)
            });
            composition.assets.push(original_url);
          }
        }
      }

      if (element.type === "timeline-1") {
        const {items = []} = element;

        for (let index = 0; index < items.length; index++) {
          const item = items[index];
          const {image = null, audio = null} = item;

          if (image !== null) {
            element.dam_assets.push({
              "type": "image",
              "id": _this.getParameterByName("uid", image)
            });
            composition.assets.push(image);
          }

          if (audio !== null) {
            element.dam_assets.push({
              "type": "audio",
              "id": _this.getParameterByName("uid", audio)
            });
            composition.assets.push(audio);
          }
        }
      }

      if (element.type === "steps-1") {
        const {items = []} = element;

        for (let index = 0; index < items.length; index++) {
          const item = items[index];
          const {image = null, audio = null} = item;

          if (image !== null) {
            element.dam_assets.push({
              "type": "image",
              "id": _this.getParameterByName("uid", image)
            });
            composition.assets.push(image);
          }

          if (audio !== null) {
            element.dam_assets.push({
              "type": "audio",
              "id": _this.getParameterByName("uid", audio)
            });
            composition.assets.push(audio);
          }
        }
      }
    }

    composition.structure = structure;

    return composition;
  }

  generateSlug(str) {
    return str !== null ? str.replace(/\s+/g, '-').toLowerCase() : "Untitled";
  }

  onPreviewClick() {
    var _this = this;
    var mirrorComposition = this.state.mirror_composition;
    var composition = JSON.stringify(this.state.composition);
    if (mirrorComposition !== composition && this.props.match.params.content_uid !== undefined) {
      _this.setState({
        "redirect_to_preview": true
      }, function () {
        _this.onSaveClick();
      });
    } else {
      _this.redirectToPreview();
    };
  }

  onToggleMenu() {
    this.setState({
      "opened_menu": !this.state.opened_menu
    });
  }

  setCurrentVersion() {
    var composition = this.state.composition;
    composition.version = "1.1";
    this.setState({
      "composition": composition
    });
    return composition;
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  getCompositionFile() {
    // var composition = this.state.composition;
    var composition = this.setAssetsUidsAndPushAssets(this.state.composition);
    composition = this.setCurrentVersion();
    var myBlob = new Blob([JSON.stringify(composition)], { type: "digitalpages/composer" });
    return this.blobToFile(myBlob, "composition.rdpcomposer");
  }

  refreshIds() {
    var composition = JSON.parse(JSON.stringify(this.state.composition));

    var structure = composition.structure;
    for (var i = 0; i < structure.length; i++) {
      var obj = structure[i];
      obj.id = uuidv1();

      var images = obj.images;
      if (images) {
        for (var j = 0; j < images.length; j++) {
          images[j].id = uuidv1();
        }
      }

      var items = obj.items;
      if (items) {
        for (var j = 0; j < items.length; j++) {
          items[j].id = uuidv1();
        }
      }

      var questions = obj.questions;
      if (questions) {
        for (var j = 0; j < questions.length; j++) {
          questions[j].id = uuidv1();

          var choices = questions[j].choices;
          if (choices) {
            for (var k = 0; k < choices.length; k++) {
              choices[k].id = uuidv1();
            }
          }
        }
      }
    }

    composition.structure = structure;

    return composition;
  }

  correctConnector()
  {
    var connector = null;
    var project = JSON.parse(window.localStorage.getItem("rdp-composer-active-project"));
    if (project && project.connectors) connector = project.connectors.find(p=> p.internal_name == "Dam");
    if (!connector && project && project.connectors) connector = project.connectors[0];

    return connector;
  }

  onSaveClick(onlyEdit) {
    var _this = this;
    const { connectors, uid: projectUid } = JSON.parse(window.localStorage.getItem("rdp-composer-active-project"));
    // const composerConnector = getConnectorFromArray(connectors, "Composer_1", "ContentManager");
    // const composerConnector = connectors.find(item => item.type === "Composer_1");
    // var connectorUid = composerConnector.uid || null;

    // if (connectorUid === null) {
    //   const projectConnectorScope = connectors.find(connector => connector.scope === "Project");
    //   connectorUid = projectConnectorScope.uid || null;
    // }

    var connector = this.correctConnector();

    var url = `https://${RDP_CONFIG.domain}/storage/v1.0/upload/connector/uid/${connector.uid}?manual_project_uid=${projectUid}`;
    var method = "POST";
    var fd = new FormData();
    var files = this.getCompositionFile();
    var userData = JSON.parse(window.localStorage.getItem("rdp-composer-user-data"));
    var type = userData.type;
    var accessToken = userData.access_token;

    _this.setState({
      "opened_menu": false,
      "displayed_preloader": true
    });

    fd.append('file', files, "composition.rdpcomposer")

    if (this.props.match.params.content_uid !== undefined) {
      method = "PUT";
      url = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${this.props.match.params.content_uid}&manual_project_uid=${projectUid}`;
    };

    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        type: method,
        data: fd,
        contentType: false,
        processData: false,
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        },
        success: function (response) {
          var newId = response[0].uid;

          _this.setState({
            "displayed_preloader": false,
            "mirror_composition": JSON.stringify(_this.state.composition)
          });
          if (_this.state.redirect_to_home === true) {
            if (onlyEdit) {
              _this.sendMessage({
                "origin": "composer",
                "new_composition_uid": newId,
                "action": "close"
              });
            } else {
              _this.redirectToHome()
            }
          } else if (_this.state.redirect_to_preview === true) {
            _this.redirectToPreview(newId)
          } else {
            var path = _this.state.is_embed > -1 ? "#/embed/composer/" : "#/composer/";
            var lastPath = onlyEdit ? "/onlyedit" : "";

            window.location.href = window.location.href.split("#/")[0] + path + newId + lastPath;

            _this.updateLocationWithID(newId);

            const lastVersion = _this.state.versions[_this.state.versions.length - 1];
            if (lastVersion) {
              _this.setState({ 
                versions: [..._this.state.versions, {
                  ...lastVersion,
                  content_uid: newId,
                  label: `Versão ${_this.state.versions.length + 1}`
                }]
              })
            }
          }
          resolve(response);
        },
        error: function (response) {
          _this.setState({
            "displayed_preloader": false
          });
          reject(response);
        }
      });
    });
  }

  updateLocationWithID(id) {
    const _this = this;
    const path = _this.state.is_embed ? "#/embed/composer/" : "#/composer/";
    const query = false === _this.state.is_embed ? `?file_uid=${new Url(window.location.href).getQueryParams().file_uid}` : "";
    window.location.href = window.location.href.split("#/")[0] + path + id + query;
  }

  onConfigurationClick() {
    this.setState({
      "opened_configurations": true,
      "opened_menu": false
    })
  }

  onVersionsHistoryClick() {
    const [first] = this.state.versions;
    const last = this.state.versions[this.state.versions.length - 1];

    console.log(first, last);

    this.setState({
      "render_versions_history": true,
      ...(first && last && {
        first_version: first.content_uid,
        second_version: last.content_uid
      })
    })
  }

  onConfigurationsClose() {
    this.setState({
      "opened_configurations": false
    })
  }

  renderElementEdit() {
    if (this.state.enabled_element_edit_props === true) {
      return <ElementEdit structure={this.state.composition.structure} labels={this.state.labels} content={this.state.element_edit_props} onChange={(element) => this.onElementChange(element)} onClose={() => this.onElementEditClose()} />;
    };
  }

  renderElementMessages() {
    if (this.state.enabled_element_message_object_uid !== null) {
      return <ElementMessages labels={this.state.labels} users={this.state.project_users} content_uid={this.props.match.params.content_uid} user_uid={UserService.getUserInfo().uid} object_uid={this.state.enabled_element_message_object_uid} onClose={() => this.onElementCloseMessages()} />;
    };
  }

  renderConfigurations() {
    if (this.state.opened_configurations === true) {
      return <Configurations
        composition={this.state.composition}
        onClose={() => this.onConfigurationsClose()}
        onMetadataChange={(index, content) => this.onMetadataChange(index, content)}
        labels={this.state.labels}
      />;
    };
  }

  renderPreloader() {
    if (this.state.displayed_preloader === true) {
      return <div className="rdp-composer-composer-preloader"></div>;
    }
  }

  redirectToHome() {
    var path = this.state.is_embed ? "/embed/" : "/";
    window.location = window.location.origin + window.location.pathname + "#" + path;
  }

  redirectToPreview(uid) {
    var path = this.state.is_embed ? "#/embed/preview/" : "#/preview/";
    var lastPath = this.props.match.path.search("onlyedit") > -1 ? "/onlyedit" : "";

    var contentUid = uid !== undefined ? uid : this.props.match.params.content_uid;
    window.location.href = window.location.href.split("#/")[0] + path + contentUid + lastPath;
  }

  sendMessage(obj) {
    window.parent.postMessage(obj, "*");
  }

  async onBackClick() {
    var _this = this;
    var onlyEdit = this.props.match.path.search("onlyedit") > -1;
    const mirrorComposition = this.state.mirror_composition;
    const composition = JSON.stringify(this.state.composition);
    const mirror = JSON.stringify(this.state.composition_backup);

    if(composition === mirror) {
      _this.redirectToHome();
      return;
    }

    _this.setState({ displayed_preloader: true })

    const userUuid = UserService.getUUid();
    const compositionCpy = {...this.state.composition};
    {
      const userData = UserService.getUser();
      const type = userData.type;
      const accessToken = userData.access_token;

      const formatedUrl = window.location.href.replace('/?#', '');

      const fileUid = new URL(formatedUrl).searchParams.get('file_uid');
      // const fileUid = new Url(window.location.href).getQueryParams().file_uid;

      if (fileUid !== undefined) {
        const resp = await DamService.searchContent(`filter=(file_uid eq '${fileUid}')`, "", type, accessToken)

        if (resp && resp.results.length === 1) {
          const file = resp.results[0];
          const content = await DamService.getContent(file.document.content_uid, accessToken);

          const comp = JSON.parse(content);

          if ("metadata" in comp && "editor_uuid" in comp.metadata && comp.metadata.editor_uuid !== userUuid) {
            Swal.fire({
              title: this.state.labels.attention,
              text: this.state.labels.lock_loss_message,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#2d7b45',
              confirmButtonText: this.state.labels.yes,
              cancelButtonText: this.state.labels.no,
              reverseButtons: true
            }).then((result) => {
              if (result.isConfirmed) {
                const comp = JSON.parse(composition);
                comp.metadata.editor_uuid = userUuid;

                this.setState({
                  "composition": comp
                }, () => {
                  this.onSaveClick().then(() => {
                    this.redirectToHome();
                  });
                });
              } else if (
                result.dismiss === Swal.DismissReason.cancel
              ) {
                this.redirectToHome();
              }
            });
            return;
          }
        }
      }
    }

    if ("metadata" in compositionCpy && "editor_uuid" in compositionCpy.metadata && compositionCpy.metadata.editor_uuid === userUuid) {
      delete compositionCpy.metadata.editor_uuid;
    }

    if (mirrorComposition !== composition && this.props.match.params.content_uid !== undefined) {
      _this.setState({
        "redirect_to_home": true,
        composition: compositionCpy
      }, function () {
        _this.onSaveClick(onlyEdit);
        // this.redirectToHome();
      });
    } else {
      if (onlyEdit) {
        _this.sendMessage({
          "origin": "composer",
          "new_composition_uid": this.props.match.params.content_uid,
          "action": "close"
        });
      } else {
        _this.redirectToHome();
      }
    };
    // window.location = window.location.origin + window.location.pathname + "#/";
  }

  renderDownloadingPackage() {
    if (this.state.downloading === true) {
      return <Popup
        message="Aguarde, o pacote SCORM está sendo gerado e em breve o download será inicializado."
        opacity={0.95}
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
    };
  }

  renderGoogleFonts() {
    var fonts = this.state.google_fonts;
    if (this.state.google_fonts_render_modal === true && fonts !== null) {
      return <div className="rdp-composer-google-fonts">
        <ul>
          {fonts.map(function(font, index) {
            return <li key={index} >{font.family}</li>
          })}
        </ul>
      </div>
    }
  }

  handleSaveComposition(onlyEdit) {
    const userUuid = UserService.getUUid();
    const compositionCpy = {...this.state.composition};

    if ("metadata" in compositionCpy && "editor_uuid" in compositionCpy.metadata && compositionCpy.metadata.editor_uuid === userUuid) {
      delete compositionCpy.metadata.editor_uuid;
    }

    this.setState({
      composition: compositionCpy
    }, () => {
      this.onSaveClick(onlyEdit);
    });
  }

  render_first_version_items

  toggleFirstVersionItems() {
    this.setState({
      "render_first_version_items": !this.state.render_first_version_items,
    })
  }

  toggleSecondVersionItems() {
    this.setState({
      "render_second_version_items": !this.state.render_second_version_items,
    })
  }

  changeFirstVersion(uid) {
    this.setState({
      "render_first_version_items": false,
      "first_version": uid
    })
  }

  changeSecondVersion(uid) {
    this.setState({
      "render_second_version_items": false,
      "second_version": uid
    })
  }

  editComposition(uid) {
    const version = this.state.versions.find(item => item.content_uid === uid);
    if (!version) return;
    // window.location = window.location.href + "composer/" + uid
    window.location.href = window.location.origin + window.location.pathname + "#/composer/" + uid + "?file_uid=" + version.file_uid;;
    // window.location.reload();
  }

  onUpdateComposition(newComposition) {
    const _this = this;
    _this.setState({ composition: newComposition })
  }

  render() {
    var _this = this;
    var versions = this.state.versions;
    var composition = this.state.composition;
    var structure = composition.structure;
    var ui = composition.interface;
    var onlyEdit = this.props.match.path.search("onlyedit") > -1;
    return (
      <CompositionProvider
        onSaveComposition={() => _this.onSaveClick()}
        clearBeforeStructure={() => _this.setState({ structure_before_removed: null })}
        beforeStructure={_this.state.structure_before_removed}
        editing={_this.state.element_edit_props}
        composition={_this.state.composition} 
        onChangeComposition={newComposition => _this.onUpdateComposition(newComposition)}>
        <div className={`rdp-composer-home rdp-composer-home-theme-${RDP_CONFIG['theme']}`}>
          {this.state.render_versions_history &&
            <div className="rdp-composer-versions-history">
              <div className="rdp-composer-versions-history-header">
                <span class="rdp-composer-sidebar-back" onClick={() => this.setState({"render_versions_history": false})}>Voltar</span>
                <div className="rdp-composer-versions-history-header-item">
                  <button className={`rdp-composer-versions-history-header-item-selected ${this.state.render_first_version_items ? "opened" : ""}`} onClick={() => this.toggleFirstVersionItems()}>
                    {versions.find(item => item.content_uid === this.state.first_version).label}
                  </button>
                  <span className="rdp-composer-versions-history-header-item-edit" onClick={() => this.editComposition(versions.find(item => item.content_uid === this.state.first_version).content_uid)}></span>
                  {this.state.render_first_version_items &&
                    <ul className="rdp-composer-versions-history-header-item-versions">
                      {versions.map(function(version, index) {
                        return <li className="rdp-composer-versions-history-header-item-version" key={index} onClick={() => _this.changeFirstVersion(version.content_uid)}>{version.label}</li>
                      })}
                    </ul>
                  }
                </div>
                <div className="rdp-composer-versions-history-header-item">
                  <button className={`rdp-composer-versions-history-header-item-selected ${this.state.render_second_version_items ? "opened" : ""}`} onClick={() => this.toggleSecondVersionItems()}>
                    {versions.find(item => item.content_uid === this.state.second_version).label}
                  </button>
                  <span className="rdp-composer-versions-history-header-item-edit" onClick={() => this.editComposition(versions.find(item => item.content_uid === this.state.second_version).content_uid)}></span>
                  {this.state.render_second_version_items &&
                    <ul className="rdp-composer-versions-history-header-item-versions">
                      {versions.map(function(version, index) {
                        return <li className="rdp-composer-versions-history-header-item-version" key={index} onClick={() => _this.changeSecondVersion(version.content_uid)}>{version.label}</li>
                      })}
                    </ul>
                  }
                </div>
              </div>
              <div className="rdp-composer-versions-history-columns">
                <div className="rdp-composer-versions-history-column">
                  <iframe title="Versão Coluna 1" src={this.getIframeSrc(this.state.first_version)}></iframe>
                </div>
                <div className="rdp-composer-versions-history-column">
                  <iframe title="Versão Coluna 2" src={this.getIframeSrc(this.state.second_version)}></iframe>
                </div>
              </div>
            </div>
          }

          {this.renderDownloadingPackage()}
          {this.renderPreloader()}
          {this.renderElementEdit()}
          {this.renderElementMessages()}
          {this.renderConfigurations()}
          {this.renderGoogleFonts()}
          <Sidebar
            onBackClick={() => this.onBackClick()}
            openedMenu={this.state.opened_menu}
            onClickSubitem={(type) => this.onClickSubitem(type)}
            onSaveClick={() => this.handleSaveComposition(onlyEdit)}
            onVersionsHistoryClick={() => this.onVersionsHistoryClick()}
            onConfigurationClick={() => this.onConfigurationClick()}
            onPreviewClick={() => this.onPreviewClick()}
            previewEnabled={this.props.match.params.content_uid !== undefined}
            onZipExport={this.state.can_download ? (previousErrorResolved) => this.onZipExport(previousErrorResolved) : null}
            onToggleMenu={() => this.onToggleMenu()}
            labels={this.state.labels}
            isEmbed={this.state.is_embed}
            composition={this.state.composition}
          />
          <Compositions
            editable={true}
            composition={this.state.composition}
            structure={structure}
            ui={ui}
            labels={this.state.labels}
            renderObjectMessages={this.state.can_send_object_messages}
            onElementOpenMessages={(element) => this.onElementOpenMessages(element)}
            onElementCopy={(element, index) => this.onElementCopy(element, index)}
            onElementEdit={(element) => this.onElementEdit(element)}
            onElementRemove={(element) => this.onElementRemove(element)}
            onElementMoveUp={(index) => this.onElementMoveUp(index)}
            onElementMoveDown={(index) => this.onElementMoveDown(index)}
            onContentChange={(index, content) => this.onContentChange(index, content)}
            onElementAdd={(index) => this.onElementAdd(index)}
          />
        </div>
      </CompositionProvider>

    );
  }
}
