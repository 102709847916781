import React, { Component } from 'react';
import { Quill } from '../../components';
import 'animate.css/animate.min.css';
import './element-note-1.css';

export default class ElementNote1 extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(text) {
    var content = this.props.content;
    content.text = text;
    this.props.onChange(content);
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;

    var textStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    };

    return <div className={`${className} rdp-element-note-1 ${this.getFont(content.font_family) || ""}`} style={textStyle}>
      {this.props.editable ? (
        <>
          {headerActionsTemplate}
          <div className="rdp-element-note-1-container" style={{background: content.secondary_color, borderColor: content.primary_color}}>
            <span className="rdp-element-note-1-container-icon">
              {content.icon === "info" &&
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                  <path fill={content.primary_color} d="M224 152c0-13.2 10.8-24 24-24h16c13.2 0 24 10.8 24 24v16c0 13.2-10.8 24-24 24h-16c-13.2 0-24-10.8-24-24v-16z"></path>
                  <path fill={content.primary_color} d="M320 384h-128v-32h32v-96h-32v-32h96v128h32z"></path>
                  <path fill={content.primary_color} d="M256 0c-141.385 0-256 114.615-256 256s114.615 256 256 256 256-114.615 256-256-114.615-256-256-256zM256 464c-114.875 0-208-93.125-208-208s93.125-208 208-208 208 93.125 208 208-93.125 208-208 208z"></path>
                </svg>
              }
            </span>
            <Quill
              value={content.text}
              onChange={(text) => this.handleChange(text)}
            />
          </div>
          {footerActionsTemplate}
        </>
      ) : (
        <div className="rdp-element-note-1-container" style={{background: content.secondary_color, borderColor: content.primary_color}}>
          <span className="rdp-element-note-1-container-icon">
            {content.icon === "info" &&
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                <path fill={content.primary_color} d="M224 152c0-13.2 10.8-24 24-24h16c13.2 0 24 10.8 24 24v16c0 13.2-10.8 24-24 24h-16c-13.2 0-24-10.8-24-24v-16z"></path>
                <path fill={content.primary_color} d="M320 384h-128v-32h32v-96h-32v-32h96v128h32z"></path>
                <path fill={content.primary_color} d="M256 0c-141.385 0-256 114.615-256 256s114.615 256 256 256 256-114.615 256-256-114.615-256-256-256zM256 464c-114.875 0-208-93.125-208-208s93.125-208 208-208 208 93.125 208 208-93.125 208-208 208z"></path>
              </svg>
            }
          </span>
          <div className="editor" dangerouslySetInnerHTML={{__html: content.text}}></div>
        </div>
      )}
    </div>;
  }
}
