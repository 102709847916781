import React from "react";

import { useStructure } from "../../../hooks/context/structure";
import { useConnectors } from "../../../hooks/useConnectors";

const GENERATIVE_IA_CONNECTOR = "IConnectorGenerativeIA";

export const CheckboxGenerativeFeedback = () => {
  const { content, onChangeIntegrations, setContent } = useStructure();
  const { getConnectorShouldHaveFeature } = useConnectors();

  const generativeConnector = getConnectorShouldHaveFeature(GENERATIVE_IA_CONNECTOR);

  if (!generativeConnector) return null;

  const onChangeGenerativeIntegration = (generative) => {
    setContent({ ...content, ai_feedback: generative });
    onChangeIntegrations("generative.feedback", generativeConnector.uid);
  };

  return (
    <div
      style={{
        display: "flex",
        gap: 5,
        padding: 20,
        alignItems: "center",
      }}
    >
      <span className="rdp-composer-element-item-secondary-label">Feedback por IA</span>
      <input
        className="rdp-composer-element-item-secondary-checkbox"
        type="checkbox"
        checked={content.ai_feedback || false}
        onClick={(e) => onChangeGenerativeIntegration(!content.ai_feedback)}
      ></input>
    </div>
  );
};
