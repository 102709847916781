import React, { Component } from 'react';
import uuidv1 from 'uuid/v1';
import { UserService } from '../../services/user';
import './element-list-properties.css';
import {
  RDPDamWidget, ColorPicker, FontPicker, RelToolbarQuill
} from '../../components';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

export default class ElementListProperties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "dam_widget_opened": false,
      "active_tab": "content",
      "rdp_dam_widget_type": null,
      "media_active_item": null
    }

    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.onPrimaryColorChange = this.onPrimaryColorChange.bind(this);
    this.onSecondaryColorChange = this.onSecondaryColorChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
  }

  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange(color) {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPrimaryColorChange(color) {
    var content = this.state.content;
    content.primary_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSecondaryColorChange(color) {
    var content = this.state.content;
    content.secondary_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onTitleChange(e, index) {
    var content = this.state.content;
    var items = content.items;
    items[index].title = e.currentTarget.value;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onTextChange(text, index) {
    var content = this.state.content;
    var items = content.items;
    items[index].text = text;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onChangeTab(target) {
    this.setState({
      "active_tab": target
    })
  }

  onRemoveItem(index) {
    var content = this.state.content;
    var items = content.items;
    var temp = [];
    for (var i = 0; i < items.length; i++) {
      if (i !== index) {
        temp.push(items[i])
      }
    }
    content.items = temp;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    })
  }

  onAddItem() {
    var content = this.state.content;
    var items = content.items;
    items.push({
      "id": uuidv1(),
      "text": '<p><span style=" ' + `color: #313537;` + ' font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula.</span></p>',
      "image": null,
      "video": null
    });
    this.setState({
      "content": content
    })
  }

  onFontChange(font) {
    var content = this.state.content;
    content.font_family = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  renderFont() {
    var content = this.state.content;
    var labels = this.props.labels;

    if (content.font_family) {
      return <FontPicker font={content.font_family} onFontChange={(font) => this.onFontChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontChange(font)} noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  onElementMoveUp(index) {
    this.onElementMove(index, index - 1);
  }

  onElementMoveDown(index) {
    this.onElementMove(index, index + 1);
  }

  onElementMove(old_index, new_index) {
    var content = this.state.content;
    var items = content.items;

    if (new_index >= items.length) {
      var k = new_index - items.length + 1;
      while (k--) {
        items.push(undefined);
      }
    }
    items.splice(new_index, 0, items.splice(old_index, 1)[0]);

    content.items = items;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }


  renderContent() {
    var _this = this;
    var activeTab = this.state.active_tab;
    var content = this.state.content;
    var labels = this.props.labels;
    if (activeTab === "content") {
      var items = content.items;
      return <>
        <ul>
          {items.map(function (item, index) {
            return <li key={item.id} className="element-list-properties-item">
              <div className="element-list-properties-item-position">
                {index > 0 &&
                  <span className="element-list-properties-item-position-item up" onClick={() => _this.onElementMoveUp(index)}></span>
                }
                {index < (items.length - 1) &&
                  <span className="element-list-properties-item-position-item down" onClick={() => _this.onElementMoveDown(index)}></span>
                }
              </div>
              <span className="element-list-properties-item-remove" onClick={() => _this.onRemoveItem(index)}></span>
              <span className="element-list-properties-item-label">{labels.elements.settings.element_list.content_label_description}</span>
              <RelToolbarQuill onChange={(e) => _this.onTextChange(e, index)} value={item.text} />
            </li>
          })}
        </ul>
        <button className="element-list-properties-item-new" onClick={() => this.onAddItem()}>{labels.elements.settings.element_list.content_label_btn_add_item}</button>
      </>
    } else if (activeTab === "configurations") {
      return <div className="element-list-properties-configurations">
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_list.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)} defaultValue={content.content_title}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_list.settings_label_background_color}</span>
        <ColorPicker labels={this.props.labels} color={content.background_color} onChange={this.onBackgroundColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_list.settings_label_primary_color}</span>
        <ColorPicker labels={this.props.labels} color={content.primary_color} onChange={this.onPrimaryColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_list.settings_label_secondary_color}</span>
        <ColorPicker labels={this.props.labels} color={content.secondary_color} onChange={this.onSecondaryColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_list.settings_label_margin_top}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_list.settings_label_margin_bottom}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_list.settings_label_label_font}:</span>
        {this.renderFont()}

        <PreferenceRequiredInteraction
          title={labels.elements.settings.preference_required_interaction.title}
          label={labels.elements.settings.preference_required_interaction.label}
          labels={labels}
          content={content}
          onContentChange={c => {
            this.setState({ content: c });
            this.props.onContentChange(c);
          }}
        />
      </div>
    }
  }


  render() {
    var activeTab = this.state.active_tab;
    var labels = this.props.labels;

    return (<div className="rdp-element-list-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className={`rdp-composer-element-edit-tabs-item ${activeTab === "content" ? "active" : ""}`} onClick={() => this.onChangeTab("content")}>{labels.elements.common_settings.label_tab_content}</li>
        <li className={`rdp-composer-element-edit-tabs-item ${activeTab === "configurations" ? "active" : ""}`} onClick={() => this.onChangeTab("configurations")}>{labels.elements.common_settings.label_tab_settings}</li>
      </ul>
      <div className="rdp-composer-element-edit-body">
        {this.renderContent()}
      </div>
    </div>);
  }
}
