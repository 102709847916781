import styled from "styled-components";

export const QuizFinishResult = styled.div`
  height: 450px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RemakeButton = styled.button`
  background: none;
  transition: all 0.3s ease-out;
  cursor: pointer;
  padding: 6px;
  width: fit-content;
  display: block;
  margin: 40px auto;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
  
  &:hover {
    border: 1px dashed #f1f1f1;
    background: #f1f1f1;
  }

  &:focus {
    outline: 2px solid ${props => props.primaryColor || "transparent"} !important;
    outline-offset: 2px !important;
  }
`;

export const RemakeButtonIcon = styled.span`
  width: 25px;
  height: 38px;
  display: block;
  margin: auto;
  background-size: 100%;
  text-align: center;
`;

export const RemakeButtonText = styled.span`
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
`;

export const ResultMessage = styled.span`
  color: #6b6b6b;
  font-size: 20px;
  font-weight: 1000;
  font-family: Lato-Bold,sans-serif;
  display: block;
  text-align: center;
  line-height: 30px;
`;