import React from "react";
import * as S from "./radio.styled";

function Radio(props) {
  const {
    value = "",
    primaryColor,
    checked,
    onChange,
    ...rest
  } = props;

  return (
    <S.Radio
      primaryColor={primaryColor}
    >
      <S.Input
        type="radio"
        checked={checked}
        value={value}
        onChange={onChange}
        readOnly={!!!onChange}
        {...rest}
      />
    </S.Radio>
  )
}

export default Radio;