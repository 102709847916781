import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { UserService } from '../../services/user';
import './element-3d-properties.css';
import {
  RDPDamWidget, ColorPicker
} from '../../components';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

export default class Element3dProperties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "dam_widget_opened": false
    }

    this.onFileChange = this.onFileChange.bind(this);
    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
  }

  onFileChange() {
    this.setState({
      "dam_widget_opened": true
    })
  }

  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange(color) {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange(asset) {
    var content = this.state.content;
    content.file = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onRDPDamWidgetClose() {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget() {
    if (this.state.dam_widget_opened === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="streams"
        onClose={() => this.onRDPDamWidgetClose()}
        onAssetChange={(asset) => this.onAssetChange(asset)}
      />;
    }
  }

  getFileUrl(audio) {
    var accessToken = UserService.getUser().access_token;
    if (audio !== "https://pocs.digitalpages.com.br/rdpcomposer/media/3d/3d.glb") {
      audio += "&access_token=" + accessToken;
    };
    return audio;
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;

    return (<div className="rdp-element-audio-properties">
      <Helmet>
        <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
      </Helmet>
      <ul className="rdp-composer-element-edit-tabs">
        <li className="rdp-composer-element-edit-tabs-item active">{labels.elements.common_settings.label_tab_settings}</li>
      </ul>
      <div className="rdp-composer-element-edit-body">
      <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_3d.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)} defaultValue={content.content_title}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_3d.settings_label_file}:</span>
        <model-viewer class="element-3d-area" src={this.getFileUrl(content.file)} shadow-intensity="1" ar ar-modes="webxr scene-viewer quick-look" camera-controls touch-action="pan-y" alt="A 3D model carousel"></model-viewer>
        <span className="rdp-composer-element-audio-btn" onClick={() => this.onFileChange()}></span>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_3d.settings_label_background_color}</span>
        <ColorPicker labels={this.props.labels} color={content.background} onChange={this.onBackgroundColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_3d.settings_label_margin_top}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_3d.settings_label_margin_bottom}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

        <PreferenceRequiredInteraction
          title={labels.elements.settings.preference_required_interaction.title}
          label={labels.elements.settings.preference_required_interaction.label}
          labels={labels}
          content={content}
          onContentChange={c => {
            this.setState({ content: c });
            this.props.onContentChange(c);
          }}
        />
      </div>
      {this.renderDAMWidget()}
    </div>);
  }
}
