export default function convertPresetProperty(elem, prop) {
  var obj = {};

  // consulta os presets salvos no localStorage;
  var stored = window.localStorage.getItem(window.location.host + "rdp-composer-converter-presets");

  // caso hajam presets salvos no localStorage, a estrutura é transformada em json;
  if (stored !== null) {
    obj = JSON.parse(stored);
  };

  elem = elem.replaceAll("-", "_");

  var key = `object_${elem}_${prop}`;

  // retorna o preset;
  return obj[key];
}