import axios from "axios";
import RDP_CONFIG from "../../config";

const api = axios.create({
  baseURL: `https://${RDP_CONFIG.domain}/`,
})

var userData = JSON.parse(window.localStorage.getItem("rdp-composer-user-data"));

if (userData) {
  var type = userData.type;
  var accessToken = userData.access_token;
  api.interceptors.request.use(
    (config) => {
      config.headers = {
        'Content-Type': 'application/json',
        "Authorization": type + " " + accessToken,
        "Project-Key" : RDP_CONFIG.projectKey,
        "Api-Env" : RDP_CONFIG.apiEnv
      };
      return config;
    },
    (error) => Promise.reject(error),
  );
}

const getGroupTree = async (schemaUid) => {
  try {
    const response = await api.get(`/auth/v1.1/entity/schema/uid/${schemaUid}/items`);

    return response.data.sort((group, sort) => group.row - sort.row);
  } catch (err) {
    console.log(err);
  }
};

export const getSkills = async () => {
  try {
    const response = await api.get(`/auth/v1.1/entity/schema/available/management?type=category`);
    const _schemas = response.data

    const schemasWithGroups = await Promise.all(
      _schemas.map(async (schema) => {
        const groups = await getGroupTree(schema.uid);
        return groups.map((group) => ({
          schema_name: schema.name,
          uid: group.uid,
          name: group.name,
          column: group.column,
          type: "Entity",
        }));
      })
    );

    return schemasWithGroups;
  } catch (err) {
    console.log(err);
  }
};
