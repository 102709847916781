import React, { Component } from 'react';
import { Quill } from '../../components';
import 'animate.css/animate.min.css';
import './element-statement-4.css';

export default class ElementStatement4 extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(text) {
    var content = this.props.content;
    content.text = text;
    this.props.onChange(content);
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;

    var textStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    };

    return <div className={`${className} rdp-element-statement-4 ${this.getFont(content.font_family) || ""}`} style={textStyle}>
      {this.props.editable ? (
        <>
          {headerActionsTemplate}
          <div className="rdp-element-statement-4-container">
            <div className="rdp-element-statement-4-container-border" style={{background: content.border_color}}></div>
            <Quill
              value={content.text}
              onChange={(text) => this.handleChange(text)}
            />
          </div>
          {footerActionsTemplate}
        </>
      ) : (
        <div className="rdp-element-statement-4-container">
          <div className="rdp-element-statement-4-container-border" style={{background: content.border_color}}></div>
          <div className="editor" dangerouslySetInnerHTML={{__html: content.text}}></div>
        </div>
      )}
    </div>;
  }
}
