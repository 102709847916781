import React, { Component } from 'react'
import './element-footer.css';

export default class ElementFooter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="rdp-composer-element-footer">
        <button className="rdp-composer-element-footer-more-btn" onClick={() => this.props.onAdd()}></button>
      </div>
    );
  }
}
