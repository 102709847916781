import React, { useState, useRef, useEffect } from "react";

import {
  Typography,
  Button,
  IconButton,
  Tooltip,
  Popover,
} from "@material-ui/core";

import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import FullScreenIcon from "@material-ui/icons/Fullscreen";

import { Col, Row } from "../Flex";

import * as S from "./styles";

const stringifyTime = (seconds) => {
  const [min, sec] = [seconds / 60, seconds % 60].map((t) =>
    Math.floor(t).toString().padStart(2, "0")
  );

  return `${min}:${sec}`;
};

const TimeLabel = ({ children, open, value }) => (
  <Tooltip
    open={open}
    enterTouchDelay={0}
    placement="top"
    title={stringifyTime(value)}
  >
    {children}
  </Tooltip>
);

const PlayerControls = ({
  title,
  playedSeconds,
  played,
  loadedSeconds,
  loaded,
  onSeek,
  duration,
  muted,
  setMuted,
  speed,
  setSpeed,
  isPlaying,
  setIsPlaying,
  volume,
  setVolume,
  anchors,
}) => {
  const containerRef = useRef();
  const speedBtnRef = useRef();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorsArray, setAnchorsArray] = useState([]);

  useEffect(() => {
    anchors && setAnchorsArray(anchors.map((anchor) => { 
      return { value: Number(anchor) }
    }));
  }, [anchors]);

  useEffect(() => {
    if (isPlaying) containerRef.current.classList.add("hide-controls");
    else containerRef.current.classList.remove("hide-controls");
  }, [isPlaying]);

  const togglePopover = () => {
    setPopoverOpen((p) => !p);
    if (!isPlaying) return;
    if (!popoverOpen) containerRef.current.classList.remove("hide-controls");
    else
      setTimeout(
        () => containerRef.current.classList.add("hide-controls"),
        500
      );
  };

  const secondThumbPos = Math.max(loadedSeconds, playedSeconds);

  return (
    <>
      <S.Container ref={containerRef}>
        <Row margin="10px 13px">
          <Typography variant="subtitle1" style={{ color: "white" }}>
            {title}
          </Typography>
        </Row>

        <S.ControlsArea>
          <IconButton>
            <FastRewindIcon />
          </IconButton>

          <IconButton onClick={() => setIsPlaying((p) => !p)}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>

          <IconButton>
            <FastForwardIcon />
          </IconButton>
        </S.ControlsArea>

        <S.BottomArea>
          <S.PlaybackSlider
            max={duration}
            value={[playedSeconds, secondThumbPos]}
            onChange={(_, [p1, p2]) => onSeek(p2 === secondThumbPos ? p1 : p2)}
            ValueLabelComponent={TimeLabel}
            track="inverted"
            marks={anchorsArray}
            disableSwap
          />

          <Row justify="space-between" fullWidth>
            <Row align="center">
              <IconButton onClick={() => setIsPlaying((p) => !p)}>
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>

              <IconButton onClick={() => setMuted((m) => !m)}>
                {muted ? (
                  <VolumeOffIcon />
                ) : volume > 50 ? (
                  <VolumeUpIcon />
                ) : volume > 0 ? (
                  <VolumeDownIcon style={{ right: 2 }} />
                ) : (
                  <VolumeMuteIcon style={{ right: 4 }} />
                )}
              </IconButton>

              <S.VolumeSlider
                muted={muted}
                max={100}
                value={volume}
                onChange={(_, value) => setVolume(value)}
              />

              <Typography
                variant="body2"
                style={{
                  color: "white",
                  marginLeft: 22,
                  marginBottom: 4,
                  width: "max-content",
                }}
              >
                {stringifyTime(playedSeconds)} / {stringifyTime(duration)}
              </Typography>
            </Row>

            <Row align="center">
              <Button ref={speedBtnRef} onClick={togglePopover} variant="text">
                <Typography variant="body2" style={{ color: "white" }}>
                  {speed}x
                </Typography>
              </Button>

              <IconButton>
                <FullScreenIcon />
              </IconButton>
            </Row>
          </Row>
        </S.BottomArea>
      </S.Container>

      <Popover
        open={!!popoverOpen}
        anchorEl={speedBtnRef.current}
        onClose={togglePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        TransitionProps={{ timeout: { appear: 0, enter: 90, exit: 200 } }}
      >
        <Col>
          {[0.5, 1, 1.5, 2].reverse().map((rate) => (
            <Button
              onClick={() => {
                setSpeed(rate);
                togglePopover();
              }}
              style={{ textTransform: "unset" }}
            >
              <Typography variant="body1" style={{ color: "#444" }}>
                {rate}x
              </Typography>
            </Button>
          ))}
        </Col>
      </Popover>
    </>
  );
};

export default PlayerControls;
