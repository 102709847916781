import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { TwitterPicker } from 'react-color';
import 'react-quill/dist/quill.bubble.css';
import './quill.css';
import katex from "katex";
import "katex/dist/katex.min.css";
import { LineHeightIcon, LineHeightIconString } from './line-height-icon';
import { getTheme } from '../../utils/getTheme';
import RDP_CONFIG from '../../config';
import ClipboardHandler from './handlers/clipboard';
window.katex = katex;

const LineHeightButton = () => (
  <button className="ql-custom">
    <LineHeightIcon />
  </button>
);
export default class Quill extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.onQuillColorChange = this.onQuillColorChange.bind(this);
    this.suppressChange = false;
    this.quillRef = React.createRef()
    this.rangeRef = React.createRef();

    this.renderRef = React.createRef();

    this.state = {
      "current_selection": null,
      "current_quill_color": getTheme() === 'dark' ? "#FFF" : "#000",
      "render_quill_color_picker": false,
      "colors": ["#000000", "#555555", "#ABB8C3", "#e60000", "#F47373", "#ff9900", "#FCB900", "#FCCB00", "#008a00", "#4CAF50", "#CDDC39", "#0066cc", "#03A9F4", "#8ED1FC", "#9933ff", "#F78DA7", "#FFF"],
      "modules": {
        "clink": true,
        "toolbar": [
          ['bold', 'italic', 'underline','strike'],
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
          [{ 'script': 'sub' }, { 'script': 'super' }, 'formula'],
          [{'size': [false, ...this.getSizeArray()]}],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          ['link'],
          [{ lineheight: this.getSpaces()}],
        ],
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true
        }
        },
        "formats": [
          'header', 'size', 'bold', 'italic', 'underline', 'strike', 'script',
          'formula', 'list', 'bullet', 'indent', 'link', 'color', 'align', 'lineheight'
        ],
      }
  }

  getSpaces() {
    const spaces = [
      '1.5',
      '1.7',
      '2.1',
      '2.5',
      '3.1',
      '3.5',
    ];

    return spaces
  };


  componentWillMount() {
    const Font = ReactQuill.Quill.import('formats/font');
    Font.whitelist = ['large', 'medium', "small", "regular", "bold", "pullquote"];

    ReactQuill.Quill.register(Font, true);

    let SizeStyle = ReactQuill.Quill.import('attributors/style/size');
    SizeStyle.whitelist = this.getSizeArray();
    ReactQuill.Quill.register(SizeStyle, true);

    ReactQuill.Quill.register('modules/clink', (quill) => {
      quill.container.addEventListener('click', (evt) => {
          if (evt.target.tagName === 'A') {
              // quill.theme.tooltip.edit();
              // quill.theme.tooltip.edit('link', quill.getFormat().link);
              this.customizeQuill(evt.target, quill.container, quill.getSelection())
          }
      });
  });
    var Parchment = ReactQuill.Quill.import('parchment');
    var lineHeightConfig = {
      scope: Parchment.Scope.INLINE,
      whitelist: this.getSpaces()
    };
    var lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig);
    var lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
    Parchment.register(lineHeightClass);
    Parchment.register(lineHeightStyle);

    ReactQuill.Quill.register(Parchment, true)

  }

  getSizeArray() {
    let sizeArray = [];
    for (let i = 9; i <= 60; i++) {
      sizeArray.push(i + "px");
    }
    return sizeArray;
  }

  componentDidMount() {
    this.addCustomColorPicker();
    this.customizeQuill();
  }

  // handleChange(text) {
  //   this.props.onChange(text);
  // }
  componentDidUpdate() {
    if (this.props.value && this.quillRef.current && !this.renderRef.current) {
      const event = new CustomEvent('quillrendered', {
        detail: {
          value: this.props.value
        }
      });
      document.dispatchEvent(event);

      this.renderRef.current = true;
    };
  }

  changeLink(link) {
    const _this = this;
    if(_this.quillRef.current) {
      const editor = _this.quillRef.current.editor;
      editor.format('link', link);
    }
  };

  customizeLineHeight(editor) {
    const lineHeight = editor.getElementsByClassName('ql-lineheight')[0];
    if(lineHeight) {
      lineHeight.removeChild(lineHeight.children[0])
      const buttonLineHeight = document.createElement('span');
      buttonLineHeight.innerHTML = LineHeightIconString;
      buttonLineHeight.style.cursor = 'pointer';
      buttonLineHeight.onclick = () => lineHeight.classList.toggle('ql-expanded')
      lineHeight.appendChild(buttonLineHeight);
    }
  }
  customizeQuill(elementLink, quillContainer, range) {
    if(this.quillRef.current) {
      const editor = quillContainer || this.quillRef.current.editingArea;
      this.customizeLineHeight(editor)
      const linkButton = elementLink || editor.getElementsByClassName('ql-link')[0];
      const containerInput = editor.getElementsByClassName('ql-tooltip-editor')[0];
      const formats = editor.getElementsByClassName('ql-toolbar')[0].childNodes;
      const input = containerInput.childNodes[0];
      const buttonClear = containerInput.getElementsByClassName('ql-close')[0];
      if(quillContainer && elementLink) {
        buttonClear.classList.add('quill-clink-remove');  
        buttonClear.onclick = () => {
          if(elementLink.parentNode) {
            elementLink.parentNode.innerHTML = elementLink.parentNode.innerHTML.replace(
              elementLink.outerHTML, `<span style="font-size: ${elementLink.style.fontSize}; color: ${elementLink.style.color};">${elementLink.textContent}</span>`
            )
          }
          
          containerInput.style.display = 'none';
        }
      }
      linkButton.onclick = () => {
        if(elementLink) {
          input.value = elementLink.href;
          input.onkeyup = e => {
            elementLink.href = e.target.value
          }
        };
        
        input.setAttribute('placeholder', 'https://www.digitalpages.com.br/');
  
        containerInput.style.display = 'block';
        
        for(let i = 0; i < formats.length; i++) {
          formats[i].style.visibility = 'hidden'
        };
      }
      
      input.focus();
      input.onblur = e => {
        if(!elementLink) this.changeLink(e.target.value);

        for(let i = 0; i < formats.length; i++) {
          formats[i].style.visibility = 'visible';
          input.value = '';
        };

        setTimeout(() => {
          buttonClear.classList.remove('quill-clink-remove');
          containerInput.style.display = 'none';
        }, 200)
      };
    }
  }



  addCustomColorPicker() {
    var _this = this;
    var quill = this.quillRef;
    if (quill.current !== null) {
      quill.current.editingArea.querySelector(".ql-toolbar").insertAdjacentHTML("beforeend", '<span class="ql-formats"><span class="ql-format-custom-color"></span></span>')
      quill.current.editingArea.querySelector(".ql-format-custom-color").addEventListener("click", function() {
        _this.toggleColor()
      });

      quill.current.editingArea.querySelector('.ql-editor').onclick = () => {
        quill.current.editingArea.querySelector('.ql-tooltip').classList.add('ql-flip');
        quill.current.editingArea.querySelector('.ql-tooltip').classList.remove('ql-hidden');
      };
    }
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
  }

  getTagName(elem) {
    return elem.tagName.toLowerCase();
  }

  getStrToSplit(str) {
    if (str.split('">').length > 1) {
      return str.split('">')[0];
    } else {
      return str.split('>')[0] + ' style="';
    }
  }

  changeSelectedTextColor(color) {
    var _this = this;
    var sel, range, node;
    var quill = this.quillRef;
    if (window.getSelection) {
      sel = getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = getSelection().getRangeAt(0);

        // var html = '<span style="color: ' + color + ';">' + range + '</span>'
        var html = `${_this.getStrToSplit(window.getSelection().focusNode.parentElement.outerHTML)} color: ${color};">${range}</${_this.getTagName(window.getSelection().focusNode.parentElement)}>`

        const isContainer = html.includes('class="ql-editor"');

        if(!isContainer) {
          html = html.replace("<p", "<span").replace("p>", "span>").replace('style=" ', 'style="');
          html = html.replace("<li", "<span").replace("li>", "span>").replace('style=" ', 'style="');
          // Tratativas extras que podem ser uteis
          html = html.replace("<table", "<span").replace("table>", "span>").replace('style=" ', 'style="');
          html = html.replace("<div", "<span").replace("div>", "span>").replace('style=" ', 'style="');
          html = html.replace("<blockquote", "<span").replace("blockquote>", "span>").replace('style=" ', 'style="');
          html = html.replace("<h1", "<span").replace("h1>", "span>").replace('style=" ', 'style="');
          html = html.replace("<h2", "<span").replace("h2>", "span>").replace('style=" ', 'style="');
          range.deleteContents();
          range.insertNode(this.createElementFromHTML(html));
        }
        else {
          const area = quill.current.editingArea.querySelector(".ql-editor");
          const elementTitle = area.getElementsByTagName('h1')[0] || area.getElementsByTagName('h2')[0];
          try {
            const span = elementTitle.childNodes[0];
            span.style.color = color;
          }
          catch(err) {
            console.log(err);
          }
        }
      }
    }
    else if (window.document.selection && window.document.selection.createRange) {
      range = window.document.selection.createRange();
      range.collapse(false);
      range.pasteHTML(html);
    }

    if (quill.current !== null) {
      this.handleChange(quill.current.editingArea.querySelector(".ql-editor").innerHTML)
    }
  }

  toggleColor() {
    if(!this.quillRef.current) return;

    var selection = window.getSelection();
    const range = this.quillRef.current.editor.getSelection();

    this.rangeRef.current = range;

    this.setState({
      "current_selection": selection.rangeCount === 0 ? null : selection.getRangeAt(0),
      "render_quill_color_picker": !this.state.render_quill_color_picker
    })
  }

  renderQuillColorPicker() {
    if (this.state.render_quill_color_picker === true) {
      return <div className="ql-custom-color-picker">
        <div className="ql-custom-color-picker-overlay" onClick={() => this.toggleColor()}></div>
        <TwitterPicker colors={this.state.colors} color={this.state.current_quill_color} onChange={this.onQuillColorChange} styles={{ 
          default: {
            card: {
              background: getTheme() === 'dark' ? '#212b36' : '#fff',
            },
            body: {
              background: getTheme() === 'dark' ? '#212b36' : '#fff'
            },

          }
        }}/>
      </div>;
    }
  }

  onQuillColorChange(color) {
    if(!this.quillRef.current || !this.rangeRef.current) return;
    const quill = this.quillRef.current.editor;
    const { index, length } = this.rangeRef.current;
    
    quill.formatText(index, length, { color: color.hex });
    quill.setSelection(null)

    this.suppressChange = true;

    setTimeout(() => {
      this.suppressChange = false;
    }, 100);

    return;
  }

  handleChange = (text, delta, source, editor) => {
    if (this.props.isProperties) {
      this.props.onChange(text);
      return;
    }

    if (!this.props.value) {
      return;
    }

    if (editor.getText().trim().length === 0 && this.quillRef.current) {
      this.setState({ preventChange: true }, () => {
        this.quillRef.current.getEditor().format('size', '18px');
        this.quillRef.current.getEditor().format('color', 'rgb(49, 53, 55)');
      });
    }
    this.props.onChange(text);
  };

  render() {
    var modules = this.state.modules;
    var formats = this.state.formats;

    return (
      <>
        <ReactQuill theme="bubble"
          className={`${this.props.className} ${this.props.isProperties ? 'quill-properties' : ''}`}
          style={this.props.style}
          ref={this.quillRef}
          value={this.props.value}
          modules={modules}
          formats={formats}
          onChange={this.handleChange}
        />
        {this.renderQuillColorPicker()}
      </>
    );
  }
}
