import React, {Component} from 'react';
import './element-code-snippet-1-properties.css';
import {ColorPicker, RelToolbarQuill} from '../../components';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

export default class ElementCodeSnippet1Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "dam_widget_opened": false
    }

    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
  }


  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }
  
  onBackgroundColorChange(color) {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSnippetBackgroundColorChange = (color) => {
    var content = this.state.content;
    content.snippet_background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onCodeChange(code) {
    var content = this.state.content;
    content.items[0].code = this.htmlEntities(code);
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  htmlEntities = (str) => String(str)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')

  onCaptionChange(caption) {
    var content = this.state.content;
    content.items[0].caption = caption;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  getItemCode() {
    const element = document.createElement("div");
    element.innerHTML = this.state.content.items[0].code;
    return element.innerText;
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;
    const item = content.items[0];

    return (<div className="rdp-element-image-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className="rdp-composer-element-edit-tabs-item active">{labels.elements.common_settings.label_tab_settings}</li>
      </ul>
      <div className="rdp-composer-element-edit-body">
        <span
          className="rdp-composer-element-edit-label"
        >
          {labels.elements.settings.element_snippet_1.settings_label_code}:
        </span>
        <div style={{marginTop: "10px", marginBottom: "25px"}}>
          <textarea
            className="rdp-composer-element-edit-textarea"
            onChange={(evt) => this.onCodeChange(evt.target.value)}
            value={this.getItemCode()}
            rows="10"
          />
        </div>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_snippet_1.settings_label_caption}</span>
        <div style={{marginTop: "10px", marginBottom: "25px"}}>
          <RelToolbarQuill
            onChange={(text) => this.onCaptionChange(text)}
            value={item.caption}
          />
        </div>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_snippet_1.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)} defaultValue={content.content_title}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_snippet_1.settings_label_background_color}</span>
        <ColorPicker labels={this.props.labels} color={content.background_color} onChange={this.onBackgroundColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_snippet_1.settings_label_snippet_background_color}</span>
        <ColorPicker labels={this.props.labels} color={content.snippet_background_color} onChange={this.onSnippetBackgroundColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_snippet_1.settings_label_margin_top}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_snippet_1.settings_label_margin_bottom}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

        <PreferenceRequiredInteraction
          title={labels.elements.settings.preference_required_interaction.title}
          label={labels.elements.settings.preference_required_interaction.label}
          labels={labels}
          content={content}
          onContentChange={c => {
            this.setState({ content: c });
            this.props.onContentChange(c);
          }}
        />
      </div>
    </div>);
  }
}
