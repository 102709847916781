import styled, {css} from "styled-components";

export const QuizMatchLine = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  transition: all 1s ease-in-out;
`;

export const Text = styled.p`
  padding: 3px 30px;
  max-height: ${props => props.lines * 15}px;
  overflow: hidden;
  text-align: justify !important;

  @media screen and (max-width: 480px) {
    max-height: ${props => props.lines * 25}px;
    padding-right: 10px;
    z-index: 3;
  }
`;

export const MatchList = styled.ol`
  width: 85%;
  margin-left: 20%;
`;

export const LeftColumn = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const MatchConnector = styled.ol`
  width: 100%;
  position: relative;
  height: ${props => props.lines * 15}px;

  @media screen and (max-width: 480px) {
    height: ${props => props.lines * 25}px;
  }
`;

export const MatchLine = styled.ol`
  width: 80%;
  margin-right: 20%;
  display: grid;
  grid-template-columns: 10% 75%;
  grid-gap: 35%;

  ${props => props.connected && css`
    grid-template-columns: 10% 85%;
    grid-gap: 25%;

    @media screen and (max-width: 480px) {
      grid-template-columns: 0 100%;
      grid-gap: 25%;
    }

    @media screen and (min-width: 430px) and (max-width: 479px) {
      grid-gap: 15%;
    }
  `}
`

const BaseChoice = styled.li`
  min-width: 200px;
  height: ${props => props.lines * 15}px;
  flex: 2;
  background-color: whitesmoke;
  position: relative;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  word-break: break-word;
  font-size: 15px;
  line-height: 15px;

  @media screen and (max-width: 480px) {
    height: ${props => props.lines * 25}px;
    transform: scale(0.7);
  }
`;

export const Choice = styled(BaseChoice)`
  z-index: 2;
  width: 45%;
  border-right: none;
  cursor: grab;
  border-left-color: whitesmoke;
  
  &:active {
    cursor: grabbing;
  }
  
  &:after {
    content: "";
    height: ${props => props.lines * 15}px;
    width: 100%;
    position: absolute;
    border-left: 5px solid ${props => props.primaryColor || "#ccc"};
  }

  @media screen and (max-width: 480px) {
    &:after {
      height: ${props => props.lines * 25}px;
    }
  }
  
  &:hover {
    background-color: #eaeaea;
    border-left-color: #eaeaea;

    img {
      opacity: 1;
    }
  }
  
  ${props => props.hidden && css`
    opacity: 0;
    pointer-events: none;
  `}
`;

export const Match = styled(BaseChoice)`
  z-index: 1;
  border-left: none;
  width: 100%;
  
  padding-left: ${props => (props.lines * 15) / 4}px;

  @media screen and (max-width: 480px) {
    padding-left: 30px;
  }
  
  min-width: 100px;
`;

export const Arrow = styled.div`
  width: 0;
  border-top: ${props => (props.lines * 15) / 2}px solid transparent;
  border-bottom: ${props => (props.lines * 15) / 2}px solid transparent;
  border-left: ${props => (props.lines * 15) / 4}px solid transparent;
  position: absolute;
  top: 0;
  margin-top: auto;
  transition: none;

  @media screen and (max-width: 480px) {
    border-top: ${props => (props.lines * 25) / 2}px solid transparent;
    border-bottom: ${props => (props.lines * 25) / 2}px solid transparent;
    border-left: ${props => (props.lines * 25) / 5}px solid transparent;
  }
`;

export const ArrowLeft = styled(Arrow)`
  right: -${props => (props.lines * 15) / 4}px;
  border-left-color: inherit;
  z-index: 2;

  @media screen and (max-width: 480px) {
    right: -${props => (props.lines * 25) / 5}px;
  }
`;

export const ArrowRight = styled(Arrow)`
  left: 0;
  border-left-color: white;
  z-index: 1;

  @media screen and (max-width: 480px) {
    ${props => props.connected && css`
      display: none;
    `}
  }
`;

export const IconImage = styled.img`
  width: 30px;
  height: ${props => props.lines * 15}px;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;

  @media screen and (max-width: 480px) {
    height: ${props => props.lines * 25}px;
  }
`;