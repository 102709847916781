export const OPERATORS = [
  {
    value: ">",
    label: "Maior que",
  },
  {
    value: ">=",
    label: "Maior ou igual",
  },
  {
    value: "=",
    label: "Igual",
  },
  {
    value: "<",
    label: "Menor que",
  },
  {
    value: "<=",
    label: "Menor ou igual",
  },
];
