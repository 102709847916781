import React, { Component } from 'react';
import { RDP_TRANSLATE } from '../../translate/translate';
import RDP_CONFIG from '../../config';
import {
  SidebarSubitem,
  ColorPicker,
  FontPicker
} from '../../components';
import './converter-presets.css';

export default class ConverterPresets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "labels": RDP_TRANSLATE[RDP_CONFIG.language]().composer
    };
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  getPresets() {
    var obj = {};
    var stored = window.localStorage.getItem(window.location.host + "rdp-composer-converter-presets");
    if (stored !== null) {
      obj = JSON.parse(stored);
    };
    return obj;
  }

  setPreset(attr, val) {
    var presets = this.getPresets();
    presets[attr] = val;

    window.localStorage.setItem(window.location.host + "rdp-composer-converter-presets", JSON.stringify(presets));
  }

  getProperty(prop) {
    var presets = this.getPresets();
    var val = presets[prop];
    if (val === undefined) val = null;
    return val;
  }

  renderFields(fields) {
    var _this = this;
    return <div className="rdp-composer-converter-presets-fields">
      {fields.map(function(field, index) {
        return <div className="rdp-composer-converter-presets-field" key={index}>
          <span className="rdp-composer-converter-presets-field-label">{field.label}</span>
          {field.type === "color" &&
            <ColorPicker labels={_this.state.labels} color={_this.getProperty(field.property) || field.default_value} onChange={(color) => _this.setPreset(field.property, color)} />
          }
          {field.type === "font" &&
            <FontPicker font={_this.getProperty(field.property) || field.default_value || "Lato"} onFontChange={(font) => _this.setPreset(field.property, font)}/>
          }
        </div>
      })}
    </div>
  }

  render() {
    return <div className="rdp-composer-converter-presets">
      <div className="rdp-composer-converter-presets-header">Presets</div>
      <ul className="rdp-composer-converter-presets-elements">
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="text-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_text_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_text_1_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="text-2"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_text_2_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_text_2_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="text-3"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_text_3_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_text_3_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="text-4"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_text_4_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_text_4_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="text-5"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_text_5_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_text_5_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="text-two-column"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_text_two_column_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_text_two_column_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="list-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_list_1_primary_color",
              "default_value": "#297C43"
            },
            {
              "type": "color",
              "label": "Cor secundária",
              "property": "object_list_1_secondary_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_list_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_list_1_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="list-2"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_list_2_primary_color",
              "default_value": "#297C43"
            },
            {
              "type": "color",
              "label": "Cor secundária",
              "property": "object_list_2_secondary_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_list_2_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_list_2_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="list-3"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_list_3_primary_color",
              "default_value": "#297C43"
            },
            {
              "type": "color",
              "label": "Cor secundária",
              "property": "object_list_3_secondary_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_list_3_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_list_3_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="image-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_image_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_image_1_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="image-2"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_image_2_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_image_2_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="images-gallery"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_images_gallery_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_images_gallery_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="image-and-text"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_image_and_text_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_image_and_text_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="image-with-text"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_image_with_text_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor do dividor",
              "property": "object_image_with_text_divider_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor da opacidade",
              "property": "object_image_with_text_opacity_color",
              "default_value": "#000000"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_image_with_text_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="audio-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_audio_1_background_color",
              "default_value": "#FFFFFF"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="video-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_video_1_background_color",
              "default_value": "#FFFFFF"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="iframe"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_iframe_background_color",
              "default_value": "#FFFFFF"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="accordion"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_accordion_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de borda",
              "property": "object_accordion_border_color",
              "default_value": "#ff9900"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_accordion_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="tabs"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_tabs_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor ativa",
              "property": "object_tabs_active_color",
              "default_value": "#00643e"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_tabs_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="flashcards-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_flashcards_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de borda",
              "property": "object_flashcards_1_border_color",
              "default_value": "#00643e"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_flashcards_1_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="flashcards-2"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_flashcards_2_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de borda",
              "property": "object_flashcards_2_border_color",
              "default_value": "#00643e"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_flashcards_2_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="divider-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_divider_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor do divisor",
              "property": "object_divider_1_divider_color",
              "default_value": "#e6e6e6"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="divider-4"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_divider_4_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor do divisor",
              "property": "object_divider_4_divider_color",
              "default_value": "#e6e6e6"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="divider-numbered"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_divider_numbered_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor do divisor primária",
              "property": "object_divider_numbered_divider_primary_color",
              "default_value": "#297C43"
            },
            {
              "type": "color",
              "label": "Cor do divisor secundária",
              "property": "object_divider_numbered_divider_secondary_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_divider_numbered_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="hotspots-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_hotspots_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor dos botões",
              "property": "object_hotspots_1_spot_color",
              "default_value": "#00643e"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_hotspots_1_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="statement-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_statement_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de borda",
              "property": "object_statement_1_border_color",
              "default_value": "#eaeaeb"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_statement_1_font_family",
              "default_value": "Grueber"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="statement-2"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_statement_2_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de borda",
              "property": "object_statement_2_border_color",
              "default_value": "#d5d5d5"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_statement_2_font_family",
              "default_value": "Helvetica Neue"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="statement-3"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_statement_3_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de borda",
              "property": "object_statement_3_border_color",
              "default_value": "#d5d5d5"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_statement_3_font_family",
              "default_value": "Helvetica Neue"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="statement-4"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_statement_4_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de borda",
              "property": "object_statement_4_border_color",
              "default_value": "#d5d5d5"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_statement_4_font_family",
              "default_value": "Grueber"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="note-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_note_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor primária",
              "property": "object_note_1_primary_color",
              "default_value": "#ff631e"
            },
            {
              "type": "color",
              "label": "Cor secundária",
              "property": "object_note_1_secondary_color",
              "default_value": "#ffefe9"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_note_1_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quote-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quote_1_primary_color",
              "default_value": "#e9eaeb"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_quote_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_quote_1_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quote-2"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quote_2_primary_color",
              "default_value": "#e9eaeb"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_quote_2_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_quote_2_font_family",
              "default_value": "Helvetica Neue"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quote-3"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quote_3_primary_color",
              "default_value": "#f8f8f8"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_quote_3_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_quote_3_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quote-4"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quote_4_primary_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_quote_4_background_color",
              "default_value": "#f5f5f5"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_quote_4_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quote-5"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quote_5_primary_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_quote_5_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quote-6"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quote_6_primary_color",
              "default_value": "#2d7b45"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_quote_6_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_quote_6_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="sorted-activity-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_sorted_activity_1_primary_color",
              "default_value": "#297C43"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_sorted_activity_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor de fundo da pilha",
              "property": "object_sorted_activity_1_pile_color",
              "default_value": "#e1e1e1"
            },
            {
              "type": "color",
              "label": "Cor do texto da pilha",
              "property": "object_sorted_activity_1_pile_text_color",
              "default_value": "#000000"
            },
            {
              "type": "color",
              "label": "Cor de fundo dos itens",
              "property": "object_sorted_activity_1_item_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "color",
              "label": "Cor do texto dos itens",
              "property": "object_sorted_activity_1_item_text_color",
              "default_value": "#000000"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_sorted_activity_1_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="timeline-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_timeline_1_primary_color",
              "default_value": "#00643e"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_timeline_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_timeline_1_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="steps-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_steps_1_primary_color",
              "default_value": "#00643e"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_steps_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_steps_1_font_family",
              "default_value": "Lato"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="attachment-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_attachment_1_primary_color",
              "default_value": "#00643e"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_attachment_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_attachment_1_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="gallery-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_gallery_1_primary_color",
              "default_value": "#2d7b45"
            },
            {
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_gallery_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_gallery_1_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="snippet-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_snippet_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_snippet_1_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="button-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_button_1_primary_color",
              "default_value": "#2d7b45"
            },{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_button_1_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_button_1_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="button-2"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_button_2_primary_color",
              "default_value": "#2d7b45"
            },{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_button_2_background_color",
              "default_value": "#FFFFFF"
            },
            {
              "type": "font",
              "label": "Fonte",
              "property": "object_button_2_font_family",
              "default_value": "Merriweather"
            }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quiz-1"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quiz_1_primary_color",
              "default_value": "#2d7b45"
            },{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_quiz_1_background_color",
              "default_value": "#FFFFFF"
            },
              {
                "type": "font",
                "label": "Fonte",
                "property": "object_quiz_1_font_family",
                "default_value": "Merriweather"
              }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quiz-2"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quiz_2_primary_color",
              "default_value": "#2d7b45"
            },{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_quiz_2_background_color",
              "default_value": "#FFFFFF"
            },
              {
                "type": "font",
                "label": "Fonte",
                "property": "object_quiz_2_font_family",
                "default_value": "Merriweather"
              }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quiz-3"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quiz_3_primary_color",
              "default_value": "#2d7b45"
            },{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_quiz_3_background_color",
              "default_value": "#FFFFFF"
            },
              {
                "type": "font",
                "label": "Fonte",
                "property": "object_quiz_3_font_family",
                "default_value": "Merriweather"
              }]
          )}
        </li>
        <li className="rdp-composer-converter-presets-element">
          <SidebarSubitem type="quiz-4"/>
          {this.renderFields(
            [{
              "type": "color",
              "label": "Cor primária",
              "property": "object_quiz_4_primary_color",
              "default_value": "#2d7b45"
            },{
              "type": "color",
              "label": "Cor de fundo",
              "property": "object_quiz_4_background_color",
              "default_value": "#FFFFFF"
            },
              {
                "type": "font",
                "label": "Fonte",
                "property": "object_quiz_4_font_family",
                "default_value": "Merriweather"
              }]
          )}
        </li>
      </ul>
    </div>
  }
}
