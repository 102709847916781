import React, { useEffect, useState } from "react";
import Radio from "../../../components/element-radio/radio";
import { Skills } from "../../../components/skills";

export const Choices = ({ labels, question: currentQuestion, onChangeQuestion }) => {
  const [question, setQuestion] = useState(currentQuestion);

  useEffect(() => {
    onChangeQuestion(question);
  }, [question]);

  const onSaveEditChoice = (choice, newValue, field = "text") => {
    setQuestion({
      ...question,
      choices: question.choices.map((currentChoice, indexEdit) => {
        if (choice.id !== currentChoice.id) return currentChoice;
        return {
          ...currentChoice,
          [field]: newValue,
        };
      }),
    });
  };

  const onRemoveChoice = (currentIndex) => {
    setQuestion({
      ...question,
      choices: question.choices.filter((_, i) => i !== currentIndex),
    });
  };

  const onChangeCorrectChoice = (choice) => {
    const newQuestion = {
      ...question,
      choices: question.choices.map((item) => {
        if (item.id !== choice.id)
          return {
            ...item,
            correct: false,
          };
        return {
          ...item,
          correct: true,
        };
      }),
    };

    setQuestion(newQuestion);
  };

  return (
    <div className="element-quiz-box-choices">
      <ul className={`element-quiz-1-properties-choices`}>
        {question.choices.map(function (choice, index) {
          return (
            <div key={choice.id} style={{ marginBottom: 25 }}>
              <span className="element-quiz-1-properties-item-label">Alternativa - {index + 1}</span>
              <li style={{ marginLeft: -25, marginBottom: 5 }}>
                <div
                  className="element-quiz-1-properties-choice"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Radio
                    value={choice.correct}
                    checked={choice.correct}
                    onChange={() => onChangeCorrectChoice(choice)}
                  />
                  <input
                    className="element-quiz-1-properties-choice-label"
                    defaultValue={choice.text}
                    onBlur={(e) => onSaveEditChoice(choice, e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && onSaveEditChoice(choice, e.target.value)}
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                  />
                  {/* <span onClick={() => onRemoveChoice(index)} className="element-quiz-1-prop-choice-btn-rm"></span> */}
                </div>
              </li>

              <span className="rdp-composer-element-edit-label" style={{ marginTop: 15 }}>
                  Pontuação
                </span>

              <input
                className="rdp-composer-element-edit-input"
                type="number"
                value={choice.value}
                onChange={(e) => {
                  setQuestion((prev) => ({
                    ...prev,
                    choices: prev.choices.map((item) => {
                      if (item.id !== choice.id) return item;
                      return {
                        ...choice,
                        value: e.target.value,
                      };
                    }),
                  }));
                }}
              />
            </div>
          );
        })}
      </ul>
    </div>
  );
};
