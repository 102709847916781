import React, { Component } from 'react';
import {UserService} from '../../services/user';
import { Quill } from '../../components';
import './element-quote-5.css';

export default class ElementQuote5 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "avatar_size": "width",
      "error_image": false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeAuthorName = this.handleChangeAuthorName.bind(this);
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  handleAvatarChange(img) {
    this.setState({
      "avatar_size": img.target.width >= img.target.height ? "height" : "width"
    });
  }

  handleChangeText(text) {
    var content = this.props.content;
    content.text = text;
    this.props.onChange(content);
  }

  renderText() {
    let { content } = this.props;

    return <div className="rdp-element-quote-5-text-container">
      <span className="rdp-element-quote-5-text-container-icon">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
          <path fill={content.primary_color} d="M112.5 224c61.856 0 112 50.145 112 112s-50.144 112-112 112-112-50.144-112-112l-0.5-16c0-123.712 100.288-224 224-224v64c-42.737 0-82.917 16.643-113.137 46.863-5.817 5.818-11.126 12.008-15.915 18.51 5.719-0.9 11.58-1.373 17.552-1.373zM400.5 224c61.855 0 112 50.145 112 112s-50.145 112-112 112-112-50.144-112-112l-0.5-16c0-123.712 100.288-224 224-224v64c-42.737 0-82.917 16.643-113.137 46.863-5.818 5.818-11.127 12.008-15.916 18.51 5.72-0.9 11.58-1.373 17.553-1.373z"></path>
        </svg>
      </span>
      <Quill
        className="rdp-element-quote-5-text"
        value={content.text}
        onChange={this.handleChangeText}
      />
    </div>;
  }

  renderAvatar() {
    let { content } = this.props;

    if (content.avatar !== null) {
      var avatarSize = this.state.avatar_size;
      return <div className="rdp-element-quote-5-avatar">
        <img
          style={avatarSize === "width" ? { width: "102%" } : { height: "102%" }}
          src={this.getAvatarUrl(content.avatar)}
          onLoad={this.handleAvatarChange.bind(this)}
        />
      </div>;
    } else {
      return null;
    }
  }

  getAvatarUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  handleChangeAuthorName(name) {
    var content = this.props.content;
    content.author_name = name;
    this.props.onChange(content);
  }

  renderAuthorInfo() {
    let { content } = this.props;

    var modules = {
      toolbar: [
        ['bold', 'italic', 'underline','strike'], [{'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']}]
      ],
    };
    var formats = ['bold', 'italic', 'underline', 'strike', 'color'];
    
    return <div className="rdp-element-quote-5-author-info">
      <Quill
        className="rdp-element-quote-5-author-name"
        style={{fontFamily: "Merriweather-Regular"}}
        value={content.author_name}
        onChange={this.handleChangeAuthorName}
      />
    </div>
  }

  getBackgroundUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/background.png") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;
    
    return <div className={`${className} rdp-element-quote-5 ${this.getFont(content.font_family) || ""}`} style={{ backgroundImage: `url(${this.getBackgroundUrl(content.background_image)})` }}>
      <img 
        alt="" 
        src={this.getBackgroundUrl(content.background_image)} 
        onError={e => {
          this.setState({ error_image: true })
          e.target.style.display = 'block';
          e.target.classList.add('broken-img');
        }}
        onLoad={e => {
          e.target.style.display = 'none';
          e.target.classList.remove('broken-img');
          this.setState({ error_image: false })
        }}
      />
      {headerActionsTemplate}
      {!this.state.error_image && (
        <div className="rdp-element-quote-5-container">
          {this.renderAvatar()}
          {this.renderText()}
          {this.renderAuthorInfo()}
        </div>
      )}
      {footerActionsTemplate}
    </div>;
  }
}
