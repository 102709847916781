import React, {Component} from 'react';
import * as S from "./status.styled";
import {RDP_TRANSLATE} from "../../translate/translate";
import RDP_CONFIG from "../../config";

class ViewStatus extends Component {
  handleBack = () => {
    window.history.go(-2);
  }

  render() {
    const {
      match: {
        params: {
          code
        }
      }
    } = this.props;



    return (
      <S.Container>
        <S.Column>
          <S.Row>
            <S.StatusCodeTitle>
              {code}
            </S.StatusCodeTitle>

            <S.VerticalDivider />

            <S.Column>
              <S.StatusCodeMessage>
                {RDP_TRANSLATE[RDP_CONFIG.language]().status[code]}
              </S.StatusCodeMessage>
            </S.Column>
          </S.Row>

          {window.history.length > 1 && (
            <S.BackButton
              type="button"
              onClick={this.handleBack}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path fill="currentColor" d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" />
              </svg>
              {RDP_TRANSLATE[RDP_CONFIG.language]().status.back}
            </S.BackButton>
          )}
        </S.Column>
      </S.Container>
    );
  }
}

export default ViewStatus;