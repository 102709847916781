import React from "react";
import { ChoiceSkills } from "./Skills";
import { ChoiceSkillProficicy } from "./SkillProficiency";
import uuidv1 from "uuid/v1";
import { useConnectors } from "../../hooks/useConnectors";
import { useStructure } from "../../hooks/context/structure";

const CONNECTOR_PROFICIENCY_NAME = "IConnectorProficiencyManagement";

export const SkillGroup = ({
  selectedSkill,
  question,
  onChangeQuestion,
  onChangeSkill,
  selectedProficiency,
  onChangeProficiency,
}) => {
  const { getConnectorShouldHaveFeature } = useConnectors();
  const { onChangeIntegrations } = useStructure();

  const connector = getConnectorShouldHaveFeature(CONNECTOR_PROFICIENCY_NAME);

  if (!connector) return null;

  const addNewSkill = () => {
    if (!onChangeQuestion || !question) return;

    onChangeQuestion({
      ...question,
      skills: [
        ...(question.skills || []),
        {
          id: uuidv1(),
          reference: null,
          proficiency: null,
        },
      ],
    });
  };


  const onChangeSkillReference = (skill) => {
    onChangeQuestion({
      ...question,
      skills: question.skills.map((item) => (item.id === skill.id ? skill : item)),
    });

    onChangeIntegrations('skill', connector.uid);
  };

  if (question && onChangeQuestion) {
    return (
      <div style={{ borderTop: "1px solid #dddddd8f", padding: "15px 0" }}>
        <div>
          {(question.skills || []).map((skill) => (
            <div style={{ display: "flex", gap: 10 }} key={skill.id}>
              <div style={{ width: "100%" }}>
                <span className="rdp-composer-element-edit-label">Habilidade</span>

                <ChoiceSkills
                  selected={skill.reference}
                  onChangeSkill={(reference) =>
                    onChangeSkillReference({
                      ...skill,
                      reference: {
                        name: reference.name,
                        reference_uid: reference.uid,
                      },
                    })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <span className="rdp-composer-element-edit-label">Nível de Proficiência</span>

                <ChoiceSkillProficicy
                  selected={skill.proficiency}
                  onChangeSkill={(reference) =>
                    onChangeSkillReference({
                      ...skill,
                      proficiency: {
                        name: reference.name,
                        reference_uid: reference.uid,
                      },
                    })
                  }
                />
              </div>
            </div>
          ))}

          <button onClick={addNewSkill} className="element-accordion-properties-choice-new">
            Adicionar habilidade
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <span className="rdp-composer-element-edit-label">Habilidade</span>

      <ChoiceSkills
        selected={selectedSkill}
        onChangeSkill={(skill) => onChangeSkill && onChangeSkill({ name: skill.name, reference_uid: skill.uid })}
      />

      <span className="rdp-composer-element-edit-label">Nível de Proficiência</span>

      <ChoiceSkillProficicy
        selected={selectedProficiency}
        onChangeSkill={(skill) =>
          onChangeProficiency && onChangeProficiency({ name: skill.name, reference_uid: skill.uid })
        }
      />
    </div>
  );
};
