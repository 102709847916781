import React, { Component } from 'react'
import './sidebar-subitem.css';

export default class SidebarSubitem extends Component {
  render() {
    return (
      <div className={`rdp-composer-sidebar-subitem ${this.props.type}`} onClick={() => this.props.click && this.props.click()}>
        {this.props.text && <div className="rdp-composer-sidebar-subitem-message">{this.props.text}</div>}
      </div>
    );
  }
}
