import React, { useState } from "react";
import { IconLike } from "./icons/like";
import { IconLove } from "./icons/love";
import { IconConfused } from "./icons/confused";
import { IconTiresome } from "./icons/tiresome";
import * as S from "./reaction-styled";

export const ElementReactions = () => {
  const [reaction, setReaction] = useState("");

  const userReaction = DEFAULT_REACTION_LIST.find((item) => item.type === reaction);
  return (
    <S.IconList>
      {!userReaction &&
        DEFAULT_REACTION_LIST.map((reaction) => (
          <S.Icon key={reaction.type} onClick={() => setReaction(reaction.type)}>
            {reaction.icon}
          </S.Icon>
        ))}

      {userReaction && <S.Icon>{userReaction.icon} <p>1</p></S.Icon>}
    </S.IconList>
  );
};

const DEFAULT_REACTION_LIST = [
  {
    label: "Curtir",
    type: "like",
    icon: <IconLike />,
  },
  {
    label: "Amei",
    type: "love",
    icon: <IconLove />,
  },
  {
    label: "Confuso",
    type: "confused",
    icon: <IconConfused />,
  },
  {
    label: "Não gostei",
    type: "dislike",
    icon: (
      <div style={{ transform: "rotate(180deg)" }}>
        <IconLike />
      </div>
    ),
  },
  {
    label: "Cansativo",
    type: "tiresome",
    icon: <IconTiresome />,
  },
];
