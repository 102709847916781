import React, {Component} from 'react';
import {UserService} from '../../services/user';
import {
  RDPDamWidget, ColorPicker
} from '../../components';
import * as S from "./element-attachment-1-properties.styled";
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

const tabs = {
  settings: "settings",
  file: "file",
}

export default class ElementAttachment1Properties extends Component {
  state = {
    content: this.props.content,
    active_tab: tabs.file,
    attachment_modal_open: false,
  }

  onBackgroundColorChange = (color) => {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPrimaryColorChange = (color) => {
    var content = this.state.content;
    content.primary_color = color;
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange = (e) => {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange = (e) => {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  getAssetUrl = (assetUrl) => {
    const publicAssetUrlArr = [
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg"
    ];
    if (false === publicAssetUrlArr.includes(assetUrl)) {
      assetUrl = `${assetUrl}&access_token=${UserService.getUser().access_token}`;
    }
    return assetUrl;
  }

  renderDAMWidget = () => {
    if (this.state.attachment_modal_open === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="attachments"
        onClose={() => this.onAttachmentModalClose()}
        onAssetChange={(asset, file) => this.onAttachmentChange(asset, file)}
      />;
    }
  }

  onAttachmentModalClose = () => {
    this.setState({
      attachment_modal_open: false
    })
  }

  onAttachmentChange = (asset, file) => {
    var content = this.state.content;
    const item = content.items[0];
    item.original_url = asset;
    item.size = file.content_size;
    item.mime_type = file.content_type;
    item.filename = file.file_name;

    content.items = [item];

    this.setState({
      content,
      attachment_modal_open: false
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onChangeTab(target) {
    this.setState({
      active_tab: target
    })
  }

  getBytes = (bytes) => {
    if (bytes < 1024) return bytes + " B";
    if (bytes < 1048576) return (bytes / 1024).toFixed(1) + " KB";
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(1) + " MB";
    if (bytes < 1099511627776) return (bytes / 1073741824).toFixed(1) + " GB";
    return (bytes / 1099511627776).toFixed(1) + " TB";
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;

    return (
      <div className="rdp-element-image-properties">
        <ul className="rdp-composer-element-edit-tabs">
          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.file ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.file)}
          >
            {labels.elements.settings.element_attachment_1.settings_label_tab_attachment}
          </li>

          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.settings ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.settings)}
          >
            {labels.elements.common_settings.label_tab_settings}
          </li>
        </ul>

        <div className="rdp-composer-element-edit-body">
          {this.state.active_tab === tabs.settings && (
            <>
              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_attachment_1.settings_label_background_color}</span>
              <ColorPicker labels={this.props.labels} color={content.background_color}
                           onChange={this.onBackgroundColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_attachment_1.settings_label_spot_color}</span>
              <ColorPicker labels={this.props.labels} color={content.primary_color}
                           onChange={this.onPrimaryColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_attachment_1.settings_label_margin_top}</span>
              <input type="number" className="rdp-composer-element-edit-input px"
                     onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_attachment_1.settings_label_margin_bottom}</span>
              <input type="number" className="rdp-composer-element-edit-input px"
                     onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

              <PreferenceRequiredInteraction
                label={labels.elements.settings.preference_required_interaction.label}
                labels={labels}
                content={content}
                onContentChange={c => {
                  this.setState({ content: c });
                  this.props.onContentChange(c);
                }}
              />
            </>
          )}

          {this.state.active_tab === tabs.file && (
            <ol>
              {content.items.map((item) => (
                <S.AttachmentItem key={item.id}>
                  <S.Body show>
                    <S.FieldContainer>
                      <span
                        className="rdp-composer-element-edit-label"
                      >
                        {labels.elements.settings.element_attachment_1.settings_label_file}:
                      </span>

                      <S.Information
                        tabIndex="1"
                        href={this.getAssetUrl(item.original_url)}
                        target="_blank"
                      >
                        <S.FileName>
                          {item.filename}
                        </S.FileName>

                        <S.FileSize>
                          {this.getBytes(item.size)}
                        </S.FileSize>
                      </S.Information>

                      <S.ChangeFileButton
                        onClick={() => {
                          this.setState({
                            attachment_modal_open: true,
                          });
                        }}
                      >
                        {labels.elements.settings.element_attachment_1.settings_button_change_file}
                      </S.ChangeFileButton>
                    </S.FieldContainer>
                  </S.Body>
                </S.AttachmentItem>
              ))}
            </ol>
          )}
        </div>
        {this.renderDAMWidget()}
      </div>
    );
  }
}
