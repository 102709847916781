import React, { useEffect, useRef, useState } from "react";

import PlayerControls from "../PlayerControls";
import Quiz from "../Quiz";

import * as S from "./styles";

const Player = ({ url, pausingMinute, pausingSecond, anchors, content }) => {
  const player = useRef();
  const [progress, setProgress] = useState({
    playedSeconds: 0,
    played: 0,
    loadedSeconds: 0,
    loaded: 0,
  });
  const [duration, setDuration] = useState(0);
  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [volume, setVolume] = useState(100);
  const [quizOpen, setQuizOpen] = useState(false);

  const momentQuestion = content.questions.find((question) => {
    const { hours, minutes, seconds: secondQuestions} = question;
    const questionSeconds = secondQuestions || 0;

    const seconds = Math.floor(progress.playedSeconds);
    const formatedSeconds = convertSecondsToTime(seconds);
    const momentToShow = `${hours || 0}:${minutes || 0}:${questionSeconds || 0}`;
    const minimalTimeOut = seconds - questionSeconds === 1;
    return momentToShow === formatedSeconds || minimalTimeOut
  });

  useEffect(() => {
    if (momentQuestion) {
      setIsPlaying(false);
      setQuizOpen(true);
    }
  }, [momentQuestion]);

  return (
    <div style={{ position: "relative" }}>
      <S.CustomReactPlayer
        url={url}
        ref={player}
        playing={isPlaying}
        playbackRate={speed}
        muted={muted}
        onProgress={setProgress}
        onDuration={setDuration}
        volume={volume / 100}
        width="780px"
      />

      <PlayerControls
        title="Título do vídeo"
        {...progress}
        onSeek={(time) => player.current.seekTo(time)}
        duration={duration}
        muted={muted}
        setMuted={setMuted}
        speed={speed}
        setSpeed={setSpeed}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        volume={volume}
        setVolume={setVolume}
        anchors={anchors}
      />
      {quizOpen && momentQuestion && (
        <Quiz
          open={momentQuestion}
          onComplete={() => {
            setQuizOpen(false);
            setIsPlaying(true);
          }}
          question={momentQuestion.title}
          options={momentQuestion.choices}
          correctOption={momentQuestion.correct}
        />
      )}
    </div>
  );
};

function convertSecondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  
  return `${hours}:${minutes}:${remainingSeconds}`;
}

export default Player;
