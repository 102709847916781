import React, { Component } from 'react';
import {UserService} from '../../services/user';
import * as S from "./element-attachment-1.styled";


class ElementAttachment1 extends Component {
  state = {
    content: [],
  }

  getAssetUrl = (assetUrl) => {
    const publicAssetUrlArr = [
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg"
    ];
    if (false === publicAssetUrlArr.includes(assetUrl)) {
      assetUrl = `${assetUrl}&access_token=${UserService.getUser().access_token}`;
    }
    return assetUrl;
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  getBytes = (bytes) => {
    if (bytes < 1024) return bytes + " B";
    if (bytes < 1048576) return (bytes / 1024).toFixed(1) + " KB";
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(1) + " MB";
    if (bytes < 1099511627776) return (bytes / 1073741824).toFixed(1) + " GB";
    return (bytes / 1099511627776).toFixed(1) + " TB";
  }

  render() {
    const {
      headerActionsTemplate,
      footerActionsTemplate,
      content,
      className
    } = this.props;
    const {
      background_color,
      primary_color,
      padding_top,
      padding_bottom,
      font_family,
      items = [],
    } = content;


    return (
      <S.ElementAttachment1
        tabIndex="1"
        className={`${className} ${this.getFont(font_family) || ""}`}
        backgroundColor={background_color}
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
      >
        {headerActionsTemplate}

        <S.AttachmentList>
          {items.map((item) => (
            <S.AttachmentItem
              key={item.id}
              className="rdp-element-attachment-1-item"
            >
              <S.Attachment
                tabIndex="1"
                href={this.getAssetUrl(item.original_url)}
                target="_blank"
                primaryColor={primary_color}
              >
                <S.LeftContainer>
                  <S.FileIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z" />
                  </S.FileIcon>

                  <S.Information>
                    <S.FileName>
                      {item.filename}
                    </S.FileName>

                    <S.FileSize>
                      {this.getBytes(item.size)}
                    </S.FileSize>
                  </S.Information>
                </S.LeftContainer>

                <S.RightContainer>
                  <S.AttachmentIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="32"
                    height="32"
                  >
                    <path fill="currentColor" d="M17,13L12,18L7,13H10V9H14V13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
                  </S.AttachmentIcon>
                </S.RightContainer>
              </S.Attachment>
            </S.AttachmentItem>
          ))}
        </S.AttachmentList>

        {footerActionsTemplate}
      </S.ElementAttachment1>
    )
  }
}

export default ElementAttachment1;
