import React, {Component} from 'react';
import './preview.css';
import {DamService} from '../../services/dam';
import {UserService} from '../../services/user';
import { Compositions } from '../../containers';
import { HeaderPreview } from '../../components';
import RDP_CONFIG from '../../config';

export default class ViewPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "is_embed": this.props.match.path.search("/embed/") > -1,
      "active_device": "mobile",
      "composition": undefined
    }

    this.changeDevice = this.changeDevice.bind(this);
  }

  componentWillMount() {
    this.getCompositionContent()
  }

  changeDevice(device) {
    this.setState({
      "active_device": device
    })
  }

  getCompositionContent() {
    var _this = this;
    var id = this.props.match.params.content_uid;
    var userData = UserService.getUser();
    var context = { api_env : RDP_CONFIG.apiEnv, project_key : RDP_CONFIG.projectKey};
    var accessToken = userData.access_token;
    DamService.getContent(id, accessToken).then(function(composition) {
      
      composition = _this.adjustStructure(context, JSON.parse(composition), userData);

      _this.setState({
        "composition": composition
      });
    });
  }

  getIframeSrc() {
    var id = this.props.match.params.content_uid;
    var userData = UserService.getUser();
    var accessToken = userData.access_token;

    var options = {
      "activity":{
        "mime_type":"digitalpages/composer",
        "url": `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${id}`
      },
      "access_token": accessToken
    }

    let domain = RDP_CONFIG.domain;
    if (domain === "api-euconquisto.azure-api.net") {
      domain = "content-euconquisto.azureedge.net";
    }

    var keyType = RDP_CONFIG.projectUid.length > 0 ? "p" : "d";
    var keyValue = keyType == "p" ? RDP_CONFIG.projectUid : RDP_CONFIG.directoryUid;

    var composerPlayer = `https://${domain}/product/learning_player/current/${RDP_CONFIG.projectKey}/${keyType}/${keyValue}/${RDP_CONFIG.apiEnv.toLocaleLowerCase()[0]}/init?custom_options=${btoa(JSON.stringify(options))}`;

    return composerPlayer;
  }

  adjustStructure(context, structure, userData)
  {
    if (!structure.assets) return structure;

    var originalJson = JSON.stringify(structure);
    var accessToken = userData ? userData.access_token : "";
    var mapReplaced = [];

    for (const index in structure.assets) {
      const url = structure.assets[index];

      if (url.indexOf("/content") == -1 || url.indexOf("/storage/v") == -1) continue;

      if (mapReplaced.find(p=> p == url)) continue;

      var newUrl = url;
      if (newUrl.indexOf("api_env") == -1) newUrl += `&api_env=${context.api_env}`;
      if (newUrl.indexOf("project_key") == -1) newUrl += `&project_key=${context.project_key}`;

      originalJson = originalJson.replaceAll(url, newUrl);
      mapReplaced.push(url);
    }

    structure = JSON.parse(originalJson);
    return structure;
  }

  renderComposition() {
    if (this.state.composition !== undefined) {
      return <Compositions
        editable={false}
        ui={this.state.composition.interface}
        composition={this.state.composition}
        structure={this.state.composition.structure}
      />
    }
  }

  getBackUrl() {
    if (this.props.location && this.props.location.state && this.props.location.state.fromHome) {
      let path = this.state.is_embed ? "#/embed" : "#/";
      return window.location.origin + window.location.pathname + path;
    } else {
      var path = this.state.is_embed ? "#/embed/composer/" : "#/composer/";
      var lastPath = this.props.match.path.search("onlyedit") > -1 ? "/onlyedit" : "";

      return window.location.origin + window.location.pathname + path + this.props.match.params.content_uid + lastPath;
    }
  }

  render() {
    return (<div className="rdp-composer-preview">
      <HeaderPreview
        onDeviceChange={(device) => this.changeDevice(device)}
        backUrl={this.getBackUrl()}
        isEmbed={this.state.is_embed}
      />
      <div className="rdp-composer-preview-background">
        <div className={`rdp-composer-preview-device ${this.state.active_device}`}>
          <iframe style={{width: "100%", height: "100%"}} src={this.getIframeSrc()}></iframe>
          {/* {this.renderComposition()} */}
        </div>
      </div>
    </div>);
  }
}
