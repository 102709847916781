var rdpTranslateEn = function() {
  return {
    "status": {
      "400": "Bad Request",
      "401": "Unauthorized",
      "403": "Forbidden",
      "404": "Not Found",
      "500": "Internal Server Error",
      "503": "Service Unavailable",
      "back": "Back"
    },
    "login": {
      "messages": {
        "form_title": "Enter your credentials to access RDP Composer."
      },
      "inputs": {
        "username_label": "Username",
        "password_label": "Password",
      },
      "buttons": {
        "sign_in": "Sign In"
      }
    },
    "home": {
      "buttons": {
        "new_composition": "New composition",
        "hello_label": "Hello",
        "project_label": "Project",
        "language_label": "Language",
        "sign_out": "Sign out"
      },
      "labels": {
        "search_placeholder": "Search...",
        "no_compositions_message": "No created compositions."
      },
      "cards": {
        "composition_tag_version": "Version",
        "composition_actor": "Author",
        "composition_last_modification": "Last modification"
      }
    },
    "composer": {
      "not_found": "Composition not found.",
      "yes": "Yes",
      "no": "No",
      "composition_being_edited": "Composition being edited in another session, do you want to take over the editing, at the risk of losing information?",
      "attention": "Attention!",
      "local_changes": "Local changes",
      "save_changes": "Save changes",
      "discard_changes": "Discard changes",
      "save_changes_message": "You have unsaved local changes, do you want to save them?",
      "saved": "Saved!",
      "saved_message": "Changes saved successfully.",
      "lock_loss_message": "Another user has taken control of the composition. Do you want to overwrite the changes?",
      "header": {
        "back_btn": "Back",
        "element_header": "Header",
        "element_text": "Text",
        "element_list": "List",
        "element_image": "Image",
        "element_multimedia": "Multimedia",
        "element_interactivity": "Interactivity",
        "element_quiz": "Quiz",
        "element_activities": "Activities",
        "element_markers": "Markers",
        "menu_save_composition": "Save composition",
        "menu_settings": "Settings",
        "menu_export_zip": "Export as zip",
        "menu_versions_history": "Versions history",
        "menu_preview": "Preview",
        "settings": {
          "title": "Settings",
          "form_label_title": "Title",
          "form_label_gtm": "Google Tag Manager ID",
          "form_label_description": "Description",
          "form_label_document_font": "Content font family",
          "form_label_document_language": "Content Language",
          "form_label_modality": "Modality",
          "form_label_tree_enable": "Show content summary",
          "form_label_max_width": "Max content width",
          "form_label_finish_btn_enable": "Enable finish content button",
          "form_label_progress_element": "Progress sinalization",
          "form_label_progress_element_color": "Progress sinalization color",
          "form_label_scorm_not_evaluative_complete_status": "SCORM finish not evaluative content status",
          "save_btn": "Save",
          "options_progress_element": {
            "none": "None",
            "circular_bottom_center": "Circular progress bar (bottom center)",
          },
          "settings_label_menu_position": "Summary menu position",
          "settings_label_header_progress": "Show progress bar on summary header",
          "settings_label_menu_position_left": "Left",
          "settings_label_menu_position_right": "Right",
          "settings_label_continuous_navigation": "Continuous navigation",
            "settings_label_continuous_navigation_enabled": "Enabled",
            "settings_label_continuous_navigation_disabled": "Disabled",
          "options_document_languages": {
            "en": "English",
            "pt_br": "Portuguese (pt-br)"
          },
          "options_modality": {
            "non_evaluative": "Non-evaluative",
            "evaluative": "Evaluative"
          },
          "tree_enable": {
            "enabled": "Enabled",
            "disabled": "Disabled"
          },
          "finish_btn": {
            "enabled": "Enabled",
            "disabled": "Disabled"
          },
          "tabs": {
            "metadata_label": "Metadata",
            "rules_label": "Evaluation rules",
            "language_label": "Language",
            "interface_label": "Interface",
            "gtm_label": "Google Tag Manager"
          }
        },
        "elements_descriptions": {
          "element_head_1": "Header with background image, center photo and editable texts.",
          "element_text_1": "Editable text with various formatting options that are available when selecting an excerpt of text.",
          "element_text_2": "Editable text and title with several formatting options that are available when selecting an excerpt of text.",
          "element_text_3": "Editable text and subtitle with various formatting options that are available when selecting an excerpt of text.",
          "element_text_4": "Editable title with several formatting options that are available when selecting an excerpt of text.",
          "element_text_5": "Editable subtitle with various formatting options that are available when selecting an excerpt of text.",
          "element_text_two_column": "Two editable text columns with various formatting options available when selecting a piece of text.",
          "element_list_1": "List of textual items with numerical sequence.",
          "element_list_2": "List of textual items with checkbox interaction.",
          "element_list_3": "List with bulleted textual items.",
          "element_image_1": "Image with customizable width being limited to 760 pixels.",
          "element_image_2": "Fullscreen image that takes up 100% of the device's width.",
          "element_images_gallery": "Images gallery in which images are displayed next to each other.",
          "element_image_and_text": "Image with text on the side.",
          "element_image_with_text": "Image with text inside.",
          "element_audio": "Audio that allows the use of .mp3 files",
          "element_video": "Video that allows the use of .mp4 files.",
          "element_iframe": "Iframe that allows the display of external websites and videos.",
          "element_accordion": "Accordion that allows you to expand editable topics. You can edit the title, format the text and insert image in the content.",
          "element_tabs": "Tabs that allow you to open editable tabs. It is possible to edit the title, format the text and insert image in the content.",
          "element_flashcards": "Flashcards that enable the flip of a card on click. You can edit the title, format the text and insert an image on the front and back of each card.",
          "element_hotspots_1": "Image that makes it possible, adds information points.",
          "sorted_activity_1": "Cards that can be dragged to the correct pile.",
          "element_timeline_1": "Timeline that allows you to create a timeline with events. It is possible to edit the title, format the text and insert image, vídeo and audio in the content.",
          "element_quiz_1": "Single choice quiz.",
          "element_quiz_2": "Multiple choice quiz.",
          "element_quiz_3": "Quiz to complete the text.",
          "element_quiz_4": "Questionnaire linking the answers.",
          "element_forum_1": "Evaluative forum integrated to the Learning module.",
          "element_tex_1": "Insert math formulas in your content, using the TeX standard.",
          "element_divider_1": "Divider line to be inserted between objects.",
          "element_divider_4": "Space to be inserted between objects.",
          "element_divider_numbered": "Divider line with number to be inserted between objects.",
          "virtual_element_index_1": "Marker to divide the composition into small blocks generating a navigation index.",
          "element_statement_1": "Text in declaration format, editable with several formatting options that are available when selecting a text excerpt.",
          "element_statement_2": "Text in declaration format, editable with several formatting options that are available when selecting a text excerpt.",
          "element_statement_3": "Text in declaration format, editable with several formatting options that are available when selecting a text excerpt.",
          "element_statement_4": "Text in declaration format, editable with several formatting options that are available when selecting a text excerpt.",
          "element_note_1": "Text in note format, editable with several formatting options that are available when selecting a text excerpt.",
          "element_quote_1": "Editable quote with several formatting options, which makes it possible to change the avatar and edit the text.",
          "element_quote_2": "Editable quote with several formatting options, which makes it possible to change the avatar and edit the text.",
          "element_quote_3": "Editable quote with several formatting options, which makes it possible to change the avatar and edit the text.",
          "element_quote_4": "Editable quote with several formatting options, which makes it possible to change the avatar and edit the text.",
          "element_quote_5": "Editable quote with several formatting options, which allows changing the avatar, background image and text editing.",
          "element_steps_1": "Slide with sequential steps. You can edit the title, format the text, and insert image and audio into the content of each step.",
          "element_snippet_1": "Allows you to add copyable code snippet.",
          "element_button_1": "Button with description. You can add a link or email address, format the description, and change the button color.",
          "element_button_2": "Group of buttons with description. You can add a link or email address, format the description, and change the button color.",
          "element_attachment_1": "Allows you to attach files to be downloaded.",
          "element_quote_6": "Editable quotes carousel with several formatting options, which allows changing the avatar, background image and text editing.",
          "element_3d": "Display of 3D objects, with options for rotating, zooming and viewing in real environments via smartphones.",
          "element_interactive_video": "Window that allows the display of a video in an interactive way.",
        }
      },
      "stage": {
        "no_elements_message": "Start composing your content now!",
        "choose_element_to_insert_above": "Choose from the menu above a resource to insert here.",
        "downloading_scorm_message": "Please wait, the SCORM package is being generated and the download will soon start."
      },
      "elements": {
        "messages": {
          "title": "Messages"
        },
        "common_settings": {
          "legend_text": "Legend: Text",
          "legend_color": "Legend: Color",
          "title": "Edit Object",
          "label_tab_content": "Content",
          "label_tab_settings": "Settings",
          "label_tab_rules": "Rules",
          "label_tab_templates": "Templates",
          "label_color_change": "Change",
          "label_color_done": "Done",
          "label_btn_save": "Save",
          "label_insert_rdp_dam_object": "Insert RDP Dam object",
          "label_click_here_to_upload": "Click here to upload",
          "label_uploading_file": "Uploading file...",
          "label_select_file": "Select",
          "label_no_has_font": "No font family selected",
          "label_template_success_alert": "Your template has been saved successfully!",
          "label_template_error_alert": "Error saving template, please try again.",
          "label_template_manager_templates": "Manage templates",
          "label_template_instructions": "Save the settings made in the element as a template, or use previously saved templates to populate the visual settings and rules of the object you are editing.",
          "label_template_save_new_template": "Save new template",
          "label_template_use_existent_template": "Use existent template",
          "label_template_save_template": "Save template",
          "label_template_instructions_template_name": "Enter the name below to save your object settings as a template:",
          "label_template_cancel": "Cancel",
          "label_template_save": "Save",
          "label_template_use_template": "Use template",
          "label_template_search_template": "Search template...",
          "label_template_empty_templates": "No templates available for use."
        },
        "settings": {
          "preference_required_interaction": {
            "label": "Required interaction",
            "title": "Progress accounting",
            "feedback_title": "Progress feedback",
            "feedback_label": "Displays an indicator of the interaction progress required to complete the object",
            "reaction_title": "Enable reactions",
            "reaction_label": "Displays a list of icons to interaction with object"
          },
          "element_interactive_video": {
            "settings_label_content_title": "Content title",
            "settings_label_webview_url": "Webview URL",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_head_1": {
            "settings_label_content_title": "Content title",
            "settings_label_background_image": "Background image",
            "settings_label_avatar_image": "Avatar image",
            "settings_label_avatar_border_color": "Avatar border color",
            "settings_label_primary_color": "Primary color",
            "settings_label_secondary_color": "Secondary color",
            "settings_label_label_font": "Element font family",
            "settings_button_avatar_remove": "Remove",
            "settings_button_avatar_restore": "Restore to default",
            "settings_button_avatar_add": "Select",
            "settings_label_show_category": "Show category",
            "settings_label_show_author_name": "Show author name",
            "settings_label_show_divider": "Show divider",
          },
          "element_text": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_text_two_column": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_list": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_primary_color": "Primary color",
            "settings_label_secondary_color": "Secondary color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "content_label_description": "Text",
            "content_label_btn_add_item": "Add item",
            "settings_label_label_font": "Element font family"
          },
          "element_image": {
            "settings_label_content_title": "Content title",
            "settings_label_background_image": "Background image",
            "settings_label_background_color": "Background color",
            "settings_label_image_width": "Image width",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_images_gallery": {
            "settings_label_content_title": "Content title",
            "settings_label_images": "Images",
            "settings_label_caption": "Caption",
            "settings_label_background_color": "Background color",
            "settings_label_text_color": "Text color",
            "settings_label_image_width": "Image width",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_image_and_text": {
            "settings_label_content_title": "Content title",
            "settings_label_background_image": "Background image",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family",
            "settings_label_image_position": "Image position",
            "settings_label_image_position_left": "Left",
            "settings_label_image_position_right": "Right",
            "settings_label_image_width": "Image width",
            "settings_label_image_border_radius": "Image border radius",
            "settings_label_image_zoom": "Zoom",
            "settings_label_image_zoom_enabled": "Enabled",
            "settings_label_image_zoom_disabled": "Disabled",
            "settings_label_image_zoom_max_level": "Zoom: Max level",
            "settings_label_image_zoom_header_background_color": "Zoom: Header background color",
            "settings_label_image_zoom_icons_color": "Zoom: Icons color"
          },
          "element_image_with_text": {
            "settings_label_content_title": "Content title",
            "settings_label_background_image": "Background image",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family",
            "settings_label_label_opacity": "Opacity",
            "settings_label_label_opacity_color": "Opacity Color",
            "settings_label_label_divider_color": "Divider Color",
          },
          "element_3d": {
            "settings_label_content_title": "Content title",
            "settings_label_file": "File",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_audio": {
            "settings_label_content_title": "Content title",
            "settings_label_audio_file": "Audio",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_video": {
            "settings_label_content_title": "Content title",
            "settings_label_video_file": "Video",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_iframe": {
            "settings_label_content_title": "Content title",
            "settings_label_webview_url": "Webview URL",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_width": "Width",
            "settings_label_height": "Height",
            "settings_label_label_font": "Element font family"
          },
          "element_accordion": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_display_behavior": "Display behavior",
            "settings_label_display_behavior_do_not_close_items": "When opening an item, do not close opened items",
            "settings_label_display_behavior_close_items": "When opening an item, close the opened items",
            "content_label_title": "Title",
            "content_label_description": "Description",
            "content_label_insert_image": "Insert image",
            "content_label_insert_video": "Insert video",
            "content_label_btn_add_item": "Add item",
            "content_label_btn_remove_asset": "Remove",
            "settings_label_label_font": "Element font family"
          },
          "element_tabs": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_active_tab_color": "Active tab color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "content_label_title": "Title",
            "content_label_description": "Description",
            "content_label_insert_image": "Insert image",
            "content_label_insert_video": "Insert video",
            "content_label_btn_add_item": "Add item",
            "content_label_btn_remove_asset": "Remove",
            "settings_label_label_font": "Element font family"
          },
          "element_flashcards": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_border_color": "Border color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_card_size": "Cards size",
            "content_front_of_the_card": "Front of the Card",
            "content_back_of_the_card": "Back of the Card",
            "content_label_btn_add_item": "Add item",
            "content_option_text": "Text",
            "content_option_centered_image": "Centered image",
            "content_option_fullscreen_image": "Fullscreen image",
            "content_option_card_size_1": "Small square",
            "content_option_card_size_2": "Big square",
            "content_option_card_size_3": "Horizontal rectangle",
            "content_option_card_size_4": "Vertical rectangle",
            "content_label_image_edit": "Edit",
            "settings_label_label_font": "Element font family"
          },
          "element_quiz_1": {
            "settings_label_content_title": "Content title",
            "settings_label_scorm_progress_count": "Progress count",
            "settings_scorm_progress_count_without_finish": "Without finish",
            "settings_scorm_progress_count_with_finish_and_minimum_score": "Finish with minimum score",
            "settings_scorm_progress_count_with_finish_without_minimum_score": "Finish Without minimum score",
            "settings_label_mandatory_minimum_achievement": "Mandatory minimum achievement",
            "settings_label_questions_random_order" : "Random order of choices",
            "settings_label_questions_random_questions_order" : "Random order of questions",
            "settings_option_to_questions_random_order_enabled" : "Enabled",
            "settings_option_to_questions_random_order_disabled" : "Disabled",
            "settings_mandatory_minimum_achievement_enabled": "Enabled",
            "settings_mandatory_minimum_achievement_disabled": "Disabled",
            "settings_label_minimum_achievement_percentage": "Minimum achievement percentage",
            "settings_label_feedback_behavior": "Feedback behavior",
            "settings_feedback_behavior_default_message_to_any_reply": "Default message to any reply",
            "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Specific message for correct and/or incorrect answer",
            "settings_feedback_behavior_no_feedback": "No feedback",
            "settings_label_number_of_questions_to_display": "Number of questions to display",
            "settings_label_option_to_repeat_quiz": "Option to repeat quiz",
            "settings_label_max_attempts": "Maximum number of remakes",
            "settings_label_sort_on_remake": "Sort on remake",
            "settings_option_to_repeat_quiz_enabled": "Enabled",
            "settings_option_to_repeat_quiz_disabled": "Disabled",
            "settings_label_main_color": "Main color",
            "settings_label_background_color": "Background color",
            "settings_label_correct_option_color": "Correct option color",
            "settings_label_wrong_option_color": "Wrong option color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "content_label_question": "Question",
            "content_label_enunciation": "Enunciation",
            "content_label_insert_image": "Insert image",
            "content_label_insert_video": "Insert video",
            "content_label_alternatives": "Alternatives",
            "content_label_no_correct_answer": "No correct answer",
            "content_placeholder_alternative": "Alternative",
            "content_button_add_alternative": "Add alternative",
            "content_label_feedback": "Feedback",
            "content_placeholder_feedback": "Default feedback",
            "content_placeholder_feedback_correct": "Feedback when correct",
            "content_placeholder_feedback_incorrect": "Feedback when incorrect",
            "content_placeholder_feedback_media_max_width": "Maximum image or video width",
            "content_button_add_question": "Add question",
            "content_label_btn_remove_asset": "Remove",
            "settings_label_label_font": "Element font family"
          },
          "element_button_1": {
            "settings_label_tab_items": "Buttons",
            "settings_label_button": "Button",
            "settings_label_summary": "Summary",
            "settings_label_type": "Type",
            "settings_label_type_link": "Address of a website",
            "settings_label_type_email": "Email address",
            "settings_label_destination": "Destination",
            "settings_label_label": "Label",
            "settings_label_description": "Description",
            "settings_label_background_color": "Background color",
            "settings_label_item_color": "Primary color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family",
            "settings_label_font_button": "Button font family",
            "settings_label_font_text": "Text font family"
          },
          "element_quiz_2": {
            "settings_label_content_title": "Content title",
            "settings_label_scorm_progress_count": "Progress count",
            "settings_label_mandatory_minimum_achievement": "Mandatory minimum achievement",
            "settings_label_questions_random_order" : "Random order of questions",
            "settings_option_to_questions_random_order_enabled" : "Enabled",
            "settings_option_to_questions_random_order_disabled" : "Disabled",
            "settings_scorm_progress_count_without_finish": "Não é necessário realizar o quiz, para contabilizar progresso",
            "settings_scorm_progress_count_with_finish_and_minimum_score": "É necessário realizar o quiz, atingindo a nota mínima para contabilizar progresso",
            "settings_scorm_progress_count_with_finish_without_minimum_score": "É necessário realizar o quiz, não precisando atingir a nota mínima para contabilizar progresso",
            "settings_mandatory_minimum_achievement_enabled": "Enabled",
            "settings_mandatory_minimum_achievement_disabled": "Disabled",
            "settings_label_minimum_achievement_percentage": "Minimum achievement percentage",
            "settings_label_feedback_behavior": "Feedback behavior",
            "settings_feedback_behavior_default_message_to_any_reply": "Default message to any reply",
            "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Specific message for correct and/or incorrect answer",
            "settings_feedback_behavior_no_feedback": "No feedback",
            "settings_label_option_to_repeat_quiz": "Option to repeat quiz",
            "settings_label_max_attempts": "Maximum number of remakes",
            "settings_option_to_repeat_quiz_enabled": "Enabled",
            "settings_option_to_repeat_quiz_disabled": "Disabled",
            "settings_label_main_color": "Main color",
            "settings_label_background_color": "Background color",
            "settings_label_correct_option_color": "Correct option color",
            "settings_label_wrong_option_color": "Wrong option color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "content_label_question": "Question",
            "content_label_enunciation": "Enunciation",
            "content_label_insert_image": "Insert image",
            "content_label_insert_video": "Insert video",
            "content_label_alternatives": "Alternatives",
            "content_label_no_correct_answer": "No correct answer",
            "content_placeholder_alternative": "Alternative",
            "content_button_add_alternative": "Add alternative",
            "content_label_feedback": "Feedback",
            "content_placeholder_feedback": "Default feedback",
            "content_placeholder_feedback_correct": "Feedback when correct",
            "content_placeholder_feedback_incorrect": "Feedback when incorrect",
            "content_placeholder_feedback_media_max_width": "Maximum image or video width",
            "content_button_add_question": "Add question",
            "content_label_btn_remove_asset": "Remove",
            "settings_label_label_font": "Element font family"
          },
          "element_quiz_3": {
            "settings_label_content_title": "Content title",
            "settings_label_scorm_progress_count": "Progress count",
            "settings_scorm_progress_count_without_finish": "Without finish",
            "settings_scorm_progress_count_with_finish_and_minimum_score": "Finish with minimum score",
            "settings_scorm_progress_count_with_finish_without_minimum_score": "Finish Without minimum score",
            "settings_label_mandatory_minimum_achievement": "Mandatory minimum achievement",
            "settings_label_questions_random_order" : "Random order of questions",
            "settings_option_to_questions_random_order_enabled" : "Enabled",
            "settings_option_to_questions_random_order_disabled" : "Disabled",
            "settings_mandatory_minimum_achievement_enabled": "Enabled",
            "settings_mandatory_minimum_achievement_disabled": "Disabled",
            "settings_label_minimum_achievement_percentage": "Minimum achievement percentage",
            "settings_label_feedback_behavior": "Feedback behavior",
            "settings_feedback_behavior_default_message_to_any_reply": "Default message to any reply",
            "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Specific message for correct and/or incorrect answer",
            "settings_feedback_behavior_no_feedback": "No feedback",
            "settings_label_option_to_repeat_quiz": "Option to repeat quiz",
            "settings_label_max_attempts": "Maximum number of remakes",
            "settings_option_to_repeat_quiz_enabled": "Enabled",
            "settings_option_to_repeat_quiz_disabled": "Disabled",
            "settings_label_main_color": "Main color",
            "settings_label_background_color": "Background color",
            "settings_label_correct_option_color": "Correct option color",
            "settings_label_wrong_option_color": "Wrong option color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "content_label_question": "Question",
            "content_label_enunciation": "Enunciation",
            "content_label_insert_image": "Insert image",
            "content_label_insert_video": "Insert video",
            "content_label_alternatives": "Alternatives",
            "content_label_no_correct_answer": "No correct answer",
            "content_placeholder_alternative": "Alternative",
            "content_button_add_alternative": "Add alternative",
            "content_label_feedback": "Feedback",
            "content_placeholder_feedback": "Default feedback",
            "content_placeholder_feedback_correct": "Feedback when correct",
            "content_placeholder_feedback_incorrect": "Feedback when incorrect",
            "content_placeholder_feedback_media_max_width": "Maximum image or video width",
            "content_button_add_question": "Add question",
            "content_label_btn_remove_asset": "Remove",
            "settings_label_label_font": "Element font family"
          },
          "element_quiz_4": {
            "settings_label_content_title": "Content title",
            "settings_label_scorm_progress_count": "Progress count",
            "settings_scorm_progress_count_without_finish": "Without finish",
            "settings_scorm_progress_count_with_finish_and_minimum_score": "Finish with minimum score",
            "settings_scorm_progress_count_with_finish_without_minimum_score": "Finish Without minimum score",
            "settings_label_mandatory_minimum_achievement": "Mandatory minimum achievement",
            "settings_label_questions_random_order" : "Random order of questions",
            "settings_option_to_questions_random_order_enabled" : "Enabled",
            "settings_option_to_questions_random_order_disabled" : "Disabled",
            "settings_mandatory_minimum_achievement_enabled": "Enabled",
            "settings_mandatory_minimum_achievement_disabled": "Disabled",
            "settings_label_minimum_achievement_percentage": "Minimum achievement percentage",
            "settings_label_feedback_behavior": "Feedback behavior",
            "settings_feedback_behavior_default_message_to_any_reply": "Default message to any reply",
            "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Specific message for correct and/or incorrect answer",
            "settings_feedback_behavior_no_feedback": "No feedback",
            "settings_label_option_to_repeat_quiz": "Option to repeat quiz",
            "settings_label_max_attempts": "Maximum number of remakes",
            "settings_option_to_repeat_quiz_enabled": "Enabled",
            "settings_option_to_repeat_quiz_disabled": "Disabled",
            "settings_label_main_color": "Main color",
            "settings_label_background_color": "Background color",
            "settings_label_correct_option_color": "Correct option color",
            "settings_label_wrong_option_color": "Wrong option color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "content_label_question": "Question",
            "content_label_enunciation": "Enunciation",
            "content_label_insert_image": "Insert image",
            "content_label_insert_video": "Insert video",
            "content_label_alternatives": "Alternatives",
            "content_label_no_correct_answer": "No correct answer",
            "content_placeholder_alternative": "Alternative",
            "content_button_add_alternative": "Add alternative",
            "content_label_feedback": "Feedback",
            "content_placeholder_feedback": "Default feedback",
            "content_placeholder_feedback_correct": "Feedback when correct",
            "content_placeholder_feedback_incorrect": "Feedback when incorrect",
            "content_placeholder_feedback_media_max_width": "Maximum image or video width",
            "content_button_add_question": "Add question",
            "content_label_btn_remove_asset": "Remove",
            "settings_label_label_font": "Element font family"
          },
          "element_quiz_5": {
            "settings_label_content_title": "Content title",
            "settings_label_scorm_progress_count": "Progress count",
            "settings_label_mandatory_minimum_achievement": "Mandatory minimum achievement",
            "settings_scorm_progress_count_without_finish": "Não é necessário realizar o quiz, para contabilizar progresso",
            "settings_scorm_progress_count_with_finish_and_minimum_score": "É necessário realizar o quiz, atingindo a nota mínima para contabilizar progresso",
            "settings_scorm_progress_count_with_finish_without_minimum_score": "É necessário realizar o quiz, não precisando atingir a nota mínima para contabilizar progresso",
            "settings_mandatory_minimum_achievement_enabled": "Enabled",
            "settings_mandatory_minimum_achievement_disabled": "Disabled",
            "settings_label_questions_random_order" : "Random order of questions",
            "settings_option_to_questions_random_order_enabled" : "Enabled",
            "settings_option_to_questions_random_order_disabled" : "Disabled",
            "settings_label_minimum_achievement_percentage": "Minimum achievement percentage",
            "settings_label_feedback_behavior": "Feedback behavior",
            "settings_feedback_behavior_default_message_to_any_reply": "Default message to any reply",
            "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Specific message for correct and/or incorrect answer",
            "settings_feedback_behavior_no_feedback": "No feedback",
            "settings_label_option_to_repeat_quiz": "Option to repeat quiz",
            "settings_label_max_attempts": "Maximum number of remakes",
            "settings_option_to_repeat_quiz_enabled": "Enabled",
            "settings_option_to_repeat_quiz_disabled": "Disabled",
            "settings_label_main_color": "Main color",
            "settings_label_background_color": "Background color",
            "settings_label_correct_option_color": "Correct option color",
            "settings_label_wrong_option_color": "Wrong option color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "content_label_question": "Question",
            "content_label_enunciation": "Enunciation",
            "content_label_insert_image": "Insert image",
            "content_label_insert_video": "Insert video",
            "content_label_no_correct_answer": "No correct answer",
            "content_label_feedback": "Feedback",
            "content_placeholder_feedback": "Default feedback",
            "content_placeholder_feedback_correct": "Feedback when correct",
            "content_placeholder_feedback_incorrect": "Feedback when incorrect",
            "content_placeholder_feedback_media_max_width": "Maximum image or video width",
            "content_button_add_question": "Add question",
            "content_label_btn_remove_asset": "Remove",
            "settings_label_label_font": "Element font family",

            "rules_label_grade_type": "Grade type",
            "rules_grade_type_numeral": "Numeral",
            "rules_label_grade_numeral_min": "Minimum grade",
            "rules_label_grade_numeral_max": "Maximum grade",
            "rules_label_grade_numeral_steps": "Steps",
          },
          "element_quiz_6": {
            "settings_label_content_title": "Content title",
            "settings_label_scorm_progress_count": "Progress count",
            "settings_scorm_progress_count_without_finish": "Without finish",
            "settings_scorm_progress_count_with_finish_and_minimum_score": "Finish with minimum score",
            "settings_scorm_progress_count_with_finish_without_minimum_score": "Finish Without minimum score",
            "settings_label_mandatory_minimum_achievement": "Mandatory minimum achievement",
            "settings_mandatory_minimum_achievement_enabled": "Enabled",
            "settings_mandatory_minimum_achievement_disabled": "Disabled",
            "settings_label_questions_random_order" : "Random order of questions",
            "settings_option_to_questions_random_order_enabled" : "Enabled",
            "settings_option_to_questions_random_order_disabled" : "Disabled",
            "settings_label_minimum_achievement_percentage": "Minimum achievement percentage",
            "settings_label_feedback_behavior": "Feedback behavior",
            "settings_feedback_behavior_default_message_to_any_reply": "Default message to any reply",
            "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Specific message for correct and/or incorrect answer",
            "settings_feedback_behavior_no_feedback": "No feedback",
            "settings_label_option_to_repeat_quiz": "Option to repeat quiz",
            "settings_label_max_attempts": "Maximum number of remakes",
            "settings_option_to_repeat_quiz_enabled": "Enabled",
            "settings_option_to_repeat_quiz_disabled": "Disabled",
            "settings_label_main_color": "Main color",
            "settings_label_background_color": "Background color",
            "settings_label_correct_option_color": "Correct option color",
            "settings_label_wrong_option_color": "Wrong option color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "content_label_question": "Question",
            "content_label_enunciation": "Enunciation",
            "content_label_insert_image": "Insert image",
            "content_label_insert_video": "Insert video",
            "content_label_alternatives": "Alternatives",
            "content_label_no_correct_answer": "No correct answer",
            "content_placeholder_alternative": "Alternative",
            "content_button_add_alternative": "Add alternative",
            "content_label_feedback": "Feedback",
            "content_placeholder_feedback": "Default feedback",
            "content_placeholder_feedback_correct": "Feedback when correct",
            "content_placeholder_feedback_incorrect": "Feedback when incorrect",
            "content_placeholder_feedback_media_max_width": "Maximum image or video width",
            "content_button_add_question": "Add question",
            "content_label_btn_remove_asset": "Remove",
            "settings_label_label_font": "Element font family",
            "quiz-1_type_label": "Unique choice",
            "quiz-2_type_label": "Multiple choice",
            "quiz-3_type_label": "Complete the sentence",
            "quiz-4_type_label": "Match the columns",
            "quiz-5_type_label": "Dissertation",

            "rules_label_grade_type": "Grade type",
            "rules_grade_type_numeral": "Numeral",
            "rules_label_grade_numeral_min": "Minimum grade",
            "rules_label_grade_numeral_max": "Maximum grade",
            "rules_label_grade_numeral_steps": "Steps",
          },
          "element_forum_1": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family",

            "settings_label_confirm_button_text_color": "Confirm button text color",
            "settings_label_confirm_button_background_color": "Confirm button background color",
            "settings_label_cancel_button_text_color": "Cancel button text color",
            "settings_label_cancel_button_background_color": "Cancel button background color",
            "settings_label_topic_button_color": "Topic button color",
            "settings_label_primary_topic_background_color": "Primary topic background color",
            "settings_label_primary_topic_author_color": "Primary topic author color",
            "settings_label_primary_topic_text_color": "Primary topic text color",
            "settings_label_secondary_topic_background_color": "Secondary topic background color",
            "settings_label_secondary_topic_author_color": "Secondary topic author color",
            "settings_label_secondary_topic_text_color": "Secondary topic text color",

            "rules_label_grade_type": "Grade type",
            "rules_grade_type_numeral": "Numeral",
            "rules_label_grade_numeral_min": "Minimum grade",
            "rules_label_grade_numeral_max": "Maximum grade",
            "rules_label_grade_numeral_steps": "Steps",

            "rules_label_permission": "Allowed to create topics",
            "rules_permission_reviewer": "Reviewer",
            "rules_permission_reviewadmin": "Admin reviewer",
            "rules_permission_reviewcontributor": "Contributor reviewer",
            "rules_permission_viewer": "Viewer",
          },
          "virtual_index_1": {
            "settings_label_content_title": "Content title",
            "settings_label_title": "Title",
            "settings_label_duration": "Duration"
          },
          "element_hotspots_1": {
            "settings_label_tab_spots": "Markers",
            "settings_label_spot": "Marker",
            "settings_label_alternative_text": "Alternative text",
            "settings_label_title": "Title",
            "settings_label_description": "Description",
            "settings_label_background_image": "Background image",
            "settings_label_spot_image": "Image",
            "settings_label_spot_audio": "Audio",
            "settings_button_upload_image": "Click to select image...",
            "settings_button_upload_audio": "Click to select audio...",
            "settings_button_remove_image": "Remove image",
            "settings_button_remove_audio": "Remove audio",
            "settings_label_spot_style": "Marker icon",
            "settings_label_background_color": "Background color",
            "settings_label_spot_color": "Marker color",
            "settings_label_image_width": "Image width",
            "settings_option_width_small": "Small",
            "settings_option_width_medium": "Medium",
            "settings_option_width_full": "Full width",
            "settings_option_page_width": "Page width",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_font_title": "Interaction title font family",
            "settings_label_font_text": "Interaction text font family"
          },
          "element_divider_1": {
            "settings_label_content_title": "Content title",
            "settings_label_divider_color": "Divider Color",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_divider_4": {
            "settings_label_content_title": "Content title",
            "settings_label_divider_color": "Divider Color",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_divider_numbered": {
            "settings_label_content_title": "Content title",
            "settings_label_divider_primary_color": "Divider Primary Color",
            "settings_label_divider_secondary_color": "Divider Secondary Color",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_statement_1": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_border_color": "Border color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_statement_2": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_border_color": "Border color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_statement_3": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_border_color": "Border color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_statement_4": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_border_color": "Border color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_note_1": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_primary_color": "Primary color",
            "settings_label_secondary_color": "Secondary color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family"
          },
          "element_quote_1": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_avatar_image": "Avatar image",
            "settings_label_primary_color": "Primary color",
            "settings_label_label_font": "Element font family",
            "settings_button_avatar_remove": "Remove",
            "settings_button_avatar_restore": "Restore to default",
            "settings_button_avatar_add": "Select",
          },
          "element_quote_2": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_avatar_image": "Avatar image",
            "settings_label_primary_color": "Primary color",
            "settings_label_label_font": "Element font family",
            "settings_button_avatar_remove": "Remove",
            "settings_button_avatar_restore": "Restore to default",
            "settings_button_avatar_add": "Select",
          },
          "element_quote_3": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_avatar_image": "Avatar image",
            "settings_label_primary_color": "Primary color",
            "settings_label_label_font": "Element font family",
            "settings_button_avatar_remove": "Remove",
            "settings_button_avatar_restore": "Restore to default",
            "settings_button_avatar_add": "Select",
          },
          "element_quote_4": {
            "settings_label_content_title": "Content title",
            "settings_label_background_color": "Background color",
            "settings_label_avatar_image": "Avatar image",
            "settings_label_primary_color": "Primary color",
            "settings_label_label_font": "Element font family",
            "settings_button_avatar_remove": "Remove",
            "settings_button_avatar_restore": "Restore to default",
            "settings_button_avatar_add": "Select",
          },
          "element_quote_5": {
            "settings_label_content_title": "Content title",
            "settings_label_background_image": "Background image",
            "settings_label_avatar_image": "Avatar image",
            "settings_label_primary_color": "Primary color",
            "settings_label_label_font": "Element font family",
            "settings_button_avatar_remove": "Remove",
            "settings_button_avatar_restore": "Restore to default",
            "settings_button_avatar_add": "Select",
          },
          "element_quote_6": {
            "settings_label_tab_spots": "Quotes",
            "settings_label_spot": "Quote",
            "settings_label_title": "Text",
            "settings_label_description": "Author name",
            "settings_label_spot_image": "Avatar",
            "settings_button_upload_image": "Click to add an image...",
            "settings_button_remove_image": "Remove image",
            "settings_label_background_color": "Background color",
            "settings_label_spot_color": "Primary color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family",
            "content_label_btn_add_item": "Add quote",
            "settings_button_avatar_remove": "Remove",
            "settings_button_avatar_restore": "Restore default",
            "settings_button_avatar_add": "Select"
          },
          "element_sorted_activity_1": {
            "settings_label_background_color": "Background color",
            "settings_label_pile_color": "Pile color",
            "settings_label_pile_text_color": "Pile text color",
            "settings_label_item_color": "Item color",
            "settings_label_item_text_color": "Item text color",
            "settings_label_primary_color": "Primary color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_tab_piles": "Piles",
            "settings_label_add_item": "Add item",
            "settings_label_add_pile": "Add pile",
          },
          "element_timeline_1": {
            "settings_label_tab_spots": "Markers",
            "settings_label_spot": "Marker",
            "settings_label_alternative_text": "Alternative text",
            "settings_label_date": "Date",
            "settings_label_title": "Title",
            "settings_label_description": "Description",
            "settings_label_background_image": "Background image",
            "settings_label_spot_image": "Image",
            "settings_label_spot_audio": "Audio",
            "settings_label_spot_max_height": "Height",
            "settings_button_upload_image": "Click to select image...",
            "settings_button_upload_audio": "Click to select audio...",
            "settings_button_remove_image": "Remove image",
            "settings_button_remove_audio": "Remove audio",
            "settings_label_spot_style": "Marker icon",
            "settings_label_background_color": "Background color",
            "settings_label_spot_color": "Marker color",
            "settings_label_image_width": "Image width",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family",
            "content_label_btn_add_item": "Add item",
          },
          "element_steps_1": {
            "settings_label_tab_items": "Steps",
            "settings_label_item": "Marker",
            "settings_label_title": "Title",
            "settings_label_description": "Description",
            "settings_label_item_image": "Image",
            "settings_label_item_audio": "Audio",
            "settings_button_upload_image": "Click to select image...",
            "settings_button_upload_audio": "Click to select audio...",
            "settings_button_remove_image": "Remove image",
            "settings_button_remove_audio": "Remove audio",
            "settings_label_background_color": "Background color",
            "settings_label_item_color": "Primary color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family",
          },
          "element_attachment_1": {
            "settings_label_tab_attachment": "Attachment",
            "settings_label_file": "File",
            "settings_button_change_file": "Change file",
            "settings_label_background_color": "Background color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Font family",
          },
          "element_gallery_1": {
            "settings_label_tab_spots": "Slides",
            "settings_label_spot": "Slide",
            "settings_label_spot_image": "Image",
            "settings_button_upload_image": "Click to add an image...",
            "settings_label_background_color": "Background color",
            "settings_label_spot_color": "Primary color",
            "settings_label_margin_top": "Top margin",
            "settings_label_margin_bottom": "Bottom margin",
            "settings_label_label_font": "Element font family",
            "content_label_btn_add_item": "Add slide",
            "settings_button_avatar_remove": "Remove",
            "settings_button_avatar_restore": "Restore default",
            "settings_button_avatar_add": "Select"
          },
        }
      }
    }
  }
}

var RDP_TRANSLATE_EN = new rdpTranslateEn();

export {
  RDP_TRANSLATE_EN
};
