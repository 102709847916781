import RDP_CONFIG from "../config";

export const getTheme = () => {
  return window.localStorage.getItem("theme") || "light";
};

export const getOverrides = () => {
  try {
    return JSON.parse(window.localStorage.getItem("overrides"));
  } catch (err) {
    return null;
  }
};
