import $ from "jquery";
import RDP_CONFIG from '../config';
import { UserService } from "../services/user";

var damService = function() {
  this.searchContent = function(filter, q, type, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/storage/v1.0/search?${filter}${q}`,
        // &q=estrondosos
        type: "GET",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getContent = function(id, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${id}&access_token=${accessToken}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`,
        type: "GET"
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.removeContent = function(id, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${id}&access_token=${accessToken}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`,
        type: "DELETE"
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.setLocalContent = function(content, id = null) {
    localStorage.setItem("rdp-composer-data", JSON.stringify(content));
    // if (id) localStorage.setItem("rdp-composer-editing-data-"+id, JSON.stringify(content));
  }

  this.getLocalContent = function(id) {
    if (id) {
      return JSON.parse(localStorage.getItem("rdp-composer-editing-data-"+id));
    } else {
      return JSON.parse(localStorage.getItem("rdp-composer-data"));
    }
  }

  this.removeLocalContent = function(id) {
    localStorage.removeItem("rdp-composer-data");

    if (id) localStorage.removeItem("rdp-composer-editing-data-"+id);
  }

  this.getPubliContent = function(uid, accessToken, index) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/storage/v1.0/content/share/scope/public?uid=${uid}&access_token=${accessToken}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`,
        type: "POST",
      }).then(function(data) {
        data.index = index;
        resolve(data);
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getTags = function(filter, query, type, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/storage/v1.0/search/autocomplete/tags?q=${query}`,
        type: "GET",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.downloadScormPackage = function(contentUid, accessToken) {
    // TODO: Implementar regra de formato do pacote SCORM(assets internos ou externos) de acordo com configuração no conector do Composer pela API.
    var url = `https://${RDP_CONFIG.domain}/storage/v1.0/content/convert/format/scorm?uid=${contentUid}`;
    if (RDP_CONFIG.domain.search("euconquisto") > -1) {
      url = `https://${RDP_CONFIG.domain}/storage/v1.0/content/private/convert/format/scorm?external_structure=true&uid=${contentUid}`;
    }
    
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: url,
        type: "GET",
        xhrFields:{
          responseType: 'blob'
        },
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        }
      }).then(function(blob) {
        resolve(blob)    
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getFileOriginalUid = function(uid, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/storage/v1.0/content/versions/dynamic?manual_project_uid=${UserService.getActiveProject().uid}&uid=${uid}`,
        type: "POST",
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
        resolve(data);
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getFileVersions = function(uid, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/storage/v1.0/content/versions/?uid=${uid}`,
        type: "GET",
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
        resolve(data);
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getGoogleFonts = function() {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBT9ObyYug-k4tOVB01qObA_xd4dOWrof8`,
        type: "GET"
      }).then(function(data) {
        resolve(data.items);
      }, function(error) {
        reject(error)
      });
    })
  }

  this.updateFile = function (contentTags, contentInfo, contentFilters, uid, type, accessToken) {
    var body = {
      content_info: [
        `file_name=${
          contentInfo.file_name !== undefined ? contentInfo.file_name : ""
        }`,
        `file_description=${
          contentInfo.file_description !== undefined
            ? contentInfo.file_description
            : ""
        }`,
        `file_cognitive_description=${
          contentInfo.file_cognitive_description !== undefined
            ? contentInfo.file_cognitive_description
            : ""
        }`,
        `file_copyright=${
          contentInfo.file_copyright !== undefined
            ? contentInfo.file_copyright
            : ""
        }`,
      ],
      content_filters: [
        `file_author=${
          contentFilters.file_author !== undefined
            ? contentFilters.file_author
            : ""
        }`,
        `file_location=${
          contentFilters.file_location !== undefined
            ? contentFilters.file_location
            : ""
        }`,
        `file_language=${
          contentFilters.file_language !== undefined
            ? contentFilters.file_language
            : ""
        }`,
      ],
      content_tags: contentTags
    };

    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/storage/${RDP_CONFIG.apiVersion}/content/metadata?manual_project_uid=${UserService.getActiveProject().uid}&uid=${uid}`,
        type: "PUT",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": RDP_CONFIG.projectKey,
          "Api-Env": RDP_CONFIG.apiEnv,
        },
        contentType: false,
        dataType: "json",
        data: JSON.stringify(body),
      }).then(function () {
        resolve();
      }, function (error) {
        reject(error);
      });
    });
  };

  return this;
}

var DamService = new damService();

export {
  DamService
};

