import React, { useEffect, useState } from "react";
import { Popper } from "./styles";
import { getSkills } from "./api";

export const ChoiceSkills = ({ onChangeSkill, selected }) => {
  const [open, setOpen] = useState(false);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleListenerClickout = (e) => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && !skills.length) {
      setLoading(true);

      (async () => {
        const skillList = await getSkills();
        if (skillList) {
          setSkills(skillList);
        }

        setLoading(false);
      })();
    }

    if (open) document.addEventListener("click", handleListenerClickout);

    return () => document.removeEventListener("click", handleListenerClickout);
  }, [open]);

  const onSelectSkill = (skill) => {
    setOpen(false);
    if (onChangeSkill) onChangeSkill(skill);
  };

  const togglePopper = (e) => {
    const shouldOpen = e.target.classList.contains("open-popper") && !open;
    setOpen(shouldOpen);
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        className="rdp-composer-element-edit-select open-popper"
        style={{ position: "relative", display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={togglePopper}
      >
        {<span className="open-popper">{loading ? "Carregando..." : selected ? selected.name : "Selecione"}</span>}
        {open && (
          <Popper>
            {skills.map((item) => {
              return (
                <span
                  onClick={() => onSelectSkill(item)}
                  style={{ marginLeft: 10 * item.column + 1, marginTop: "15px", cursor: "pointer" }}
                  key={item.uid}
                >
                  {item.name}
                </span>
              );
            })}
          </Popper>
        )}
      </div>
    </div>
  );
};
