import React, { Component } from 'react';
import './virtual-element-index-1-properties.css';

export default class VirtualElementIndex1Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content
    }

    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
  }


  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onTitleChange(e) {
    var content = this.state.content;
    content.title = e.currentTarget.value !== "" ? e.currentTarget.value : content.title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onDurationChange(e) {
    var content = this.state.content;
    content.duration = e.currentTarget.value !== "" ? e.currentTarget.value : content.duration;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;

    return (<div className="rdp-element-audio-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className="rdp-composer-element-edit-tabs-item active">{labels.elements.common_settings.label_tab_settings}</li>
      </ul>
      <div className="rdp-composer-element-edit-body">
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.virtual_index_1.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)} defaultValue={content.content_title}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.virtual_index_1.settings_label_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onTitleChange(e)} defaultValue={content.title}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.virtual_index_1.settings_label_duration}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onDurationChange(e)} defaultValue={content.duration}></input>
      </div>
    </div>);
  }
}
