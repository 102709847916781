import styled from "styled-components";

import Slider from "@material-ui/core/Slider";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    180deg,
    rgba(137, 137, 137, 0) 73%,
    rgb(135 135 135 / 15%) 83%,
    rgb(133 133 133 / 37%) 100%
  );
  background-position: 0 100px;
  background-repeat-y: no-repeat;

  transition-property: background-position;
  transition-duration: 0.2s;

  > div {
    opacity: 0;
    pointer-events: none;
  }

  :hover,
  :not(.hide-controls) {
    background-position: 0;
    transition-duration: 0.1s;

    > div {
      opacity: 1;
      pointer-events: all;
    }
  }

  .MuiSvgIcon-root {
    fill: white;
  }
`;

export const ControlsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiButtonBase-root {
    color: #777;
    height: 54px;
    width: 54px;
    margin: 3px;
    transform: scale(0.95);

    :hover {
      transform: scale(1);
    }

    .MuiIconButton-label {
      transform: scale(1.15);
    }
  }

  .MuiButtonBase-root:nth-child(2) .MuiIconButton-label {
    transform: scale(1.35);
  }
`;

export const BottomArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .MuiButtonBase-root {
    color: #999;
    height: 34px;
    width: 34px;
    margin: 7px;

    .MuiSvgIcon-root {
      position: relative;
    }
  }

  .MuiButton-root {
    min-width: 50px;
    text-transform: unset;
  }
`;

export const PlaybackSlider = styled(Slider)`
  &.MuiSlider-root {
    width: calc(100% - 30px);
    box-sizing: border-box;
  }

  .MuiSlider-thumb {
    height: 16px;
    width: 16px;
    background-color: white;
    border: 2px solid currentColor;
    margin-top: -6px;
    margin-left: -9px;
    z-index: 1;
    &:focus,
    &:hover,
    &:active {
      box-shadow: inherit;
    }
  }

  .MuiSlider-thumb:last-of-type {
    opacity: 0;
    pointer-events: none;
  }

  .MuiSlider-valueLabel {
    left: calc(-50% + 4px);
  }

  .MuiSlider-track,
  .MuiSlider-rail {
    height: 4px;
    border-radius: 2px;
  }

  .MuiSlider-rail {
    background-color: rgb(182, 188, 226);

    ::after {
      content: "";
      height: 4px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      z-index: 1;
      width: ${(props) => (props.value[0] / props.max) * 100}%;
      background-color: currentColor;
    }
  }

  .MuiSlider-track {
    left: 1px !important;
    width: ${(props) => (props.value[1] / props.max) * 100}% !important;
    background-color: #ffffff9c !important;
  }

  .MuiSlider-mark {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-top: 12px;
  }
`;

export const VolumeSlider = styled(Slider)`
  &.MuiSlider-root {
    width: 100px;
  }

  ${(props) => props.muted && `pointer-events: none; opacity: 0.1;`}
`;
