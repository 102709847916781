import { useEffect, useState } from "react";

export const useWindowResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const onChangeResize = (e) => {
    setWidth(e.target.innerWidth);
    setHeight(e.target.innerHeight);
  };


  useEffect(() => {
    window.addEventListener("resize", onChangeResize);

    return () => {
      window.removeEventListener("resize", onChangeResize);
    };
  }, []);

  return {
    width,
    height,
  };
};
