import React from "react";
import * as S from "./input-group.styled";

function InputGroup(props) {
  const {
    type = "radiogroup",
    children,
    ...rest
  } = props;

  return (
    <S.InputGroup
      role={type}
      {...rest}
    >
      {children}
    </S.InputGroup>
  )
}

export default InputGroup;