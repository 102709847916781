import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useComposition } from "./composition";

export const StructureContext = createContext();

export const StructureProvider = ({ children, structure, element, onContentChange }) => {
  const [content, setContent] = useState(element);
  const { composition, onChangeComposition } = useComposition();
  const [compositionContext, setCompositionContext] = useState({});

  useEffect(() => {
    document.addEventListener("compositionChange", saveProperties);
  }, []);

  useEffect(() => {
    onContentChange(content);
  }, [content]);

  const saveProperties = ({ detail }) => {
    clearListener();

    const newComposition = detail.newComposition;

    if (!newComposition) return;

    onChangeComposition({
      ...newComposition,
      ...compositionContext,
    });
  };

  const onChangeIntegrations = (connectorName, connectorUid) => {
    let currentIntegrations = composition.integrations || {};
    let connectors = currentIntegrations.connectors || {};
    const keys = connectorName.split(".");
    let currentLevel = connectors;

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      if (!(key in currentLevel)) {
        currentLevel[key] = {};
      }
      currentLevel = currentLevel[key];
    }

    const finalKey = keys[keys.length - 1];
    currentLevel[finalKey] = connectorUid;
    currentIntegrations.connectors = connectors;
    composition.integrations = currentIntegrations;

    setCompositionContext(composition);
  };

  const getElementsPerType = (type, includes = false, filterTypes = []) => {
    if (includes) {
      return structure.filter((item) => item.type.includes(type) && !filterTypes.includes(item.type));
    }

    return structure.filter((item) => item.type === type);
  };

  const clearListener = () => {
    document.removeEventListener("compositionChange", saveProperties);
  };

  const memoizedValues = useMemo(
    () => ({
      structure,
      getElementsPerType,
      element,
      onContentChange,
      content,
      setContent,
      onChangeIntegrations,
      compositionContext,
      composition,
    }),
    [structure, element, content, composition]
  );

  return <StructureContext.Provider value={memoizedValues}>{children}</StructureContext.Provider>;
};

export const useStructure = () => useContext(StructureContext);

/**
 * @description contexto que encapsula ElementEdit para facil manutenção de properties de objetos
 */
