import styled from 'styled-components';

export const ImagesGalleryContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  :root {
    --images-gallery-width: 760px;
  }

  /*region element max-width*/
  /*SM*/
  &.max-w-sm .element-images-gallery__images-container  {
    max-width: 760px;
    --images-gallery-width: 760px;
  }
  /*MD*/
  &.max-w-md .element-images-gallery__images-container  {
    max-width: 1160px;
    --images-gallery-width: 1160px;
  }
  /*LG*/
  &.max-w-lg .element-images-gallery__images-container  {
    max-width: 1600px;
    --images-gallery-width: 1600px;
  }
  /*FULL*/
  &.max-w-full .element-images-gallery__images-container  {
    max-width: calc(100% - 60px);
    --images-gallery-width: 100vw;
  }
  /*endregion*/
`;

export const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  
  @media(max-width: 800px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;

  @media(max-width: 800px) {
    margin: 10px 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  & > img {
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 8px;
    object-fit: cover;
    max-width: 380px;
    max-height: 380px;
    width: calc(var(--images-gallery-width) / ${props => props.numberOfImages || 1});
    height: calc(var(--images-gallery-width) / ${props => props.numberOfImages || 1});

    @media screen and (max-width: 800px) {
      width: 70vw;
      height: 70vw;
    }
  }
`;

export const Modal = styled.div`
  position: fixed;
  z-index: 1000;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
  overflow-x: hidden;

  .slick-list {
    width: 100%;
  }

  .slick-track, .slick-slider {
    display: flex;
    justify-content: center;
  }

  .slick-slide {
    margin: 0;
    position: relative;
    text-align: center;
    flex-flow: column;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media(max-width: 700px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ModalImage = styled.img`
  margin: auto;
  display: block;
  width: 70%;
  max-width: 700px;

  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }

  animation-name: zoom;
  animation-duration: 0.6s;
  
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const Caption = styled.p`
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;

  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }

  animation-name: zoom;
  animation-duration: 0.6s;
`;

export const ArrowButton = styled.button`
  background: #f1f1f1;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  z-index: 1000;
  border: none;
  border-radius: 50%;
  padding: 0;

  &:first-of-type {
    left: 28px;
  }

  &:last-of-type {
    right: 28px;
  }

  &:hover,
  &:focus {
    background: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 3px;
  }

  @media (max-width: 700px) {
    &:first-of-type {
      left: 8px;
    }

    &:last-of-type {
      right: 8px;
    }
  }
`;

export const ArrowLeft = styled.i`
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 5px;
`;

export const ArrowRight = styled.i`
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 5px;
`;


export const Dots = styled.ul`
  width: 100vw;
  text-align: center;
  margin-top: 10px;
`;

export const Dot = styled.li`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #f1f1f1;
  border-radius: 50%;
  opacity: ${({ isSelected }) => isSelected ? 1 : 0.25};
  cursor: pointer;
`;

export const Close = styled.span`
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;

  &:focus, &:hover {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
`;