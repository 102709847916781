import React, { Component } from 'react';
import uuidv1 from 'uuid/v1';
import './compositions.css';
import './compositions-dark.css';
import Img2 from './image-2.jpg';
import Img3 from './image-3.jpg';
import MathJax from 'react-mathjax';

import {
  ElementHeader,
  ElementFooter
} from '../../components';

import {
  ElementHead1,
  ElementText,
  ElementTextTwoColumn,
  ElementList,
  ElementImage,
  ElementAudio,
  ElementVideo,
  ElementIframe,
  ElementAccordion,
  ElementTabs,
  ElementFlashcards1,
  ElementFlashcards2,
  ElementQuiz1,
  ElementQuiz2,
  ElementQuiz3,
  ElementQuiz5,
  ElementQuiz6,
  ElementQuiz7,
  VideoInteractive,
  ElementImagesGallery,
  ElementImageAndText,
  ElementImageWithText,
  ElementForum1,
  ElementTex1,
  ElementDivider1,
  ElementDividerNumbered,
  VirtualElementIndex1,
  ElementHotspots1,
  ElementStatement1,
  ElementStatement2,
  ElementStatement3,
  ElementStatement4,
  ElementNote1,
  ElementQuote1,
  ElementQuote2,
  ElementQuote3,
  ElementQuote4,
  ElementQuote5,
  ElementQuote6,
  ElementSortedActivity1,
  ElementTimeline1,
  ElementSteps1,
  ElementAttachment1,
  ElementGallery1,
  ElementCodeSnippet1,
  ElementQuiz4,
  ElementButton1,
  ElementDivider4,
  Element3d
} from '../../elements';
import { getTheme } from '../../utils/getTheme';
import ElementInteractiveVideo from '../../elements/element-interactive-video';
import { ElementReactions } from '../../components/element-reactions';

export default class Compositions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "structure": [],
      "composition": {},
      "enabled_element_edit_props": false
    }

    this.getMaxWidthClass = this.getMaxWidthClass.bind(this);
    this.getElementHeader = this.getElementHeader.bind(this);
    this.getElementFooter = this.getElementFooter.bind(this);
  }

  getElementHeader(index, structure_length, element) {
    if (this.props.editable === true) {
      return <ElementHeader
        index={index}
        length={structure_length}
        renderObjectMessages={this.props.renderObjectMessages}
        onOpenMessages={() => this.props.onElementOpenMessages(element)}
        onCopy={() => this.props.onElementCopy(element, index)}
        onEdit={() => this.props.onElementEdit(element)}
        onRemove={() => this.props.onElementRemove(element)}
        onUp={() => this.props.onElementMoveUp(index)}
        onDown={() => this.props.onElementMoveDown(index)}
      />;
    } else {
      return "";
    };
  }

  getElementFooter(index, structure_length, element) {
    if (this.props.editable === true) {
      return (
        <>
          <ElementFooter
            index={index}
            length={structure_length}
            onAdd={() => this.props.onElementAdd(index)}
          />
          
          {element.enable_reactions && (
            <ElementReactions />
          )}
        </>
      );
    } else {
      return (
        <>
          {element.enable_reactions && (
            <ElementReactions />
          )}
        </>
      );
    };
  }

  onContentChange(index, content) {
    if (this.props.editable !== false) {
      this.props.onContentChange(index, content);
    }
  }

  transformFontToSlug(font) {
    return font.replace(/\s/g, '-').toLowerCase();
  }

  getElementIndexByType(element) {
    var index = 0;
    var structure = this.props.structure;
    var objects = structure.filter(obj => obj.type === element.type);
    for (var i = 0; i < objects.length; i++) {
      if (objects[i].id === element.id) {
        index = i;
      };
    }
    return index;
  }

  getMaxWidthClass() {
    const { composition } = this.props;
    const { interface: inter } = composition || {};
    const { content_max_width = "760px" } = inter || {};

    const sizes = {
      "760px": "max-w-sm",
      "1160px": "max-w-md",
      "1600px": "max-w-lg",
      "100%": "max-w-full",
    }

    return sizes[content_max_width] || "width-sm";
  }

  renderStructure() {
    const self = this;
    const structure = this.props.structure;
    const ui = this.props.ui;
    const structure_length = structure.length;
    const theme = getTheme();

    if (structure.length > 0) {
      return (
        <div className={`rdp-composer-structure rdp-composer-structure-theme-${theme} ${self.transformFontToSlug(ui.font_family)}`}>
          {structure.map((element, index) => {
            const type = element.type;
            const headerActionsTemplate = self.getElementHeader(index, structure_length, element);
            const footerActionsTemplate = self.getElementFooter(index, structure_length, element);

            if (type === "temp-slot") {
              return (
                <div
                  className={`rdp-composer-element-temp-slot ${this.getMaxWidthClass()}`}
                  key={index}
                >
                  {element.text}
                </div>
              )
            } else if (type === "head-1") {
              return (
                <ElementHead1
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  key={index}
                  editable={self.props.editable}
                  content={element}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                />
              )
            } else if (type === "text-1" || type === "text-2" || type === "text-3" || type === "text-4" || type === "text-5" || type === "text-7") {
              return (
                <ElementText
                  className={`element-${element.type} element-text-x ${this.getMaxWidthClass()}`}
                  key={index}
                  editable={self.props.editable}
                  content={element}
                  backgroundColor={element.background_color}
                  paddingTop={element.padding_top}
                  paddingBottom={element.padding_bottom}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                />
              );
            }
            else if (element.type === "interactive-video") {
              return <ElementInteractiveVideo 
                key={index}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } 
            else if (element.type === "text-two-column") {
              return (
                <ElementTextTwoColumn
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  key={index}
                  editable={self.props.editable}
                  content={element}
                  backgroundColor={element.background_color}
                  paddingTop={element.padding_top}
                  paddingBottom={element.padding_bottom}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                />
              )
            } else if (element.type === "text-6") {
              return (
                <div
                  key={index}
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                >
                  {self.getElementHeader(index, structure_length, element)}
                  {self.getElementFooter(index, structure_length, element)}
                  <div className="left" contentEditable="true">{element.left}</div>
                  <div className="right" contentEditable="true">{element.right}</div>
                </div>
              )
            } else if (type === "list-1" || type === "list-2" || type === "list-3") {
              return <ElementList
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                editable={self.props.editable}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "image-1") {
              return <ElementImage
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />;
            } else if (element.type === "image-2") {
              return <ElementImage
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />;
            } else if (element.type === "image-3") {
              return <ElementImage
                key={index}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                image={element.image}
                text={element.text}
                backgroundColor={element.background_color}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />;
            } else if (element.type === "image-4") {
              return (
                <div
                  key={index}
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  style={{ backgroundImage: `url(${element.image})` }}
                >
                  {self.getElementHeader(index, structure_length, element)}
                  <div className="element-image-overlay" />
                  <div className="container">
                    <div className="text" contentEditable="true">{element.text}</div>
                    <div className="ruler" />
                  </div>
                </div>
              )
            } else if (element.type === "images-gallery") {
              return <ElementImagesGallery
                key={index}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />;
            } else if (element.type === "image-and-text") {
              return <ElementImageAndText
                key={index}
                content={element}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />;
            } else if (element.type === "image-with-text") {
              return (
                <ElementImageWithText
                  key={index}
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  content={element}
                  editable={self.props.editable}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                />
              );
            } else if (element.type === "video-xpto") {
              return (
                <VideoInteractive
                  key={index}
                  index={index}
                  className={`element-video-1 ${this.getMaxWidthClass()}`}
                  element={element}
                >
                  {self.getElementHeader(index, structure_length, element)}
                </VideoInteractive>
              )
            } else if (element.type === "audio-1") {
              return <ElementAudio
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />;
            } else if (element.type === "video-1") {
              return <ElementVideo
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />;
            } else if (element.type === "iframe") {
              return <ElementIframe
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />;
            } else if (element.type === "accordion") {
              return <ElementAccordion
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={`${index}-${element.id}`}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "tabs") {
              return <ElementTabs
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "flashcards-1") {
              return (
                <ElementFlashcards1
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  editable={self.props.editable}
                  key={index}
                  content={element}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                />
              )
            } else if (element.type === "flashcards-2") {
              return (
                <ElementFlashcards2
                  editable={self.props.editable}
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  key={index}
                  content={element}
                  contentLanguage={self.props.ui.content_language}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                />
              )
            } else if (element.type === "quiz-1") {
              return <ElementQuiz1
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "quiz-2") {
              return <ElementQuiz2
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "quiz-3") {
              return <ElementQuiz3
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "quiz-4") {
              return <ElementQuiz4
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "quiz-5") {
              return <ElementQuiz5
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "quiz-6") {
              return <ElementQuiz6
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "quiz-7") {
              return <ElementQuiz7
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "forum-1") {
              return <ElementForum1
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "gallery-2") {
              return (
                <div
                  key={index}
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                >
                  <div className="container">
                    <div className="first-image">
                      <img src={Img2}></img>
                    </div>
                    <div className="second-image">
                      <img src={Img3}></img>
                    </div>
                  </div>
                </div>
              )
            } else if (element.type === "tex-1") {
              return <ElementTex1
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                editable={self.props.editable}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "virtual-index-1") {
              return <VirtualElementIndex1
                className={`virtual-element-index-1 ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "hotspots-1") {
              return <ElementHotspots1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "divider-1") {
              return <ElementDivider1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "divider-numbered") {
              return <ElementDividerNumbered
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
                objectIndex={self.getElementIndexByType(element)}
              />
            } else if (element.type === "divider-4") {
              return <ElementDivider4
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "statement-1") {
              return <ElementStatement1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
                objectIndex={self.getElementIndexByType(element)}
              />
            } else if (element.type === "statement-2") {
              return <ElementStatement2
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
                objectIndex={self.getElementIndexByType(element)}
              />
            } else if (element.type === "statement-3") {
              return <ElementStatement3
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
                objectIndex={self.getElementIndexByType(element)}
              />
            } else if (element.type === "statement-4") {
              return <ElementStatement4
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
                objectIndex={self.getElementIndexByType(element)}
              />
            } else if (element.type === "note-1") {
              return <ElementNote1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
                objectIndex={self.getElementIndexByType(element)}
              />
            } else if (element.type === "quote-1") {
              return (
                <ElementQuote1
                  editable={self.props.editable}
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  key={index}
                  content={element}
                  contentLanguage={self.props.ui.content_language}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                  objectIndex={self.getElementIndexByType(element)}
                />
              )
            } else if (element.type === "quote-2") {
              return (
                <ElementQuote2
                  editable={self.props.editable}
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  key={index}
                  content={element}
                  contentLanguage={self.props.ui.content_language}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                  objectIndex={self.getElementIndexByType(element)}
                />
              )
            } else if (element.type === "quote-3") {
              return (
                <ElementQuote3
                  editable={self.props.editable}
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  key={index}
                  content={element}
                  contentLanguage={self.props.ui.content_language}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                  objectIndex={self.getElementIndexByType(element)}
                />
              )
            } else if (element.type === "quote-4") {
              return (
                <ElementQuote4
                  editable={self.props.editable}
                  className={`element-${element.type} ${this.getMaxWidthClass()}`}
                  key={index}
                  content={element}
                  contentLanguage={self.props.ui.content_language}
                  headerActionsTemplate={headerActionsTemplate}
                  footerActionsTemplate={footerActionsTemplate}
                  onChange={(content) => self.onContentChange(index, content)}
                  objectIndex={self.getElementIndexByType(element)}
                />
              )
            } else if (element.type === "quote-5") {
              return <ElementQuote5
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
                objectIndex={self.getElementIndexByType(element)}
              />
            } else if (element.type === "quote-6") {
              return <ElementQuote6
                editable={self.props.editable}
                c className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "sorted-activity-1") {
              return <ElementSortedActivity1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "timeline-1") {
              return <ElementTimeline1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "steps-1") {
              return <ElementSteps1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "attachment-1") {
              return <ElementAttachment1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "gallery-1") {
              return <ElementGallery1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "snippet-1") {
              return <ElementCodeSnippet1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "button-1") {
              return <ElementButton1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "button-2") {
              return <ElementButton1
                editable={self.props.editable}
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                contentLanguage={self.props.ui.content_language}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />
            } else if (element.type === "3d") {
              return <Element3d
                className={`element-${element.type} ${this.getMaxWidthClass()}`}
                key={index}
                content={element}
                headerActionsTemplate={headerActionsTemplate}
                footerActionsTemplate={footerActionsTemplate}
                onChange={(content) => self.onContentChange(index, content)}
              />;
            }
          })}
        </div>
      )
    } else {
      return <div className="rdp-composer-structure-message">
        <h1>{this.props.labels.stage.no_elements_message}</h1>
      </div>;
    }
  }

  render() {
    return this.renderStructure()
  }
}
