import React, { Component } from 'react';
import uuidv1 from 'uuid/v1';
import { UserService } from '../../services/user';

import './element-flashcards-1-properties.css';
import {
  RDPDamWidget, ColorPicker, FontPicker, Quill
} from '../../components';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

const cardSizes = [
  { height: 240, width: 240, label: 'content_option_card_size_1' },
  { height: 360, width: 360, label: 'content_option_card_size_2' },
  { height: 240, width: 360, label: 'content_option_card_size_3' },
  { height: 360, width: 240, label: 'content_option_card_size_4' },
]

export default class ElementFlashcards1Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "dam_widget_opened": false,
      "active_tab": "content",
      "media_active_item": null,
      "media_active_target": null,
      "media_active_image_type": null
    }

    this.onMediaChange = this.onMediaChange.bind(this);
    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.onBorderColorChange = this.onBorderColorChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
  }

  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange(color) {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBorderColorChange(color) {
    var content = this.state.content;
    content.border_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onCardSizeChange(label) {
    const { height, width } = cardSizes.find(s => s.label === label);

    var content = this.state.content;
    content.card_height = height || content.card_height;
    content.card_width = width || content.card_width;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange(asset) {
    var content = this.state.content;
    var items = content.items;
    var activeItem = this.state.media_active_item;
    var activeTarget = this.state.media_active_target;
    var activeImageType = this.state.media_active_image_type;
    items[activeItem][activeTarget][activeImageType] = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onRDPDamWidgetClose() {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget() {
    if (this.state.dam_widget_opened === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="image"
        onClose={() => this.onRDPDamWidgetClose()}
        onAssetChange={(asset) => this.onAssetChange(asset)}
      />;
    }
  }

  getAssetUrl(asset) {
    var accessToken = UserService.getUser().access_token;
    if (asset !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg") {
      asset += "&access_token=" + accessToken;
    };
    return asset;
  }

  onChangeTab(target) {
    this.setState({
      "active_tab": target
    })
  }

  onRemoveItem(index) {
    var content = this.state.content;
    var items = content.items;
    var temp = [];
    for (var i = 0; i < items.length; i++) {
      if (i !== index) {
        temp.push(items[i])
      }
    }
    content.items = temp;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    })
  }

  onUploadClick(index) {
    var content = this.state.content;
    var items = content.items;
    items[index].displayed_media_dropdown = true;
    this.setState({
      "content": content
    });
  }

  onAddItem() {
    var content = this.state.content;
    var items = content.items;
    items.push({
      "id": uuidv1(),
      "front_card": {
        "text": "But in certain " + (items.length + 1),
        "centered_image": null,
        "fullscreen_image": null
      },
      "back_card": {
        "text": "Itaque earum " + (items.length + 1),
        "centered_image": null,
        "fullscreen_image": null
      }
    });
    this.setState({
      "content": content
    })
  }

  onValueTextChange(value, index, target) {
    var content = this.state.content;
    var items = content.items;
    items[index][target].text = value;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onMediaChange(index, target, imageType) {
    this.setState({
      "media_active_item": index,
      "media_active_target": target,
      "media_active_image_type": imageType
    }, function () {
      this.setState({
        "dam_widget_opened": true
      })
    })
  }

  renderCardData(item, index, target, labels) {
    const { content } = this.props;
    var data = item[target];
    if (data.text !== null) {
      return (
        <>
          <Quill
            isProperties
            className="element-flashcards-1-properties-item-text"
            value={data.text}
            style={{ borderColor: content.primary_color }}
            onChange={(value) => this.onValueTextChange(value, index, target)}
          />
        </>
      )
    };
    if (data.centered_image !== null) {
      return <div className="element-flashcards-1-properties-item-image-container">
        <img className="element-flashcards-1-properties-item-image" src={this.getAssetUrl(data.centered_image)} />
        <span className="element-flashcards-1-properties-item-image-remove" onClick={() => this.onMediaChange(index, target, "centered_image")}>{labels.elements.settings.element_flashcards.content_label_image_edit}</span>
      </div>;
    };
    if (data.fullscreen_image !== null) {
      return <div className="element-flashcards-1-properties-item-image-container">
        <img className="element-flashcards-1-properties-item-image" src={this.getAssetUrl(data.fullscreen_image)} />
        <span className="element-flashcards-1-properties-item-image-remove" onClick={() => this.onMediaChange(index, target, "fullscreen_image")}>{labels.elements.settings.element_flashcards.content_label_image_edit}</span>
      </div>;
    };
  }

  onCardTypeChange(value, target, index) {
    var content = this.state.content;
    var items = content.items;
    items[index][target].text = null;
    items[index][target].centered_image = null;
    items[index][target].fullscreen_image = null;

    if (value === "text") {
      items[index][target].text = (target === "front_card" ? "But in certain " : "Itaque earum ") + index + 1;
    } else if (value === "centered_image") {
      items[index][target].centered_image = "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg";
    } else if (value === "fullscreen_image") {
      items[index][target].fullscreen_image = "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg";
    };

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  getTypeValue(data) {
    if (data.text !== null) {
      return "text";
    } else if (data.centered_image) {
      return "centered_image";
    } else if (data.fullscreen_image) {
      return "fullscreen_image";
    };
  }

  getCardSize(h, w) {
    const { label } = cardSizes.find(s => s.height === h && s.width === w) || {};
    return label;
  }

  renderRemoveItemBtn(index) {
    var items = this.state.content.items;
    if (items.length > 1) {
      return <span className="element-flashcards-1-properties-item-remove" onClick={() => this.onRemoveItem(index)}></span>
    }
  }

  onFontChange(font) {
    var content = this.state.content;
    content.font_family = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  renderFont() {
    var content = this.state.content;
    var labels = this.props.labels;

    if (content.font_family) {
      return <FontPicker font={content.font_family} onFontChange={(font) => this.onFontChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontChange(font)} noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  renderContent() {
    var _this = this;
    var activeTab = this.state.active_tab;
    var content = this.state.content;
    var labels = this.props.labels;
    if (activeTab === "content") {
      var items = content.items;
      return <>
        <ul>
          {items.map(function (item, index) {
            return <li key={item.id} className="element-flashcards-1-properties-item">
              <div className="element-flashcards-1-properties-item-drag"></div>
              {_this.renderRemoveItemBtn(index)}
              <span className="element-flashcards-1-properties-item-label">{labels.elements.settings.element_flashcards.content_front_of_the_card} {index + 1}</span>
              <select defaultValue={_this.getTypeValue(item.front_card)} className="rdp-composer-element-edit-select" onChange={(e) => _this.onCardTypeChange(e.target.value, "front_card", index)}>>
                <option value="text">{labels.elements.settings.element_flashcards.content_option_text}</option>
                <option value="centered_image">{labels.elements.settings.element_flashcards.content_option_centered_image}</option>
                <option value="fullscreen_image">{labels.elements.settings.element_flashcards.content_option_fullscreen_image}</option>
              </select>
              {_this.renderCardData(item, index, "front_card", labels)}

              <span className="element-flashcards-1-properties-item-label">{labels.elements.settings.element_flashcards.content_back_of_the_card} {index + 1}</span>
              <select defaultValue={_this.getTypeValue(item.back_card)} className="rdp-composer-element-edit-select" onChange={(e) => _this.onCardTypeChange(e.target.value, "back_card", index)}>>
                <option value="text">{labels.elements.settings.element_flashcards.content_option_text}</option>
                <option value="centered_image">{labels.elements.settings.element_flashcards.content_option_centered_image}</option>
                <option value="fullscreen_image">{labels.elements.settings.element_flashcards.content_option_fullscreen_image}</option>
              </select>
              {_this.renderCardData(item, index, "back_card", labels)}
            </li>
          })}
        </ul>
        <button className="element-flashcards-1-properties-item-new" onClick={() => this.onAddItem()}>{labels.elements.settings.element_flashcards.content_label_btn_add_item}</button>
      </>
    } else if (activeTab === "configurations") {
      return <div className="element-flashcards-1-properties-configurations">
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_flashcards.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)} defaultValue={content.content_title}></input>
        
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_flashcards.settings_label_background_color}</span>
        <ColorPicker labels={this.props.labels} color={content.background_color} onChange={this.onBackgroundColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_flashcards.settings_label_border_color}:</span>
        <ColorPicker labels={this.props.labels} color={content.border_color} onChange={this.onBorderColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_flashcards.settings_label_margin_top}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_flashcards.settings_label_card_size}</span>
        <select defaultValue={this.getCardSize(content.card_height, content.card_width)} className="rdp-composer-element-edit-select" onChange={(e) => this.onCardSizeChange(e.target.value)}>
          {cardSizes.map((o) => <option value={o.label}>{`${labels.elements.settings.element_flashcards[o.label]} (${o.width}x${o.height})`}</option>)}
        </select>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_flashcards.settings_label_label_font}:</span>
        {this.renderFont()}

        <PreferenceRequiredInteraction
          title={labels.elements.settings.preference_required_interaction.title}
          label={labels.elements.settings.preference_required_interaction.label}
          labels={labels}
          content={content}
          onContentChange={c => {
            this.setState({ content: c });
            this.props.onContentChange(c);
          }}
        />
      </div>
    }
  }

  render() {
    var content = this.state.content;
    var activeTab = this.state.active_tab;
    var labels = this.props.labels;

    return (<div className="rdp-element-flashcards-1-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className={`rdp-composer-element-edit-tabs-item ${activeTab === "content" ? "active" : ""}`} onClick={() => this.onChangeTab("content")}>{labels.elements.common_settings.label_tab_content}</li>
        <li className={`rdp-composer-element-edit-tabs-item ${activeTab === "configurations" ? "active" : ""}`} onClick={() => this.onChangeTab("configurations")}>{labels.elements.common_settings.label_tab_settings}</li>
      </ul>
      <div className="rdp-composer-element-edit-body">
        {this.renderContent()}
      </div>
      {this.renderDAMWidget()}
    </div>);
  }
}
