import styled from "styled-components";

export const Choice = styled.div`
  transition: all 0.3s ease-out;
  border: 1px solid ${props => props.borderColor || "transparent"};
  border-radius: ${props => props.borderRadius || 0} !important;
  margin: 10px 0;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;

  &:hover {
    background: #f8f8f8;
    border-radius: 10px;
  }
  
  &:disabled,
  &[disabled],
  &[disabled=disabled],
  &[disabled=true] {
    cursor: not-allowed;
  }
`;