import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { CompositionActionComponentHandler } from "./actions";

export const CompositionContext = createContext();

export const CompositionProvider = ({
  children,
  composition,
  onChangeComposition,
  editing,
  beforeStructure,
  clearBeforeStructure,
  onSaveComposition,
}) => {
  const actionRef = useRef(false);
  const restoreRef = useRef(false);
  const [action, setAction] = useState("");

  const onSavePerShortCut = (e) => {
    if((e.ctrlKey && e.code === "KeyS" ) || (e.metaKey && e.code === "KeyS")) {
      e.preventDefault(); // Evita o comportamento padrão (salvar página)
      e.stopPropagation(); // Evita a propagação do evento para outros elementos
      onSaveComposition();
    }
  }

  const onError = e => {
    e.target.classList.add('broken-img');
  };

  const onLoad = (e) => {
    e.target.classList.remove('broken-img');
  }

  const handleListenerAllImages = (cleanUp) => {
    const wrapper = document.querySelector('.rdp-composer-structure');

    if (!wrapper) return;

    const images = wrapper.querySelectorAll('img');

    images.forEach(item => {
      item.onerror = cleanUp ? null : onError;
      item.onload = cleanUp ? null : onLoad;
    })
  };

  useEffect(() => {
    document.addEventListener('keydown', onSavePerShortCut);

    return () => {
      document.removeEventListener('keydown', onSavePerShortCut);
    }
  }, []);

  useEffect(() => {
    handleListenerAllImages();

    return () => {
      handleListenerAllImages(true)
    }
  }, [composition]);

  return (
    <CompositionContext.Provider
      value={{
        composition,
        onChangeComposition,
        editing,
        actionRef,
        restoreRef,
        beforeStructure,
        clearBeforeStructure,
        action,
        setAction,
        onSaveComposition
      }}
    >
      <CompositionActionComponentHandler />
      {children}
    </CompositionContext.Provider>
  );
};

export const useComposition = () => useContext(CompositionContext);
