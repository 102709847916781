import styled from 'styled-components';

export const ImageContainer = styled.div`
  text-align: right;
  img {
    margin-bottom: 0;
  }
`;

export const RemoveButton = styled.button`
  position: relative;
  right: 0;
  top: 40px;
`;