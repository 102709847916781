import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {UserService} from '../../services/user';
import {
  RDPDamWidget, ColorPicker, RelToolbarQuill
} from '../../components';
import * as S from "./element-quote-6-properties.styled";
import uuidv1 from "uuid/v1";
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

const tabs = {
  "settings": "settings",
  "items": "items",
}

export default class ElementQuote6Properties extends Component {
  state = {
    content: this.props.content,
    dam_widget_opened: false,
    active_tab: tabs.items,
    expanded_spot_id: null,
    item_avatar_modal_open: false,
    avatar_backups: [],
  }

  onImageChange = () => {
    this.setState({
      "dam_widget_opened": true
    })
  }

  onSpotDateChange = (e, id) => {
    const content = this.state.content;

    content.items = content.items.map(spot => {
      if (spot.id === id) {
        spot.date = e.currentTarget.value !== "" ? e.currentTarget.value : spot.date;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotTitleChange = (text, id) => {
    const content = this.state.content;

    content.items = content.items.map(spot => {
      if (spot.id === id) {
        spot.text = text !== "" ? text : spot.text;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotDescriptionChange = (text, id) => {
    const content = this.state.content;

    content.items = content.items.map(spot => {
      if (spot.id === id) {
        spot.author_name = text;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange = (color) => {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPrimaryColorChange = (color) => {
    var content = this.state.content;
    content.primary_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange = (e) => {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange = (e) => {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange = (asset) => {
    var content = this.state.content;
    content.image = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onRDPDamWidgetClose = () => {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget = () => {
    if (this.state.item_avatar_modal_open === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="image"
        onClose={() => this.onSpotImageModalClose()}
        onAssetChange={(asset) => this.onSpotImageChange(asset)}
      />;
    }
  }

  onSpotImageModalClose = () => {
    this.setState({
      "item_avatar_modal_open": false
    })
  }

  onSpotAudioModalClose = () => {
    this.setState({
      "spot_audio_modal_open": false
    })
  }

  onSpotImageRemove = (id) => {
    var content = this.state.content;
    content.items = content.items.map(spot => {
      if (spot.id === id) {
        spot.image = null;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotAudioRemove = (id) => {
    var content = this.state.content;
    content.items = content.items.map(spot => {
      if (spot.id === id) {
        spot.audio = null;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotImageChange = (asset) => {
    let {
      avatar_backups = []
    } = this.state;
    var content = this.state.content;
    content.items = content.items.map(spot => {
      if (spot.id === this.state.expanded_spot_id) {
        avatar_backups[this.state.expanded_spot_id] = spot.avatar;
        spot.avatar = asset;
      }
      return spot;
    });

    this.setState({
      "content": content,
      "item_avatar_modal_open": false,
      avatar_backups
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onRemoveAvatar = () => {
    let {
      avatar_backups = []
    } = this.state;
    var content = this.state.content;
    content.items = content.items.map(spot => {
      if (spot.id === this.state.expanded_spot_id) {
        avatar_backups[this.state.expanded_spot_id] = spot.avatar;
        spot.avatar = null;
      }
      return spot;
    });

    this.setState({
      "content": content,
      "item_avatar_modal_open": false,
      avatar_backups
    }, function () {
      this.props.onContentChange(content)
    });
  }


  getAssetUrl = (assetUrl) => {
    const publicAssetUrlArr = [
      "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png",
      "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/background.png"
    ];
    if (false === publicAssetUrlArr.includes(assetUrl)) {
      assetUrl = `${assetUrl}&access_token=${UserService.getUser().access_token}`;
    }
    return assetUrl;
  }

  getAudioUrl = (audio) => {
    const accessToken = UserService.getUser().access_token;
    audio += "&access_token=" + accessToken;
    return audio;
  }

  handleDeleteSpot = (id) => {
    var content = this.state.content;
    content.items = content.items.filter(spot => spot.id !== id);
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onChangeTab(target) {
    this.setState({
      "active_tab": target
    })
  }

  isDefaultAvatar() {
    var content = this.state.content;
    const item = content.items.find(item => item.id === this.state.expanded_spot_id);
    if (item) {
      return (item.avatar === this.state.avatar_backups[item.id] && this.state.avatar_backups[item.id] !== undefined) || this.state.avatar_backups[item.id] === undefined;
    }
  }

  handleExpandSpot = (id) => {
    if (this.state.expanded_spot_id === id) {
      this.setState({
        "expanded_spot_id": null
      })
    } else {
      this.setState({
        "expanded_spot_id": id
      })
    }
  }

  handleOnDragEndSpots = (result, spots = []) => {
    if (!result.destination) return;

    const items = Array.from(spots);

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const content = this.state.content;

    content.items = items
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  };

  onAddItem() {
    var content = this.state.content;
    var items = content.items;
    items.push({
      "id": uuidv1(),
      "date": "Date",
      "title": "Lorem ipsum dolor sit amet",
      "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed. Accusantium amet cum cumque deleniti doloribus earum eligendi, eum exercitationem harum hic illum ipsa itaque labore libero magni minus neque nesciunt nihil non odio quam repellendus tempore velit, veniam veritatis? Aspernatur aut beatae blanditiis corporis dolorem eius harum incidunt ipsa, itaque iure labore laboriosam laborum magni maiores molestiae natus omnis quae reiciendis reprehenderit sed similique sit tempora tenetur velit veniam! Aliquid autem debitis dolores ducimus eaque est facilis incidunt ipsa ipsum laboriosam modi non perspiciatis placeat, quaerat quas quis totam vel velit? Deleniti deserunt expedita laudantium molestias porro, quas veritatis? Architecto dolor, provident. A accusantium architecto consequatur illum itaque necessitatibus perspiciatis reprehenderit sapiente veritatis vero. Consequatur doloremque ducimus ex molestias non. Dolore, harum ipsa ipsam laudantium nostrum tenetur ut voluptates!",
    });
    this.setState({
      "content": content
    })
  }

  onAvatarImageChange() {
    this.setState({
      "item_avatar_modal_open": true,
    })
  }

  onRestoreAvatar = () => {
    var content = this.state.content;
    content.items = content.items.map(spot => {
      if (spot.id === this.state.expanded_spot_id) {
        spot.avatar = this.state.avatar_backups[this.state.expanded_spot_id];
      }
      return spot;
    });

    this.setState({
      "content": content,
      "item_avatar_modal_open": false
    }, function () {
      this.props.onContentChange(content)
    });
  }

  render() {
    const content = this.state.content;
    const {
      items = []
    } = content;
    const labels = this.props.labels;

    return (
      <div className="rdp-element-image-properties">
        <ul className="rdp-composer-element-edit-tabs">
          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.items ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.items)}
          >
            {labels.elements.settings.element_quote_6.settings_label_tab_spots}
          </li>

          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.settings ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.settings)}
          >
            {labels.elements.common_settings.label_tab_settings}
          </li>
        </ul>

        <div className="rdp-composer-element-edit-body">
          {this.state.active_tab === tabs.settings && (
            <>
              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_quote_6.settings_label_background_color}</span>
              <ColorPicker labels={this.props.labels} color={content.background_color}
                           onChange={this.onBackgroundColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_quote_6.settings_label_spot_color}</span>
              <ColorPicker labels={this.props.labels} color={content.primary_color} onChange={this.onPrimaryColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_quote_6.settings_label_margin_top}</span>
              <input type="number" className="rdp-composer-element-edit-input px"
                     onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_quote_6.settings_label_margin_bottom}</span>
              <input type="number" className="rdp-composer-element-edit-input px"
                     onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

              <PreferenceRequiredInteraction
                label={labels.elements.settings.preference_required_interaction.label}
                labels={labels}
                content={content}
                onContentChange={c => {
                  this.setState({ content: c });
                  this.props.onContentChange(c);
                }}
              />
            </>
          )}

          {this.state.active_tab === tabs.items && (
            <>
              <DragDropContext
                onDragEnd={(result) => {
                  this.handleOnDragEndSpots(result, this.state.content.items);
                }}
              >
                <Droppable droppableId="spots">
                  {(provided) => (
                    <ol ref={provided.innerRef} {...provided.droppableProps}>
                      {
                        items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            isDragDisabled={false}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <S.SpotItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <S.SpotHead
                                    tabIndex="1"
                                    role="button"
                                    onClick={() => this.handleExpandSpot(item.id)}
                                  >
                                    <S.SpotItemHeadLeft>
                                      <S.MoveButton {...provided.dragHandleProps}>
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 24 24"
                                        >
                                          <path fill="currentColor"
                                                d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z"/>
                                        </svg>
                                      </S.MoveButton>

                                      {labels.elements.settings.element_quote_6.settings_label_spot} {index + 1}
                                    </S.SpotItemHeadLeft>

                                    <S.SpotItemHeadRight>
                                      <S.IconRemove
                                        width="18"
                                        height="18"
                                        viewBox="0 0 768 768"
                                        onClick={() => this.handleDeleteSpot(item.id)}
                                      >
                                        <path fill="currentColor"
                                              d="M607.5 127.5v64.5h-447v-64.5h111l33-31.5h159l33 31.5h111zM192 607.5v-384h384v384c0 34.5-30 64.5-64.5 64.5h-255c-34.5 0-64.5-30-64.5-64.5z"></path>
                                      </S.IconRemove>

                                      <S.IconDown
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        isExpanded={this.state.expanded_spot_id === item.id}
                                      >
                                        <path fill="currentColor"
                                              d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                                      </S.IconDown>
                                    </S.SpotItemHeadRight>
                                  </S.SpotHead>

                                  <S.SpotItemBody
                                    show={this.state.expanded_spot_id === item.id}
                                  >
                                    <S.FieldContainer>
                                      <span
                                        className="rdp-composer-element-edit-label"
                                      >
                                        {labels.elements.settings.element_quote_6.settings_label_spot_image}:
                                      </span>
                                      <S.Avatar className={["rdp-composer-element-quote-1-avatar", item.avatar === null ? "add-avatar" : ""].join(" ")} onClick={() => this.onAvatarImageChange()}>
                                        <S.AvatarImg src={this.getAssetUrl(item.avatar)} />
                                      </S.Avatar>

                                      <S.ItemOptions className="rdp-composer-element-quote-1-avatar-options">
                                        <S.ItemOptionsButton className="rdp-composer-element-quote-1-avatar-add" type="button" onClick={() => this.onAvatarImageChange()} hidden={item.avatar !== null}>{labels.elements.settings.element_quote_6.settings_button_avatar_add}</S.ItemOptionsButton>
                                        <S.ItemOptionsButton className="rdp-composer-element-quote-1-avatar-remove" type="button" onClick={() => this.onRemoveAvatar()} hidden={item.avatar === null}>{labels.elements.settings.element_quote_6.settings_button_avatar_remove}</S.ItemOptionsButton>
                                        <S.ItemOptionsButton className="rdp-composer-element-quote-1-avatar-restore-default" type="button" onClick={() => this.onRestoreAvatar()} disabled={this.isDefaultAvatar()}>{labels.elements.settings.element_quote_6.settings_button_avatar_restore}</S.ItemOptionsButton>
                                      </S.ItemOptions>
                                    </S.FieldContainer>

                                    <span
                                      className="rdp-composer-element-edit-label">{labels.elements.settings.element_quote_6.settings_label_title}:</span>
                                    <div style={{marginTop: "10px", marginBottom: "25px"}}>
                                      <RelToolbarQuill
                                        onChange={(text) => this.onSpotTitleChange(text, item.id)}
                                        value={item.text}
                                      />
                                    </div>

                                    <span
                                      className="rdp-composer-element-edit-label">
                                          {labels.elements.settings.element_quote_6.settings_label_description}:
                                        </span>
                                    <div style={{marginTop: "10px", marginBottom: "25px"}}>
                                      <RelToolbarQuill
                                        onChange={(text) => this.onSpotDescriptionChange(text, item.id)}
                                        value={item.author_name}
                                      />
                                    </div>
                                  </S.SpotItemBody>
                                </S.SpotItem>
                              </>
                            )}
                          </Draggable>
                        ))
                      }
                    </ol>
                  )}
                </Droppable>
              </DragDropContext>

              <S.AddButton onClick={() => this.onAddItem()}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 640 640">
                  <path fill="currentColor" d="M352 288h128v64h-128v128h-64v-128h-128v-64h128v-128h64v128zM320 640c-176.731 0-320-143.269-320-320s143.269-320 320-320v0c176.731 0 320 143.269 320 320s-143.269 320-320 320v0zM320 576c141.385 0 256-114.615 256-256s-114.615-256-256-256v0c-141.385 0-256 114.615-256 256s114.615 256 256 256v0z"></path>
                </svg>
                {labels.elements.settings.element_quote_6.content_label_btn_add_item}
              </S.AddButton>
            </>
          )}
        </div>
        {this.renderDAMWidget()}
      </div>
    );
  }
}
