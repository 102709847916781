import React from "react";
import * as S from "./spot.styled";
import markers from "./markers";
import {UserService} from "../../services/user";

function Spot(props) {
  const {
    id,
    icon = "01",
    title = "",
    description = "",
    image,
    audio,
    className,
    posX,
    posY,
    editable,
    onClickRemove,
    onGrab,
    onMove,
    onRelease,
    onNext,
    onPrev,
    onFocus,
    onBlur,
    color,
    fontFamilyTitle,
    fontFamilyText
  } = props;
  const containerRef = React.useRef(null);
  const tooltipRef = React.useRef(null);
  const [expanded, setExpanded] = React.useState("false");
  const [isMoving, setIsMoving] = React.useState(false);
  const [isTryingToDelete, setIsTryingToDelete] = React.useState(false);

  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef && !containerRef.current.contains(e.target)) {
        setExpanded("false");
        if (isTryingToDelete) {
          setIsTryingToDelete(false);
        }

        if (typeof onBlur === "function") {
          onBlur(e);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleRemoveClick = e => {
    if (typeof onClickRemove === "function") {
      onClickRemove(id);
    }
  }

  const closeDragElement = () => {
    document.removeEventListener("mouseup", closeDragElement);
    document.removeEventListener("mousemove", elementDrag);
    setIsMoving(false);

    if (typeof onRelease === "function") {
      onRelease(id);
    }
  }

  const elementDrag = (e) => {
    e = e || window.event;
    e.preventDefault();
    // Posição do botão de mover relativo ao spot
    const relativePostOfMovingButton = 30;
    const x = e.clientX + (posX > 60 ? (relativePostOfMovingButton - (relativePostOfMovingButton * 2)) : relativePostOfMovingButton)
    const y = e.clientY - relativePostOfMovingButton;

    if (typeof onMove === "function" && x && y) {
      onMove(e, {
        id, x, y
      });
    }
  }

  const getImageUrl = (image) => {
    const accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg" &&
      image.search("https://images.articulate.com/") === -1) {
      image += "&access_token=" + accessToken;
    }
    return image;
  }

  const getAudioUrl = (audio) => {
    const accessToken = UserService.getUser().access_token;
    audio += "&access_token=" + accessToken;
    return audio;
  }

  const handleMouseDown = e => {
    e = e || window.event;
    e.preventDefault();

    if (!isMoving) {
      document.addEventListener("mouseup", closeDragElement);
      document.addEventListener("mousemove", elementDrag);
    }

    if (typeof onGrab === "function") {
      setIsMoving(true);
      onGrab(id);
    }
  }

  const handleMouseUp = e => {
    if (typeof onRelease === "function") {
      setIsMoving(false);
      onRelease(id);
    }
  }

  const handleCancelClick = e => {
    if (isTryingToDelete) {
      setIsTryingToDelete(false);
    }
  }

  const handleConfirmClick = e => {
    if (isTryingToDelete) {
      handleRemoveClick(e);
    }
  }

  const handleFocus = e => {
    setExpanded("true");

    if (typeof onFocus === "function") {
      onFocus(e);
    }
  }

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleConfirmClick(e);
    }
    if (e.key === "Escape") {
      handleCancelClick(e);
    }
    if (e.key === "ArrowRight" && typeof onNext === "function") {
      handleNextClick(e);
    }
    if (e.key === "ArrowLeft" && typeof onPrev === "function") {
      handlePrevClick(e);
    }
  }

  const handleNextClick = e => {
    e.stopPropagation();
    setExpanded("false");
    if (typeof onNext === "function") {
      onNext(e, id);
    }
  }

  const handlePrevClick = e => {
    e.stopPropagation();
    setExpanded("false");
    if (typeof onPrev === "function") {
      onPrev(e, id);
    }
  }

  const handleClose = e => {
    e.stopPropagation();
    setExpanded("false")
  }

  const getFont = font => {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  return (
    <div
      ref={containerRef}
      onClick={e => e.stopPropagation()}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
    >
      <S.Spot
        id={id}
        aria-expanded={expanded}
        className={[expanded === "true" ? "expanded" : "", posX > 60 ? "flipped" : ""].concat(className).join(" ")}
        posX={posX}
        posY={posY}
        flipped={posX > 60}
        isMoving={isMoving}
        color={color}
      >
        {markers[icon]()}
        {
          editable && (
            <>
              <S.RemoveButton
                className="hotspot-remove-btn"
                role="button"
                type="button"
                onClick={e => setIsTryingToDelete(true)}
                tooltip="Remove"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 768 768"
                >
                  <title>remove</title>
                  <g id="icomoon-ignore">
                  </g>
                  <path fill="currentColor"
                        d="M607.5 127.5v64.5h-447v-64.5h111l33-31.5h159l33 31.5h111zM192 607.5v-384h384v384c0 34.5-30 64.5-64.5 64.5h-255c-34.5 0-64.5-30-64.5-64.5z"></path>
                </svg>
              </S.RemoveButton>

              <S.MoveButton
                className="hotspot-move-btn"
                role="button"
                type="button"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                tooltip="Move"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                >
                  <path fill="currentColor"
                        d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z"/>
                </svg>
              </S.MoveButton>

              {
                isTryingToDelete && (
                  <>
                    <S.CancelButton
                      className="hotspot-cancel-btn"
                      role="button"
                      type="button"
                      onClick={handleCancelClick}
                      tooltip="Cancel"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      >
                        <path fill="currentColor"
                              d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z"/>
                      </svg>
                    </S.CancelButton>

                    <S.ConfirmButton
                      className="hotspot-confirm-btn"
                      role="button"
                      type="button"
                      onClick={handleConfirmClick}
                      tooltip="Confirm"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      >
                        <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/>
                      </svg>
                    </S.ConfirmButton>
                  </>
                )
              }
            </>
          )
        }
      </S.Spot>

      {
        !isMoving && (
          <S.Tooltip
            ref={tooltipRef}
            className="hotspot-tooltip"
            posX={posX}
            posY={posY}
            flippedX={posX > 60}
            flippedY={posY > 70}
            expanded={expanded}
            onClick={e => e.stopPropagation()}
          >
            <S.TooltipHeader>
              <p className={`${getFont(fontFamilyTitle) || ""}`}>{title}</p>

              <div>
                <S.NavigationButton
                  className="hotspot-tooltip-navigation-button-prev"
                  tabIndex="0"
                  role="button"
                  type="button"
                  onClick={handlePrevClick}
                  color={color}
                >
                  &larr;
                </S.NavigationButton>

                <S.NavigationButton
                  className="hotspot-tooltip-navigation-button-next"
                  tabIndex="0"
                  role="button"
                  type="button"
                  onClick={handleNextClick}
                  color={color}
                >
                  &rarr;
                </S.NavigationButton>

                <S.CloseButton
                  className="hotspot-tooltip-navigation-button-close"
                  tabIndex="0"
                  role="button"
                  type="button"
                  onClick={handleClose}
                  color={color}
                >
                  <svg width="30" height="30" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                  </svg>
                </S.CloseButton>
              </div>
            </S.TooltipHeader>

            {
              image && (
                <S.TooltipImage
                  src={getImageUrl(image)}
                />
              )
            }

            {
              audio && (
                <S.TooltipAudio
                  src={getAudioUrl(audio)}
                  controls
                />
              )
            }

            <S.TooltipDescription
              className={`${getFont(fontFamilyText) || ""}`}
              dangerouslySetInnerHTML={{__html: description}}
            />
          </S.Tooltip>
        )
      }
    </div>
  );
}

export default Spot;