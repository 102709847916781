import React, { Component } from 'react';
import { Quill } from '../../components';
import {UserService} from '../../services/user';
import './element-accordion.css';

export default class ElementAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": [],
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.content !== prevProps.content) {
      this.setState({ content: this.props.content });
    }
  };

  componentWillMount() {
    this.manipulateItems();
  }

  manipulateItems() {
    var content = this.props.content;
    var items = content.items;
    for (var i = 0; i < items.length; i++) {
      items[i].expanded = false;
    };
    content.items = items;
    this.setState({
      "content": content
    })
  }

  handleChange(text, index) {
    var content = this.state.content;
    content.items[index].text = text;
    this.setState({
      "content": content
    });
    this.props.onChange(content);
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
    image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  onToggleItem(index) {
    var content = this.state.content;
    var items = content.items;
    if(content.behavior === "close-all") {
      for (var i = 0; i < items.length; i++) {
        if (i !== index) {
          items[i].expanded = false;
        }
      };
    };
    items[index].expanded = !items[index].expanded;
    this.setState({
      "content": content
    })
  }

  renderQuill(item, index) {
    if (this.props.editable) {
      return <>
        <Quill
          value={item.text}
          onChange={(text) => this.handleChange(text, index)}
        />
      </>
    } else {
      return <div className="editor" dangerouslySetInnerHTML={{__html: item.text}}></div>
    }
  }

  getAssetUrl(asset) {
    var accessToken = UserService.getUser().access_token;
    return asset += "&access_token=" + accessToken;
  }

  renderImage(item) {
    if (item.image !== null) {
      return <img className="element-accordion-item-image" src={this.getAssetUrl(item.image)}/>
    }
  }

  renderVideo(item) {
    if (item.video !== null) {
      return <video className="element-accordion-item-video" controls src={this.getAssetUrl(item.video)}/>
    }
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    var _this = this;
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    var accordionStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }
    var items = this.state.content.items;

    return <div className={`${this.props.className} ${this.getFont(content.font_family) || ""}`} style={accordionStyle}>
      {headerActionsTemplate}
      <ul className="element-accordion-items">
        {items.map(function(item, index) {
          return <li key={item.id} className={`element-accordion-item ${item.expanded === true ? "expanded" : "not-expanded"}`}>
            <span className="element-accordion-item-title" onClick={() => _this.onToggleItem(index)}>{item.title}</span>
            <div className="element-accordion-item-text">
              {_this.renderQuill(item, index)}
            </div>
            {_this.renderImage(item)}
            {_this.renderVideo(item)}
          </li>
        })}
      </ul>
      {footerActionsTemplate}
    </div>
  }
}
