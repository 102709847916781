var rdpTranslatePtBr = function() {
    return {
      "status": {
        "400": "Solicitação inválida",
        "401": "Não autorizado",
        "403": "Proibido",
        "404": "Conteúdo não encontrado",
        "500": "Erro interno no servidor",
        "503": "Serviço indisponível",
        "back": "Voltar"
      },
      "login": {
        "messages": {
          "form_title": "Entre com suas credenciais para acessar o RDP Composer."
        },
        "inputs": {
          "username_label": "Usuário",
          "password_label": "Senha",
        },
        "buttons": {
          "sign_in": "Entrar"
        }
      },
      "home": {
        "buttons": {
          "new_composition": "Nova composição",
          "hello_label": "Olá",
          "project_label": "Projeto",
          "language_label": "Idioma",
          "sign_out": "Sair"
        },
        "labels": {
          "search_placeholder": "Buscar...",
          "no_compositions_message": "Nenhuma composição existente."
        },
        "cards": {
          "composition_tag_version": "Versão",
          "composition_actor": "Autor",
          "composition_last_modification": "Última modificação"
        }
      },
      "composer": {
        "not_found": "Composição não encontrada.",
        "yes": "Sim",
        "no": "Não",
        "composition_being_edited": "Composição sendo editada em outra sessão, você deseja assumir a edição, com risco de perder informações?",
        "attention": "Atenção!",
        "local_changes": "Alterações locais",
        "save_changes": "Salvar alterações",
        "discard_changes": "Descartar alterações",
        "save_changes_message": "Você tem alterações locais que não foram salvas, deseja salvá-las?",
        "saved": "Salvo!",
        "saved_message": "Alterações salvas com sucesso.",
        "lock_loss_message": "Outro usuário assumiu o controle da composição. Deseja sobrepor as alterações?",
        "header": {
          "back_btn": "Voltar",
          "element_header": "Cabeçalho",
          "element_text": "Texto",
          "element_list": "Lista",
          "element_image": "Imagem",
          "element_multimedia": "Multimídia",
          "element_interactivity": "Interatividade",
          "element_quiz": "Quiz",
          "element_activities": "Atividades",
          "element_markers": "Marcadores",
          "menu_save_composition": "Salvar composição",
          "menu_settings": "Configurações",
          "menu_export_zip": "Exportar como zip",
          "menu_versions_history": "Histórico de versões",
          "menu_preview": "Preview",
          "settings": {
            "title": "Configurações",
            "form_label_title": "Título",
            "form_label_gtm": "Google Tag Manager ID",
            "form_label_description": "Descrição",
            "form_label_document_font": "Fonte do conteúdo",
            "form_label_document_language": "Idioma do conteúdo",
            "form_label_modality": "Modalidade",
            "form_label_tree_enable": "Exibir sumário com os marcadores no conteúdo",
            "form_label_max_width": "Largura máxima do conteúdo",
            "form_label_finish_btn_enable": "Habilitar botão para finalizar conteúdo",
            "form_label_progress_element": "Sinalização de progresso",
            "form_label_progress_element_color": "Cor da sinalização de progresso",
            "form_label_scorm_not_evaluative_complete_status": "Status de finalização de um conteúdo SCORM não avaliativo",
            "save_btn": "Salvar",
            "options_progress_element": {
              "none": "Nenhum",
              "circular_bottom_center": "Progresso circular (inferior centralizado)",
              "circular_bottom_left": "Progresso circular (inferior esquerdo)",
              "circular_bottom_right": "Progresso circular (inferior direito)",
            },
            "settings_label_menu_position": "Posição do menu do sumário",
            "settings_label_header_progress": "Exibir progresso no cabeçalho do sumário",
            "settings_label_menu_position_left": "Esquerda",
            "settings_label_menu_position_right": "Direita",
            "settings_label_continuous_navigation": "Navegação contínua",
            "settings_label_continuous_navigation_enabled": "Habilitado",
            "settings_label_continuous_navigation_disabled": "Desabilitado",
            "options_document_languages": {
              "en": "Inglês",
              "pt_br": "Português Brasil (pt-br)"
            },
            "options_modality": {
              "non_evaluative": "Não avaliativa",
              "evaluative": "Avaliativa"
            },
            "tree_enable": {
              "enabled": "Habilitado",
              "disabled": "Desabilitado"
            },
            "finish_btn": {
              "enabled": "Habilitado",
              "disabled": "Desabilitado"
            },
            "tabs": {
              "metadata_label": "Metadados",
              "rules_label": "Regras de avaliação",
              "language_label": "Idiomas",
              "interface_label": "Interface",
              "gtm_label": "Google Tag Manager"
            }
          },
          "elements_descriptions": {
            "element_head_1": "Cabeçalho com imagem de fundo, foto central e textos editáveis.",
            "element_text_1": "Texto editável com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_text_2": "Texto e título editáveis com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_text_3": "Texto e subtítulo editáveis com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_text_4": "Título editável com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_text_5": "Subtítulo editável com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_text_two_column": "Duas colunas de texto editáveis com várias opções de formatação disponíveis ao selecionar um trecho de texto.",
            "element_list_1": "Lista com itens textuais com sequência numérica.",
            "element_list_2": "Lista com itens textuais com interações via checkbox.",
            "element_list_3": "Lista com itens textuais com bullets.",
            "element_image_1": "Imagem com largura customizável sendo limitada em 760 pixels.",
            "element_image_2": "Imagem fullscreen que ocupa 100% da largura do dispositivo.",
            "element_images_gallery": "Galeria de imagens que são exibidas uma do lado da outra.",
            "element_image_and_text": "Imagem com texto ao lado.",
            "element_image_with_text": "Imagem com texto interno.",
            "element_audio": "Áudio que possibilita a utilização de arquivos .mp3.",
            "element_video": "Vídeo que possibilita a utilização de arquivos .mp4.",
            "element_iframe": "Iframe que possibilita a exibição de sites e vídeos externos.",
            "element_accordion": "Accordion que possibilita expandir os tópicos editáveis. É possível editar o título, formatar o texto e inserir imagem no conteúdo.",
            "element_tabs": "Tabs que possibilitam abrir as abas editáveis. É possível editar o título, formatar o texto e inserir imagem no conteúdo.",
            "element_flashcards": "Flashcards que possibilita, a virada de um card no clique. É possível editar o título, formatar o texto e inserir imagem na frente e verso de cada card.",
            "element_flashcards_2": "Slide de flashcards que possibilita, a virada de um card no clique. É possível editar o título, formatar o texto e inserir imagem na frente e verso de cada card.",
            "element_hotspots_1": "Imagem que possibilita, adicionar pontos de informações.",
            "sorted_activity_1": "Cartões que podem ser arrastados para a pilha correta.",
            "element_timeline_1": "Linha do tempo que possibilita a inserção de marcadores e acompanhamento de tempo. É possível editar o título, formatar o texto e inserir imagem, áudio e vídeo no conteúdo.",
            "element_quiz_1": "Questionário de única escolha.",
            "element_quiz_2": "Questionário de múltipla escolha.",
            "element_quiz_3": "Questionário de completar o texto.",
            "element_quiz_4": "Questionário de ligar as respostas.",
            "element_quiz_5": "Questionário dissertativo.",
            "element_quiz_6": "Questionário misto.",
            "element_forum_1": "Fórum avaliativo integrado ao módulo de Learning",
            "element_tex_1": "Insira fórmulas matemáticas no seu conteúdo, utilizando o padrão TeX.",
            "element_divider_1": "Linha divisora para ser inserida entre objetos.",
            "element_divider_4": "Espaço para ser inserido entre objetos.",
            "element_divider_numbered": "Linha divisora com número para ser inserida entre objetos.",
            "virtual_element_index_1": "Marcador para dividir a composição em pequenos blocos gerando um índice de navegação.",
            "element_statement_1": "Texto no formato de declaração, editável com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_statement_2": "Texto no formato de declaração, editável com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_statement_3": "Texto no formato de declaração, editável com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_statement_4": "Texto no formato de declaração, editável com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_note_1": "Texto no formato de nota, editável com diversas opções de formatação que são disponibilizadas ao selecionar um trecho do texto.",
            "element_quote_1": "Citação editável com diversas opções de formatação, que possibilita a troca do avatar e edição do texto.",
            "element_quote_2": "Citação editável com diversas opções de formatação, que possibilita a troca do avatar e edição do texto.",
            "element_quote_3": "Citação editável com diversas opções de formatação, que possibilita a troca do avatar e edição do texto.",
            "element_quote_4": "Citação editável com diversas opções de formatação, que possibilita a troca do avatar e edição do texto.",
            "element_quote_5": "Citação editável com diversas opções de formatação, que possibilita a troca do avatar, imagem de fundo e edição do texto.",
            "element_steps_1": "Slide com etapas sequênciais. É possível editar o título, formatar o texto e inserir imagem e áudio no conteúdo de cada etapa.",
            "element_snippet_1": "Permite adicionar trecho de código copiável.",
            "element_button_1": "Botão com descrição. É possível adicionar um link ou endereço de email, formatar a descrição e alterar a cor do botão.",
            "element_button_2": "Grupo de botões com descrição. É possível adicionar um link ou endereço de email, formatar a descrição e alterar a cor do botão.",
            "element_attachment_1": "Permite anexar arquivos para serem baixados.",
            "element_quote_6": "Carroussel de citações editáveis com diversas opções de formatação, que possibilita a troca do avatar, imagem de fundo e edição do texto.",
            "element_3d": "Exibição de objetos 3D, com as opções de rotação, zoom e visualização em ambientes reais via smartphones.",
            "element_interactive_video": "Janela que possibilita a exibição de um vídeo de maneira interativa.",
          }
        },
        "stage": {
          "no_elements_message": "Comece já a composição do seu conteúdo!",
          "choose_element_to_insert_above": "Escolha no menu acima um recurso para inserir aqui.",
          "downloading_scorm_message": "Aguarde, o pacote SCORM está sendo gerado e em breve o download será inicializado."
        },
        "elements": {
          "messages": {
            "title": "Mensagens"
          },
          "common_settings": {
            "legend_text": "Legenda da Imagem: Texto",
            "legend_color": "Legenda da Imagem: Cor",
            "title": "Editar objeto",
            "label_tab_content": "Conteúdo",
            "label_tab_settings": "Configurações",
            "label_tab_rules": "Regras",
            "label_tab_templates": "Templates",
            "label_color_change": "Alterar",
            "label_color_done": "Concluir",
            "label_btn_save": "Salvar",
            "label_insert_rdp_dam_object": "Inserir objeto do RDP Dam",
            "label_click_here_to_upload": "Clique aqui para fazer o upload",
            "label_uploading_file": "Realizando upload do arquivo...",
            "label_select_file": "Selecionar",
            "label_no_has_font": "Nenhuma fonte selecionada",
            "label_template_success_alert": "Seu template foi salvo com sucesso!",
            "label_template_error_alert": "Erro ao salvar template, tente novamente.",
            "label_template_manager_templates": "Gerenciar templates",
            "label_template_instructions": "Salve as configurações realizadas no elemento como template, ou utilize templates salvos anteriormente para popular as configurações visuais e regras do objeto que está editando.",
            "label_template_save_new_template": "Salvar novo template",
            "label_template_use_existent_template": "Utilizar template existente",
            "label_template_save_template": "Salvar template",
            "label_template_instructions_template_name": "Insira o nome abaixo para salvar as configurações do seu objeto como um template:",
            "label_template_cancel": "Cancelar",
            "label_template_save": "Salvar",
            "label_template_use_template": "Utilizar template",
            "label_template_search_template": "Buscar template...",
            "label_template_empty_templates": "Nenhum template disponível para utilização."
          },
          "settings": {
            "preference_required_interaction": {
              "label": "Interação obrigatória para avançar no conteúdo",
              "title": "Contabilização do progresso",
              "feedback_title": "Feedback de progresso",
              "feedback_label": "Exibe um indicador do progresso de interação necessária para completar o objeto",
              "reaction_title": "Habilitar reações",
              "reaction_label": "Exibe uma lista de ícones para interação com o objeto"
            },
            "element_interactive_video": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_webview_url": "URL da Webview",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_head_1": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_image": "Imagem de fundo",
              "settings_label_avatar_image": "Imagem do avatar",
              "settings_label_avatar_border_color": "Cor de borda do Avatar",
              "settings_label_primary_color": "Cor primária",
              "settings_label_secondary_color": "Cor secundária",
              "settings_label_label_font": "Fonte do elemento",
              "settings_button_avatar_remove": "Remover",
              "settings_button_avatar_restore": "Restaurar padrão",
              "settings_button_avatar_add": "Selecionar",
              "settings_label_show_category": "Exibir categoria",
              "settings_label_show_author_name": "Exibir nome do autor",
              "settings_label_show_divider": "Exibir divisor",
            },
            "element_text": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_text_two_column": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_list": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_primary_color": "Cor primária",
              "settings_label_secondary_color": "Cor secundária",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "content_label_description": "Texto",
              "content_label_btn_add_item": "Adicionar item",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_image": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_image": "Imagem de fundo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_image_width": "Largura da imagem",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_snippet_1": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_snippet_background_color": "Cor de fundo do snippet",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento",
              "settings_label_code": "Código",
              "settings_label_caption": "Caption",
            },
            "element_images_gallery": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_images": "Imagens",
              "settings_label_caption": "Legenda",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_text_color": "Cor do texto",
              "settings_label_image_width": "Largura da imagem",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_image_and_text": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_image": "Imagem de fundo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento",
              "settings_label_image_position": "Posição da imagem",
              "settings_label_image_position_left": "Esquerda",
              "settings_label_image_position_right": "Direita",
              "settings_label_image_width": "Largura da imagem",
              "settings_label_image_border_radius": "Arredondamento das bordas da imagem",
              "settings_label_image_zoom": "Zoom",
              "settings_label_image_zoom_enabled": "Habilitado",
              "settings_label_image_zoom_disabled": "Desabilitado",
              "settings_label_image_zoom_max_level": "Zoom: Nível máximo",
              "settings_label_image_zoom_header_background_color": "Zoom: Cor de fundo do cabeçalho",
              "settings_label_image_zoom_icons_color": "Zoom: Cor dos ícones"
            },
            "element_image_with_text": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_image": "Imagem de fundo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento",
              "settings_label_label_opacity": "Opacidade",
              "settings_label_label_opacity_color": "Cor da opacidade",
              "settings_label_label_divider_color": "Cor do divisor",
            },
            "element_audio": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_audio_file": "Áudio",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_3d": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_file": "Arquivo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_video": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_video_file": "Vídeo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_iframe": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_webview_url": "URL da Webview",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_width": "Largura",
              "settings_label_height": "Altura",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_accordion": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_display_behavior": "Comportamento de exibição",
              "settings_label_display_behavior_do_not_close_items": "Ao abrir um item, não fechar os itens abertos",
              "settings_label_display_behavior_close_items": "Ao abrir um item, fechar os itens abertos",
              "content_label_title": "Título",
              "content_label_description": "Descrição",
              "content_label_insert_image": "Inserir imagem",
              "content_label_insert_video": "Inserir vídeo",
              "content_label_btn_add_item": "Adicionar item",
              "content_label_btn_remove_asset": "Remover",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_tabs": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_active_tab_color": "Cor da Tab ativa",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "content_label_title": "Título",
              "content_label_description": "Descrição",
              "content_label_insert_image": "Inserir imagem",
              "content_label_insert_video": "Inserir vídeo",
              "content_label_btn_add_item": "Adicionar item",
              "content_label_btn_remove_asset": "Remover",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_flashcards": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_border_color": "Cor da borda",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_card_size": "Tamanho dos Cards",
              "content_front_of_the_card": "Frente do Card",
              "content_back_of_the_card": "Verso do Card",
              "content_label_btn_add_item": "Adicionar item",
              "content_option_text": "Texto",
              "content_option_centered_image": "Imagem centralizada",
              "content_option_fullscreen_image": "Imagem fullscreen",
              "content_option_card_size_1": "Quadrado pequeno",
              "content_option_card_size_2": "Quadrado grande",
              "content_option_card_size_3": "Retângulo horizontal",
              "content_option_card_size_4": "Retângulo vertical",
              "content_label_image_edit": "Editar",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_quiz_1": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_scorm_progress_count": "Contabilização de progresso",
              "settings_scorm_progress_count_without_finish": "Não é necessário realizar o quiz, para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_and_minimum_score": "É necessário realizar o quiz, atingindo a nota mínima para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_without_minimum_score": "É necessário realizar o quiz, não precisando atingir a nota mínima para contabilizar progresso",
              "settings_label_mandatory_minimum_achievement": "Aproveitamento mínimo obrigatório", //Aproveitamento mínimo obrigatório
              "settings_label_questions_random_order" : "Ordem randômica das alternativas",
              "settings_label_questions_random_questions_order" : "Ordem randômica das questões",
              "settings_label_number_of_questions_to_display": "Quantidade de questões a serem exibidas",
              "settings_label_option_to_repeat_quiz": "Permitir refazer o questionário",
              "settings_label_sort_on_remake": "Aleatorizar questões ao refazer",
              "settings_option_to_questions_random_order_enabled" : "Habilitado",
              "settings_option_to_questions_random_order_disabled" : "Desabilitado",
              "settings_mandatory_minimum_achievement_enabled": "Habilitado",
              "settings_mandatory_minimum_achievement_disabled": "Desabilitado",
              "settings_label_minimum_achievement_percentage": "Porcentagem de aproveitamento mínimo",
              "settings_label_feedback_behavior": "Comportamento de feedback", //Comportamento de feedback
              "settings_feedback_behavior_default_message_to_any_reply": "Mensagem padrão a qualquer resposta", //Mensagem padrão a qualquer resposta
              "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Mensagem específica para resposta correta e/ou incorreta", //Mensagem específica para resposta correta e/ou incorreta
              "settings_feedback_behavior_no_feedback": "Sem feedback", //Sem feedback
              "settings_label_max_attempts": "Número máximo de refações",
              "settings_option_to_repeat_quiz_enabled": "Habilitado",
              "settings_option_to_repeat_quiz_disabled": "Desabilitado",
              "settings_label_main_color": "Cor principal", //Cor principal
              "settings_label_background_color": "Cor de fundo",
              "settings_label_correct_option_color": "Cor da opção correta", //Cor da opção correta
              "settings_label_wrong_option_color": "Cor da opção incorreta", //Cor da opção incorreta
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "content_label_question": "Questão", //Questão
              "content_label_enunciation": "Enunciado", //Enunciado
              "content_label_insert_image": "Inserir imagem", //Inserir imagem
              "content_label_insert_video": "Inserir vídeo", //Inserir vídeo,
              "content_label_alternatives": "Alternativas", //Alternativas
              "content_label_no_correct_answer": "Sem resposta correta", //Sem resposta correta
              "content_placeholder_alternative": "Choice", //Choice
              "content_button_add_alternative": "Adicionar alternativa", //Adicionar alternativa
              "content_label_feedback": "Feedback", //Feedback,
              "content_placeholder_feedback": "Feedback padrão", //Feedback padrão
              "content_placeholder_feedback_correct": "Feedback quando correto",
              "content_placeholder_feedback_incorrect": "Feedback quando incorreto",
              "content_placeholder_feedback_media_max_width": "Largura máxima da imagem ou vídeo",
              "content_button_add_question": "Adicionar questão", //Adicionar questão
              "content_label_btn_remove_asset": "Remover",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_quiz_2": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_scorm_progress_count": "Contabilização de progresso",
              "settings_scorm_progress_count_without_finish": "Não é necessário realizar o quiz, para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_and_minimum_score": "É necessário realizar o quiz, atingindo a nota mínima para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_without_minimum_score": "É necessário realizar o quiz, não precisando atingir a nota mínima para contabilizar progresso",
              "settings_label_mandatory_minimum_achievement": "Aproveitamento mínimo obrigatório", //Aproveitamento mínimo obrigatório
              "settings_label_questions_random_order" : "Ordem randômica das alternativas",
              "settings_label_questions_random_questions_order" : "Ordem randômica das questões",
              "settings_label_number_of_questions_to_display": "Quantidade de questões a serem exibidas",
              "settings_label_option_to_repeat_quiz": "Permitir refazer o questionário",
              "settings_label_sort_on_remake": "Aleatorizar questões ao refazer",
              "settings_option_to_questions_random_order_enabled" : "Habilitado",
              "settings_option_to_questions_random_order_disabled" : "Desabilitado",
              "settings_mandatory_minimum_achievement_enabled": "Habilitado",
              "settings_mandatory_minimum_achievement_disabled": "Desabilitado",
              "settings_label_minimum_achievement_percentage": "Porcentagem de aproveitamento mínimo",
              "settings_label_feedback_behavior": "Comportamento de feedback", //Comportamento de feedback
              "settings_feedback_behavior_default_message_to_any_reply": "Mensagem padrão a qualquer resposta", //Mensagem padrão a qualquer resposta
              "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Mensagem específica para resposta correta e/ou incorreta", //Mensagem específica para resposta correta e/ou incorreta
              "settings_feedback_behavior_no_feedback": "Sem feedback", //Sem feedback
              "settings_label_max_attempts": "Número máximo de refações",
              "settings_option_to_repeat_quiz_enabled": "Habilitado",
              "settings_option_to_repeat_quiz_disabled": "Desabilitado",
              "settings_label_main_color": "Cor principal", //Cor principal
              "settings_label_background_color": "Cor de fundo",
              "settings_label_correct_option_color": "Cor da opção correta", //Cor da opção correta
              "settings_label_wrong_option_color": "Cor da opção incorreta", //Cor da opção incorreta
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "content_label_question": "Questão", //Questão
              "content_label_enunciation": "Enunciado", //Enunciado
              "content_label_insert_image": "Inserir imagem", //Inserir imagem
              "content_label_insert_video": "Inserir vídeo", //Inserir vídeo,
              "content_label_alternatives": "Alternativas", //Alternativas
              "content_label_no_correct_answer": "Sem resposta correta", //Sem resposta correta
              "content_placeholder_alternative": "Choice", //Choice
              "content_button_add_alternative": "Adicionar alternativa", //Adicionar alternativa
              "content_label_feedback": "Feedback", //Feedback,
              "content_placeholder_feedback": "Feedback padrão", //Feedback padrão
              "content_placeholder_feedback_correct": "Feedback quando correto",
              "content_placeholder_feedback_incorrect": "Feedback quando incorreto",
              "content_placeholder_feedback_media_max_width": "Largura máxima da imagem ou vídeo",
              "content_button_add_question": "Adicionar questão", //Adicionar questão
              "content_label_btn_remove_asset": "Remover",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_quiz_3": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_scorm_progress_count": "Contabilização de progresso",
              "settings_scorm_progress_count_without_finish": "Não é necessário realizar o quiz, para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_and_minimum_score": "É necessário realizar o quiz, atingindo a nota mínima para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_without_minimum_score": "É necessário realizar o quiz, não precisando atingir a nota mínima para contabilizar progresso",
              "settings_label_mandatory_minimum_achievement": "Aproveitamento mínimo obrigatório", //Aproveitamento mínimo obrigatório
              "settings_label_questions_random_order" : "Ordem randômica das alternativas",
              "settings_label_questions_random_questions_order" : "Ordem randômica das questões",
              "settings_label_number_of_questions_to_display": "Quantidade de questões a serem exibidas",
              "settings_label_option_to_repeat_quiz": "Permitir refazer o questionário",
              "settings_label_sort_on_remake": "Aleatorizar questões ao refazer",
              "settings_option_to_questions_random_order_enabled" : "Habilitado",
              "settings_option_to_questions_random_order_disabled" : "Desabilitado",
              "settings_mandatory_minimum_achievement_enabled": "Habilitado",
              "settings_mandatory_minimum_achievement_disabled": "Desabilitado",
              "settings_label_minimum_achievement_percentage": "Porcentagem de aproveitamento mínimo",
              "settings_label_feedback_behavior": "Comportamento de feedback", //Comportamento de feedback
              "settings_feedback_behavior_default_message_to_any_reply": "Mensagem padrão a qualquer resposta", //Mensagem padrão a qualquer resposta
              "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Mensagem específica para resposta correta e/ou incorreta", //Mensagem específica para resposta correta e/ou incorreta
              "settings_feedback_behavior_no_feedback": "Sem feedback", //Sem feedback
              "settings_label_max_attempts": "Número máximo de refações",
              "settings_option_to_repeat_quiz_enabled": "Habilitado",
              "settings_option_to_repeat_quiz_disabled": "Desabilitado",
              "settings_label_main_color": "Cor principal", //Cor principal
              "settings_label_background_color": "Cor de fundo",
              "settings_label_correct_option_color": "Cor da opção correta", //Cor da opção correta
              "settings_label_wrong_option_color": "Cor da opção incorreta", //Cor da opção incorreta
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "content_label_question": "Questão", //Questão
              "content_label_enunciation": "Enunciado", //Enunciado
              "content_label_insert_image": "Inserir imagem", //Inserir imagem
              "content_label_insert_video": "Inserir vídeo", //Inserir vídeo,
              "content_label_alternatives": "Alternativas", //Alternativas
              "content_label_no_correct_answer": "Sem resposta correta", //Sem resposta correta
              "content_placeholder_alternative": "Choice", //Choice
              "content_button_add_alternative": "Adicionar alternativa", //Adicionar alternativa
              "content_label_feedback": "Feedback", //Feedback,
              "content_placeholder_feedback": "Feedback padrão", //Feedback padrão
              "content_placeholder_feedback_correct": "Feedback quando correto",
              "content_placeholder_feedback_incorrect": "Feedback quando incorreto",
              "content_placeholder_feedback_media_max_width": "Largura máxima da imagem ou vídeo",
              "content_button_add_question": "Adicionar questão", //Adicionar questão
              "content_label_btn_remove_asset": "Remover",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_quiz_4": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_scorm_progress_count": "Contabilização de progresso",
              "settings_scorm_progress_count_without_finish": "Não é necessário realizar o quiz, para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_and_minimum_score": "É necessário realizar o quiz, atingindo a nota mínima para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_without_minimum_score": "É necessário realizar o quiz, não precisando atingir a nota mínima para contabilizar progresso",
              "settings_label_mandatory_minimum_achievement": "Aproveitamento mínimo obrigatório", //Aproveitamento mínimo obrigatório
              "settings_label_questions_random_order" : "Ordem randômica das alternativas",
              "settings_label_questions_random_questions_order" : "Ordem randômica das questões",
              "settings_label_number_of_questions_to_display": "Quantidade de questões a serem exibidas",
              "settings_label_option_to_repeat_quiz": "Permitir refazer o questionário",
              "settings_label_sort_on_remake": "Aleatorizar questões ao refazer",
              "settings_option_to_questions_random_order_enabled" : "Habilitado",
              "settings_option_to_questions_random_order_disabled" : "Desabilitado",
              "settings_mandatory_minimum_achievement_enabled": "Habilitado",
              "settings_mandatory_minimum_achievement_disabled": "Desabilitado",
              "settings_label_minimum_achievement_percentage": "Porcentagem de aproveitamento mínimo",
              "settings_label_feedback_behavior": "Comportamento de feedback", //Comportamento de feedback
              "settings_feedback_behavior_default_message_to_any_reply": "Mensagem padrão a qualquer resposta", //Mensagem padrão a qualquer resposta
              "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Mensagem específica para resposta correta e/ou incorreta", //Mensagem específica para resposta correta e/ou incorreta
              "settings_feedback_behavior_no_feedback": "Sem feedback", //Sem feedback
              "settings_label_max_attempts": "Número máximo de refações",
              "settings_option_to_repeat_quiz_enabled": "Habilitado",
              "settings_option_to_repeat_quiz_disabled": "Desabilitado",
              "settings_label_main_color": "Cor principal", //Cor principal
              "settings_label_background_color": "Cor de fundo",
              "settings_label_correct_option_color": "Cor da opção correta", //Cor da opção correta
              "settings_label_wrong_option_color": "Cor da opção incorreta", //Cor da opção incorreta
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "content_label_question": "Questão", //Questão
              "content_label_enunciation": "Enunciado", //Enunciado
              "content_label_insert_image": "Inserir imagem", //Inserir imagem
              "content_label_insert_video": "Inserir vídeo", //Inserir vídeo,
              "content_label_alternatives": "Alternativas", //Alternativas
              "content_label_no_correct_answer": "Sem resposta correta", //Sem resposta correta
              "content_placeholder_alternative": "Choice", //Choice
              "content_button_add_alternative": "Adicionar alternativa", //Adicionar alternativa
              "content_label_feedback": "Feedback", //Feedback,
              "content_placeholder_feedback": "Feedback padrão", //Feedback padrão
              "content_placeholder_feedback_correct": "Feedback quando correto",
              "content_placeholder_feedback_incorrect": "Feedback quando incorreto",
              "content_placeholder_feedback_media_max_width": "Largura máxima da imagem ou vídeo",
              "content_button_add_question": "Adicionar questão", //Adicionar questão
              "content_label_btn_remove_asset": "Remover",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_quiz_5": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_scorm_progress_count": "Contabilização de progresso",
              "settings_scorm_progress_count_without_finish": "Não é necessário realizar o quiz, para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_and_minimum_score": "É necessário realizar o quiz, atingindo a nota mínima para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_without_minimum_score": "É necessário realizar o quiz, não precisando atingir a nota mínima para contabilizar progresso",
              "settings_label_mandatory_minimum_achievement": "Aproveitamento mínimo obrigatório", //Aproveitamento mínimo obrigatório
              "settings_label_questions_random_order" : "Ordem randômica das alternativas",
              "settings_label_questions_random_questions_order" : "Ordem randômica das questões",
              "settings_label_number_of_questions_to_display": "Quantidade de questões a serem exibidas",
              "settings_label_option_to_repeat_quiz": "Permitir refazer o questionário",
              "settings_label_sort_on_remake": "Aleatorizar questões ao refazer",
              "settings_option_to_questions_random_order_enabled" : "Habilitado",
              "settings_option_to_questions_random_order_disabled" : "Desabilitado",
              "settings_mandatory_minimum_achievement_enabled": "Habilitado",
              "settings_mandatory_minimum_achievement_disabled": "Desabilitado",
              "settings_label_minimum_achievement_percentage": "Porcentagem de aproveitamento mínimo",
              "settings_label_feedback_behavior": "Comportamento de feedback", //Comportamento de feedback
              "settings_feedback_behavior_default_message_to_any_reply": "Mensagem padrão a qualquer resposta", //Mensagem padrão a qualquer resposta
              "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Mensagem específica para resposta correta e/ou incorreta", //Mensagem específica para resposta correta e/ou incorreta
              "settings_feedback_behavior_no_feedback": "Sem feedback", //Sem feedback
              "settings_label_max_attempts": "Número máximo de refações",
              "settings_option_to_repeat_quiz_enabled": "Habilitado",
              "settings_option_to_repeat_quiz_disabled": "Desabilitado",
              "settings_label_main_color": "Cor principal", //Cor principal
              "settings_label_background_color": "Cor de fundo",
              "settings_label_correct_option_color": "Cor da opção correta", //Cor da opção correta
              "settings_label_wrong_option_color": "Cor da opção incorreta", //Cor da opção incorreta
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "content_label_question": "Questão", //Questão
              "content_label_enunciation": "Enunciado", //Enunciado
              "content_label_insert_image": "Inserir imagem", //Inserir imagem
              "content_label_insert_video": "Inserir vídeo", //Inserir vídeo,
              "content_label_no_correct_answer": "Sem resposta correta", //Sem resposta correta
              "content_label_feedback": "Feedback", //Feedback,
              "content_placeholder_feedback": "Feedback padrão", //Feedback padrão
              "content_placeholder_feedback_correct": "Feedback quando correto",
              "content_placeholder_feedback_incorrect": "Feedback quando incorreto",
              "content_placeholder_feedback_media_max_width": "Largura máxima da imagem ou vídeo",
              "content_button_add_question": "Adicionar questão", //Adicionar questão
              "content_label_btn_remove_asset": "Remover",
              "settings_label_label_font": "Fonte do elemento",

              "rules_label_grade_type": "Tipo de nota",
              "rules_grade_type_numeral": "Numeral",
              "rules_label_grade_numeral_min": "Nota mínima",
              "rules_label_grade_numeral_max": "Nota máxima",
              "rules_label_grade_numeral_steps": "Steps",
            },
            "element_quiz_6": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_scorm_progress_count": "Contabilização de progresso",
              "settings_scorm_progress_count_without_finish": "Não é necessário realizar o quiz, para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_and_minimum_score": "É necessário realizar o quiz, atingindo a nota mínima para contabilizar progresso",
              "settings_scorm_progress_count_with_finish_without_minimum_score": "É necessário realizar o quiz, não precisando atingir a nota mínima para contabilizar progresso",
              "settings_label_mandatory_minimum_achievement": "Aproveitamento mínimo obrigatório", //Aproveitamento mínimo obrigatório
              "settings_label_questions_random_order" : "Ordem randômica das alternativas",
              "settings_label_questions_random_questions_order" : "Ordem randômica das questões",
              "settings_label_number_of_questions_to_display": "Quantidade de questões a serem exibidas",
              "settings_label_option_to_repeat_quiz": "Permitir refazer o questionário",
              "settings_label_sort_on_remake": "Aleatorizar questões ao refazer",
              "settings_mandatory_minimum_achievement_enabled": "Habilitado",
              "settings_mandatory_minimum_achievement_disabled": "Desabilitado",
              "settings_option_to_questions_random_order_enabled" : "Habilitado",
              "settings_option_to_questions_random_order_disabled" : "Desabilitado",
              "settings_label_minimum_achievement_percentage": "Porcentagem de aproveitamento mínimo",
              "settings_label_feedback_behavior": "Comportamento de feedback", //Comportamento de feedback
              "settings_feedback_behavior_default_message_to_any_reply": "Mensagem padrão a qualquer resposta", //Mensagem padrão a qualquer resposta
              "settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer": "Mensagem específica para resposta correta e/ou incorreta", //Mensagem específica para resposta correta e/ou incorreta
              "settings_feedback_behavior_no_feedback": "Sem feedback", //Sem feedback
              "settings_label_max_attempts": "Número máximo de refações",
              "settings_option_to_repeat_quiz_enabled": "Habilitado",
              "settings_option_to_repeat_quiz_disabled": "Desabilitado",
              "settings_label_main_color": "Cor principal", //Cor principal
              "settings_label_background_color": "Cor de fundo",
              "settings_label_correct_option_color": "Cor da opção correta", //Cor da opção correta
              "settings_label_wrong_option_color": "Cor da opção incorreta", //Cor da opção incorreta
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "content_label_question": "Questão", //Questão
              "content_label_enunciation": "Enunciado", //Enunciado
              "content_label_insert_image": "Inserir imagem", //Inserir imagem
              "content_label_insert_video": "Inserir vídeo", //Inserir vídeo,
              "content_label_alternatives": "Alternativas", //Alternativas
              "content_label_no_correct_answer": "Sem resposta correta", //Sem resposta correta
              "content_placeholder_alternative": "Choice", //Choice
              "content_button_add_alternative": "Adicionar alternativa", //Adicionar alternativa
              "content_label_feedback": "Feedback", //Feedback,
              "content_placeholder_feedback": "Feedback padrão", //Feedback padrão
              "content_placeholder_feedback_correct": "Feedback quando correto",
              "content_placeholder_feedback_incorrect": "Feedback quando incorreto",
              "content_placeholder_feedback_media_max_width": "Largura máxima da imagem ou vídeo",
              "content_button_add_question": "Adicionar questão", //Adicionar questão
              "content_label_btn_remove_asset": "Remover",
              "settings_label_label_font": "Fonte do elemento",
              "quiz-1_type_label": "Única escolha",
              "quiz-2_type_label": "Múltipla escolha",
              "quiz-3_type_label": "Completar questão",
              "quiz-4_type_label": "Ligar alternativas",
              "quiz-5_type_label": "Dissertativa",

              "rules_label_grade_type": "Tipo de nota",
              "rules_grade_type_numeral": "Numeral",
              "rules_label_grade_numeral_min": "Nota mínima",
              "rules_label_grade_numeral_max": "Nota máxima",
              "rules_label_grade_numeral_steps": "Steps",
            },
            "element_forum_1": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento",

              "settings_label_confirm_button_text_color": "Cor do texto do botão de confirmação",
              "settings_label_confirm_button_background_color": "Cor do fundo do botão de confirmação",
              "settings_label_cancel_button_text_color": "Cor do texto do botão de cancelamento",
              "settings_label_cancel_button_background_color": "Cor do fundo do botão de cancelamento",
              "settings_label_topic_button_color": "Cor do botão de um tópico",
              "settings_label_primary_topic_background_color": "Cor de fundo dos tópicos primários",
              "settings_label_primary_topic_author_color": "Cor do autor dos tópicos primários",
              "settings_label_primary_topic_text_color": "Cor do texto dos tópicos primários",
              "settings_label_secondary_topic_background_color": "Cor de fundo dos tópicos secundários",
              "settings_label_secondary_topic_author_color": "Cor do autor dos tópicos secundários",
              "settings_label_secondary_topic_text_color": "Cor do texto dos tópicos secundários",

              "rules_label_grade_type": "Tipo de nota",
              "rules_grade_type_numeral": "Numeral",
              "rules_label_grade_numeral_min": "Nota mínima",
              "rules_label_grade_numeral_max": "Nota máxima",
              "rules_label_grade_numeral_steps": "Steps",

              "rules_label_permission": "Permitido criar tópicos",
              "rules_permission_reviewer": "Revisor",
              "rules_permission_reviewadmin": "Revisor administrador",
              "rules_permission_reviewcontributor": "Revisor contribuidor",
              "rules_permission_viewer": "Visualizador",
            },
            "virtual_index_1": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_title": "Título",
              "settings_label_duration": "Duração"
            },
            "element_hotspots_1": {
              "settings_label_tab_spots": "Marcadores",
              "settings_label_spot": "Marcador",
              "settings_label_alternative_text": "Texto alternativo",
              "settings_label_title": "Título",
              "settings_label_description": "Descrição",
              "settings_label_background_image": "Imagem de fundo",
              "settings_label_spot_image": "Imagem",
              "settings_label_spot_audio": "Áudio",
              "settings_button_upload_image": "Clique para adicionar uma imagem...",
              "settings_button_upload_audio": "Clique para adicionar um áudio...",
              "settings_button_remove_image": "Remover imagem",
              "settings_button_remove_audio": "Remover áudio",
              "settings_label_spot_style": "Ícone do marcador",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_spot_color": "Cor dos marcadores",
              "settings_label_image_width": "Largura da imagem",
              "settings_option_width_small": "Pequena",
              "settings_option_width_medium": "Média",
              "settings_option_width_full": "Largura total",
              "settings_option_page_width": "Largura da página",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_font_title": "Fonte do título das interações",
              "settings_label_font_text": "Fonte do texto das interações"
            },
            "element_divider_1": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_divider_color": "Cor do divisor",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_divider_4": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_divider_color": "Cor do divisor",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_divider_numbered": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_divider_primary_color": "Cor primária do divisor",
              "settings_label_divider_secondary_color": "Cor secundária do divisor",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_statement_1": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_border_color": "Cor da borda",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_statement_2": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_border_color": "Cor da borda",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_statement_3": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_border_color": "Cor da borda",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_statement_4": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_border_color": "Cor da borda",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_note_1": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_primary_color": "Cor primária",
              "settings_label_secondary_color": "Cor secundária",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_quote_1": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_avatar_image": "Imagem do avatar",
              "settings_label_primary_color": "Cor primária",
              "settings_label_label_font": "Fonte do elemento",
              "settings_button_avatar_remove": "Remover",
              "settings_button_avatar_restore": "Restaurar padrão",
              "settings_button_avatar_add": "Selecionar"
            },
            "element_quote_2": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_avatar_image": "Imagem do avatar",
              "settings_label_primary_color": "Cor primária",
              "settings_label_label_font": "Fonte do elemento",
              "settings_button_avatar_remove": "Remover",
              "settings_button_avatar_restore": "Restaurar padrão",
              "settings_button_avatar_add": "Selecionar"
            },
            "element_quote_3": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_avatar_image": "Imagem do avatar",
              "settings_label_primary_color": "Cor primária",
              "settings_label_label_font": "Fonte do elemento",
              "settings_button_avatar_remove": "Remover",
              "settings_button_avatar_restore": "Restaurar padrão",
              "settings_button_avatar_add": "Selecionar"
            },
            "element_quote_4": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_avatar_image": "Imagem do avatar",
              "settings_label_primary_color": "Cor primária",
              "settings_label_label_font": "Fonte do elemento",
              "settings_button_avatar_remove": "Remover",
              "settings_button_avatar_restore": "Restaurar padrão",
              "settings_button_avatar_add": "Selecionar"
            },
            "element_quote_5": {
              "settings_label_content_title": "Título do conteúdo",
              "settings_label_background_image": "Imagem de fundo",
              "settings_label_avatar_image": "Imagem do avatar",
              "settings_label_primary_color": "Cor primária",
              "settings_label_label_font": "Fonte do elemento",
              "settings_button_avatar_remove": "Remover",
              "settings_button_avatar_restore": "Restaurar padrão",
              "settings_button_avatar_add": "Selecionar"
            },
            "element_quote_6": {
              "settings_label_tab_spots": "Citações",
              "settings_label_spot": "Citação",
              "settings_label_title": "Texto",
              "settings_label_description": "Nome do autor",
              "settings_label_background_image": "Imagem de fundo",
              "settings_label_spot_image": "Avatar",
              "settings_button_upload_image": "Clique para adicionar uma imagem...",
              "settings_button_upload_audio": "Clique para adicionar um áudio...",
              "settings_button_remove_image": "Remover imagem",
              "settings_button_remove_audio": "Remover áudio",
              "settings_label_spot_style": "Ícone do marcador",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_spot_color": "Cor dos primária",
              "settings_label_image_width": "Largura da imagem",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento",
              "content_label_btn_add_item": "Adicionar citação",
              "settings_button_avatar_remove": "Remover",
              "settings_button_avatar_restore": "Restaurar padrão",
              "settings_button_avatar_add": "Selecionar"
            },
            "element_sorted_activity_1": {
              "settings_label_background_color": "Cor de fundo",
              "settings_label_pile_color": "Cor da pilha",
              "settings_label_pile_text_color": "Cor do texto da pilha",
              "settings_label_item_color": "Cor do item",
              "settings_label_item_text_color": "Cor do texto do item",
              "settings_label_primary_color": "Cor de destaque",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_tab_piles": "Pilhas",
              "settings_label_add_item": "Adicionar item",
              "settings_label_add_pile": "Adicionar pilha",
            },
            "element_timeline_1": {
              "settings_label_tab_spots": "Marcadores",
              "settings_label_spot": "Marcador",
              "settings_label_alternative_text": "Texto alternativo",
              "settings_label_date": "Data",
              "settings_label_title": "Título",
              "settings_label_description": "Descrição",
              "settings_label_background_image": "Imagem de fundo",
              "settings_label_spot_image": "Imagem",
              "settings_label_spot_audio": "Áudio",
              "settings_label_spot_max_height": "Altura",
              "settings_button_upload_image": "Clique para adicionar uma imagem...",
              "settings_button_upload_audio": "Clique para adicionar um áudio...",
              "settings_button_remove_image": "Remover imagem",
              "settings_button_remove_audio": "Remover áudio",
              "settings_label_spot_style": "Ícone do marcador",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_spot_color": "Cor dos marcadores",
              "settings_label_image_width": "Largura da imagem",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento",
              "content_label_btn_add_item": "Adicionar item",
            },
            "element_steps_1": {
              "settings_label_tab_items": "Etapas",
              "settings_label_item": "Etapa",
              "settings_label_intro": "Introdução",
              "settings_label_summary": "Sumário",
              "settings_label_title": "Título",
              "settings_label_description": "Descrição",
              "settings_label_item_image": "Imagem",
              "settings_label_item_audio": "Áudio",
              "settings_button_upload_image": "Clique para adicionar uma imagem...",
              "settings_button_upload_audio": "Clique para adicionar um áudio...",
              "settings_button_remove_image": "Remover imagem",
              "settings_button_remove_audio": "Remover áudio",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_item_color": "Cor primária",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_button_1": {
              "settings_label_tab_items": "Botões",
              "settings_label_button": "Botão",
              "settings_label_summary": "Sumário",
              "settings_label_type": "Tipo",
              "settings_label_type_link": "Endereço de um site",
              "settings_label_type_email": "Endereço de email",
              "settings_label_destination": "Destino",
              "settings_label_label": "Texto do botão",
              "settings_label_description": "Descrição",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_item_color": "Cor primária",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento",
              "settings_label_font_button": "Fonte do botão",
              "settings_label_font_text": "Fonte do texto"
            },
            "element_attachment_1": {
              "settings_label_tab_attachment": "Anexo",
              "settings_label_file": "Arquivo",
              "settings_button_change_file": "Alterar arquivo",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento"
            },
            "element_gallery_1": {
              "settings_label_tab_spots": "Slides",
              "settings_label_spot": "Slide",
              "settings_label_spot_image": "Imagem",
              "settings_button_upload_image": "Clique para adicionar uma imagem...",
              "settings_label_background_color": "Cor de fundo",
              "settings_label_spot_color": "Cor dos primária",
              "settings_label_margin_top": "Margem superior",
              "settings_label_margin_bottom": "Margem inferior",
              "settings_label_label_font": "Fonte do elemento",
              "content_label_btn_add_item": "Adicionar slide",
              "settings_button_avatar_remove": "Remover",
              "settings_button_avatar_restore": "Restaurar padrão",
              "settings_button_avatar_add": "Selecionar"
            },
          }
        }
      }
    }
  }

  var RDP_TRANSLATE_PT_BR = new rdpTranslatePtBr();

  export {
    RDP_TRANSLATE_PT_BR
  };
