import React, {Component} from 'react';
import uuidv1 from 'uuid/v1';
import {UserService} from '../../services/user';
import './element-quiz-5-properties.css';
import {ColorPicker, FontPicker, RDPDamWidget, RelToolbarQuill} from '../../components';
import { SkillGroup } from '../../components/skills/SkillGroup';
import { CheckboxGenerativeFeedback } from '../../components/context/generative-feedback';

export default class ElementQuiz5Properties extends Component {
  constructor(props) {
    super(props);

    let content = this.props.content;

    if (content.random_question_order === undefined) {
      content.random_question_order = {
        "enabled": false
      };
    }

    if (content.random_on_remake === undefined) {
      content.random_on_remake = {
        "enabled": false
      };
    }

    this.state = {
      content: content,
      contentBkp: this.props.content,
      dam_widget_opened: false,
      active_tab: "content",
      rdp_dam_widget_type: null,
      media_active_item: null,
      question_choice_or_feedback: null,
      choice_index: null,
      feedback_type: null,
    };

    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onMediaChange = this.onMediaChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
    this.onChangeUtilization = this.onChangeUtilization.bind(this);
    this.onUtilizationPercentageChange = this.onUtilizationPercentageChange.bind(this);
    this.renderFeedback = this.renderFeedback.bind(this);
    this.renderUtilizationPercentage = this.renderUtilizationPercentage.bind(this);
    this.renderEditContent = this.renderEditContent.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.onQuestionChange = this.onQuestionChange.bind(this);
    this.renderItemMedia = this.renderItemMedia.bind(this);
    this.onChangeGradeType = this.onChangeGradeType.bind(this);
    this.onGradeNumeralMinChange = this.onGradeNumeralMinChange.bind(this);
    this.onGradeNumeralMaxChange = this.onGradeNumeralMaxChange.bind(this);
  }

  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  componentDidMount() {
    var content = this.props.content;
    if (content.utilization === undefined) {
      content.utilization = {
        "enabled": false,
        "percentage": 0
      };
    }
    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    })
  }

  onMaxAttemptsChange(e) {
    const {content} = this.state;
    const {max_attempts = 0} = content;
    const currentTarget = e.currentTarget;
    const value = currentTarget.value;
    content.max_attempts = value !== "" ? value : max_attempts;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onNoFeedbackChange(question, checked) {
    var content = this.state.content;
    var questions = content.questions;
    for (var i = 0; i < questions.length; i++) {
      if (question.id === questions[i].id) {
        questions[i].no_feedback = checked;
        question.no_feedback = checked;
      }
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onChangeUtilization(option) {
    var content = this.state.content;
    content.utilization.enabled = option === "enabled" ? true : false;
    if (option === "enabled") {
      content.utilization.percentage = 70;
    } else {
      content.utilization.percentage = null;
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onUtilizationPercentageChange(val) {
    var content = this.state.content;
    var percentage = parseInt(val);
    if (percentage > 100) {
      percentage = 100;
    } else if (percentage < 0) {
      percentage = 0;
    }
    content.utilization.percentage = percentage;
    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onChangeFeedbackType(type) {
    var content = this.state.content;
    content.feedback.type = type;
    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onChangeRemake(text) {
    var content = this.state.content;
    content.remake = text;
    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onMediaChange(type, index, questionChoiceOrFeedback, choiceIndex, feedbackType) {
    this.setState({
      "rdp_dam_widget_type": type,
      "media_active_item": index,
      "question_choice_or_feedback": questionChoiceOrFeedback,
      "choice_index": choiceIndex,
      "feedback_type": feedbackType
    }, function () {
      this.setState({
        "dam_widget_opened": true
      })
    })
  }

  onMediaRemove(index, questionChoiceOrFeedback, questionIndexOrIdentifier) {
    var content = this.state.content;
    var questions = content.questions;
    if (questionChoiceOrFeedback === 'question') {
      questions[index].image = null;
      questions[index].video = null;
    } else if (questionChoiceOrFeedback === 'choice') {
      var choices = questions[questionIndexOrIdentifier].choices;
      choices[index].image = null;
      choices[index].video = null;
    } else if (questionChoiceOrFeedback === 'feedback') {
      questions[index][questionIndexOrIdentifier].image = null;
      questions[index][questionIndexOrIdentifier].video = null;
    }
    this.setState({
      "content": content
    });
  }

  onColorChange = (identifier, color) => {
    var content = this.state.content;
    content[identifier] = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange(asset) {
    let content = this.state.content;
    let questions = content.questions;
    let activeItem = this.state.media_active_item;
    let mediaType = this.state.rdp_dam_widget_type;
    let questionChoiceOrFeedback = this.state.question_choice_or_feedback;
    let feedbackType = this.state.feedback_type;

    if (questionChoiceOrFeedback === 'question') {
      questions[activeItem].image = null;
      questions[activeItem].video = null;
      questions[activeItem][mediaType] = asset;
    } else if (questionChoiceOrFeedback === 'feedback') {
      questions[activeItem][feedbackType].image = null;
      questions[activeItem][feedbackType].video = null;
      questions[activeItem][feedbackType][mediaType] = asset;
    }

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }


  onRDPDamWidgetClose() {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget() {
    if (this.state.dam_widget_opened === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type={this.state.rdp_dam_widget_type}
        onClose={() => this.onRDPDamWidgetClose()}
        onAssetChange={(asset) => this.onAssetChange(asset)}
      />;
    }
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    }
    return image;
  }

  getAssetUrl(asset) {
    if (asset.slice(0, '/static/media/'.length) === '/static/media/') return asset;
    let accessToken = UserService.getUser().access_token;
    return asset += "&access_token=" + accessToken;
  }

  onChangeTab(target) {
    this.setState({
      "active_tab": target
    })
  }

  onRemoveItem(index) {
    let content = this.state.content;
    let questions = content.questions;
    let temp = [];
    for (let i = 0; i < questions.length; i++) {
      if (i !== index) {
        temp.push(questions[i])
      }
    }
    content.questions = temp;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    })
  }

  onQuestionChange(text, index) {
    var content = this.state.content;
    var questions = content.questions;
    questions[index].question = text;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onUploadClick(index) {
    var content = this.state.content;
    var questions = content.questions;
    questions[index].displayed_media_dropdown = true;
    this.setState({
      "content": content
    });
  }

  onAddQuestion(labels) {
    let content = this.state.content;
    let questions = content.questions;
    questions.push({
      "id": uuidv1(),
      "question": '<p><span style="font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?</span></p>',
      "feedback_default": {
        "text": null,
        "image": null,
        "video": null,
        "media_max_width": null
      },
      "feedback_correct": {
        "text": null,
        "image": null,
        "video": null,
        "media_max_width": null
      },
      "feedback_incorrect": {
        "text": null,
        "image": null,
        "video": null,
        "media_max_width": null
      },
      "no_correct_answer": false,
      "no_feedback": false,
      "image": null,
      "video": null,
      "answer": "",
      "answered": false
    });
    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }


  renderItemMedia(item, index, labels, questionIndexOrIdentifier, questionIndex) {
    let questionChoiceOrFeedback = item.choices ? 'question' : (item.id ? 'choice' : 'feedback');
    if (item.image !== null && item.image) {
      return <div className="element-accordion-properties-item-image-container">
        <img
          key={item.image}
          className="element-accordion-properties-item-image"
          src={this.getImageUrl(item.image)}
          onClick={() => this.onMediaChange("image", questionIndex, questionChoiceOrFeedback, index, questionIndexOrIdentifier)}
        />
        <span className="element-accordion-properties-item-image-remove"
              onClick={() => this.onMediaRemove(index, questionChoiceOrFeedback, questionIndexOrIdentifier)}>{labels.elements.settings.element_quiz_5.content_label_btn_remove_asset}</span>
      </div>;
    } else if (item.video !== null && item.video) {
      return <div className="element-accordion-properties-item-video-container">
        <video key={item.video} className="element-accordion-properties-item-video" src={this.getAssetUrl(item.video)}
               onClick={() => this.onMediaChange("video", questionIndex, questionChoiceOrFeedback, index, questionIndexOrIdentifier)}/>
        <span className="element-accordion-properties-item-video-remove"
              onClick={() => this.onMediaRemove(index, questionChoiceOrFeedback, questionIndexOrIdentifier)}>{labels.elements.settings.element_quiz_5.content_label_btn_remove_asset}</span>
      </div>;
    } else {
      return <div className="element-accordion-properties-item-upload-media">
        <button className="element-accordion-properties-item-upload-image"
                onClick={() => this.onMediaChange("image", questionIndex, questionChoiceOrFeedback, index, questionIndexOrIdentifier)}>{labels.elements.settings.element_quiz_5.content_label_insert_image}</button>
        <button className="element-accordion-properties-item-upload-video"
                onClick={() => this.onMediaChange("video", questionIndex, questionChoiceOrFeedback, index, questionIndexOrIdentifier)}>{labels.elements.settings.element_quiz_5.content_label_insert_video}</button>
      </div>
    }
  }

  changeFeedback = (questionId, identifier, text) => {
    let cloneContent = this.state.content;
    cloneContent.questions = this.state.content.questions.map(question => {
      if (question.id === questionId) {
        question[identifier].text = text;
      }
      return question;
    });
    this.setState({
      "content": cloneContent
    }, () => {
      this.props.onContentChange(cloneContent)
    });
  }


  changeFeedbackMediaMaxWidth = (questionId, feedbackType, value) => {
    const content = this.state.content;

    content.questions = content.questions.map((question) => {
      if (question.id === questionId) question[feedbackType].media_max_width = value;
      return question;
    });

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  renderMediaMaxWidthInput = (question, labels, feedbackType) => {
    return ((question[feedbackType].image || question[feedbackType].video) &&
      <input disabled={question.no_feedback} className="element-quiz-box-feedback-input" type="number"
             defaultValue={question[feedbackType].media_max_width}
             onChange={(e) => this.changeFeedbackMediaMaxWidth(question.id, feedbackType, e.target.value)}
             placeholder={`${labels.elements.settings.element_quiz_5.content_placeholder_feedback_media_max_width}...`}/>
    );
  }

  renderFeedbackInputs(question, labels, index) {
    const {feedback} = this.state.content;
    if (feedback.type === "default" || (feedback.type === "correct_incorrect" && question.no_correct_answer)) {
      return <>
        <input disabled={question.no_feedback} className="element-quiz-box-feedback-input" type="text"
               value={(question["feedback_incorrect"] && question["feedback_correct"].text) || ""}
               onChange={(e) => this.changeFeedback(question.id, "feedback_default", e.target.value)}
               placeholder={`${labels.elements.settings.element_quiz_5.content_placeholder_feedback}...`}/>
        {this.renderItemMedia(question.feedback_default, index, labels, 'feedback_default', index)}
        {this.renderMediaMaxWidthInput(question, labels, 'feedback_default')}
      </>
    } else if (feedback.type === "correct_incorrect") {
      return <>
        <input disabled={question.no_feedback} className="element-quiz-box-feedback-input" type="text"
               value={(question["feedback_correct"] && question["feedback_correct"].text)}
               onChange={(e) => this.changeFeedback(question.id, "feedback_correct", e.target.value)}
               placeholder={`${labels.elements.settings.element_quiz_5.content_placeholder_feedback_correct}...`}/>
        {this.renderItemMedia(question.feedback_correct, index, labels, 'feedback_correct', index)}
        {this.renderMediaMaxWidthInput(question, labels, 'feedback_correct')}
        <div className="rdp-composer-element-divider" style={{marginTop: '8px'}}/>
        <input disabled={question.no_feedback} className="element-quiz-box-feedback-input" type="text"
               value={(question["feedback_incorrect"] && question["feedback_incorrect"].text)}
               onChange={(e) => this.changeFeedback(question.id, "feedback_incorrect", e.target.value)}
               placeholder={`${labels.elements.settings.element_quiz_5.content_placeholder_feedback_incorrect}...`}/>
        {this.renderItemMedia(question.feedback_incorrect, index, labels, 'feedback_incorrect', index)}
        {this.renderMediaMaxWidthInput(question, labels, 'feedback_incorrect')}
      </>
    }
  }

  renderFeedback(question, labels, index) {
    const {feedback} = this.state.content;
    if (feedback.type !== "disabled") {
      return <div className="element-quiz-box-feedback">
        <span
          className="element-quiz-5-properties-item-label">{labels.elements.settings.element_quiz_5.content_label_feedback}</span>
        <div className="rdp-composer-element-item-secondary-select">
          <span
            className="rdp-composer-element-item-secondary-label">{labels.elements.settings.element_quiz_5.settings_feedback_behavior_no_feedback}</span>
          <input className="rdp-composer-element-item-secondary-checkbox" type="checkbox" checked={question.no_feedback}
                 onChange={(e) => this.onNoFeedbackChange(question, e.currentTarget.checked)}/>
        </div>
        {this.renderFeedbackInputs(question, labels, index)}
      </div>
    }
  }

  renderEditContent = (labels) => {
    let _this = this;
    let {questions} = this.state.content;

    return (<>
      <ul>
        {questions.map(function (question, index) {
          return (
            <li key={question.id} className="element-accordion-properties-item">
              <div className="element-accordion-properties-item-drag"/>
              <span className="element-accordion-properties-item-remove" onClick={() => _this.onRemoveItem(index)}/>
              <span className="element-accordion-properties-question-label">
                {labels.elements.settings.element_quiz_5.content_label_question} - {index + 1}
              </span>
              <span className="element-accordion-properties-item-label">
                {labels.elements.settings.element_quiz_5.content_label_enunciation}
              </span>
              <RelToolbarQuill
                onChange={(e) => _this.onQuestionChange(e, index)}
                value={question.question}
              />
              {_this.renderItemMedia(question, index, labels, index, index)}
                <SkillGroup question={question} onChangeQuestion={newQuestion => {
                  const newContent = {
                    ..._this.state.content,
                    questions: questions.map(item => 
                      item.id !== newQuestion.id ? item : newQuestion)
                    }
                    
                    _this.setState({ content: newContent }, () => _this.props.onContentChange(newContent))
                  }} />
              {_this.renderFeedback(question, labels, index)}
            </li>
          )
        })}
      </ul>
      <CheckboxGenerativeFeedback />
      <button
        className="element-accordion-properties-item-new"
        onClick={() => this.onAddQuestion(labels)}>
        {labels.elements.settings.element_quiz_5.content_button_add_question}
      </button>
    </>);
  }

  renderUtilizationPercentage(content, labels) {
    if (content.utilization.enabled === true) {
      return <>
        <span
          className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_minimum_achievement_percentage}</span>
        <input type="number" className="rdp-composer-element-edit-input px"
               onChange={(e) => this.onUtilizationPercentageChange(e.target.value)}
               defaultValue={content.utilization.percentage}/>
      </>
    }
  }

  onFontChange(font) {
    let content = this.state.content;
    content.font_family = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  renderFont() {
    let content = this.state.content;
    let labels = this.props.labels;

    if (content.font_family) {
      return <FontPicker font={content.font_family} onFontChange={(font) => this.onFontChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontChange(font)}
                         noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  onChangeGradeType(gradeType) {
    var content = this.state.content;
    content.grade_type = gradeType;

    if (gradeType === "numeral") {
      content.grade_numeral = {
        min: 0,
        max: 100,
        steps: 10
      }
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onGradeNumeralMinChange(value) {
    const {content} = this.state;
    const {grade_numeral = {}} = content;
    grade_numeral.min = parseInt(value);

    content.grade_numeral = grade_numeral;

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  checkGradeNumeralMinValue() {
    const {content} = this.state;
    const {grade_numeral = {}} = content;

    if (isNaN(grade_numeral.min)) {
      grade_numeral.min = 0;
    }

    if (grade_numeral.min >= grade_numeral.max) {
      grade_numeral.max = grade_numeral.min + 1;
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onGradeNumeralMaxChange(value) {
    const {content} = this.state;
    const {grade_numeral = {}} = content;
    grade_numeral.max = parseInt(value);

    content.grade_numeral = grade_numeral;

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  checkGradeNumeralMaxValue() {
    const {content} = this.state;
    const {grade_numeral = {}} = content;

    if (isNaN(grade_numeral.max)) {
      grade_numeral.max = 1;
    }

    if (grade_numeral.max <= grade_numeral.min) {
      grade_numeral.min = grade_numeral.max - 1;
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onGradeNumeralStepsChange(value) {
    const {content} = this.state;
    const {grade_numeral = {}} = content;
    grade_numeral.steps = parseInt(value);
    content.grade_numeral = grade_numeral;

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onScormProgressCountChange(value) {
    var content = this.state.content;
    content.scorm_progress_count = value !== "" ? value : content.scorm_progress_count;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  checkGradeNumeralStepsValue() {
    const {content} = this.state;
    const {grade_numeral = {}} = content;

    if (isNaN(grade_numeral.steps)) {
      grade_numeral.steps = 10;
    }

    if (grade_numeral.steps > grade_numeral.max) {
      grade_numeral.steps = grade_numeral.max;
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onChangeRandomQuestionOrder(option) {
    var content = this.state.content;
    content.random_question_order.enabled = option === "enabled";

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onChangeRandomOnRemake(option) {
    var content = this.state.content;
    content.random_on_remake.enabled = option === "enabled";

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onNumberOfQuestionsToDisplay(e) {
    const {content} = this.state;
    const {number_of_questions_to_display = 0} = content;
    const currentTarget = e.currentTarget;
    const value = currentTarget.value;
    content.number_of_questions_to_display = value !== "" ? value : number_of_questions_to_display;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  renderContent() {
    let activeTab = this.state.active_tab;
    let content = this.state.content;
    let labels = this.props.labels;
    const _this = this;
    const {grade_numeral = {}} = content;

    const {
      min = 0,
      max = 100,
      steps = 10
    } = grade_numeral;

    if (activeTab === "content") {
      return this.renderEditContent(labels);
    } else if (activeTab === "configurations") {
      return <div className="element-accordion-properties-configurations">
        <span
          className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)}
               defaultValue={content.content_title}/>
        <SkillGroup
          selectedProficiency={content.proficiency} 
          selectedSkill={content.skill} 
          onChangeProficiency={proficiency => {
            const newContent = {
              ...content,
              proficiency
            }
            _this.setState({ content: newContent }, () => _this.props.onContentChange(newContent))
          }} 
          onChangeSkill={skill => {
            const newContent = {
              ...content,
              skill
            }
            _this.setState({ content: newContent }, () => _this.props.onContentChange(newContent))
          }} 
        />
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_scorm_progress_count}</span>
        <select defaultValue={content.scorm_progress_count || "without_finish"} className="rdp-composer-element-edit-select" onChange={(e) => this.onScormProgressCountChange(e.target.value)}>
          <option value="without_finish">{labels.elements.settings.element_quiz_5.settings_scorm_progress_count_without_finish}</option>
          <option value="with_finish_and_minimum_score">{labels.elements.settings.element_quiz_5.settings_scorm_progress_count_with_finish_and_minimum_score}</option>
          <option value="with_finish_without_minimum_score">{labels.elements.settings.element_quiz_5.settings_scorm_progress_count_with_finish_without_minimum_score}</option>
        </select>

        <span
          className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_mandatory_minimum_achievement}</span>
        <select defaultValue={content.utilization.enabled === true ? "enabled" : "disabled"}
                className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeUtilization(e.target.value)}>
          <option
            value="disabled">{labels.elements.settings.element_quiz_5.settings_mandatory_minimum_achievement_disabled}</option>
          <option
            value="enabled">{labels.elements.settings.element_quiz_5.settings_mandatory_minimum_achievement_enabled}</option>
        </select>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_questions_random_questions_order}</span>
        <select defaultValue={content.random_question_order.enabled === true ? "enabled" : "disabled"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeRandomQuestionOrder(e.target.value)}>
          <option value="disabled">{labels.elements.settings.element_quiz_5.settings_option_to_questions_random_order_disabled}</option>
          <option value="enabled">{labels.elements.settings.element_quiz_5.settings_option_to_questions_random_order_enabled}</option>
        </select>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_number_of_questions_to_display}:</span>
        <input type="number" className="rdp-composer-element-edit-input" onChange={(e) => this.onNumberOfQuestionsToDisplay(e)} defaultValue={content.number_of_questions_to_display || content.questions.length}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_option_to_repeat_quiz}</span>
        <select defaultValue={content.remake} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeRemake(e.target.value)}>
          <option value="enable">{labels.elements.settings.element_quiz_5.settings_option_to_repeat_quiz_enabled}</option>
          <option value="disable">{labels.elements.settings.element_quiz_5.settings_option_to_repeat_quiz_disabled}</option>
        </select>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_max_attempts}:</span>
        <input type="number" className="rdp-composer-element-edit-input" onChange={(e) => this.onMaxAttemptsChange(e)} defaultValue={content.max_attempts || 0}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_sort_on_remake}</span>
        <select defaultValue={content.random_on_remake.enabled === true ? "enabled" : "disabled"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeRandomOnRemake(e.target.value)}>
          <option value="enabled">{labels.elements.settings.element_quiz_5.settings_option_to_repeat_quiz_enabled}</option>
          <option value="disabled">{labels.elements.settings.element_quiz_5.settings_option_to_repeat_quiz_disabled}</option>
        </select>

        {this.renderUtilizationPercentage(content, labels)}

        <span className="rdp-composer-element-edit-label">
          {labels.elements.settings.element_quiz_5.rules_label_grade_type}
        </span>

        <select
          defaultValue={content.grade_type || ""}
          className="rdp-composer-element-edit-select"
          onChange={(e) => this.onChangeGradeType(e.target.value)}
        >
          <option value="" disabled>
            {labels.elements.settings.element_quiz_5.rules_label_grade_type}
          </option>
          <option value="numeral">{labels.elements.settings.element_quiz_5.rules_grade_type_numeral}</option>
        </select>

        {(content.grade_type === "numeral") &&
          <>
              <span
                className="rdp-composer-element-edit-label">
                {labels.elements.settings.element_quiz_5.rules_label_grade_numeral_min}:
              </span>
            <input
              type="number"
              className="rdp-composer-element-edit-input"
              max={String(max)}
              onChange={(e) => this.onGradeNumeralMinChange(e.target.value)}
              onBlur={(e) => this.checkGradeNumeralMinValue()}
              value={String(min)}
            />

            <span
              className="rdp-composer-element-edit-label">
                {labels.elements.settings.element_quiz_5.rules_label_grade_numeral_max}:
              </span>
            <input
              type="number"
              className="rdp-composer-element-edit-input"
              onChange={(e) => this.onGradeNumeralMaxChange(e.target.value)}
              onBlur={(e) => this.checkGradeNumeralMaxValue()}
              value={String(max)}
            />

            <span
              className="rdp-composer-element-edit-label">
                {labels.elements.settings.element_quiz_5.rules_label_grade_numeral_steps}:
              </span>
            <input
              type="number"
              className="rdp-composer-element-edit-input"
              min={1}
              max={String(max)}
              onChange={(e) => this.onGradeNumeralStepsChange(e.target.value)}
              onBlur={(e) => this.checkGradeNumeralStepsValue()}
              value={steps || 10}
            />
          </>
        }

        <div className="rdp-composer-element-divider"/>

        <span
          className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_feedback_behavior}</span>
        <select defaultValue={content.feedback.type} className="rdp-composer-element-edit-select"
                onChange={(e) => this.onChangeFeedbackType(e.target.value)}>
          <option
            value="default">{labels.elements.settings.element_quiz_5.settings_feedback_behavior_default_message_to_any_reply}</option>
          <option
            value="correct_incorrect">{labels.elements.settings.element_quiz_5.settings_feedback_behavior_specific_message_for_correct_and_or_incorrect_answer}</option>
          <option
            value="disabled">{labels.elements.settings.element_quiz_5.settings_feedback_behavior_no_feedback}</option>
        </select>

        <p
          className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_main_color}:</p>
        <ColorPicker labels={this.props.labels} color={content.primary_color}
                     onChange={(color) => this.onColorChange("primary_color", color)}/>

        <p
          className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_background_color}:</p>
        <ColorPicker labels={this.props.labels} color={content.background_color}
                     onChange={(color) => this.onColorChange("background_color", color)}/>

        <span
          className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_margin_top}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)}
               defaultValue={content.padding_top}/>

        <span
          className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_margin_bottom}</span>
        <input type="number" className="rdp-composer-element-edit-input px"
               onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}/>

        <span
          className="rdp-composer-element-edit-label">{labels.elements.settings.element_quiz_5.settings_label_label_font}:</span>
        {this.renderFont()}
      </div>
    }
  }

  render() {
    let activeTab = this.state.active_tab;
    let labels = this.props.labels;

    return (
      <div className="rdp-element-accordion-properties">
        <ul className="rdp-composer-element-edit-tabs">
          <li
            className={`rdp-composer-element-edit-tabs-item ${activeTab === "content" ? "active" : ""}`}
            onClick={() => this.onChangeTab("content")}>
            {labels.elements.common_settings.label_tab_content}
          </li>
          <li
            className={`rdp-composer-element-edit-tabs-item ${activeTab === "configurations" ? "active" : ""}`}
            onClick={() => this.onChangeTab("configurations")}>
            {labels.elements.common_settings.label_tab_settings}
          </li>
        </ul>
        <div className="rdp-composer-element-edit-body">
          {this.renderContent()}
        </div>
        {this.renderDAMWidget()}
      </div>
    );
  }
}
