import styled from "styled-components";

import { Button } from "@material-ui/core";

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  background-color: rgb(135 135 135 / 65%);
  background-position: 0 100px;
  z-index: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 500px;
  height: calc(100% - 30px);
  background-color: white;
  padding: 10px 16px;
`;

export const CheckRow = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 11px;

  cursor: pointer;

  :hover {
    background-color: #e7e7e7;
  }

  background-color: ${(props) =>
    props.finished && props.checked && props.correct
      ? "#aaf1aa !important"
      : props.finished && props.checked && !props.correct
      ? "#fba9a9 !important"
      : props.checked
      ? "#d1e5ff !important"
      : "#ddd"};

  .MuiCheckbox-root {
    color: ${(props) =>
      !props.finished ? "#6c6c6c" : props.correct ? "green" : "red"} !important;
    ${(props) => props.finished && !props.checked && "opacity: 0;"}
  }

  .MuiIconButton-root:hover {
    background-color: unset;
  }

  .MuiIconButton-label {
    transition: unset;
  }
`;

export const ButtonCheck = styled(Button)`
  &.MuiButton-root {
    background-color: rgb(111 147 227);
    color: white;
    text-transform: unset;
    font-weight: 600;
    width: fit-content;

    :hover {
      background-color: rgb(87 122 201);
    }
  }

  &.MuiButton-text {
    padding: 6px 11px;
  }

  svg {
    margin-left: -4px;
    margin-right: 5px;
  }
`;

export const ButtonRefresh = styled(ButtonCheck)`
  &.MuiButton-root {
    width: 36px;
    min-width: unset;
    border-radius: 50%;
    margin-left: 10px;
  }

  svg {
    position: relative;
    right: 1px;
    margin: 0;
  }
`;
