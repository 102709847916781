import React from "react";
import styled from "styled-components";

import Quill from "../quill/quill";

const QuillWithProps = ({ ...props }) => <Quill {...props} isProperties />;

const RelToolbarQuill = styled(QuillWithProps)`
  .ql-editor {
    padding: 0;
    overflow-y: unset;
    font-size: 18px;
  }

  * {
    font: revert;
  }

  .ql-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .ql-container .ql-tooltip {
    position: unset !important;
    padding: 0 !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin-top: 10px !important;
    transform: translateY(-10px);
    text-align: unset;
    z-index: 0;
  }

  .ql-picker-options {
    max-height: 140px !important;
  }

  .ql-toolbar {
    background-color: #eee !important;
    border-radius: 4px !important;
    transition: 0.2s;
  }

  .ql-bubble .ql-tooltip.ql-editing .ql-tooltip-editor {
    height: 100%;
    border-radius: 4px;
  }

  .ql-tooltip-arrow {
    display: none;
  }
`;

export default RelToolbarQuill;
