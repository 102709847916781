import styled from "styled-components";

export const Container = styled.div``;

export const AnchorInputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 25px;
`;

export const AnchorInput = styled.input`
  width: 80%;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 16px;
`;

export const RemoveButton = styled.button`
  position: relative;
  right: unset;
  top: unset;
  border: none;
`;

export const Popper = styled.div`
  position: absolute;
  top: 100%;
  background-color: #fff;
  position: absolute;
  top: 100%;
  background-color: #fff;
  width: 100%;
  left: 0;
  padding: 10px;
  box-shadow: 0 0 3px #ddd;
  z-index: 1211;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  max-height: 300px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      padding-left: 10px;
    }
  }
`;

export const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;
