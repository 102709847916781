import styled from 'styled-components';

export const Title = styled.label`
  font-weight: bold;
`;

export const Label = styled.label`
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: 12px;
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;