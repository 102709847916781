import React, { useEffect, useRef, useState } from "react";
import { useComposition } from "../composition";
import { ActionsMenu } from "../../../components/actions-menu";

export const CompositionActionComponentHandler = () => {
  const [stack, setStack] = useState([]);
  const [restores, setRestores] = useState([]);
  const {
    composition,
    editing,
    actionRef,
    onChangeComposition,
    beforeStructure,
    clearBeforeStructure,
    onSaveComposition,
  } = useComposition();

  const currentStructure = JSON.parse(JSON.stringify(composition.structure));
  const effectRan = useRef(false);
  const quillRender = useRef(false);

  const [initialStructure, setInitialStructure] = useState("[]");

  const isEdition = () => {
    const uid = window.location.href.split("composer/").pop();
    return Boolean(uid) && uid.length > 100;
  };

  const restoreEvent = (event) => {
    if (event.ctrlKey && event.code === "KeyY") return true;
    if (event.ctrlKey && event.shiftKey && event.code === "KeyZ") return true;
    if (event.metaKey && event.shiftKey && event.code === "KeyZ") return true;
    return false;
  };

  const undoEvent = (event) => {
    if (event.shiftKey) return false;
    if (event.ctrlKey && event.code === "KeyZ") return true;
    if (event.metaKey && event.code === "KeyZ") return true;
    return false;
  };

  const handleStructureWhenComponentMount = (e) => {
    if (!quillRender.current) {
      setStack([]);
      setRestores([]);
      quillRender.current = true;
    }
  };

  useEffect(() => {
    document.addEventListener("quillrendered", handleStructureWhenComponentMount);

    return () => {
      document.removeEventListener("quillrendered", handleStructureWhenComponentMount);
    };
  }, []);

  useEffect(() => {
    if (beforeStructure) {
      const seted = stack.includes(beforeStructure);
      if (!seted) {
        setStack((prev) => [...prev, beforeStructure]);
        if (restores.length) {
          setRestores([]);
        }
      }
      clearBeforeStructure();
    }
  }, [beforeStructure]);

  useEffect(() => {
    if (composition.structure && composition.structure.length && !effectRan.current && isEdition()) {
      effectRan.current = true;
      setInitialStructure(JSON.stringify(composition.structure));
    }
  }, [composition]);

  useEffect(() => {
    const serialized = JSON.stringify(currentStructure);
    const seted = stack.includes(serialized);
    if (!seted && !actionRef.current && serialized !== "[]" && !beforeStructure) {
      if (restores.length) {
        setRestores([]);
      }
      setStack((prev) => {
        return [...prev, serialized];
      });
    }
  }, [currentStructure, editing, restores, beforeStructure]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (undoEvent(event) && !restoreEvent(event)) {
        undo();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [stack, editing]);

  const undo = () => {
    actionRef.current = true;
    if (!stack.length || editing) return;
    const newStack = [...stack];
    const removedStructure = newStack.pop();

    if ((removedStructure !== "[]" && !isEdition()) || (isEdition() && removedStructure !== initialStructure))
      setRestores((prev) => [...prev, removedStructure]);
    else return;

    if (!newStack.length && initialStructure) newStack.push(initialStructure);

    const previousStructure = newStack[newStack.length - 1];

    setStack(newStack);

    onChangeComposition({
      ...composition,
      structure: JSON.parse(previousStructure),
    });

    actionClear();
  };

  const actionClear = () => {
    setTimeout(() => {
      actionRef.current = false;
    }, 200);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (restoreEvent(event) && !undoEvent(event)) {
        restore();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [stack, restores, beforeStructure]);

  const restore = () => {
    actionRef.current = true;
    const newRestores = [...restores];
    const structure = newRestores.pop();
    if (!structure) return;
    setRestores(newRestores);
    setStack((prev) => [...prev, structure]);
    onChangeComposition({
      ...composition,
      structure: JSON.parse(structure),
    });

    setTimeout(() => {
      actionRef.current = false; // TIMER TO CONTROL SET STACK
    }, 2000);
  };

  return (
    <ActionsMenu
      onClickSave={onSaveComposition}
      onClickRestore={restore}
      onClickUndo={undo}
      stack={stack}
      restores={restores}
      initialStructure={initialStructure}
    />
  );
  // return null;
};
