import React from "react";

const markers = {
  "01": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  ),
  "02": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
    </svg>
  ),
  "03": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M 11,4L 13,4L 13,15L 11,15L 11,4 Z M 13,18L 13,20L 11,20L 11,18L 13,18 Z" />
    </svg>
  ),
  "04": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />
    </svg>
  ),
  "05": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
    </svg>
  ),
  "06": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
    </svg>
  ),
  "07": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" />
    </svg>
  ),
  "08": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
    </svg>
  ),
  "09": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
    </svg>
  ),
  "10": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
    </svg>
  ),
  "11": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
    </svg>
  ),
  "12": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
    </svg>
  ),
  "13": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" style={{transform: "rotate(45deg)"}}>
      <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
    </svg>
  ),
  "14": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" style={{transform: "rotate(45deg)"}}>
      <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
    </svg>
  ),
  "15": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" style={{transform: "rotate(45deg)"}}>
      <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
    </svg>
  ),
  "16": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" style={{transform: "rotate(45deg)"}}>
      <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
    </svg>
  ),
  "17": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M10,7V9H12V17H14V7H10Z" />
    </svg>
  ),
  "18": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M9,7V9H13V11H11A2,2 0 0,0 9,13V17H11L15,17V15H11V13H13A2,2 0 0,0 15,11V9A2,2 0 0,0 13,7H9Z" />
    </svg>
  ),
  "19": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M15,15V13.5A1.5,1.5 0 0,0 13.5,12A1.5,1.5 0 0,0 15,10.5V9C15,7.89 14.1,7 13,7H9V9H13V11H11V13H13V15H9V17H13A2,2 0 0,0 15,15" />
    </svg>
  ),
  "20": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M9,7V13H13V17H15V7H13V11H11V7H9Z" />
    </svg>
  ),
  "21": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M9,7V13H13V15H9V17H13A2,2 0 0,0 15,15V13A2,2 0 0,0 13,11H11V9H15V7H9Z" />
    </svg>
  ),
  "22": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M11,7A2,2 0 0,0 9,9V15A2,2 0 0,0 11,17H13A2,2 0 0,0 15,15V13A2,2 0 0,0 13,11H11V9H15V7H11M11,13H13V15H11V13Z" />
    </svg>
  ),
  "23": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M11,17L15,9V7H9V9H13L9,17" />
    </svg>
  ),
  "24": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M11,13H13V15H11M11,9H13V11H11M11,17H13A2,2 0 0,0 15,15V13.5A1.5,1.5 0 0,0 13.5,12A1.5,1.5 0 0,0 15,10.5V9C15,7.89 14.1,7 13,7H11A2,2 0 0,0 9,9V10.5A1.5,1.5 0 0,0 10.5,12A1.5,1.5 0 0,0 9,13.5V15C9,16.11 9.9,17 11,17" />
    </svg>
  ),
  "25": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M13,17A2,2 0 0,0 15,15V9A2,2 0 0,0 13,7H11A2,2 0 0,0 9,9V11A2,2 0 0,0 11,13H13V15H9V17H13M13,11H11V9H13V11Z" />
    </svg>
  ),
  "26": props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path fill="currentColor" d="M11,7A2,2 0 0,0 9,9V15A2,2 0 0,0 11,17H13A2,2 0 0,0 15,15V9A2,2 0 0,0 13,7H11M11,9H13V15H11V9Z" />
    </svg>
  ),
}

export default markers;
