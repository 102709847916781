import React, { useEffect, useState } from "react";
import uuidv1 from "uuid/v1";
import { Choices } from "./Choices";
import { SkillGroup } from "../../../components/skills/SkillGroup";

export const Questions = ({ content: composerContent, labels, onContentChange }) => {
  const [content, setContent] = useState(composerContent);

  useEffect(() => {
    onContentChange(content);
  }, [content]);

  const onChangeQuestion = (question) => {
    setContent((prev) => ({
      ...prev,
      questions: prev.questions.map((item) => {
        if (question.id !== item.id) return item;
        return question;
      }),
    }));
  };

  const onAddQuestion = () => {
    setContent((prev) => ({
      ...prev,
      questions: [...prev.questions, genQuestion()],
    }));
  };

  const onRemoveQuestion = (question) => {
    setContent((prev) => ({
      ...prev,
      questions: prev.questions.filter((item) => item.id !== question.id),
    }));
  };

  return (
    <>
      <ul>
        {content.questions.map((question, index) => {
          return (
            <li key={question.id} className="element-accordion-properties-item">
              <span class="element-accordion-properties-item-remove" onClick={() => onRemoveQuestion(question)}></span>
              <span className="rdp-composer-element-edit-label">Questão - {index + 1}</span>

              <input
                onChange={(e) => onChangeQuestion({ ...question, title: e.target.value })}
                defaultValue={question.title}
                type="text"
                className="rdp-composer-element-edit-input"
                style={{
                  border: "none",
                  borderBottom: "1px solid #ddd",
                }}
              />

              <span className="rdp-composer-element-edit-label">Mostrar questão no momento (hh:mm:ss)</span>

              <div style={{ display: "flex", alignItems: "center", gap: "10px", margin: "10px 0 30px" }}>
                {["hours", "minutes", "seconds"].map((item) => (
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <input
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                      placeholder="Horas"
                      type="number"
                      className="rdp-composer-element-edit-input"
                      onBlur={(e) => onChangeQuestion({ ...question, [item]: Number(e.target.value) })}
                      onKeyUp={(e) => e.key === "Enter" && onChangeQuestion({ ...question, [item]: e.target.value })}
                      defaultValue={question[item] || 0}
                    />
                    {item !== "seconds" && ":"}
                  </div>
                ))}
              </div>

              <div style={{ borderTop: '1px solid #dddddd8f', padding: '15px 0'}}>
                <SkillGroup question={question} onChangeQuestion={onChangeQuestion} />
              </div>
              
              <Choices
                question={question}
                labels={labels}
                onChangeQuestion={(newQuestion) =>
                  onContentChange({
                    ...content,
                    questions: content.questions.map((item) => {
                      if (item.id === newQuestion.id) return newQuestion;
                      return item;
                    }),
                  })
                }
              />
            </li>
          );
        })}
        <button className="element-accordion-properties-choice-new" onClick={() => onAddQuestion()} type="button">
          Adicionar questão
        </button>
      </ul>
    </>
  );
};

const genQuestion = () => ({
  id: uuidv1(),
  title: "Lorem ipsum set dollor?",
  seconds: 5,
  minutes: 0,
  hours: 0,
  anchor_time: {
    correct: 75,
    error: 5,
  },
  choices: [
    {
      id: uuidv1(),
      correct: true,
      text: "Sed ut perspiciatis unde omnis iste natus",
      value: 5,
      skills: [
        {
          id: uuidv1(),
          value: "",
          reference: null,
        },
      ],
    },
    {
      id: uuidv1(),
      correct: false,
      text: "Sed ut perspiciatis unde omnis iste natus",
      value: 10,
      skills: [
        {
          id: uuidv1(),
          value: "",
          reference: null,
        },
      ],
    },
  ],
});
