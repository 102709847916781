import React, { Component } from 'react';
import './element-iframe.css';

export default class ElementIframe extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    var iframeContainerStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }

    var iframeStyle = {
      width: content.width + "px",
      height: content.height + "px"
    }

    return (<div className={this.props.className} style={iframeContainerStyle}>
      {headerActionsTemplate}
      <iframe style={iframeStyle} src={content.url} allow="fullscreen" />
      {footerActionsTemplate}
    </div>)
  }
}
