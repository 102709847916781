import styled, {css} from "styled-components";

export const AttachmentItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  height: auto;
  transition: all 0.3s ease-in-out;
`;

export const Body = styled.div`
  width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out, padding 0s ease-in-out;
  
  ${props => props.show && css`
    opacity: 1;
    height: auto;
    overflow: auto;
  `}
`;

export const FieldContainer = styled.div`
  padding-top: 10px;
  margin-bottom: 25px;
`;

export const ChangeFileButton = styled.button`
  background-color: #2d7b45;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 11px 26px;
  border: 0;
`;

export const Information = styled.a`
  display: block;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover, &:focus {
    text-decoration: underline;
    color: #00643e;
    border: 1px solid #00643e;
  }

  &:visited {
    color: initial;
  }

  &:focus {
    outline: 2px solid #00643e !important;
    outline-offset: 2px;
  }
`;

export const FileName = styled.p`
  margin: 5px;
  font-weight: bold;
  max-width: 300px;
  color: inherit;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
`;

export const FileSize = styled.p`
  margin: 5px;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: inherit;
`;

