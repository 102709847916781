class Url {
  constructor(url) {
    this.url = url;
  }

  getQueryParams() {
    const params = {};
    this.url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => {
      params[key] = value;
    });
    return params;
  }
}

export default Url;