import styled, {css} from "styled-components";

export const MoveButton = styled.div`
  cursor: grab;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin: 0 10px;
  overflow: hidden;
  color: black;
  pointer-events: auto;
  
  &:active {
    cursor: grabbing;
  }
`;

export const SpotItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);
  min-height: 32px;
  height: auto;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out;
  padding: 8px;
`;

export const SpotHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  pointer-events: all;
`;

export const SpotItemHeadLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SpotItemHeadRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SpotItemBody = styled.div`
  width: 100%;
  height: 0;
  opacity: 0;
  border-top: 1px solid #ddd;
  overflow: hidden;
  transition: all 0.3s ease-in-out, padding 0s ease-in-out;
  
  ${props => props.show && css`
    opacity: 1;
    height: auto;
    overflow: auto;
    padding: 20px 8px;
    margin-top: 10px;
  `}
`;

export const IconDown = styled.svg`
  ${props => props.isExpanded && css`
    transform: rotate(180deg);
  `} 
`

export const IconRemove = styled.svg`
  pointer-events: all;
  margin: 0 10px;
  cursor: pointer;
  fill: black;
  color: black;
  
  &:hover {
    fill: #2d7b45;
    color: #2d7b45;
  }
`

export const FieldContainer = styled.div`
  padding-top: 10px;
  margin-bottom: 25px;
`;

export const SpotIconsList = styled.ul`
  width: 100%;
  background: #eee;
  min-height: 150px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

export const SpotIcon = styled.li`
  cursor: pointer;
  height: 43px;
  width: 43px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  
  ${props => props.selected && css`
    border: 2px solid #2d7b45;
  `}
`;

export const SpotIconPreview = styled.button`
  cursor: pointer;
  height: 43px;
  width: 43px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  color: ${props => props.color || '#2d7b45'};

  &:hover {
    border: 2px solid ${props => props.color || "#2d7b45"};
  }

  &:focus {
    background: ${props => props.color || "#2d7b45"};
    color: white;
    outline: none !important;
    border-color: white;

    &:hover {
      border: 2px solid #bbb;
    }
  }
`;

export const SpotIconPreviewContainer = styled.li`
  border: 1px solid black;
  height: 80px;
  width: 80px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.color || '#2d7b45'};
  margin-right: 4px;
`;

export const SpotImageUpload = styled.button`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
  background: #eee;
  border: 1px dashed #ddd;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  
  &:hover {
    border: 1px solid #2d7b45;
    background: #bbb;
  }
`;

export const SpotImageRemoveButton = styled.button`
  background-color: #2d7b45;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 11px 26px;
  border: 0;
`;

export const SpotAudioPreview = styled.audio`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
`;

export const AddButton = styled.button`
  border: 1px solid #a3cb66;
  border-radius: 4px;
  color: #80b33a;
  padding: 12px 20px 12px 35px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 9px;
  cursor: pointer;
  position: relative;
  transition: 0.2s all linear;
  margin: 8px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

