import React from "react";
import * as S from "./quiz-textarea.styled";

function QuizTextarea(props) {
  const {
    disabled,
    primaryColor,
    onChange = (value) => {},
    defaultValue = "",
  } = props;
  const [value, setValue] = React.useState(defaultValue);

  return (
    <S.TextAreaContainer>
      <S.TextArea
        tabIndex={1}
        value={value}
        onChange={event => {
          setValue(event.target.value);
          onChange(event.target.value);
        }}
        disabled={disabled}
        primaryColor={primaryColor}
        rows={10}
      />
    </S.TextAreaContainer>
  );
}

export default QuizTextarea;