import styled, { css } from "styled-components";

export const Root = styled.div`
  background-color: ${props => props.backgroundColor || "#fff"};
  padding-top: ${props => props.paddingTop || "0"}px;
  padding-bottom: ${props => props.paddingBottom || "0"}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const Carousel = styled.div`
  width: 600px;
  text-align: center;
  overflow: hidden;
  position: relative;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Slides = styled.ul`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  pointer-events: none;
  
  @media (max-width: 800px) {
    pointer-events: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
`;

export const Slide = styled.li`
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 600px;
  margin-right: 50px;
  border-radius: 10px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Item = styled.div`
  width: 100%;
  max-width: 760px;
  display: block;
  text-align: center;
  margin: 30px auto;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    height: auto;
    box-shadow: none
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.4);
`;

export const AuthorInfo = styled.div`
  display: block;
  margin-top: 10px;
`;

export const AuthorName = styled.div`
  color: #fff;
  font-family: Prompt-Regular;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: inline-block;
  vertical-align: middle;
`;

export const TextContainer = styled.div`
  position: relative;
  
  .element-quote-6-text {
    color: #fff;
    margin-top: 30px;
    width: 100%;
    display: block;

    @media (max-width: 600px) {
      padding-left: 5px;
      padding-right: 5px;
    }

    .ql-editor {
      padding: 0;
      line-height: inherit;
      overflow: hidden;
    }

    .ql-editor p:before,
    .ql-editor p:after {
      content: '"';
      color: #313537;
      font-size: 16px;
    }

    .ql-editor * {
      line-height: 150%;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 800px;
`;

export const ControlsLeft = styled.div`
  color: black;
  width: 50px;
  height: 100px;
  margin-top: calc(-50% - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  @media (max-width: 800px) {
    opacity: 0;
    pointer-events: none;
  }
`;

export const ControlsLeftArrow = styled.svg``;

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Page = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  pointer-events: auto;
  border-radius: 100%;
  background-color: ${props => props.primaryColor || "#2d7b45"};
  color: white;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  opacity: 0.5;
  
  ${props => props.active && css`
    opacity: 1;
  `}
`;

export const ControlsRight = styled.div`
  color: black;
  width: 50px;
  height: 100px;
  margin-top: calc(-50% - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 800px) {
    opacity: 0;
    pointer-events: none;
  }
`;

export const ControlsRightArrow = styled.svg``;