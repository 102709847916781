import styled, {css} from "styled-components";

export const ElementHotspots1 = styled.div`
  padding-top: ${props => props.paddingTop || "30"}px;
  padding-bottom: ${props => props.paddingBottom || "30"}px;
  overflow: hidden;
  position: relative;
  background-color: ${props => props.backgroundColor || "transparent"};

  /*region element max-width*/
  /*SM*/
  &.max-w-sm .element-hotspots-1__stage  {
    max-width: 760px;
  }
  /*MD*/
  &.max-w-md .element-hotspots-1__stage  {
    max-width: 1160px;
  }
  /*LG*/
  &.max-w-lg .element-hotspots-1__stage  {
    max-width: 1600px;
  }
  /*FULL*/
  &.max-w-full .element-hotspots-1__stage  {
    max-width: calc(100% - 60px);
  }
  /*endregion*/
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Stage = styled.figure`
  height: auto;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  
  ${({width}) => width !== "page_width" && css`
    max-width: ${width || "800px"} !important;
  `}
`;

export const Img = styled.img`
  width: 100%;
  height: auto;
  display: block;
  cursor: ${props => props.showCursor ? "crosshair" : "default"};
  margin-left: auto;
  margin-right: auto;
`;