import React, { Component } from 'react';
import {UserService} from '../../services/user';
import {VideoPlayer} from '../../components';
import './element-video.css';

export default class ElementVideo extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  getVideoUrl(video) {
    var accessToken = UserService.getUser().access_token;
    if (video !== "https://pocs.digitalpages.com.br/rdpcomposer/media/video-1/video-1.mp4") {
      video += "&access_token=" + accessToken;
    };
    return video;
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    var imageStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }
    const videoJsOptions = {
      responsive: true,
      autoplay: true,
      controls: true,
      playbackRates: [0.5, 1, 1.5, 2],
      sources: [{
        src: this.getVideoUrl(content.video)
      }]
    }

    return (<div className={this.props.className} style={imageStyle}>
      {headerActionsTemplate}
      {/* <VideoPlayer { ...videoJsOptions } /> */}
      <video controls src={this.getVideoUrl(content.video)}></video>
      {footerActionsTemplate}
    </div>)
  }
}
