import $ from 'jquery';
import React, { Component } from 'react';
import {DamService} from '../../services/dam';
import {UserService} from '../../services/user';
import {LoginService} from '../../services/login';
import './rdp-dam-widget.css';
import RDP_CONFIG from '../../config';

export default class RDPDamWidget extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onDamSelect = this.onDamSelect.bind(this);
    this.handler = this.handler.bind(this);

    this.state = {
      "images": [],
      "videos": [],
      "audios": [],
      "streams": [],
      "loading": true,
      "uploading": false,
      "progress": 0
    };
  }

  componentWillMount() {
    // this.getDAMAssets();
  }

  getProject() {
    var userData = UserService.getUser();
    var activeProject = UserService.getActiveProject();
    var type = userData.type;
    var accessToken = userData.access_token;
    var a = RDP_CONFIG;
    return {
      "user_data": userData,
      "active_project": activeProject,
      "type": type,
      "access_token": accessToken
    };
  }

  onChange(type, asset) {
    this.props.onAssetChange(asset);
  }

  onDamSelect(contentType, uid, data) {
    var config = RDP_CONFIG;
    var path = contentType === "digitalpages/video" ? "content/default/target" : "content";
    // https://api.digitalpages.com.br/storage/v1.0/content?uid=H4sIAAAAAAAACgBCAb3-AwAAAS0NAAAAADIwMGJkZDU4PpuVivfpdGtC4LYMHuBRWx7QHYERUNKRMtbCYeafqnRY_QDnUGCgFyBDOOMYN-8Y3plDeKcKjVA61boxTHQpVjEly0GkEzVXXu94Sg_vHT7KBDYzvSlLcI7GrBEc1AAZ8w5l1VVOiDmUWM8Xix2yaGpR4j5uWMTK1rjHm1HSkOdZzU4b_PXlIsW3ZDNbTzXQsw_oivqUTQcqbrF8ZuczVt43nhZXVsqyi4JrHlKl3HI8KVFiRvhHpj1rdsxq5tELJd2Yphco0EbzNQ5euEMPanur0gEhmpyY2Lw0JCqh4f6zOgX4MBguNmdO6ttSjgjaPddRjDVPTh6r1-iQtEkCBUinbK6YYswi3V00Lae8FL-9RazkDRSPQmRLNijPUahq1SnBTg0InEx3TuVnInzvMgAAAP__&project_key=394159a7b39f4e63aedb1a674d8b8055&api_env=production
    var asset = `https://${config.domain}/storage/${config.apiVersion}/${path}?uid=${uid}&project_key=${config.projectKey}&api_env=${config.apiEnv}`

    window.removeEventListener("message", this.handler);
    this.props.onAssetChange(asset, data);
  }

  componentDidMount() {
    window.addEventListener("message", this.handler);
    // return () => window.removeEventListener("message", this.handler);
  }

  handler(event) {
    if (event.data.content_type !== undefined) {
      this.onDamSelect(event.data.content_type, event.data.uid, event.data);
    }
  }

  getDAMImages() {
    var _this = this;
    var userData = UserService.getUser();
    var activeProject = UserService.getActiveProject();
    var type = userData.type;
    var accessToken = userData.access_token;
    var filter = "(content_type eq 'image/jpeg' or content_type eq 'image/jpg' or content_type eq 'image/gif' or content_type eq 'image/png') and (project_uid eq '" + activeProject.uid + "')";

    DamService.searchContent(filter, "", type, accessToken).then(function(data) {
      var assets = data.results;
      for (var i = 0; i < assets.length; i++) {
        var id = assets[i].document.content_uid;
        assets[i].image = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${id}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
      };
      _this.setState({
        "loading": false,
        "images": assets
      });
    });
  }

  setAccessToken(asset) {
    var accessToken = UserService.getUser().access_token;
    return asset + "&access_token=" + accessToken;
  }

  getDAMVideos() {
    var _this = this;
    var userData = UserService.getUser();
    var activeProject = UserService.getActiveProject();
    var type = userData.type;
    var accessToken = userData.access_token;
    var filter = "(content_type eq 'digitalpages/video' or content_type eq 'video/mp4') and (project_uid eq '" + activeProject.uid + "')";
    
    DamService.searchContent(filter, "", type, accessToken).then(function(data) {
      var assets = data.results;
      for (var i = 0; i < assets.length; i++) {
        var id = assets[i].document.content_uid;
        var path = assets[i].document.content_type === "digitalpages/video" ? "content/default/target" : "content";
        assets[i].video = `https://${RDP_CONFIG.domain}/storage/v1.0/${path}?uid=${id}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
      };
      _this.setState({
        "loading": false,
        "videos": assets
      });
    });
  }

  getDAMAudios() {
    var _this = this;
    var userData = UserService.getUser();
    var activeProject = UserService.getActiveProject();
    var type = userData.type;
    var accessToken = userData.access_token;
    var filter = "(content_type eq 'audio/mpeg') and (project_uid eq '" + activeProject.uid + "')";
    
    DamService.searchContent(filter, "", type, accessToken).then(function(data) {
      var assets = data.results;
      for (var i = 0; i < assets.length; i++) {
        var id = assets[i].document.content_uid;
        assets[i].audio = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${id}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
      };
      _this.setState({
        "loading": false,
        "audios": assets
      });
    });
  }

  getDAMStreams() {
    var _this = this;
    var userData = UserService.getUser();
    var activeProject = UserService.getActiveProject();
    var type = userData.type;
    var accessToken = userData.access_token;
    var filter = "(content_type eq 'application/octet-stream') and (project_uid eq '" + activeProject.uid + "')";
    
    DamService.searchContent(filter, "", type, accessToken).then(function(data) {
      var assets = data.results;
      for (var i = 0; i < assets.length; i++) {
        var id = assets[i].document.content_uid;
        assets[i].file = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${id}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.apiEnv}`;
      };
      _this.setState({
        "loading": false,
        "streams": assets
      });
    });
  }

  getDAMAssets() {
    this.setState({
      "loading": true,
      "uploading": false,
      "percent": 0
    });

    var type = this.props.type;
    if (type === "image") {
      return this.getDAMImages()
    } else if (type === "video") {
      return this.getDAMVideos()
    } else if (type === "audio") {
      return this.getDAMAudios()
    } else if (type === "streams") {
      return this.getDAMStreams();
    }
  }

  renderDAMImages() {
    var _this = this;
    var images = this.state.images;

    return <ul className="rdp-dam-widget-images">
      {images.map(function(image, index) {
        return (<li data-image={image.id} key={index} style={{ backgroundImage: `url(${_this.setAccessToken(image.image)})` }} onClick={() => _this.onChange("image", image.image)}></li>);
      })}
    </ul>;
  }

  renderDAMVideos() {
    var _this = this;
    var videos = this.state.videos;

    return <ul className="rdp-dam-widget-videos">
      {videos.map(function(video, index) {
        return (<li data-video={video.id} key={index} onClick={() => _this.onChange("video", video.video)}>
          <video src={_this.setAccessToken(video.video)}></video>
        </li>);
      })}
    </ul>;
  }

  renderDAMAudios() {
    var _this = this;
    var audios = this.state.audios;

    return <ul className="rdp-dam-widget-audios">
      {audios.map(function(audio, index) {
        return (<li data-audio={audio.id} key={index} onClick={() => _this.onChange("audio", audio.audio)}>
          <audio controls src={_this.setAccessToken(audio.audio)}></audio>
          <span className="rdp-dam-widget-select">{_this.props.labels.elements.common_settings.label_select_file}</span>
        </li>);
      })}
    </ul>;
  }

  renderPreloader() {
    return <div className="rdp-dam-widget-preloader"></div>;
  }

  renderDAMAssets() {
    var type = this.props.type;

    if (this.state.loading === true) {
      return this.renderPreloader();
    } else {
      if (type === "image") {
        return this.renderDAMImages()
      } else if (type === "video") {
        return this.renderDAMVideos()
      } else if (type === "audio") {
        return this.renderDAMAudios()
      } else if (type === "streams") {
        return this.renderDAMStreams()
      }
    }
  }
  
  uploadFile(e) {
    var uid = JSON.parse(window.localStorage.getItem("rdp-composer-active-project")).connectors[0].uid;
    var url = `https://${RDP_CONFIG.domain}/storage/v1.0/upload/connector/uid/${uid}`;
    var _this = this;
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    var fd = new FormData();
    var files = e.target.files[0];
    fd.append('file', files);

    var userData = JSON.parse(window.localStorage.getItem("rdp-composer-user-data"));
    var type = userData.type;
    var accessToken = userData.access_token;

    $.ajax({
      url: url,
      type: "POST",
      data: fd,
      contentType: false,
      processData: false,
      headers: {
        "Authorization": type + " " + accessToken,
        "Project-Key" : RDP_CONFIG.projectKey,
        "Api-Env" : RDP_CONFIG.apiEnv
      },
      xhr: function(){
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener("progress", function(evt){
          if (evt.lengthComputable) {
            var percentComplete = parseInt((evt.loaded / evt.total) * 100);
            _this.calculateUploadProgress(percentComplete);
          }
        }, false);
        return xhr;
      },
      success: function(response) {
        _this.getDAMAssets();
      }
    });
  }

  calculateUploadProgress(percent) {
    var _this = this;
    _this.setState({
      "uploading": true,
      "percent": percent
    });
  }

  getUrl() {
    var based = "https://api.digitalpages.com.br";
    if (RDP_CONFIG.domain) based = "https://" + RDP_CONFIG.domain;

    // if (window.location.href.search("/product/manager") > -1) {
    //   based = window.location.protocol + "//" + window.location.host;
    // };
    return based;
  }


  getCurrentLanguage() {
    return window.localStorage.getItem(window.location.host + "rdp-composer-current-language")
  }

  getEmbedType() {
    var type = this.props.type;
    if (type === "image") {
      return "images";
    } else if (type === "video") {
      return "videos";
    } else if (type === "audio") {
      return "audios";
    } else if (type === "attachments") {
      return "attachments";
    } else if (type === "streams") {
      return "streams";
    }
  }

  render() {
    return (
      <div className="rdp-dam-widget-iframe-modal">
        <div className="rdp-dam-widget-iframe-overlay" onClick={() => this.props.onClose()}></div>
        <iframe
          className="rdp-dam-widget-iframe"
          src={`${this.getUrl()}/product/dam/current/${RDP_CONFIG.projectKey}/p/-/${RDP_CONFIG.apiEnv.charAt(0)}/#/auth-with-token/${this.getCurrentLanguage()}/${this.getEmbedType()}/${this.getProject().active_project.uid}/${this.getProject().access_token}`}
        />
      </div>
    );
  }
}
