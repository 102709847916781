import React, { Component } from 'react';
import { UserService } from '../../services/user';
import './element-image-with-text-properties.css';
import {
  RDPDamWidget, ColorPicker, FontPicker
} from '../../components';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

export default class ElementImageWithTextProperties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "dam_widget_opened": false
    }

    this.onImageChange = this.onImageChange.bind(this);
    this.onOpacityChange = this.onOpacityChange.bind(this);
    this.onOpacityColorChange = this.onOpacityColorChange.bind(this);
    this.onDividerColorChange = this.onDividerColorChange.bind(this);
    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
    this.onLegendChange = this.onLegendChange.bind(this);
    this.onLegendColorChange = this.onLegendColorChange.bind(this);
  }

  onLegendChange(e) {
    var content = this.state.content;
    content.legend = e.currentTarget.value !== "" ? e.currentTarget.value : content.legend;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onLegendColorChange(color) {
    var content = this.state.content;
    content.legend_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onImageChange() {
    this.setState({
      "dam_widget_opened": true
    })
  }

  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange(color) {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onOpacityColorChange(color) {
    var content = this.state.content;
    content.opacity_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onDividerColorChange(color) {
    var content = this.state.content;
    content.divider_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange(asset) {
    var content = this.state.content;
    content.image = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onOpacityChange(opacity) {
    var content = this.state.content;
    content.opacity = opacity;
    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onRDPDamWidgetClose() {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget() {
    if (this.state.dam_widget_opened === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="image"
        onClose={() => this.onRDPDamWidgetClose()}
        onAssetChange={(asset) => this.onAssetChange(asset)}
      />;
    }
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  onFontChange(font) {
    var content = this.state.content;
    content.font_family = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  renderFont() {
    var content = this.state.content;
    var labels = this.props.labels;

    if (content.font_family) {
      return <FontPicker font={content.font_family} onFontChange={(font) => this.onFontChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontChange(font)} noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;

    return (<div className="rdp-element-image-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className="rdp-composer-element-edit-tabs-item active">{labels.elements.common_settings.label_tab_settings}</li>
      </ul>
      <div className="rdp-composer-element-edit-body">
      <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image_with_text.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)} defaultValue={content.content_title}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image_with_text.settings_label_background_image}:</span>
        <img className="rdp-composer-element-image" onClick={() => this.onImageChange()} alt="Composer" src={this.getImageUrl(content.image)} />

        <span className="rdp-composer-element-edit-label">{labels.elements.common_settings.legend_text}</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={this.onLegendChange} defaultValue={content.legend}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.common_settings.legend_color}</span>
        <ColorPicker labels={this.props.labels} color={content.legend_color} onChange={this.onLegendColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image_with_text.settings_label_background_color}</span>
        <ColorPicker labels={this.props.labels} color={content.background_color} onChange={this.onBackgroundColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image_with_text.settings_label_margin_top}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image_with_text.settings_label_margin_bottom}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image_with_text.settings_label_label_opacity}</span>
        <select defaultValue={content.opacity || "50%"} className="rdp-composer-element-edit-select" onChange={(e) => this.onOpacityChange(e.target.value)}>
          <option value="0%">0%</option>
          <option value="10%">10%</option>
          <option value="20%">20%</option>
          <option value="30%">30%</option>
          <option value="40%">40%</option>
          <option value="50%">50%</option>
          <option value="60%">60%</option>
          <option value="70%">70%</option>
          <option value="80%">80%</option>
          <option value="90%">90%</option>
          <option value="100%">100%</option>
        </select>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image_with_text.settings_label_label_opacity_color}</span>
        <ColorPicker labels={this.props.labels} color={content.opacity_color} onChange={this.onOpacityColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image_with_text.settings_label_label_divider_color}</span>
        <ColorPicker labels={this.props.labels} color={content.divider_color} onChange={this.onDividerColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image_with_text.settings_label_label_font}:</span>
        {this.renderFont()}

        <PreferenceRequiredInteraction
          title={labels.elements.settings.preference_required_interaction.title}
          label={labels.elements.settings.preference_required_interaction.label}
          labels={labels}
          content={content}
          onContentChange={c => {
            this.setState({ content: c });
            this.props.onContentChange(c);
          }}
        />
      </div>
      {this.renderDAMWidget()}
    </div>);
  }
}
