import React, { Component } from 'react';
import { UserService } from '../../services/user';
import { LoginService } from '../../services/login';
import { RDP_TRANSLATE } from '../../translate/translate';
import RDP_CONFIG from '../../config';
import './login.css';
import uuidv1 from "uuid/v1";

import {
} from '../../components';

export default class ViewLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "loading": false,
      "active_device": "mobile",
      "labels": RDP_TRANSLATE[RDP_CONFIG.language]().login,
      "render_languages": window.localStorage.getItem(window.location.host + "rdp-composer-current-language") === null
    }

    this.getPreloader = this.getPreloader.bind(this);
    this.login = this.login.bind(this);
    this.handleKeyDown = this.login.bind(this);
  }

  componentWillMount() {
    this.redirectToHome();
    var a = RDP_TRANSLATE[RDP_CONFIG.language]();
    console.log(a)
  }

  redirectToHome() {
    // var slug = this.props.match.params.slug;
    // if (window.localStorage.getItem("rdp-composer-auth-" + slug) !== null) {
    //   window.location = window.location.origin + "/#/" + slug;
    // };
    if (window.localStorage.getItem("rdp-composer-user-data") !== null) {
      // window.location = window.location.origin + "/#/";
      window.location = window.location.origin + window.location.pathname + "#/";
    };
  }

  getPreloader() {
    return this.state.loading ? <div className="rdp-composer-login-sidebar-preloader"></div> : "";
  }

  async login(e) {
    var self = this;
    var login = document.getElementById("rdp-composer-login-username").value;
    var password = document.getElementById("rdp-composer-login-password").value;
    this.setState({
      "loading": true
    });

    try{
      let loginResult = await LoginService.login(login, password);

      UserService.setUser(loginResult);
      UserService.setUuid(uuidv1());
      self.getProjectsList();
    }catch(e)
    {
      console.log(e);
    }

    self.setState({"loading": false});

    if (e != null && e != undefined) e.preventDefault();
  }

  async getProjectsList() {
    var userData = UserService.getUser();
    var type = userData.type;
    var accessToken = userData.access_token;

    var projects = await LoginService.getProjectsList(type, accessToken);
    UserService.setProjects(projects);

    LoginService.getUserInfo(type, accessToken).then(function(data) {
      data.projects = projects;
      UserService.setUserInfo(data);
      // UserService.setProjects(data.projects);
      UserService.setActiveProject(data.projects[0].uid, type, accessToken).then(function(projectInfo) {
        // window.location = window.location.origin + "/#/";
        window.location = window.location.origin + window.location.pathname + "#/";
      })
    }, function (error) {
      // self.setState({
      //   "loading": false
      // });
    })
  }

  onSelectLanguage(language) {
    RDP_CONFIG.language = language;
    this.setState({
      "labels": RDP_TRANSLATE[language]().login,
      "render_languages": false
    });
    window.localStorage.setItem(window.location.host + "rdp-composer-current-language", language);
  }

  renderLanguages() {
    if (this.state.render_languages) {
      return <div className="rdp-composer-login-sidebar-languages">
        <div className="rdp-composer-login-sidebar-languages-overlay">
          <ul className="rdp-composer-login-sidebar-languages-items">
            <li className="rdp-composer-login-sidebar-languages-item" onClick={() => this.onSelectLanguage("en")}>
              <span className="rdp-composer-login-sidebar-languages-item-label">English</span>
              <span className="rdp-composer-login-sidebar-languages-item-icon en"></span>
            </li>
            <li className="rdp-composer-login-sidebar-languages-item" onClick={() => this.onSelectLanguage("pt_br")}>
              <span className="rdp-composer-login-sidebar-languages-item-label">Português Brasil</span>
              <span className="rdp-composer-login-sidebar-languages-item-icon pt_br"></span>
            </li>
          </ul>
        </div>
      </div>
    }
  }

  render() {
    var labels = this.state.labels;

    return (<div className="rdp-composer-login">
      <div className="rdp-composer-login-sidebar">
        <div className="rdp-composer-login-sidebar-logo"></div>
        <div className="rdp-composer-login-sidebar-form">
          <span className="rdp-composer-login-title">{labels.messages.form_title}</span>

          <div className="rdp-composer-login-sidebar-input">
            <label>{labels.inputs.username_label}</label>
            <input id="rdp-composer-login-username" type="text"></input>
          </div>
          <div className="rdp-composer-login-sidebar-input">
            <label>{labels.inputs.password_label}</label>
            <form onSubmit={(e) => this.login(e)}>
              <input id="rdp-composer-login-password" type="password"></input>
            </form>
          </div>
          <span onClick={(e) => this.login(e)} className="rdp-composer-login-sidebar-submit">{labels.buttons.sign_in}</span>
        </div>
        {this.renderLanguages()}
        {this.getPreloader()}
      </div>
    </div>);
  }
}
