import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { DragIcon, ReundoIcon, SaveIcon, UndoIcon } from "./icons";
import ReactTooltip from "react-tooltip";
import { useWindowResize } from "../../hooks/useWindowResize";

const isEmbed = window.location.href.includes('embed');

const styles = {
  button: {
    background: "none",
    border: "none",
    color: isEmbed ? "#764ab0" : "#2d7b45",
    cursor: "pointer",
  },
  container: {
    padding: "0.5rem",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
    position: "absolute",
  },
};

export const ActionsMenu = ({ onClickUndo, onClickRestore, stack, restores, initialStructure, onClickSave }) => {
  const [_, setActiveDrags] = useState(0);
  const { height } = useWindowResize();
  const [position, setPosition] = useState({ x: 5, y: height - 155 });

  useEffect(() => {
    setPosition((prev) => ({
      ...prev,
      y: height - 155,
    }));
  }, [height]);

  const onStart = () => {
    setActiveDrags((prev) => prev + 1);
  };

  const onStop = () => {
    setActiveDrags((prev) => prev - 1);
  };

  const disableds = {
    undo: stack.length === 0 || (stack.length === 1 && stack[0] === initialStructure),
    restore: restores.length === 0 || (restores[restores.length - 1] === initialStructure),
  };

  function getOperatingSystem() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes("windows")) {
      return "Windows";
    } else if (userAgent.includes("mac os") || userAgent.includes("macos")) {
      return "macOS";
    } else if (userAgent.includes("linux")) {
      return "Linux";
    } else {
      return "Unknown";
    }
  }

  const operatingSystem = getOperatingSystem();

  const command = operatingSystem === "macOS" ? "Cmd" : "Ctrl";

  const onDragMenu = (e) => {
    setPosition({
      x: e.x - 15,
      y: e.y - 126,
    });
  };

  return (
    <Draggable
      allowAnyClick={false}
      handle=".handle"
      onStart={onStart}
      onStop={onStop}
      position={position}
      onDrag={onDragMenu}
    >
      <div style={styles.container}>
        <div style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
          <ReactTooltip
            id="save-composition"
            className="rdp-tooltip"
            multiline={true}
            place="bottom"
            type="dark"
            backgroundColor="#1b1b1bda"
          />
          <ReactTooltip
            id="undo-composition"
            className="rdp-tooltip"
            multiline={true}
            place="bottom"
            type="dark"
            backgroundColor="#1b1b1bda"
          />
          <ReactTooltip
            id="restore-composition"
            className="rdp-tooltip"
            multiline={true}
            place="bottom"
            type="dark"
            backgroundColor="#1b1b1bda"
          />

          <button
            data-for="save-composition"
            data-tip={`Salvar composição (${command} + S)`}
            style={{ ...styles.button }}
            onClick={onClickSave}
          >
            <SaveIcon />
          </button>
          <button
            data-for="undo-composition"
            data-tip={`Desfazer ações (${command} + Z)`}
            style={{
              ...styles.button,
              ...(disableds.undo && {
                color: "#ccc",
              }),
            }}
            onClick={onClickUndo}
            disabled={stack.length === 0}
          >
            <UndoIcon />
          </button>
          <button
            style={{
              ...styles.button,
              ...(disableds.restore && {
                color: "#ccc",
              }),
            }}
            onClick={onClickRestore}
            disabled={restores.length === 0}
            data-for="undo-composition"
            data-tip={`Refazer ações (${command} + Shift + Z)`}
          >
            <ReundoIcon />
          </button>
          <button className="handle" style={{ ...styles.button, cursor: "move" }}>
            <DragIcon />
          </button>
        </div>
      </div>
    </Draggable>
  );
};
