import React from "react";
import * as S from "./preference-required-interaction.styled";
import Checkbox from "../element-checkbox/checkbox";
import { ElementConfigVisibility } from "../context/element-config-visibility";

function PreferenceRequiredInteraction(props) {
  const {
    content,
    onContentChange,
    label,
    title = "Contabilização do progresso",
    labels,
  } = props;

  const onChange = (e) => {
    let contentCopy = { ...content };
    contentCopy.required_interaction = e.currentTarget.checked;
    if (onContentChange) {
      onContentChange(contentCopy);
    }
  }

  const onChangeFeedback = (e) => {
    let contentCopy = { ...content };
    contentCopy.show_interaction_progress = e.currentTarget.checked;
    if (onContentChange) {
      onContentChange(contentCopy);
    }
  }

  const onChangeEnableReactions = (e) => {
    let contentCopy = { ...content };
    contentCopy.enable_reactions = e.currentTarget.checked;
    if (onContentChange) {
      onContentChange(contentCopy);
    }
  }

  const getCheckboxValue = () => {
    return content.required_interaction ? "on" : "";
  }

  const getCheckboxShowValue = () => {
    return content.show_interaction_progress ? "on" : "";
  }

  const getReactionsValue = () => {
    return content.enable_reactions ? "on" : "";
  }
  
  return (
    <React.Fragment>
      <ElementConfigVisibility />

      <div>
        <S.Title>
          {title}:
        </S.Title>

        <S.Label>
          <Checkbox
            checked={content.required_interaction !== undefined ? getCheckboxValue() : ""}
            onChange={onChange}
            padding={false}
          />
          <span>
            {label}
          </span>
        </S.Label>
      </div>

      <div>
        <S.Title>
          {labels.elements.settings.preference_required_interaction.feedback_title}:
        </S.Title>

        <S.Label>
          <Checkbox
            checked={content.show_interaction_progress !== undefined ? getCheckboxShowValue() : ""}
            onChange={onChangeFeedback}
            padding={false}
          />
          <span>
          {labels.elements.settings.preference_required_interaction.feedback_label}
        </span>
        </S.Label>
      </div>

      {/* <div>
        <S.Title>
        {labels.elements.settings.preference_required_interaction.reaction_title}
        </S.Title>

        <S.Label>
          <Checkbox
            checked={content.enable_reactions !== undefined ? getReactionsValue() : ""}
            onChange={onChangeEnableReactions}
            padding={false}
          />
          <span>
          {labels.elements.settings.preference_required_interaction.reaction_label}
        </span>
        </S.Label>
      </div> */}
    </React.Fragment>
  );
}

export default PreferenceRequiredInteraction;