import React from "react";
import * as S from "./quiz-image.styled";
import {UserService} from "../../services/user";

function QuizImage(props) {
  const {
    data = null,
    mediaMaxWidth,
  } = props;
  const style = mediaMaxWidth ? {maxWidth: mediaMaxWidth.toString() + 'px'} : {};

  const getImageUrl = (image) => {
    const accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    }
    return image;
  }

  if (data !== null) {
    return <S.QuizImage src={getImageUrl(data)} style={style}/>
  }

  return null;
}

export default QuizImage;