import styled from "styled-components";

export const QuizChoiceText = styled.div`
  display: inline-block;
`;

export const QuizChoiceVideo = styled.video`
  width: calc(100% - 60px);
  margin: 20px;
`;

export const ChoiceContainer = styled.div`
  display: inline-flex;
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const QuizImageVideoContainer = styled(CenterContainer)``;

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const RowContainer = styled.div`
  width: 100%;
`;