import React, {Component} from 'react';
import {ColorPicker} from "../../components";
import * as S from "./element-sorted-activity-1-properties.styled";
import uuidv1 from "uuid/v1";
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

const tabs = {
  "settings": "settings",
  "piles": "piles",
}

class ElementSortedActivity1Properties extends Component {
  state = {
    content: this.props.content,
    active_tab: tabs.settings,
  }

  onPrimaryColorChange = (color) => {
    const content = this.state.content;
    content.primary_color = color;
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange = (color) => {
    const content = this.state.content;
    content.background_color = color;
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPileColorChange = (color) => {
    const content = this.state.content;
    content.pile_color = color;
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPileTextColorChange = (color) => {
    const content = this.state.content;
    content.pile_text_color = color;
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onItemColorChange = (color) => {
    const content = this.state.content;
    content.item_color = color;
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onItemTextColorChange = (color) => {
    const content = this.state.content;
    content.item_text_color = color;
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange = (e) => {
    const content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange = (e) => {
    const content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onChangeTab = (target) => {
    this.setState({
      "active_tab": target
    })
  }

  onItemTitleChange = (e, itemId) => {
    const content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === itemId) {
        item.title = e.currentTarget.value;
      }
      return item;
    });
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onClickRemoveItem = (itemId) => {
    const content = this.state.content;
    content.items = content.items.filter(item => item.id !== itemId);
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onClickRemovePile = (pileId) => {
    const content = this.state.content;
    content.items = content.items.filter(item => item.pileId !== pileId);
    content.piles = content.piles.filter(pile => pile.id !== pileId);
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onClickAddItem = (pileId) => {
    const content = this.state.content;
    content.items.push({
      id: uuidv1(),
      title: "Item " + (content.items.length + 1),
      pileId: pileId,
    });
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onClickAddPile = () => {
    const content = this.state.content;
    content.piles.push({
      id: uuidv1(),
      title: "Pile " + (content.piles.length + 1),
    });
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPileTitleChange = (e, pileId) => {
    const content = this.state.content;
    content.piles = content.piles.map(pile => {
      if (pile.id === pileId) {
        pile.title = e.currentTarget.value;
      }
      return pile;
    });
    this.setState({
      content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  render() {
    const {
      content,
    } = this.state;
    const {
      piles = [],
      items = [],
    } = content;
    const {
      labels
    } = this.props;

    return (
      <div className="rdp-element-sorted-activity-1-properties">
        <ul className="rdp-composer-element-edit-tabs">
          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.settings ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.settings)}
          >
            {labels.elements.common_settings.label_tab_settings}
          </li>

          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.piles ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.piles)}
          >
            {labels.elements.settings.element_sorted_activity_1.settings_label_tab_piles}
          </li>
        </ul>

        <div className="rdp-composer-element-edit-body">
          {this.state.active_tab === tabs.settings && (
            <>
              <span
                className="rdp-composer-element-edit-label"
              >
                {labels.elements.settings.element_sorted_activity_1.settings_label_margin_top}
              </span>
              <input
                type="number"
                className="rdp-composer-element-edit-input px"
                onChange={(e) => this.onPaddingTopChange(e)}
                defaultValue={content.padding_top}
              />

              <span
                className="rdp-composer-element-edit-label"
              >
                {labels.elements.settings.element_sorted_activity_1.settings_label_margin_bottom}
              </span>
              <input
                type="number"
                className="rdp-composer-element-edit-input px"
                onChange={(e) => this.onPaddingBottomChange(e)}
                defaultValue={content.padding_bottom}
              />

              <span
                className="rdp-composer-element-edit-label"
              >
                {labels.elements.settings.element_sorted_activity_1.settings_label_primary_color}
              </span>
              <ColorPicker
                labels={this.props.labels}
                color={content.primary_color}
                onChange={this.onPrimaryColorChange}
              />

              <span
                className="rdp-composer-element-edit-label"
              >
                {labels.elements.settings.element_sorted_activity_1.settings_label_background_color}
              </span>
              <ColorPicker
                labels={this.props.labels}
                color={content.background_color}
                onChange={this.onBackgroundColorChange}
              />

              <span
                className="rdp-composer-element-edit-label"
              >
                {labels.elements.settings.element_sorted_activity_1.settings_label_pile_color}
              </span>
              <ColorPicker
                labels={this.props.labels}
                color={content.pile_color}
                onChange={this.onPileColorChange}
              />

              <span
                className="rdp-composer-element-edit-label"
              >
                {labels.elements.settings.element_sorted_activity_1.settings_label_pile_text_color}
              </span>
              <ColorPicker
                labels={this.props.labels}
                color={content.pile_text_color}
                onChange={this.onPileTextColorChange}
              />

              <span
                className="rdp-composer-element-edit-label"
              >
                {labels.elements.settings.element_sorted_activity_1.settings_label_item_color}
              </span>
              <ColorPicker
                labels={this.props.labels}
                color={content.item_color}
                onChange={this.onItemColorChange}
              />

              <span
                className="rdp-composer-element-edit-label"
              >
                {labels.elements.settings.element_sorted_activity_1.settings_label_item_text_color}
              </span>
              <ColorPicker
                labels={this.props.labels}
                color={content.item_text_color}
                onChange={this.onItemTextColorChange}
              />

              <PreferenceRequiredInteraction
                label={labels.elements.settings.preference_required_interaction.label}
                labels={labels}
                content={content}
                onContentChange={c => {
                  this.setState({ content: c });
                  this.props.onContentChange(c);
                }}
              />
            </>
          )}

          {this.state.active_tab === tabs.piles && (
            <>
              <S.PileList>
                {
                  piles.map((pile, index) => (
                    <S.Pile
                      key={pile.id}
                    >
                      <S.PileHeader>
                        <S.PileTextArea
                          value={pile.title}
                          onChange={(e) => this.onPileTitleChange(e, pile.id)}
                          rows={3}
                        />
                      </S.PileHeader>

                      <S.RemoveButton
                        tabIndex={1}
                        type="button"
                        onClick={() => this.onClickRemovePile(pile.id)}
                      >
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 768 768">
                          <path fill="#000" d="M607.5 127.5v64.5h-447v-64.5h111l33-31.5h159l33 31.5h111zM192 607.5v-384h384v384c0 34.5-30 64.5-64.5 64.5h-255c-34.5 0-64.5-30-64.5-64.5z"></path>
                        </svg>
                      </S.RemoveButton>

                      <S.ItemList>
                        {
                          items.filter(item => item.pileId === pile.id).map((item, index) => (
                            <S.Item
                              key={item.id}
                            >
                              <S.ItemTextArea
                                value={item.title}
                                onChange={(e) => this.onItemTitleChange(e, item.id)}
                                rows={3}
                              />

                              <S.RemoveButton
                                tabIndex={1}
                                type="button"
                                onClick={() => this.onClickRemoveItem(item.id)}
                              >
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 768 768">
                                  <path fill="#000" d="M607.5 127.5v64.5h-447v-64.5h111l33-31.5h159l33 31.5h111zM192 607.5v-384h384v384c0 34.5-30 64.5-64.5 64.5h-255c-34.5 0-64.5-30-64.5-64.5z"></path>
                                </svg>
                              </S.RemoveButton>
                            </S.Item>
                          ))
                        }

                        <S.AddButton
                          tabIndex={1}
                          type="button"
                          onClick={() => this.onClickAddItem(pile.id)}
                        >
                          {labels.elements.settings.element_sorted_activity_1.settings_label_add_item}
                        </S.AddButton>
                      </S.ItemList>
                    </S.Pile>
                  ))
                }
                <S.AddButton
                  tabIndex={1}
                  type="button"
                  onClick={() => this.onClickAddPile()}
                >
                  {labels.elements.settings.element_sorted_activity_1.settings_label_add_pile}
                </S.AddButton>
              </S.PileList>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ElementSortedActivity1Properties;