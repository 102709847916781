import React, { Component } from 'react';
import {UserService} from '../../services/user';
import { Quill } from '../../components';
import './element-quote-3.css';

export default class ElementQuote3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "avatar_size": "width"
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeAuthorName = this.handleChangeAuthorName.bind(this);
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  handleAvatarChange(img) {
    this.setState({
      "avatar_size": img.target.width >= img.target.height ? "height" : "width"
    });
  }

  handleChangeText(text) {
    var content = this.props.content;
    content.text = text;
    this.props.onChange(content);
  }

  renderText() {
    let { content } = this.props;

    return <div className="rdp-element-quote-3-text-container">
      <span className="rdp-element-quote-3-text-container-icon">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="416" height="448" viewBox="0 0 416 448">
          <path fill={content.primary_color} d="M192 240v96c0 26.5-21.5 48-48 48h-96c-26.5 0-48-21.5-48-48v-176c0-70.5 57.5-128 128-128h16c8.75 0 16 7.25 16 16v32c0 8.75-7.25 16-16 16h-16c-35.25 0-64 28.75-64 64v8c0 13.25 10.75 24 24 24h56c26.5 0 48 21.5 48 48zM416 240v96c0 26.5-21.5 48-48 48h-96c-26.5 0-48-21.5-48-48v-176c0-70.5 57.5-128 128-128h16c8.75 0 16 7.25 16 16v32c0 8.75-7.25 16-16 16h-16c-35.25 0-64 28.75-64 64v8c0 13.25 10.75 24 24 24h56c26.5 0 48 21.5 48 48z"></path>
        </svg>
      </span>
      <Quill
        className="rdp-element-quote-3-text"
        value={content.text}
        onChange={this.handleChangeText}
      />
      {this.renderAuthorInfo()}
    </div>;
  }

  renderAvatar() {
    let { content } = this.props;

    if (content.avatar !== null) {
      var avatarSize = this.state.avatar_size;
      return <div className="rdp-element-quote-3-avatar">
        <img
          style={avatarSize === "width" ? { width: "102%" } : { height: "102%" }}
          src={this.getAvatarUrl(content.avatar)}
          onLoad={this.handleAvatarChange.bind(this)}
        />
      </div>;
    } else {
      return null;
    }
  }

  getAvatarUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/avatar.png") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  handleChangeAuthorName(name) {
    var content = this.props.content;
    content.author_name = name;
    this.props.onChange(content);
  }

  renderAuthorInfo() {
    let { content } = this.props;

    var modules = {
      toolbar: [
        ['bold', 'italic', 'underline','strike'], [{'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f1", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c281ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#1c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']}]
      ],
    };
    var formats = ['bold', 'italic', 'underline', 'strike', 'color'];
    
    return <div className="rdp-element-quote-3-author-info">
      <Quill
        className="rdp-element-quote-3-author-name"
        style={{fontFamily: "Merriweather-Regular"}}
        value={content.author_name}
        onChange={this.handleChangeAuthorName}
      />
    </div>
  }

  getBackgroundUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/background.png") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;
    
    return <div className={`${className} rdp-element-quote-3 ${this.getFont(content.font_family) || ""}`} style={{ background: content.background_color }}>
      {headerActionsTemplate}
      <div className="rdp-element-quote-3-container">
        {this.renderAvatar()}
        {this.renderText()}
      </div>
      {footerActionsTemplate}
    </div>;
  }
}
