import React from "react";
import * as S from "./choice.styled";
import classNameFn from "../../utils/classNameFn";


function Choice(props) {
  const {
    children,
    className,
    selected,
    primaryColor,
    selectedColor,
    borderRadius,
    disabled,
    ...rest
  } = props;

  return (
    <S.Choice
      {...rest}
      borderColor={selected ? (selectedColor || primaryColor) : null}
      className={classNameFn([
        "element-choice",
        className,
      ])}
      borderRadius={(selected && disabled) ? "0px" : "10px"}
      disabled={disabled}
    >
      {children}
    </S.Choice>
  )
}

export default Choice;