import styled from "styled-components";

export const QuizHeader = styled.div`
  margin-bottom: 10px;
`;

const Span = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.primaryColor || "#00643e"}
`;

export const CurrentQuestionText = styled(Span)``;

export const ProgressText = styled(Span)`
  float: right;
  letter-spacing: 2px;
`;