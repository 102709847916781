import React, { useState, useEffect } from "react";
import { ColorPicker } from "../../components";
import { Questions } from "./components/Questions";
import PreferenceRequiredInteraction from "../../components/preference-required-interaction/preference-required-interaction";
import { getSkills } from "./skills";
import { SkillGroup } from "../../components/skills/SkillGroup";

const ElementInteractiveVideoProperties = ({ content, onContentChange, labels }) => {
  const [anchors, setAnchors] = useState(content.anchors || []);
  const [activeTab, setActiveTab] = useState("content");
  const [config, setConfig] = useState(content);

  useEffect(() => {
    onContentChange({ ...content, anchors });
  }, [anchors]);

  const buildChangeHandler =
    (name, getter = (e) => e.target.value) =>
    (e) =>
      onContentChange({ ...content, [name]: getter(e) || content[name] });

  const onAnchorChange = (i, e) => {
    anchors[i] = e.target.value;
    setAnchors([...anchors]);
  };

  const addNewAnchor = () => {
    setAnchors([...anchors, 0]);
  };

  const removeAnchor = (i) => {
    setAnchors(anchors.filter((anchor, index) => index !== i));
  };

  const editContentQuestion = (choice) => {};

  const renderSettings = (
    <>
      <span className="rdp-composer-element-edit-label">
        {labels.elements.settings.element_interactive_video.settings_label_content_title}:
      </span>
      <input
        type="text"
        className="rdp-composer-element-edit-input"
        onChange={buildChangeHandler("content_title")}
        defaultValue={content.content_title}
      />

        <SkillGroup
          selectedProficiency={config.proficiency} 
          selectedSkill={config.skill} 
          onChangeProficiency={proficiency => {
            const newContent = {
              ...config,
              proficiency
            }
            setConfig(newContent);
            onContentChange(newContent)
          }} 
          onChangeSkill={skill => {
            const newContent = {
              ...config,
              skill
            }
            setConfig(newContent);
            onContentChange(newContent)
          }} 
        />
      <span className="rdp-composer-element-edit-label">
        {labels.elements.settings.element_interactive_video.settings_label_background_color}
      </span>
      <ColorPicker
        labels={labels}
        color={content.background_color}
        onChange={buildChangeHandler("background_color", (color) => color.hex)}
      />

      <span className="rdp-composer-element-edit-label">
        {labels.elements.settings.element_interactive_video.settings_label_margin_top}
      </span>
      <input
        type="number"
        className="rdp-composer-element-edit-input px"
        onChange={buildChangeHandler("padding_top")}
        defaultValue={content.padding_top}
      />

      <span className="rdp-composer-element-edit-label">
        {labels.elements.settings.element_interactive_video.settings_label_margin_bottom}
      </span>
      <input
        type="number"
        className="rdp-composer-element-edit-input px"
        onChange={buildChangeHandler("padding_bottom")}
        defaultValue={content.padding_bottom}
      />

      <PreferenceRequiredInteraction
        title={labels.elements.settings.preference_required_interaction.title}
        label={labels.elements.settings.preference_required_interaction.label}
        labels={labels}
        content={content}
        onContentChange={onContentChange}
      />
    </>
  );

  const renderContent = (
    <div className="element-accordion-properties-item">
      <span className="rdp-composer-element-edit-label">
        {labels.elements.settings.element_interactive_video.settings_label_webview_url}:
      </span>
      <input
        type="text"
        className="rdp-composer-element-edit-input"
        onChange={buildChangeHandler("url")}
        defaultValue={content.url}
      />
    </div>
  );

  return (
    <div className="rdp-element-text-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li
          className={`rdp-composer-element-edit-tabs-item ${activeTab === "content" ? "active" : ""}`}
          onClick={() => setActiveTab("content")}
        >
          {labels.elements.common_settings.label_tab_content}
        </li>
        <li
          className={`rdp-composer-element-edit-tabs-item ${activeTab === "settings" ? "active" : ""}`}
          onClick={() => setActiveTab("settings")}
        >
          {labels.elements.common_settings.label_tab_settings}
        </li>
      </ul>
      <div className="rdp-composer-element-edit-body">
        {activeTab === "settings" && renderSettings}
        {activeTab === "content" && (
          <>
            {renderContent}
            <Questions content={content} onContentChange={onContentChange} labels={labels} />
          </>
        )}
      </div>
    </div>
  );
};

export default ElementInteractiveVideoProperties;
