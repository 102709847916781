import styled, {css} from "styled-components";

export const ElementFlashcards2 = styled.div`
  background-color: ${props => props.backgroundColor || "#fff"};
  padding-top: ${props => props.paddingTop || "0"}px;
  padding-bottom: ${props => props.paddingBottom || "0"}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const CardList = styled.ul`
  width: 100%;
  max-width: 800px;
  padding: 10px;
  display: block;
  margin: auto;
  text-align: center;
  overflow: hidden;
  height: calc(${props => props.maxHeight || "400"}px + 30px);
  position: relative;
`;

export const CardListItem = styled.li`
  display: inline-block;
  transition: all 0.2s linear;
  perspective: 1000px;
  top: 0;
  cursor: pointer;
  pointer-events: all;
  height: ${props => props.maxHeight || "400"}px;
  width: ${props => props.maxWidth || "400"}px;
  aspect-ratio: ${props => props.aspectRatio || "1"};
  opacity: 0;
  position: absolute;

  ${props => props.position === "left" && css`
    left: 0;
  `}

  ${props => props.position === "right" && css`
    left: 100%;
  `}
  
  ${props => props.position === "center" && css`
    opacity: 1;
    left: calc(50% - ${props => props.maxWidth || "400"}px / 2);
  `}
`;

export const CardInner = styled.div`
  pointer-events: none;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  ${props => props.isFlipped && css`
    transform: rotateY(180deg);
  `}
`;

export const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  background-color: #FFFFFF;
  color: black;
  pointer-events: none;
  border: 1px solid #ededed;
  border-top: 3px solid #00643e;
`;

export const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  background-color: #fafafa;
  color: black;
  transform: rotateY(180deg);
  pointer-events: none;
  border: 1px solid #ededed;
  box-shadow: 0 0.4rem 1.2rem 0.2rem rgba(0,0,0,.05);
`;

export const CardItemTypeText = styled.p`
  padding: 10px;
`;

export const CardItemTypeImage = styled.img``;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.maxWidth || "400"}px;
`;

export const ChangeCardButton = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  color: ${props => props.fontColor || "white"};
  background: ${props => props.backgroundColor || "#00643e"};
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    filter: brightness(1.3);
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  &:focus {
    outline: 2px solid ${props => props.backgroundColor || "#00643e"} !important;
    outline-offset: 2px;
  }
`;

export const Progress = styled.div`
  width: 100%;
  height: 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 200px;
`;

export const ProgressLabel = styled.div`
  width: calc(100% - 10px);
  height: 6px;
  border-radius: 50px;
  background: #ccc;
  margin-top: 23px;
  display: inline-block;
`;

export const ProgressCounter = styled.div`
  font-weight: bold;
  font-size: 11px;
  color: #2d7b45;
  letter-spacing: 2px;
`;

export const ProgressBar = styled.div`
  width: 0;
  height: 6px;
  border-radius: 50px;
  background: #2d7b45;
  transition: all 0.3s ease-out;
`;