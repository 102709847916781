import React, { Component } from 'react';
import { Quill } from '../../components';
import {UserService} from '../../services/user';
import './element-tabs.css';

export default class ElementTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": [],
      "active_tab_content": null
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.content !== prevProps.content) {
      this.manipulateItems();
    }
  };
  
  componentWillMount() {
    this.manipulateItems();
  }

  manipulateItems() {
    var content = this.props.content;
    this.setState({
      "content": content,
      "active_tab_content": content.items[0]
    })
  }

  handleChange(text) {
    var _this = this;
    var content = this.state.content;
    var items = content.items;
    var activeItem = this.state.active_tab_content;
    for (var i = 0; i < items.length; i++) {
      if (items[i].id === activeItem.id) {
        items[i].text = text;
        _this.setState({
          "active_tab_content": items[i]
        });
      };
    };

    this.setState({
      "content": content
    });
    this.props.onChange(content);
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
    image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  onTabClick(item) {
    this.setState({
      "active_tab_content": item
    })
  }

  renderQuill() {
    var content = this.state.active_tab_content;

    if (this.props.editable) {
      return <>
        <Quill
          value={content.text}
          onChange={(text) => this.handleChange(text)}
        />
      </>
    } else {
      return <div className="editor" dangerouslySetInnerHTML={{__html: content.text}}></div>
    }
  }

  getAssetUrl(asset) {
    var accessToken = UserService.getUser().access_token;
    return asset += "&access_token=" + accessToken;
  }

  renderImage() {
    var content = this.state.active_tab_content;
    if (content.image !== null) {
      return <img className="element-tabs-item-image" src={this.getAssetUrl(content.image)}/>
    }
  }

  renderVideo() {
    var content = this.state.active_tab_content;
    if (content.video !== null) {
      return <video className="element-tabs-item-video" controls src={this.getAssetUrl(content.video)}/>
    }
  }

  renderActiveTabContent() {
    return <div className="element-tabs-content">
      {this.renderQuill()}
      {this.renderImage()}
      {this.renderVideo()}
    </div>
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    var _this = this;
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    var accordionStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }
    var items = this.state.content.items;

    return <div className={`${this.props.className} ${this.getFont(content.font_family) || ""}`} style={accordionStyle}>
      {headerActionsTemplate}
      <div className="element-tabs-container">
        <ul className="element-tabs-items">
          {items.map(function(item, index) {
            return <li key={index} style={_this.state.active_tab_content.id === item.id ? {borderColor: content.active_color} : {}} className={`element-tabs-item ${_this.state.active_tab_content.id === item.id ? "active" : ""}`}>
              <span className="element-tabs-item-title" style={_this.state.active_tab_content.id === item.id ? {color: content.active_color} : {}} onClick={() => _this.onTabClick(item)}>{item.title}</span>
            </li>
          })}
        </ul>
        {_this.renderActiveTabContent()}
      </div>
      {footerActionsTemplate}
    </div>
  }
}
