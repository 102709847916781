import React from "react";
import Player from "./components/Player";

import * as S from "./styles";

const ElementInteractiveVideo = ({ content, onChange, headerActionsTemplate, footerActionsTemplate }) => {
  return (
    <div style={{ position: "relative" }}>
      <S.Container
        backgroundColor={content.background_color}
        paddingTop={content.padding_top}
        paddingBottom={content.padding_bottom}
      >
        {headerActionsTemplate}
        <Player
          content={content}
          url={content.url}
          pausingMinute={content.pausing_minute}
          pausingSecond={content.pausing_second}
          anchors={content.anchors}
        />
      </S.Container>
      {footerActionsTemplate}
    </div>
  );
};

export default ElementInteractiveVideo;
