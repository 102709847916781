import React, {useEffect} from "react";
import {Quill} from "../index";
import Choice from "../element-choice/choice";
import Radio from "../element-radio/radio";
import * as S from "./quiz-choice-quill.styled";
import QuizImage from "../element-quiz-image/quiz-image";
import QuizVideo from "../element-quiz-video/quiz-video";
import Checkbox from "../element-checkbox/checkbox";
import useIsChoiceSelected from "../../hooks/useIsChoiceSelected";
import {ChoiceContainer, ColumnContainer} from "./quiz-choice-quill.styled";

function QuizChoiceQuill(props) {
  const {
    question,
    type,
    choice,
    content,
    editable = false,
    disabled = false,
    activeChoices = [],
    onChoiceClick = () => {
    },
    onEditChoiceText = (text) => {
    },
  } = props;
  const [colorChoice, setColorChoice] = React.useState(content.primary_color || "#00bcd4");
  const selected = useIsChoiceSelected(activeChoices, choice, question);

  useEffect(() => {
    if (selected) {
      if (disabled) {
        let correctAnswer = false;
        question.choices.forEach(ch => {
          if (ch.correct && selected) {
            correctAnswer = true;
          }
        });

        setColorChoice(correctAnswer ? content.correct_color : content.incorrect_color);
      }
    }
  }, [activeChoices, choice]);

  useEffect(() => {
    setColorChoice(content.primary_color || "#00bcd4");
  }, [content]);

  const renderChoiceRadio = (question, choice) => {
    return (
      <Radio
        value={choice.text}
        checked={selected}
        primaryColor={content.primary_color}
      />
    )
  }

  const renderChoiceCheckbox = (question, choice) => {
    return (
      <Checkbox
        value={choice.text}
        checked={selected}
        primaryColor={content.primary_color}
      />
    )
  }

  const handleChoiceClick = () => {
    if (!disabled && typeof onChoiceClick === "function") {
      try {
        onChoiceClick(question, choice);
      } catch (e) {
        console.error(e);
      }
    }
  }

  const renderChoiceInputType = () => {
    switch (type) {
      case "quiz-1":
        return renderChoiceRadio(question, choice);
      case "quiz-2":
        return renderChoiceCheckbox(question, choice);
      default:
        return null;
    }
  }

  return (
    <>
      <Choice
        role="button"
        selected={selected}
        primaryColor={content.primary_color}
        selectedColor={colorChoice}
        disabled={disabled}
        onClick={handleChoiceClick}
      >
        <S.ColumnContainer>
          <S.ChoiceContainer>
            {renderChoiceInputType()}
            {
              editable ? (
                <Quill
                  value={choice.text}
                  onChange={(text) => onEditChoiceText(text)}
                />
              ) : (
                <S.QuizChoiceText
                  dangerouslySetInnerHTML={{__html: choice.text}}
                ></S.QuizChoiceText>
              )
            }
          </S.ChoiceContainer>

          <S.CenterContainer>
            <S.QuizImageVideoContainer>
              <S.RowContainer>
                {
                  (choice.image && <QuizImage data={choice.image} mediaMaxWidth={choice.media_max_width}/>)
                  || (choice.video && <QuizVideo data={choice.video} mediaMaxWidth={choice.media_max_width}/>)
                }
              </S.RowContainer>
            </S.QuizImageVideoContainer>
          </S.CenterContainer>
        </S.ColumnContainer>
      </Choice>
    </>
  )
    ;
}

export default QuizChoiceQuill;