import React, {Component} from "react";

class HtmlString extends Component {
  elementRef = React.createRef()

  componentDidMount() {
    if (this.elementRef) {
      const {
        value = ""
      } = this.props;
      let htmlString = value;

      if ("Sanitizer" in window) {
        try {
          this.sanitized(htmlString);
        } catch (e) {
          console.error(e);
          this.unsanitized(htmlString);
        }
      } else {
        this.unsanitized(htmlString);
      }
    }
  }

  sanitized(htmlString) {
    // eslint-disable-next-line no-undef
    const sanitizer = new Sanitizer();

    if (sanitizer && "sanitizeFor" in sanitizer) {
      let sanitizedElement = sanitizer.sanitizeFor("div", htmlString);

      if (sanitizedElement) {
        this.elementRef.current.insertAdjacentHTML("afterbegin", sanitizedElement.innerHTML);
      } else {
        this.unsanitized(htmlString);
      }
    } else {
      this.unsanitized(htmlString);
    }
  }

  unsanitized(htmlString) {
    const dom = new DOMParser().parseFromString(htmlString, 'text/html');
    this.elementRef.current.insertAdjacentElement("afterbegin", dom.body.firstElementChild);
  }

  render() {
    const {
      value,
      ...rest
    } = this.props;

    return <div ref={this.elementRef} {...rest}/>
  }
}

export default HtmlString;