import React from "react";
import * as S from "./quiz-finish-result.styled";

function QuizFinishResult(props) {
  const {
    grades,
    content,
    labels,
    onClickRemake = () => {
    },
    contentLanguage,
  } = props;

  const getResultLabel = (hits, total) => {
    if (contentLanguage === "en") {
      return `You got ${hits} of ${total} questions correct.`
    } else if (contentLanguage === "pt_br") {
      return `Você acertou ${hits} de ${total} questões.`
    }
  }

  const getPendingResultLabel = (total) => {
    if (contentLanguage === "en") {
      return `This quiz has ${total} dissertation question${total > 1 ? "s" : ""} awaiting review.`
    } else if (contentLanguage === "pt_br") {
      return `Este questionário possui ${total} quest${total > 1 ? "ões" : "ão"} dissertativa aguardando revisão.`
    }
  }

  const renderFinalMessage = (grades) => {
    const feedback = content.feedback.type;
    if (feedback === "disabled") {
      return (
        <S.ResultMessage>
          {labels.finish_quiz_message}
        </S.ResultMessage>
      );
    } else {
      return (
        <>
          <S.ResultMessage>
            {getResultLabel(grades.hits, grades.total, `${parseFloat(grades.percentage_average).toFixed(0)}%`)}
          </S.ResultMessage>
          {grades.pending_review_total> 0 && (
            <S.ResultMessage>
              {getPendingResultLabel(grades.pending_review_total)}
            </S.ResultMessage>
          )}
        </>
      );
    }
  }

  return (
    <S.QuizFinishResult>
      <div>
        {renderFinalMessage(grades.rdp_html_content)}

        {content.remake === "enable" && (
          <S.RemakeButton
            tabIndex={1}
            onClick={onClickRemake}
            type="button"
            primaryColor={content.primary_color}
          >
            <S.RemakeButtonIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                width="32px"
                height="32px"
                viewBox="0 0 512 512"
                enableBackground="new 0 0 512 512"
                space="preserve"
              >
                <g>
                  <path fill={content.primary_color ? content.primary_color : "#111111"}
                        d="M126.97,328.84c-5.84,0-11.63-2.42-15.78-7.15L5.17,200.31c-6.89-7.88-6.89-19.64,0-27.53L111.19,51.41   c7.61-8.7,20.83-9.59,29.53-1.99c8.71,7.6,9.59,20.82,2,29.52l-94,107.61l94,107.6c7.6,8.7,6.71,21.92-2,29.52   C136.75,327.14,131.85,328.84,126.97,328.84z"/>
                  <path fill={content.primary_color ? content.primary_color : "#111111"}
                        d="M359.93,469.75H48.83c-11.56,0-20.93-9.37-20.93-20.93s9.37-20.93,20.93-20.93h311.1   c60.78,0,110.22-49.44,110.22-110.21s-49.44-110.21-110.22-110.21h-339C9.37,207.47,0,198.1,0,186.54   c0-11.55,9.37-20.92,20.93-20.92h339c83.85,0,152.07,68.22,152.07,152.06C512,401.53,443.77,469.75,359.93,469.75z"/>
                </g>
              </svg>
            </S.RemakeButtonIcon>

            <S.RemakeButtonText>
              {labels.retry}
            </S.RemakeButtonText>
          </S.RemakeButton>
        )}
      </div>
    </S.QuizFinishResult>
  );
}

export default QuizFinishResult;