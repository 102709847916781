import $ from 'jquery';
import jwtDecode from 'jwt-decode';
import React, {Component} from 'react';
import {UserService} from '../../services/user';
import {LoginService} from '../../services/login';
import {DamService} from '../../services/dam';
import { RDP_TRANSLATE } from '../../translate/translate';
import RDP_CONFIG from '../../config';
import './home.css';
import './home-dark.css';

import {
  Header,
  Subheader,
  SubheaderEmbed,
  Preloader,
  RemoveModal
} from '../../components';
import replaceObjectKeyValue from "../../utils/replaceObjectKeyValue";
import uuidv1 from "uuid/v1";
import replaceCompositionObjectsIds from "../../utils/replaceCompositionObjectsIds";
import { getOverrides, getTheme } from '../../utils/getTheme';

export default class ViewHome extends Component {
  constructor(props) {
    super(props);

    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.getCompositions = this.getCompositions.bind(this);
    this.getMoreCompositions = this.getMoreCompositions.bind(this);

    this.state = {
      "is_embed": this.props.match.path === "/embed",
      "content_remove": null,
      "displayed_full_preloader": false,
      "displayed_preloader": true,
      "compositions": [],
      "interval": 0,
      "render_import_modal": false,
      "render_import_modal_login": false,
      "labels": RDP_TRANSLATE[RDP_CONFIG.language]().home,
      "is_searching": false,
      "theme": getTheme(),
      "nextQuery": null,
      "loading": false,
      "search": "",
    }
  }

  componentWillMount() {
    this.reloadValidUserToken();
    this.redirectToLogin();
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  reloadValidUserToken() {
    var userData = UserService.getUser();
    if (userData !== null) {
      var accessToken = userData.access_token;
      if (jwtDecode(accessToken).exp < Math.floor(Date.now() / 1000)) {
        this.logout();
      }
    }
  }
  
  getCompositionsContent() {
    var _this = this;
    var compositions = this.state.compositions;
    for (var i = 0; i < compositions.length; i++) {
      _this.getCompositionContent(compositions[i]);
    };
  }
  
  getCompositionContent(composition) {
    var _this = this;
    var userData = UserService.getUser();
    var accessToken = userData.access_token;
    var id = composition.document.content_uid;
    DamService.getContent(id, accessToken).then(function(data) {
      var compositions = _this.state.compositions;
      for (var i = 0; i < compositions.length; i++) {
        if (id === compositions[i].document.content_uid) {
          compositions[i].content = JSON.parse(data);
        };
      };
      _this.setState({
        "compositions": compositions
      });
    });
  }

  refreshCompositions() {
    var _this = this;
    _this.setState({
      "interval": setInterval(() => {
        if (this.state.is_searching === false)
          _this.getCompositions();
      }, 15000)
    })
  }

  getCompositions(query, customFilter) {
    var _this = this;

    var userData = UserService.getUser();
    var activeProject = UserService.getActiveProject();
    var type = userData.type;
    var accessToken = userData.access_token;
    var filter = customFilter ? customFilter : "filter=(content_type eq 'digitalpages/composer') and (project_uid eq '" + activeProject.uid + "')&orderBy=last_modified desc";
    var q = query !== undefined ? ("&q=" + query) : "";
    // (content_info_values/any(v: v eq 'Os estrondosos')) and 

    DamService.searchContent(filter, q, type, accessToken).then(function(data) {
      var compositions = data.results;
      
      _this.setState({ nextQuery: data.next_result_query })
      // if (data.facets.project_name === undefined) {
      //   _this.logout();
      // };
      
      compositions.forEach(composition=>{
        var infos = composition.document.content_info;
        infos.forEach(info=>{
          var values = info.split("=");
          if (!composition.metadata) composition.metadata ={};
          composition.metadata[values[0]] = values[1];
        })
      })

      if(customFilter) {
        compositions = _this.state.compositions.concat(compositions);
      }
    
      _this.setState({
        "displayed_preloader": false,
        "loading": false,
        "compositions": compositions
      });
    });
  }

  logout() {
    LoginService.logout().then(function(data) {
      window.location = window.location.origin + window.location.pathname + "#/login";
    });
  }

  redirectToLogin() {
    if (window.localStorage.getItem("rdp-composer-user-data") === null) {
      window.location = window.location.origin + window.location.pathname + "#/login";
    } else {
      // this.refreshCompositions();
      this.getCompositions();
    };
  }

  renderHeader() {
    if (UserService.getUserDecoded() !== null) {
      return <Header labels={this.state.labels}/>;
    }
  }

  openComposition(composition) {
    window.location = window.location.href + "composer/" + composition.document.content_uid + "?file_uid=" + composition.document.file_uid;
  }

  blobToFile(theBlob, fileName){
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  getCompositionFile(composition) {
    var myBlob = new Blob([JSON.stringify(composition)], {type : "digitalpages/composer"});
    return this.blobToFile(myBlob, "composition.rdpcomposer");
  }

  correctConnector()
  {
    var connector = null;
    var project = JSON.parse(window.localStorage.getItem("rdp-composer-active-project"));

    if (project && project.connectors) connector = project.connectors.find(p=> p.internal_name == "Dam");
    if (!connector && project && project.connectors) connector = project.connectors[0];

    return connector;
  }

  onCompositionClone(composition) {
    var _this = this;
    var userData = UserService.getUser();
    var accessToken = userData.access_token;
    var random = Math.floor(Math.random() * (999 - 100 + 1) + 100);

    _this.setState({
      "displayed_full_preloader": true
    })

    DamService.getContent(composition.document.content_uid, accessToken).then(function(data) {
      data = JSON.parse(data);
      data.metadata.title = "Cópia#" + random + " - " + data.metadata.title;

      data.structure = replaceCompositionObjectsIds([...data.structure]);

      var connector = _this.correctConnector();
      var type = userData.type;
      var fd = new FormData();
      var files = _this.getCompositionFile(data);
      fd.append('file', files, "composition.rdpcomposer");
      
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/storage/v1.0/upload/connector/uid/${connector.uid}`,
        type: "POST",
        data: fd,
        contentType: false,
        processData: false,
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        },
        success: function(newComposition) {
          window.location = window.location.href + "composer/" + newComposition[0].uid;
        }
      });
    });
  }

  onPreviewClick(composition) {
    this.props.history.push({
      pathname:  `${this.state.is_embed ? "/embed" : ""}/preview/${composition.document.content_uid}`,
      state: { fromHome: true }
    });
  }

  formatDate(date) {
    return date.split("-")[2].split("T")[0] + "/" + date.split("-")[1] + "/" + date.split("-")[0];
  }

  versionStyle() {
    const overrides = getOverrides();
    if (this.state.is_embed) {
      return {
        background: "#e8d7ff",
        ...(!overrides || overrides.themeContrast != 'config' && {
          color: "#764ab0"
        })
      }
    } else {
      return {};
    }
  }

  renderCompositionCard(composition) {
    var labels = this.state.labels;
    return <div className={`rdp-composer-home-composition-data rdp-composer-home-composition-data-theme-${this.state.theme}`} onClick={() => this.openComposition(composition)}>
      <span className="rdp-composer-home-composition-title rdp-composer-home-composition-title-sso">{composition.metadata ? composition.metadata.title : ""}</span>
      {/* <span className="rdp-composer-home-composition-versions">{composition.document.content_versions}ª versão</span> */}
      <span className="rdp-composer-home-composition-versions" style={this.versionStyle()}>{labels.cards.composition_tag_version} {composition.document.content_versions}</span>
      <span className="rdp-composer-home-composition-author">{labels.cards.composition_actor}: {composition.document.user_name}</span>
      <span className="rdp-composer-home-composition-date">{labels.cards.composition_last_modification}: {this.formatDate(composition.document.last_modified)}</span>
    </div>;
  }

  renderCompositionActions(composition) {
    return <ul className="rdp-composer-home-composition-actions">
      <li className="rdp-composer-home-composition-action-clone" onClick={() => this.onCompositionClone(composition)}></li>
      <li className="rdp-composer-home-composition-action-preview" onClick={() => this.onPreviewClick(composition)}></li>
      <li className="rdp-composer-home-composition-action-remove" onClick={() => this.onRemoveClick(composition)}></li>
    </ul>
  }

  renderPreloader() {
    if (this.state.displayed_preloader === true) {
      return <div className='rdp-composer-home-preloader' style={{top: this.state.is_embed ? "55px" : "135px"}}></div>
    }
  }

  renderFullPreloader() {
    if (this.state.displayed_full_preloader === true) {
      return <Preloader
        opacity={0.8}
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
    }
  }

  onRemoveClick(composition) {
    this.setState({
      "content_remove": composition
    });
  }

  onContentRemoveClose() {
    this.setState({
      "content_remove": null
    });
  }

  onContentRemoveConfirm() {
    var _this = this;
    var composition = this.state.content_remove;
    var id = composition.document.content_uid;
    var accessToken = UserService.getUser().access_token;

    DamService.removeContent(id, accessToken).then(function(composition) {
      setTimeout(function() {
        _this.getCompositions();
      }, 1000);
    });

    _this.setState({
      "displayed_preloader": true,
      "content_remove": null
    });
  }

  renderContentRemove() {
    if (this.state.content_remove !== null) {
      var title = this.state.content_remove.metadata.title;
      var message = "Você realmente deseja apagar esta composição?";
  
      return <RemoveModal
        title="Remover composição"
        message={message}
        value={title}
        removeLabel="Apagar composição"
        onClose={() => this.onContentRemoveClose()}
        onRemoveConfirm={() => this.onContentRemoveConfirm()}
      />
    }
  }

  onSearchSubmit(query) {
    if (query === "") {
      this.setState({
        search: query,
        is_searching: false,
      }, () => this.getCompositions())
    } else {
      this.setState({
        search: query,
        is_searching: true,
      }, () => this.getCompositions(query))
    }
  }

  toggleRenderImport() {
    this.setState({
      "render_import_modal": !this.state.render_import_modal
    })
  }

  toggleRenderImportLogin() {
    this.setState({
      "render_import_modal_login": !this.state.render_import_modal_login
    })
  }

  getMoreCompositions() {
    var _this = this;
    const { nextQuery, search } = _this.state;

    _this.setState({ loading: true }, () => _this.getCompositions(search, nextQuery));

  }

  renderCompositions() {
    var _this = this;
    const { loading, nextQuery, compositions } = this.state;

    if (compositions.length > 0) {
      return <ul className="rdp-composer-home-compositions" style={{top: this.state.is_embed ? "55px" : "135px"}}>
        {compositions.map(function(composition, index) {
          return <li key={index} className={`rdp-composer-home-composition`}>
            {_this.renderCompositionCard(composition)}
            {_this.renderCompositionActions(composition)}
          </li>
        })}

        {nextQuery && (
          <li onClick={_this.getMoreCompositions}>
            <button className='btn-load-more'>
              {loading ? 'CARREGANDO...' : 'CARREGAR MAIS'}
            </button>
          </li>
        )}

      </ul>;
    } else {
      return <div className="rdp-composer-structure-message">
        <h1>{this.state.labels.labels.no_compositions_message}</h1>
      </div>
    }
  }

  render() {
    return (<div className={`rdp-composer-home rdp-composer-home-theme-${this.state.theme}`}>
      {this.renderContentRemove()}
      {this.renderFullPreloader()}
      {this.renderPreloader()}
      {!this.state.is_embed && this.renderHeader()}

      {this.state.is_embed &&
        <SubheaderEmbed
          onImport={() => this.toggleRenderImport()}
          onSearchSubmit={(term) => this.onSearchSubmit(term)}
          labels={this.state.labels}
        />
      }
      {!this.state.is_embed &&
        <Subheader
          onSearchSubmit={(term) => this.onSearchSubmit(term)}
          labels={this.state.labels}
          isEmbed={this.state.is_embed}
        />
      }
      {this.state.render_import_modal &&
        <div className="rdp-composer-home-import-modal">
          <div className="rdp-composer-home-import-modal-overlay"></div>
          <div className="rdp-composer-home-import-modal-window">
            <div className="rdp-composer-home-import-modal-header">
              <span className="rdp-composer-home-import-modal-header-title">Importar composição</span>
              <span className="rdp-composer-home-import-modal-header-close" onClick={() => this.toggleRenderImport()}></span>
            </div>
            <div className="rdp-composer-home-import-modal-body">
              <div className="rdp-composer-home-import-modal-body-sidebar">
                <button className="rdp-composer-home-import-modal-body-sidebar-btn" onClick={() => this.toggleRenderImportLogin()}>Nova conexão</button>
              </div>
              {this.state.render_import_modal_login &&
                <div className="rdp-composer-home-import-modal-body-login">
                  <span className="rdp-composer-home-import-modal-body-login-instructions">
                    Crie uma conexão com outra instância do Composer para importar composições de outros projetos.
                  </span>
                  <span className="rdp-composer-home-import-modal-body-login-label">Usuário</span>
                  <input className="rdp-composer-home-import-modal-body-login-input" type="text"/>

                  <span className="rdp-composer-home-import-modal-body-login-label">Senha</span>
                  <input className="rdp-composer-home-import-modal-body-login-input" type="password"/>

                  <button className="rdp-composer-home-import-modal-body-login-submit" onClick={() => this.toggleRenderImportLogin()}>Realizar conexão</button>
                </div>
              }
            </div>
          </div>
        </div>
      }
      {this.renderCompositions()}

    </div>);
  }
}
