import styled from "styled-components";

import ReactPlayer from "react-player";

export const CustomReactPlayer = styled(ReactPlayer)`
  background-color: black;

  @media (max-width: 640px) {
    width: 100vw !important;
  }
`;
