import styled from "styled-components";


export const ProgressContainer = styled.div`
  flex: 2;
  max-width: 300px;
`;

export const Progress = styled.div`
  width: 100%;
  height: 50px;
  margin: 0 auto;
`;

export const ProgressBarPlaceholder = styled.div`
  width: calc(100% - 40px);
  height: 6px;
  border-radius: 50px;
  background: #ccc;
  margin-top: 23px;
  display: inline-block;
`;

export const ProgressBar = styled.div`
  width: 0;
  height: 6px;
  border-radius: 50px;
  background: #2d7b45;
  transition: all 0.3s ease-out;
`;

export const ProgressCount = styled.span`
  font-weight: bold;
  font-size: 11px;
  margin-left: 5px;
  color: #2d7b45;
  letter-spacing: 2px;
`;