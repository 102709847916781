import React from "react";
import * as S from "./progress.styled";


function Progress(props) {
  const {
    primaryColor,
    currentProgress = 1,
    totalProgress = 2,
  } = props;
  const percentage = (100 / totalProgress * currentProgress) + "%";

  return (
    <S.ProgressContainer>
      <S.Progress>
        <S.ProgressBarPlaceholder>
          <S.ProgressBar
            style={{
              width: percentage,
              background: primaryColor
            }}
          />
        </S.ProgressBarPlaceholder>

        <S.ProgressCount
          style={{
            color: primaryColor
          }}
        >
          {currentProgress}/{totalProgress}
        </S.ProgressCount>
      </S.Progress>
    </S.ProgressContainer>
  );
}

export default Progress;