import {fetch} from 'whatwg-fetch';

var generalService = function() {
  this.getSubscriptionKeyBySlug = function(slug) {
    var subscriptionKey = "";
    if (slug === "exame-research") {
      subscriptionKey = "35c75b238f4f4c67a3f409b5f34f3cea";
    };
    return new Promise(function(resolve, reject) {
      resolve({
        "subscription_key": subscriptionKey
      });
    });
  }

  return this;
}

var GeneralService = new generalService();

export {
  GeneralService
};



