import React, { Component } from "react";
import { UserService } from "../../services/user";
import * as S from "./element-flashcards-2.styled";
import { getTheme } from "../../utils/getTheme";

class ElementFlashcard2 extends Component {
  state = {
    content: [],
    flippedItemsIds: [],
    visibleCardIndex: 0,
  };

  getAssetUrl = (assetUrl) => {
    const publicAssetUrlArr = [
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg",
    ];
    if (false === publicAssetUrlArr.includes(assetUrl)) {
      assetUrl = `${assetUrl}&access_token=${UserService.getUser().access_token}`;
    }
    return assetUrl;
  };

  handleCenteredImageChange = (img) => {
    if (img.target.width > img.target.height) {
      img.target.style.width = "85%";
    } else {
      img.target.style.height = "85%";
    }
  };

  handleFullscreenImageChange = (img) => {
    img.target.style.objectFit = "cover";
    if (img.target.width > img.target.height) {
      img.target.style.height = "110%";
      img.target.style.width = "100%";
    } else {
      img.target.style.width = "110%";
    }
  };

  renderCardData(data) {
    if (null !== data.text) {
      return <S.CardItemTypeText dangerouslySetInnerHTML={{ __html: data.text }} />;
    }
    if (null !== data.centered_image) {
      return (
        <S.CardItemTypeImage onLoad={this.handleCenteredImageChange} src={this.getAssetUrl(data.centered_image)} />
      );
    }
    if (null !== data.fullscreen_image) {
      return (
        <S.CardItemTypeImage onLoad={this.handleFullscreenImageChange} src={this.getAssetUrl(data.fullscreen_image)} />
      );
    }
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, "-").toLowerCase();
  }

  handleToggleFlipCard = (e, id) => {
    const { flippedItemsIds } = this.state;

    const cardId = id || e.target.id;

    if (flippedItemsIds.includes(cardId)) {
      this.setState({
        flippedItemsIds: flippedItemsIds.filter((id) => id !== cardId),
      });
    } else {
      this.setState({
        flippedItemsIds: [...flippedItemsIds, cardId],
      });
    }
  };

  handlePrevCard = () => {
    const { visibleCardIndex } = this.state;

    if (visibleCardIndex > 0) {
      this.setState({
        visibleCardIndex: visibleCardIndex - 1,
      });
    }
  };

  handleNextCard = () => {
    const { content } = this.props;
    const { items = [] } = content;
    const { visibleCardIndex } = this.state;

    if (visibleCardIndex < items.length - 1) {
      this.setState({
        visibleCardIndex: visibleCardIndex + 1,
      });
    }
  };

  renderProgress = () => {
    const { content } = this.props;
    const { visibleCardIndex } = this.state;
    const { items, border_color } = content;
    const current = visibleCardIndex + 1;
    const total = items.length;
    const percentage = (100 / total) * current + "%";
    return (
      <S.Progress>
        <S.ProgressLabel>
          <S.ProgressBar style={{ width: percentage, background: border_color }} />
        </S.ProgressLabel>

        <S.ProgressCounter style={{ color: border_color }}>
          {current}/{total}
        </S.ProgressCounter>
      </S.Progress>
    );
  };

  handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      this.handlePrevCard();
    } else if (e.key === "ArrowRight") {
      this.handleNextCard();
    } else if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
      e.preventDefault();
      const { content } = this.props;
      const { items } = content;
      const { visibleCardIndex } = this.state;
      const cardId = items[visibleCardIndex].id;
      this.handleToggleFlipCard(e, cardId);
    }
  };

  render() {
    const { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;
    const {
      background_color,
      padding_top,
      padding_bottom,
      font_family,
      card_height = 240,
      card_width = 240,
      items = [],
      border_color,
    } = content;
    const { flippedItemsIds, visibleCardIndex } = this.state;

    return (
      <S.ElementFlashcards2
        tabIndex="1"
        className={`${className} ${this.getFont(font_family) || ""}`}
        backgroundColor={background_color}
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
        onKeyDown={this.handleKeyDown}
      >
        {headerActionsTemplate}

        <S.CardList maxHeight={card_height}>
          {items.map((item, index) => (
            <S.CardListItem
              key={item.id}
              id={item.id}
              tabIndex="1"
              role="button"
              type="button"
              onClick={this.handleToggleFlipCard}
              maxWidth={card_width}
              maxHeight={card_height}
              aspectRatio={card_width / card_height}
              position={index === visibleCardIndex ? "center" : index < visibleCardIndex ? "left" : "right"}
            >
              <S.CardInner isFlipped={flippedItemsIds.includes(item.id)}>
                <S.CardFront
                  style={{
                    borderTopColor: border_color,
                  }}
                >
                  {this.renderCardData(item.front_card)}
                </S.CardFront>

                <S.CardBack
                >
                  {this.renderCardData(item.back_card)}
                </S.CardBack>
              </S.CardInner>
            </S.CardListItem>
          ))}
        </S.CardList>

        <S.NavigationContainer maxWidth={card_width}>
          <S.ChangeCardButton
            tabIndex="1"
            type="button"
            onClick={this.handlePrevCard}
            backgroundColor={border_color}
            disabled={visibleCardIndex === 0}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
            </svg>
          </S.ChangeCardButton>

          {this.renderProgress()}

          <S.ChangeCardButton
            tabIndex="1"
            type="button"
            onClick={this.handleNextCard}
            backgroundColor={border_color}
            disabled={visibleCardIndex === items.length - 1}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </S.ChangeCardButton>
        </S.NavigationContainer>

        {footerActionsTemplate}
      </S.ElementFlashcards2>
    );
  }
}

export default ElementFlashcard2;
