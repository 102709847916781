import React, { Component } from 'react';
import {LoginService} from '../../services/login';
import {UserService} from '../../services/user';
import RDP_CONFIG from '../../config';
import './header.css';

import {
  Preloader
} from '../../components';

export default class Header extends Component {
  constructor(props) {
    super(props);

    let userInfo = UserService.getUserInfo()
    const projects = UserService.getProjects();

    this.state = {
      "username": userInfo.detail === undefined ? userInfo.name : userInfo.detail.name,
      // userInfo.detail.name != null ? userInfo.detail.name : userInfo.login,
      "projects": projects,
      "active_project": UserService.getActiveProject(),
      "active_user_dropdown": false,
      "active_projects_dropdown": false,
      "displayed_preloader": false,
      "labels": this.props.labels
    };
  }

  logout() {
    this.toggleUserDropdown();
    LoginService.logout().then(function(data) {
      window.location = window.location.origin + window.location.pathname + "#/login";
    });
  }

  changeProject(project) {
    this.setState({
      "displayed_preloader": true
    });
    var userData = UserService.getUser();
    var type = userData.type;
    var accessToken = userData.access_token;

    UserService.setActiveProject(project.uid, type, accessToken).then(function(projectInfo) {
      window.location.reload();
    })
  }

  renderProjectsDropdown() {
    var _this = this;
    var activeProject = this.state.active_project;
    var projects = this.state.projects;
    if (this.state.active_projects_dropdown === true) {
      return <div className="rdp-composer-header-projects-dropdown-container">
        <div className="rdp-composer-header-projects-dropdown-overlay" onClick={() => this.toggleProjectsDropdown()}></div>
        <ul className="rdp-composer-header-projects-dropdown-popup">
          {projects.map(function(project, index) {
            return <li key={index} className={`rdp-composer-header-projects-dropdown-popup-name ${activeProject.uid === project.uid ? "active" : ""}`} onClick={() => _this.changeProject(project)}>{project.name}</li>
          })}
        </ul>
      </div>;
    };
  }

  toggleProjectsDropdown() {
    this.setState({
      "active_projects_dropdown": !this.state.active_projects_dropdown
    });
  }

  renderUserDropdown(labels) {
    if (this.state.active_user_dropdown === true) {
      return <div className="rdp-composer-header-user-dropdown-container">
        <div className="rdp-composer-header-user-dropdown-overlay" onClick={() => this.toggleUserDropdown()}></div>
        <div className="rdp-composer-header-user-dropdown-popup">
          {/* <div className="rdp-composer-header-user-dropdown-user">
            <span className="rdp-composer-header-user-dropdown-user-avatar"></span>
            <span className="rdp-composer-header-user-dropdown-user-name">{this.state.username}</span>
          </div> */}
          <span className="rdp-composer-header-user-dropdown-signout" onClick={() => this.logout()}>{labels.buttons.sign_out}</span>
        </div>
      </div>;
    };
  }

  toggleUserDropdown() {
    this.setState({
      "active_user_dropdown": !this.state.active_user_dropdown
    });
  }

  renderPreloader() {
    if (this.state.displayed_preloader === true) {
      return <Preloader
        opacity={0.8}
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
    }
  }

  getCurrentLanguage() {
    var language = RDP_CONFIG.language;
    if (language === "en") {
      return "English";
    } else if (language === "pt_br") {
      return "Português (pt-br)"
    }
  }

  render() {
    var labels = this.state.labels;
    var activeProjectName = this.state.active_project !== null ? this.state.active_project.name : "";
    return (
      <header className="rdp-composer-header">
        {this.renderPreloader()}
        <div className="rdp-composer-header-logo"></div>
        {/* <span className="rdp-composer-header-active-project" onClick={() => this.toggleProjectsDropdown()}>{activeProjectName}</span>
        <span className="rdp-composer-header-avatar" onClick={() => this.toggleUserDropdown()}></span>
        {this.renderProjectsDropdown()}
        {this.renderUserDropdown()} */}

        {/* <div className="rdp-composer-header-active-project" onClick={() => this.toggleProjectsDropdown()}>
          <span className="rdp-composer-header-active-project-label">{labels.buttons.language_label}</span>
          <span className="rdp-composer-header-active-project-name">
            {this.getCurrentLanguage()}
          </span>
        </div> */}

        <div className="rdp-composer-header-active-project" onClick={() => this.toggleProjectsDropdown()}>
          <span className="rdp-composer-header-active-project-label">{labels.buttons.project_label}</span>
          <span className="rdp-composer-header-active-project-name">
            {activeProjectName}
          </span>
        </div>

        <div className="rdp-composer-header-active-user" onClick={() => this.toggleUserDropdown()}>
          <span className="rdp-composer-header-active-user-avatar"></span>
          <span className="rdp-composer-header-active-user-hello">{labels.buttons.hello_label},</span>
          <span className="rdp-composer-header-active-user-name">
            {this.state.username}
          </span>
        </div>
        {this.renderProjectsDropdown()}
        {this.renderUserDropdown(labels)}
      </header>
    );
  }
}
