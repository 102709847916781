import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {UserService} from '../../services/user';
import './element-hotspots-1-properties.css';
import {
  RDPDamWidget, ColorPicker, RelToolbarQuill, FontPicker
} from '../../components';
import * as S from "./element-hotspots-1-properties.styled";
import markers from "../../components/element-spot/markers";
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

const tabs = {
  "settings": "settings",
  "spots": "spots",
}

export default class ElementHotspots1Properties extends Component {
  state = {
    content: this.props.content,
    dam_widget_opened: false,
    active_tab: tabs.settings,
    expanded_spot_id: null,
    spot_image_modal_open: false,
    spot_audio_modal_open: false
  }

  onImageChange = () => {
    this.setState({
      "dam_widget_opened": true
    })
  }

  onAlternativeTextChange = (e) => {
    var content = this.state.content;
    content.image_alt = e.currentTarget.value !== "" ? e.currentTarget.value : content.image_alt;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotTitleChange = (e, id) => {
    const content = this.state.content;

    content.spots = content.spots.map(spot => {
      if (spot.id === id) {
        spot.title = e.currentTarget.value !== "" ? e.currentTarget.value : spot.title;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotDescriptionChange = (text, id) => {
    const content = this.state.content;

    content.spots = content.spots.map(spot => {
      if (spot.id === id) {
        spot.description = text;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange = (color) => {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotColorChange = (color) => {
    var content = this.state.content;
    content.spot_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onWidthChange = (e) => {
    var content = this.state.content;
    content.width = e.currentTarget.value;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange = (e) => {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange = (e) => {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange = (asset) => {
    var content = this.state.content;
    content.image = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onRDPDamWidgetClose = () => {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget = () => {
    if (this.state.dam_widget_opened === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="image"
        onClose={() => this.onRDPDamWidgetClose()}
        onAssetChange={(asset) => this.onAssetChange(asset)}
      />;
    } else if (this.state.spot_image_modal_open === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="image"
        onClose={() => this.onSpotImageModalClose()}
        onAssetChange={(asset) => this.onSpotImageChange(asset)}
      />;
    } else if (this.state.spot_audio_modal_open === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="audio"
        onClose={() => this.onSpotAudioModalClose()}
        onAssetChange={(asset) => this.onSpotAudioChange(asset)}
      />;
    }
  }

  onSpotImageModalClose = () => {
    this.setState({
      "spot_image_modal_open": false
    })
  }

  onSpotAudioModalClose = () => {
    this.setState({
      "spot_audio_modal_open": false
    })
  }

  onSpotImageRemove = (id) => {
    var content = this.state.content;
    content.spots = content.spots.map(spot => {
      if (spot.id === id) {
        spot.image = null;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotAudioRemove = (id) => {
    var content = this.state.content;
    content.spots = content.spots.map(spot => {
      if (spot.id === id) {
        spot.audio = null;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotImageChange = (asset) => {
    var content = this.state.content;
    content.spots = content.spots.map(spot => {
      if (spot.id === this.state.expanded_spot_id) {
        spot.image = asset;
      }
      return spot;
    });

    this.setState({
      "content": content,
      "spot_image_modal_open": false
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotAudioChange = (asset) => {
    var content = this.state.content;
    content.spots = content.spots.map(spot => {
      if (spot.id === this.state.expanded_spot_id) {
        spot.audio = asset;
      }
      return spot;
    });

    this.setState({
      "content": content,
      "spot_audio_modal_open": false
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotIconChange = (icon, id) => {
    const content = this.state.content;

    content.spots = content.spots.map(spot => {
      if (spot.id === id) {
        spot.icon = icon;
      }
      return spot;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  getImageUrl = (image) => {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    }
    return image;
  }

  getAudioUrl = (audio) => {
    const accessToken = UserService.getUser().access_token;
    audio += "&access_token=" + accessToken;
    return audio;
  }

  handleDeleteSpot = (id) => {
    var content = this.state.content;
    content.spots = content.spots.filter(spot => spot.id !== id);
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onChangeTab(target) {
    this.setState({
      "active_tab": target
    })
  }

  handleExpandSpot = (id) => {
    if (this.state.expanded_spot_id === id) {
      this.setState({
        "expanded_spot_id": null
      })
    } else {
      this.setState({
        "expanded_spot_id": id
      })
    }
  }

  orderAsc = (a, b) => {
    return parseInt(a) - parseInt(b);
  }

  handleOnDragEndSpots = (result, spots = []) => {
    if (!result.destination) return;

    const items = Array.from(spots);

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const content = this.state.content;

    content.spots = items
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  };


  onFontTitleChange(font) {
    var content = this.state.content;
    content.font_family_title = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    })
  }


  renderFontTitle() {
    var content = this.state.content;
    var labels = this.props.labels;

    if (content.font_family_title) {
      return <FontPicker font={content.font_family_title} onFontChange={(font) => this.onFontTitleChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontTitleChange(font)} noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  onFontTextChange(font) {
    var content = this.state.content;
    content.font_family_text = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    })
  }


  renderFontText() {
    var content = this.state.content;
    var labels = this.props.labels;

    if (content.font_family_text) {
      return <FontPicker font={content.font_family_text} onFontChange={(font) => this.onFontTextChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontTextChange(font)} noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;

    return (
      <div className="rdp-element-image-properties">
        <ul className="rdp-composer-element-edit-tabs">
          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.settings ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.settings)}
          >
            {labels.elements.common_settings.label_tab_settings}
          </li>

          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.spots ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.spots)}
          >
            {labels.elements.settings.element_hotspots_1.settings_label_tab_spots}
          </li>
        </ul>

        <div className="rdp-composer-element-edit-body">
          {this.state.active_tab === tabs.settings && (
            <>
              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_background_image}:</span>
              <img className="rdp-composer-element-image" onClick={() => this.onImageChange()}
                   alt={content.image_alt || ""}
                   src={this.getImageUrl(content.image)}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_alternative_text}:</span>
              <input type="text" className="rdp-composer-element-edit-input"
                     onChange={(e) => this.onAlternativeTextChange(e)} defaultValue={content.image_alt}></input>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_background_color}</span>
              <ColorPicker labels={this.props.labels} color={content.background_color}
                           onChange={this.onBackgroundColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_spot_color}</span>
              <ColorPicker labels={this.props.labels} color={content.spot_color} onChange={this.onSpotColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_image_width}</span>
              <select defaultValue={content.width} className="rdp-composer-element-edit-select" onChange={(e) => this.onWidthChange(e)}>
                <option value="small">{labels.elements.settings.element_hotspots_1.settings_option_width_small}</option>
                <option value="medium">{labels.elements.settings.element_hotspots_1.settings_option_width_medium}</option>
                <option value="full">{labels.elements.settings.element_hotspots_1.settings_option_width_full}</option>
                <option value="page_width">{labels.elements.settings.element_hotspots_1.settings_option_page_width}</option>
              </select>

              <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_margin_top}</span>
              <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

              <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_margin_bottom}</span>
              <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

              <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_font_title}:</span>
              {this.renderFontTitle()}

              <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_font_text}:</span>
              {this.renderFontText()}

              <PreferenceRequiredInteraction
                label={labels.elements.settings.preference_required_interaction.label}
                labels={labels}
                content={content}
                onContentChange={c => {
                  this.setState({ content: c });
                  this.props.onContentChange(c);
                }}
              />
            </>
          )}

          {this.state.active_tab === tabs.spots && (
            <>
              <DragDropContext
                onDragEnd={(result) => {
                  this.handleOnDragEndSpots(result, this.state.content.spots);
                }}
              >
                <Droppable droppableId="spots">
                  {(provided) => (
                    <ol ref={provided.innerRef} {...provided.droppableProps}>
                      {
                        content.spots.map((spot, index) => (
                          <Draggable
                            key={spot.id}
                            isDragDisabled={false}
                            draggableId={spot.id}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <S.SpotItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <S.SpotHead
                                    tabIndex="1"
                                    role="button"
                                    onClick={() => this.handleExpandSpot(spot.id)}
                                  >
                                    <S.SpotItemHeadLeft>
                                      <S.MoveButton {...provided.dragHandleProps}>
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 24 24"
                                        >
                                          <path fill="currentColor"
                                                d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z"/>
                                        </svg>
                                      </S.MoveButton>

                                      {labels.elements.settings.element_hotspots_1.settings_label_spot} {index + 1}
                                    </S.SpotItemHeadLeft>

                                    <S.SpotItemHeadRight>
                                      <S.IconRemove
                                        width="18"
                                        height="18"
                                        viewBox="0 0 768 768"
                                        onClick={() => this.handleDeleteSpot(spot.id)}
                                      >
                                        <path fill="currentColor"
                                              d="M607.5 127.5v64.5h-447v-64.5h111l33-31.5h159l33 31.5h111zM192 607.5v-384h384v384c0 34.5-30 64.5-64.5 64.5h-255c-34.5 0-64.5-30-64.5-64.5z"></path>
                                      </S.IconRemove>

                                      <S.IconDown
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        isExpanded={this.state.expanded_spot_id === spot.id}
                                      >
                                        <path fill="currentColor"
                                              d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                                      </S.IconDown>
                                    </S.SpotItemHeadRight>
                                  </S.SpotHead>

                                  <S.SpotItemBody
                                    show={this.state.expanded_spot_id === spot.id}
                                  >
                                    <span
                                      className="rdp-composer-element-edit-label">{labels.elements.settings.element_hotspots_1.settings_label_title}:</span>
                                    <input
                                      type="text"
                                      className="rdp-composer-element-edit-input"
                                      onChange={(e) => this.onSpotTitleChange(e, spot.id)}
                                      defaultValue={spot.title}
                                    />

                                    <span
                                      className="rdp-composer-element-edit-label">
                                          {labels.elements.settings.element_hotspots_1.settings_label_description}:
                                        </span>
                                    <div style={{marginTop: "10px", marginBottom: "25px"}}>
                                      <RelToolbarQuill
                                        onChange={(text) => this.onSpotDescriptionChange(text, spot.id)}
                                        value={spot.description}
                                      />
                                    </div>

                                    <S.FieldContainer>
                                      <span
                                        className="rdp-composer-element-edit-label">
                                        {labels.elements.settings.element_hotspots_1.settings_label_spot_style}:
                                      </span>

                                      <S.SpotIconsList>
                                        <S.SpotIconPreviewContainer>
                                          <S.SpotIconPreview
                                            tabIndex="1"
                                            role="button"
                                            color={this.state.content.spot_color}
                                          >
                                            {markers[spot.icon]()}
                                          </S.SpotIconPreview>
                                        </S.SpotIconPreviewContainer>

                                        {Object.keys(markers).sort(this.orderAsc).map((key) => (
                                          <S.SpotIcon
                                            key={key}
                                            selected={spot.icon === key}
                                            onClick={() => this.onSpotIconChange(key, spot.id)}
                                          >
                                            {markers[key]()}
                                          </S.SpotIcon>
                                        ))}
                                      </S.SpotIconsList>
                                    </S.FieldContainer>

                                    <S.FieldContainer>
                                      <span
                                        className="rdp-composer-element-edit-label"
                                      >
                                        {labels.elements.settings.element_hotspots_1.settings_label_spot_image}:
                                      </span>
                                      {
                                        spot.image ? (
                                          <>
                                            <img
                                              className="rdp-composer-element-image"
                                              onClick={() => this.onImageChange()}
                                              alt="spot image"
                                              src={this.getImageUrl(spot.image)}
                                            />
                                            <S.SpotImageRemoveButton
                                              onClick={() => this.onSpotImageRemove(spot.id)}
                                            >
                                              {labels.elements.settings.element_hotspots_1.settings_button_remove_image}
                                            </S.SpotImageRemoveButton>
                                          </>
                                        ) : (
                                          <S.SpotImageUpload
                                            tabIndex="1"
                                            type="button"
                                            onClick={() => {
                                              this.setState({
                                                spot_image_modal_open: true,
                                              });
                                            }}
                                          >
                                            {labels.elements.settings.element_hotspots_1.settings_button_upload_image}

                                            <svg width="48" height="48" viewBox="0 0 24 24">
                                              <path fill="currentColor" d="M4,5H7L9,3H15L17,5H20A2,2 0 0,1 22,7V19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19V7A2,2 0 0,1 4,5M13.09,9.45L11.05,12.18L12.6,14.25L11.73,14.91L9.27,11.64L6,16H18L13.09,9.45Z" />
                                            </svg>
                                          </S.SpotImageUpload>
                                        )
                                      }
                                    </S.FieldContainer>

                                    <S.FieldContainer>
                                      <span
                                        className="rdp-composer-element-edit-label"
                                      >
                                        {labels.elements.settings.element_hotspots_1.settings_label_spot_audio}:
                                      </span>
                                      {
                                        spot.audio ? (
                                          <>
                                            <S.SpotAudioPreview src={this.getAudioUrl(spot.audio)} controls>
                                              O seu navegador não suporta o elemento <code>audio</code>.
                                            </S.SpotAudioPreview>

                                            <S.SpotImageRemoveButton
                                              onClick={() => this.onSpotAudioRemove(spot.id)}
                                            >
                                              {labels.elements.settings.element_hotspots_1.settings_button_remove_audio}
                                            </S.SpotImageRemoveButton>
                                          </>
                                        ) : (
                                          <S.SpotImageUpload
                                            tabIndex="1"
                                            type="button"
                                            onClick={() => {
                                              this.setState({
                                                spot_audio_modal_open: true,
                                              });
                                            }}
                                          >
                                            {labels.elements.settings.element_hotspots_1.settings_button_upload_audio}

                                            <svg width="48" height="48" viewBox="0 0 24 24">
                                              <path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13,13H11V18A2,2 0 0,1 9,20A2,2 0 0,1 7,18A2,2 0 0,1 9,16C9.4,16 9.7,16.1 10,16.3V11H13V13M13,9V3.5L18.5,9H13Z" />
                                            </svg>
                                          </S.SpotImageUpload>
                                        )
                                      }
                                    </S.FieldContainer>
                                  </S.SpotItemBody>
                                </S.SpotItem>
                              </>
                            )}
                          </Draggable>
                        ))
                      }
                    </ol>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </div>
        {this.renderDAMWidget()}
      </div>
    );
  }
}
