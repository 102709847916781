export const useConnectors = () => {
  const getConnectors = () => {
    try {
      return JSON.parse(window.localStorage.getItem("rdp-composer-active-project")).connectors;
    } catch (err) {
      console.log(err);
    }
  };

  const getConnectorDetails = (field = "service", value = "Insights", shouldHaveFeature) => {
    try {
      const connectors = getConnectors();
      if (!shouldHaveFeature) {
        return connectors.find((item) => item[field] === value);
      }

      return connectors.find((item) => item[field] === value && item.features.includes(shouldHaveFeature));
    } catch (err) {
      console.log(err);
    }
  };

  const getConnectorShouldHaveFeature = (feature) => {
    try {
      const connectors = getConnectors();
      return connectors.find((item) => item.features.includes(feature));
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getConnectors,
    getConnectorDetails,
    getConnectorShouldHaveFeature,
  };
};
