import React, {Component} from 'react';
import {UserService} from '../../services/user';
import * as S from "./element-steps-1.styled";


class ElementSteps1 extends Component {
  itemRef = React.createRef();
  state = {
    itemsStringfy: JSON.stringify(this.props.content.items),
    visibleCardIndex: 0,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Redefine o estado para evitar exibir um item que deixou de existir ou está oculto
    if (JSON.stringify(nextProps.content.items) !== prevState.itemsStringfy) {
      return {
        itemsStringfy: JSON.stringify(nextProps.content.items),
        visibleCardIndex: 0,
      };
    }
    return null;
  }

  getLabels = () => {
    const {contentLanguage} = this.props;

    const labels = {
      "pt_br": {
        "retry": "Tentar novamente",
        "start": "Iniciar",
        "step": "Passo",
      },
      "en": {
        "retry": "Start again",
        "start": "Start",
        "step": "Step",
      }
    };

    return labels[contentLanguage || "pt_br"];
  }

  getAssetUrl = (assetUrl) => {
    const publicAssetUrlArr = [
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg"
    ];
    if (false === publicAssetUrlArr.includes(assetUrl)) {
      assetUrl = `${assetUrl}&access_token=${UserService.getUser().access_token}`;
    }
    return assetUrl;
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  handlePrevCard = () => {
    const {
      visibleCardIndex,
    } = this.state;

    if (visibleCardIndex > 0) {
      this.setState({
        visibleCardIndex: visibleCardIndex - 1,
      })
    }
  }

  handleNextCard = () => {
    const {
      content,
    } = this.props;
    const {
      items = [],
    } = content;
    const {
      visibleCardIndex,
    } = this.state;

    if (visibleCardIndex < items.length - 1) {
      this.setState({
        visibleCardIndex: visibleCardIndex + 1,
      })
    }
  }

  handleResetCard = () => {
    this.setState({
      visibleCardIndex: 0,
    })
  }

  handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      this.handlePrevCard();
    } else if (e.key === "ArrowRight") {
      this.handleNextCard();
    }
  }

  getCurrentItemHeight = () => {
    if (this.itemRef.current) {
      return this.itemRef.current.offsetHeight;
    }
  }

  isVisibleItem = (item) => {
    return false === item.isHidden
  }

  getItemsLength = () => {
    const {
      content,
    } = this.props;
    const {
      items = [],
    } = content;
    const hasIntro = items.find(item => false === item.isHidden && "intro" === item.type);
    let count = items.filter(this.isVisibleItem).length;

    if (hasIntro) {
      return count - 1;
    }

    return count;
  }

  getItemIndex = (i) => {
    const {
      content,
    } = this.props;
    const {
      items = [],
    } = content;
    const hasHiddenIntro = items.find(item => false === item.isHidden && "intro" === item.type);

    if (hasHiddenIntro) {
      return i + 1;
    }
    return i;
  }

  render() {
    const {
      headerActionsTemplate,
      footerActionsTemplate,
      content,
      className
    } = this.props;
    const {
      background_color,
      padding_top,
      padding_bottom,
      font_family,
      primary_color,
      items = [],
    } = content;
    const {
      visibleCardIndex,
    } = this.state;

    const filteredItems = items.filter(this.isVisibleItem);

    return (
      <S.ElementSteps1
        tabIndex="1"
        className={`${className} ${this.getFont(font_family) || ""}`}
        backgroundColor={background_color}
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
        onKeyDown={this.handleKeyDown}
      >
        {headerActionsTemplate}

        <S.NavigationContainer>
          <S.ChangeCardButton
            className="change-step-1-button"
            tabIndex="1"
            type="button"
            onClick={this.handlePrevCard}
            backgroundColor={primary_color}
            disabled={visibleCardIndex === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="36"
              height="36"
            >
              <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"/>
            </svg>
          </S.ChangeCardButton>

          <S.CardList
            minHeight={this.getCurrentItemHeight() + 30}
          >
            {filteredItems.map((item, index) => (
              <S.Card
                key={item.id}
                show={visibleCardIndex === index}
                backgroundColor={background_color}
                primaryColor={primary_color}
                position={index === visibleCardIndex ? "center" : index < visibleCardIndex ? "left" : "right"}
              >
                {
                  ("intro" !== item.type && "summary" !== item.type) && (
                    <S.StepBadge
                      primaryColor={primary_color}
                    >
                      {this.getLabels().step} {(items[0].type === "intro" && items[0].isHidden) ? index + 1 : index}
                    </S.StepBadge>
                  )
                }

                <S.CardTitle
                  align={("intro" === item.type  || "summary" === item.type) ? "center" : "left"}
                  dangerouslySetInnerHTML={{__html: item.title}}
                />

                {item.image && (
                  <S.CardImage
                    src={this.getAssetUrl(item.image)}
                  />
                )}

                {item.audio && (
                  <S.CardAudio
                    src={this.getAssetUrl(item.audio)}
                    controls
                  />
                )}

                <S.CardDescription
                  dangerouslySetInnerHTML={{__html: item.description}}
                />

                {item.type === "intro" && (
                  <S.StartButton
                    tabIndex="1"
                    type="button"
                    onClick={this.handleNextCard}
                    backgroundColor={primary_color}
                  >
                    {this.getLabels().start}
                  </S.StartButton>
                )}

                {item.type === "summary" && (
                  <S.RetryButton
                    tabIndex="1"
                    type="button"
                    onClick={this.handleResetCard}
                    fontColor={primary_color}
                  >
                    {this.getLabels().retry}
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 768 768"
                    >
                      <path fill="currentColor" d="M625.504 469.344c-23.552 66.656-71.84 117.44-131.008 145.696s-128.992 33.952-195.648 10.4-117.44-71.84-145.696-131.008-33.952-128.992-10.4-195.648 71.84-117.44 131.008-145.696 128.992-33.952 195.648-10.4c38.016 13.44 70.688 34.816 95.584 60.224l90.368 85.088h-111.36c-17.664 0-32 14.336-32 32s14.336 32 32 32h191.904c0.128 0 0.288 0 0.416 0 4.384-0.032 8.544-0.96 12.352-2.624 3.808-1.632 7.328-4.032 10.368-7.2 0.384-0.384 0.736-0.8 1.088-1.216 2.432-2.784 4.288-5.888 5.6-9.184 1.312-3.264 2.080-6.816 2.208-10.496 0.064-0.448 0.064-0.896 0.064-1.344v-191.936c0-17.664-14.336-32-32-32s-32 14.336-32 32v117.92l-93.312-87.84c-31.776-32.384-72.768-59.072-119.936-75.712-83.328-29.472-170.752-22.336-244.608 12.96s-134.304 98.816-163.744 182.144-22.272 170.72 12.992 244.576 98.816 134.304 182.144 163.744 170.72 22.304 244.576-12.992 134.304-98.816 163.744-182.144c5.888-16.672-2.848-34.944-19.52-40.832s-34.944 2.848-40.832 19.52z"></path>
                    </svg>
                  </S.RetryButton>
                )}

                {item.type !== "intro" && (
                  <S.ProgressList>
                    {
                      Array.from({ length: this.getItemsLength() }, (_, i) => (
                        <S.ProgressItem
                          key={i}
                          tabIndex="1"
                          role="button"
                          onClick={() => this.setState({
                            visibleCardIndex: this.getItemIndex(i),
                          })}
                          active={visibleCardIndex === this.getItemIndex(i)}
                          primaryColor={primary_color}
                        >
                          {items[i+1].type === "summary" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                            >
                              <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                            </svg>
                          ) : i + 1}
                        </S.ProgressItem>
                      ))
                    }
                  </S.ProgressList>
                )}
              </S.Card>
            ))}
          </S.CardList>

          <S.ChangeCardButton
            className="change-step-1-button"
            tabIndex="1"
            type="button"
            onClick={this.handleNextCard}
            backgroundColor={primary_color}
            disabled={visibleCardIndex === filteredItems.length - 1}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="36"
              height="36"
            >
              <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
            </svg>
          </S.ChangeCardButton>
        </S.NavigationContainer>

        {footerActionsTemplate}
      </S.ElementSteps1>
    )
  }
}

export default ElementSteps1;
