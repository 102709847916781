import * as React from "react";
export const IconConfused = () => (
  <svg
    width="100px"
    height="100px"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    className="iconify iconify--noto"
    preserveAspectRatio="xMidYMid meet"
  >
    <radialGradient
      id="IconifyId17ecdb2904d178eab6131"
      cx={63.6}
      cy={1400.9}
      r={56.96}
      gradientTransform="translate(0 -1338)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0.5} stopColor="#fde030" />
      <stop offset={0.92} stopColor="#f7c02b" />
      <stop offset={1} stopColor="#f4a223" />
    </radialGradient>
    <path
      d="M63.6 118.8c-27.9 0-58-17.5-58-55.9S35.7 7 63.6 7c15.5 0 29.8 5.1 40.4 14.4c11.5 10.2 17.6 24.6 17.6 41.5s-6.1 31.2-17.6 41.4c-10.6 9.3-25 14.5-40.4 14.5z"
      fill="url(#IconifyId17ecdb2904d178eab6131)"
    />
    <path
      d="M111.49 29.67c5.33 8.6 8.11 18.84 8.11 30.23c0 16.9-6.1 31.2-17.6 41.4c-10.6 9.3-25 14.5-40.4 14.5c-18.06 0-37-7.35-48.18-22.94c10.76 17.66 31 25.94 50.18 25.94c15.4 0 29.8-5.2 40.4-14.5c11.5-10.2 17.6-24.5 17.6-41.4c0-12.74-3.47-24.06-10.11-33.23z"
      fill="#eb8f00"
    />
    <g>
      <path
        d="M44 40.94c-4.19 0-8 3.54-8 9.42s3.81 9.41 8 9.41c4.2 0 8-3.54 8-9.41s-3.76-9.42-8-9.42z"
        fill="#422b0d"
      />
      <path
        d="M43.65 44.87a2.874 2.874 0 0 0-3.82 1.34c-.53 1.11-.29 2.44.6 3.3c1.42.68 3.13.08 3.82-1.34c.53-1.11.29-2.44-.6-3.3z"
        fill="#896024"
      />
      <path
        d="M82.4 40.94c-4.19 0-8 3.54-8 9.42s3.81 9.41 8 9.41c4.19 0 8-3.54 8-9.41s-3.81-9.42-8-9.42z"
        fill="#422b0d"
      />
      <path
        d="M82 44.87a2.874 2.874 0 0 0-3.82 1.34c-.53 1.11-.29 2.44.6 3.3c1.42.68 3.13.08 3.82-1.34c.53-1.11.29-2.44-.6-3.3z"
        fill="#896024"
      />
      <path
        d="M40 91.94c7.07-5.28 15.3-8.8 24-10.27A55.408 55.408 0 0 1 90 83c1.41.43 2.51-1.25 1.36-2.09c-7.53-5.48-17.89-8-28.65-6.06S43.13 82.7 38 90.46c-.81 1.18.8 2.38 2 1.48z"
        fill="#422b0d"
      />
    </g>
  </svg>
);
