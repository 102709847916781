import styled from "styled-components";

export const IconList = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 20px auto 0;
  max-width: 760px;
`;

export const Icon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  gap: 3px;
  p {
    font-size: 12px;
  }
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }

  span {
    font-size: 12px;
    margin-top: 10px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
