import React, {Component} from 'react';
import * as S from "./element-sorted-activity-1.styled";

class ItemCard extends Component {
  state = {
    isDragging: false,
  }

  handleDragStart = (e) => {
    e.dataTransfer.setData("text", e.target.id);
    this.setState({
      isDragging: true,
    });
  }

  handleDrag = (e) => {}

  handleDragEnd = (e) => {
    this.setState({
      isDragging: false,
    });
  }

  getCursor = () => {
    const {
      draggable,
    } = this.props;
    const {
      isDragging,
    } = this.state;

    if (isDragging) {
      return "grabbing";
    } else if (draggable) {
      return "grab";
    }

    return "default"
  }

  render() {
    const {
      id,
      title,
      primaryColor,
      backgroundColor,
      draggable,
      isCorrect,
      color,
    } = this.props;
    const {
      isDragging,
    } = this.state;

    return (
      <S.ItemCard
        id={id}
        draggable={draggable ? "true" : "false"}
        borderColor={primaryColor}
        backgroundColor={backgroundColor}
        fontColor={color}
        cursorType={this.getCursor()}
        isDragging={isDragging}
        onDragStart={this.handleDragStart}
        onDrag={this.handleDrag}
        onDragEnd={this.handleDragEnd}
        title={title}
        isCorrect={isCorrect}
      >
        <p>
          {title}
        </p>
      </S.ItemCard>
    );
  }
}

export default ItemCard;