import React from "react";

export const InputPoints = ({ value, choice, question, content, onChange }) => (
  <>
    <span className="rdp-composer-element-edit-label" style={{ marginTop: 15 }}>
      Pontuação
    </span>
    <input
      className="rdp-composer-element-edit-input"
      type="number"
      value={value}
      onChange={(e) => {
        content = {
          ...content,
          questions: content.questions.map((item) => {
            if (item.id !== question.id) return item;
            return {
              ...question,
              choices: question.choices.map((itemChoice) => {
                if (itemChoice.id !== choice.id) return itemChoice;
                return {
                  ...choice,
                  value: e.target.value,
                };
              }),
            };
          }),
        };

        onChange(content);
      }}
    />
  </>
);
