import React from "react";
import { createGlobalStyle, css } from "styled-components";

const INITIAL_ELEMENT_PREFERENCES = [
  {
    name: "Hiperlinks",
    color: "#cccccc",
    value: "themeLinkColor",
  },
  {
    name: "Textos",
    color: "#f5e042",
    value: "themeTextColor",
  },
  {
    name: "Texto selecionado",
    color: "#6ff542",
    value: "themeTextSelectedColor",
  },
  {
    name: "Texto desabilitado",
    color: "#fdffd1",
    value: "themeTextDesabilited",
  },
];

export const getColors = (settings) => {
  var elementColors = {};
  INITIAL_ELEMENT_PREFERENCES.forEach((item) => {
    if (settings[item.value]) {
      elementColors = {
        ...elementColors,
        [item.value]: settings[item.value].replace("%23", "#"),
      };
      return;
    }
    elementColors[item.value] = item.color;
  });
  return elementColors;
};

const sizes = {
  normal: 1,
  big: 1.2,
  xbig: 1.5,
};

export const OverrideTheme = ({ settings }) => {
  const colors = getColors(settings);
  const font = sizes[settings.themeFontsize] || 0;
  const blackContrast = settings.themeHighContrastColor == "%23000000";
  const isDark = settings.themeMode === "dark";

  const pxToRem = (value) => {
    if (font) return `${(value * font) / 16}rem`;
    return `${value / 16}rem`;
  };

  const isHighContrast = settings.themeContrast == "config";

  const Globalcontrast = createGlobalStyle`
      * {
        //OVERRIDE COLORS IF CONTRAST IS CHECKED AS BOLD
        ${
          isHighContrast &&
          css`
            .disabled {
              color: ${colors.themeTextDesabilited};
            }
            &::selection {
              background: ${colors.themeTextSelectedColor};
              color: #fff;
            }
            a {
              color: ${colors.themeLinkColor} !important;
            }
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            span,
            .rdp-component-configurations-modal .rdp-composer-element-edit-select,
            .rdp-component-configurations-modal .rdp-composer-element-edit-textarea,
            .rdp-component-configurations-modal .rdp-composer-element-edit-input,
            .rdp-component-configurations-modal .rdp-component-configurations-tab,
            .rdp-component-configurations-modal .rdp-component-configurations-tab.active,
            fieldset {
              &:not(
                  .ql-editor h1 span,
                  .rdp-composer-home-composition-author,
                  .rdp-composer-home-composition-date,
                  .ql-editor h2 span,
                  .ql-editor h3 span,
                  .ql-editor h4 span,
                  .ql-editor p span,
                  .rdp-composer-structure *
                ) {
                color: ${colors.themeTextColor} !important;
              }
            }
          `
        }

        ${
          isDark &&
          css`
            .rdp-composer-element-edit-input,
            .rdp-composer-element-edit-textarea,
            .rdp-component-configurations-modal,
            .rdp-composer-element-edit-select,
            .ql-bubble .ql-tooltip,
            .rdp-composer-sidebar-subitems,
            .element-flashcards-1-properties-item:hover,
            .element-flashcards-1-properties-item-text,
            .ql-bubble .ql-picker-options,
            .rdp-composer-element-edit-modal-theme-dark,
            .rdp-composer-home-composition,
            .element-quiz-1-properties-choice:nth-child(even),
            .element-accordion-properties-item:hover .ql-toolbar,
            .element-list-properties-item:hover .ql-toolbar,
            .ql-tooltip .ql-toolbar,
            .ql-tooltip .ql-toolbar:hover,
            .element-list-properties-item {
              background: ${blackContrast ? "#111111" : "#212b36"} !important;
            }
            .rdp-composer-home-composition-versions {
              ${blackContrast && `background: #000 !important;`}
            }
            .ql-bubble .ql-stroke,
            .ql-bubble .ql-fill,
            .ql-bubble .ql-stroke.ql-fill {
              stroke: #fff !important;
            }
            .rdp-component-configurations-header-title,
            .rdp-composer-element-edit-label,
            .rdp-composer-element-edit-select,
            .element-list-properties-item-label,
            .element-flashcards-1-properties-item-label,
            .ql-bubble .ql-tooltip .ql-picker-item.ql-selected,
            .ql-bubble .ql-tooltip .ql-picker-label.ql-active {
              color: #fff !important;
            }
          `
        }

        //OVERRIDE SIZE FONTS IF SETTLED IN SETTINGS FROM THEME
        span:not(
          .ql-editor h1 span, 
          .rdp-composer-home-composition-author, 
          .rdp-composer-home-composition-date, 
          .rdp-composer-home-composition-versions,
          .ql-editor h2 span, 
          .ql-editor h3 span, 
          .ql-editor h4 span
          ), 
          li, 
          a, 
          p {
          /* font-size: ${pxToRem(13)} !important; */
        }

        .rdp-composer-home-composition-versions,
        .rdp-composer-home-composition-author, 
        .rdp-composer-home-composition-date {
            /* font-size: ${pxToRem(10)} !important; */
        }
        ${
          font === 1.5 &&
          css`
            .rdp-composer-sidebar-btn:nth-of-type(6) {
              width: 120px;
            }
          `
        }
      }
    `;

  return (
    <div>
      <Globalcontrast />
    </div>
  );
};
