import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {UserService} from '../../services/user';
import {
  ColorPicker, RelToolbarQuill, FontPicker
} from '../../components';
import * as S from "./element-button-1-properties.styled";
import uuidv1 from "uuid/v1";
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

const tabs = {
  "settings": "settings",
  "items": "items",
}

export default class ElementButton1Properties extends Component {
  state = {
    content: this.props.content,
    dam_widget_opened: false,
    active_tab: tabs.items,
    expanded_item_id: null,
    item_image_modal_open: false,
    item_audio_modal_open: false
  }

  componentDidMount() {
    if (this.state.content.type === "button-1") {
      this.setState({
        expanded_item_id: this.state.content.items[0].id
      })
    }
  }

  onImageChange = () => {
    this.setState({
      "dam_widget_opened": true
    })
  }

  onLabelChange = (e, id) => {
    const content = this.state.content;

    content.items = content.items.map(item => {
      if (item.id === id) {
        item.label = e.currentTarget.value !== "" ? e.currentTarget.value : item.label;
      }
      return item;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onDestinationChange = (e, id) => {
    const content = this.state.content;

    content.items = content.items.map(item => {
      if (item.id === id) {
        item.destination = e.currentTarget.value !== "" ? e.currentTarget.value : item.destination;
      }
      return item;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onChangeType = (e, id) => {
    const content = this.state.content;

    content.items = content.items.map(item => {
      if (item.id === id) {
        item.type = e.currentTarget.value !== "" ? e.currentTarget.value : item.type;
      }
      return item;
    });

    console.log("content", content);

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onDescriptionChange = (text, id) => {
    const content = this.state.content;

    content.items = content.items.map(item => {
      if (item.id === id) {
        item.description = text;
      }
      return item;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange = (color) => {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPrimaryColorChange = (color) => {
    var content = this.state.content;
    content.primary_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange = (e) => {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange = (e) => {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange = (asset) => {
    var content = this.state.content;
    content.image = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onRDPDamWidgetClose = () => {
    this.setState({
      "dam_widget_opened": false
    })
  }

  onSpotImageModalClose = () => {
    this.setState({
      "item_image_modal_open": false
    })
  }

  onSpotAudioModalClose = () => {
    this.setState({
      "item_audio_modal_open": false
    })
  }

  onSpotImageRemove = (id) => {
    var content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === id) {
        item.image = null;
      }
      return item;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotAudioRemove = (id) => {
    var content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === id) {
        item.audio = null;
      }
      return item;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotImageChange = (asset) => {
    var content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === this.state.expanded_item_id) {
        item.image = asset;
      }
      return item;
    });

    this.setState({
      "content": content,
      "item_image_modal_open": false
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotAudioChange = (asset) => {
    var content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === this.state.expanded_item_id) {
        item.audio = asset;
      }
      return item;
    });

    this.setState({
      "content": content,
      "item_audio_modal_open": false
    }, function () {
      this.props.onContentChange(content)
    });
  }

  getImageUrl = (image) => {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    }
    return image;
  }

  getAudioUrl = (audio) => {
    const accessToken = UserService.getUser().access_token;
    audio += "&access_token=" + accessToken;
    return audio;
  }

  handleDeleteSpot = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    var content = this.state.content;
    content.items = content.items.filter(item => item.id !== id);
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  handleHiddenItem = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === id) {
        item.isHidden = !item.isHidden;
      }
      return item;
    });
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onChangeTab(target) {
    this.setState({
      "active_tab": target
    })
  }

  handleExpandSpot = (id) => {
    if (this.state.expanded_item_id === id) {
      this.setState({
        "expanded_item_id": null
      })
    } else {
      this.setState({
        "expanded_item_id": id
      })
    }
  }

  handleOnDragEndSpots = (result, items = []) => {
    if (!result.destination) return;

    const itemsCpy = Array.from(items);

    const [reorderedItem] = itemsCpy.splice(result.source.index, 1);
    let destinationIndex = result.destination.index;

    itemsCpy.splice(destinationIndex, 0, reorderedItem);

    const content = this.state.content;

    content.items = itemsCpy
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  };

  onAddItem() {
    var content = this.state.content;
    var items = content.items;
    const summary = items.find(item => item.type === "summary");
    let itemsWithoutSummary = items.filter(item => item.type !== "summary");

    itemsWithoutSummary.push({
      "id": uuidv1(),
      "type": "link",
      "label": "Button",
      "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed.",
      "destination": "",
    });

    if (summary) {
      itemsWithoutSummary.push(summary);
    }

    content.items = itemsWithoutSummary;

    this.setState({
      "content": content
    })
  }

  onFontTitleChange(font) {
    var content = this.state.content;
    content.font_family_button = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    })
  }


  renderFontButton() {
    var content = this.state.content;
    var labels = this.props.labels;

    if (content.font_family_button) {
      return <FontPicker font={content.font_family_button} onFontChange={(font) => this.onFontTitleChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontTitleChange(font)} noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  onFontTextChange(font) {
    var content = this.state.content;
    content.font_family_text = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    })
  }


  renderFontText() {
    var content = this.state.content;
    var labels = this.props.labels;

    if (content.font_family_text) {
      return <FontPicker font={content.font_family_text} onFontChange={(font) => this.onFontTextChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontTextChange(font)} noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;

    return (
      <div className="rdp-element-image-properties">
        <ul className="rdp-composer-element-edit-tabs">
          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.items ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.items)}
          >
            {labels.elements.settings.element_button_1.settings_label_tab_items}
          </li>

          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.settings ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.settings)}
          >
            {labels.elements.common_settings.label_tab_settings}
          </li>
        </ul>

        <div className="rdp-composer-element-edit-body">
          {this.state.active_tab === tabs.settings && (
            <>
              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_button_1.settings_label_background_color}</span>
              <ColorPicker labels={this.props.labels} color={content.background_color}
                           onChange={this.onBackgroundColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_button_1.settings_label_item_color}</span>
              <ColorPicker labels={this.props.labels} color={content.primary_color} onChange={this.onPrimaryColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_button_1.settings_label_margin_top}</span>
              <input type="number" className="rdp-composer-element-edit-input px"
                     onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_button_1.settings_label_margin_bottom}</span>
              <input type="number" className="rdp-composer-element-edit-input px"
                     onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

              <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_button_1.settings_label_font_button}:</span>
              {this.renderFontButton()}

              <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_button_1.settings_label_font_text}:</span>
              {this.renderFontText()}

              <PreferenceRequiredInteraction
                label={labels.elements.settings.preference_required_interaction.label}
                labels={labels}
                content={content}
                onContentChange={c => {
                  this.setState({ content: c });
                  this.props.onContentChange(c);
                }}
              />
            </>
          )}

          {this.state.active_tab === tabs.items && (
            <>
              <DragDropContext
                onDragEnd={(result) => {
                  this.handleOnDragEndSpots(result, this.state.content.items);
                }}
              >
                <Droppable droppableId="items">
                  {(provided) => (
                    <ol ref={provided.innerRef} {...provided.droppableProps}>
                      {
                        content.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            isDragDisabled={content.type === "button-1"}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <S.SpotItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <S.SpotHead
                                    tabIndex="1"
                                    role="button"
                                    onClick={() => content.type === "button-2" ? this.handleExpandSpot(item.id) : null}
                                  >
                                    <S.SpotItemHeadLeft>
                                      {
                                        content.type === "button-2" && (
                                          <S.MoveButton
                                            {...provided.dragHandleProps}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}
                                          >
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                            >
                                              <path fill="currentColor"
                                                    d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z"/>
                                            </svg>
                                          </S.MoveButton>
                                        )
                                      }

                                      <>
                                        {labels.elements.settings.element_button_1.settings_label_button} {index + 1}
                                      </>
                                    </S.SpotItemHeadLeft>

                                    <S.SpotItemHeadRight>
                                      {
                                        content.type === "button-2" && (
                                          <>
                                            <S.IconRemove
                                              width="18"
                                              height="18"
                                              viewBox="0 0 768 768"
                                              onClick={(e) => this.handleDeleteSpot(e, item.id)}
                                            >
                                              <path fill="currentColor"
                                                    d="M607.5 127.5v64.5h-447v-64.5h111l33-31.5h159l33 31.5h111zM192 607.5v-384h384v384c0 34.5-30 64.5-64.5 64.5h-255c-34.5 0-64.5-30-64.5-64.5z"></path>
                                            </S.IconRemove>
                                            <S.IconDown
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                              isExpanded={this.state.expanded_item_id === item.id}
                                            >
                                              <path
                                                fill="currentColor"
                                                d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                                              />
                                            </S.IconDown>
                                          </>
                                        )
                                      }
                                    </S.SpotItemHeadRight>
                                  </S.SpotHead>

                                  <S.SpotItemBody
                                    show={this.state.expanded_item_id === item.id}
                                  >
                                    <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_button_1.settings_label_type}</span>
                                    <select defaultValue={item.type} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeType(e, item.id)}>
                                      <option value="link">{labels.elements.settings.element_button_1.settings_label_type_link}</option>
                                      <option value="email">{labels.elements.settings.element_button_1.settings_label_type_email}</option>
                                    </select>

                                    <span
                                      className="rdp-composer-element-edit-label">{labels.elements.settings.element_button_1.settings_label_destination}:</span>
                                    <input
                                      type="text"
                                      className="rdp-composer-element-edit-input"
                                      onChange={(e) => this.onDestinationChange(e, item.id)}
                                      defaultValue={item.destination}
                                      placeholder={item.type === "link" ? "https://" : "example@email.com"}
                                    />

                                    <span
                                      className="rdp-composer-element-edit-label">{labels.elements.settings.element_button_1.settings_label_label}:</span>
                                    <input
                                      type="text"
                                      className="rdp-composer-element-edit-input"
                                      onChange={(e) => this.onLabelChange(e, item.id)}
                                      defaultValue={item.label}
                                    />

                                    <span
                                      className="rdp-composer-element-edit-label">
                                          {labels.elements.settings.element_button_1.settings_label_description}:
                                        </span>
                                    <div style={{marginTop: "10px", marginBottom: "25px"}}>
                                      <RelToolbarQuill
                                        onChange={(text) => this.onDescriptionChange(text, item.id)}
                                        value={item.description}
                                      />
                                    </div>
                                  </S.SpotItemBody>
                                </S.SpotItem>
                              </>
                            )}
                          </Draggable>
                        ))
                      }
                    </ol>
                  )}
                </Droppable>
              </DragDropContext>

              {
                content.type === "button-2" && (
                  <S.AddButton onClick={() => this.onAddItem()}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 640 640">
                      <path fill="currentColor" d="M352 288h128v64h-128v128h-64v-128h-128v-64h128v-128h64v128zM320 640c-176.731 0-320-143.269-320-320s143.269-320 320-320v0c176.731 0 320 143.269 320 320s-143.269 320-320 320v0zM320 576c141.385 0 256-114.615 256-256s-114.615-256-256-256v0c-141.385 0-256 114.615-256 256s114.615 256 256 256v0z"></path>
                    </svg>
                    {labels.elements.settings.element_timeline_1.content_label_btn_add_item}
                  </S.AddButton>
                )
              }
            </>
          )}
        </div>
      </div>
    );
  }
}
