import React, { Component } from 'react';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import {UserService} from '../../services/user';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import 'animate.css/animate.min.css';
import './element-tex-1.css';

export default class ElementTex1 extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // this.resizeWebview();
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    var imageStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }

    return <div className={this.props.className} style={imageStyle}>
    {headerActionsTemplate}

    <BlockMath
      displayMode={true}
      math={'\\int_0^\\infty x^2 dx'}
    ></BlockMath>

    {footerActionsTemplate}
  </div>
  }
}
