import React, {Component} from 'react';
import * as S from "./element-button-1.styled";
import { getTheme } from '../../utils/getTheme';

class ElementButton1 extends Component {
  state = {
    content: [],
  }

  getFont = (font) => font ? font.replace(/\s/g, '-').toLowerCase() : "";

  getDestinationUrl = (item) => {
    switch (item.type) {
      case "link":
        return item.destination;
      case "email":
        return `mailto:${item.destination}`;
      default:
        return item.destination;
    }
  }

  render() {
    const {
      headerActionsTemplate,
      footerActionsTemplate,
      content,
      className
    } = this.props;
    const {
      background_color,
      primary_color,
      padding_top,
      padding_bottom,
      font_family,
      items = [],
      font_family_button,
      font_family_text
    } = content;

    return (
      <S.ElementButton1
        className={`${className} ${this.getFont(font_family) || ""}`}
        backgroundColor={background_color}
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
      >
        {headerActionsTemplate}

        <S.ButtonList
          className="element-button-1__button-list"
        >
          {items.map(item => (
            <S.ButtonItem
              className="element-button-1__button-item"
              key={item.id}
            >
              <S.ButtonLabel
                className={`${this.getFont(font_family_text) || ""}`}
                htmlFor={item.id}
                dangerouslySetInnerHTML={{__html: item.description}}
              />

              <S.ButtonLink
                className={`element-button-1__button ${this.getFont(font_family_button) || ""}`}
                id={item.id}
                tabIndex="1"
                href={this.getDestinationUrl(item)}
                target="_blank"
                primaryColor={primary_color}
              >
                {item.label}
              </S.ButtonLink>
            </S.ButtonItem>
          ))}
        </S.ButtonList>

        {footerActionsTemplate}
      </S.ElementButton1>
    );
  }
}

export default ElementButton1;