import React from "react";
import * as S from "./navigation.styled";
import Progress from "../element-progress/progress";


function Navigation(props) {
  const {
    onClickPrevius,
    onClickNext,
    onClickFinish,
    labels,
    primaryColor,
    currentProgress = 1,
    totalProgress = 2,
    showFinishButton = false,
    disableFinishButton = true,
  } = props;

  const renderPreviousQuestionBtn = () => {
    if (currentProgress !== 1) {
      return (
        <S.Button
          tabIndex={1}
          onClick={onClickPrevius}
          primaryColor={primaryColor}
        >
          {labels.previous}
        </S.Button>
      )
    }
  }

  const renderNextQuestionBtn = () => {
    return (
      <S.Button
        onClick={onClickNext}
        primaryColor={primaryColor}
      >
        {labels.next}
      </S.Button>
    )
  }

  const renderFinishBtn = () => {
    return (
      <S.ContainedButton
        tabIndex={1}
        primaryColor={primaryColor}
        onClick={onClickFinish}
        disabled={disableFinishButton}
      >
        {labels.finish}
      </S.ContainedButton>
    )
  }

  return (
    <S.Navigation>
      <S.ButtonContainer
        justify="left"
      >
        {renderPreviousQuestionBtn()}
      </S.ButtonContainer>

      <Progress
        primaryColor={primaryColor}
        currentProgress={currentProgress}
        totalProgress={totalProgress}
      />

      <S.ButtonContainer
        justify="right"
      >
        {
          showFinishButton
            ? renderFinishBtn()
            : renderNextQuestionBtn()
        }
      </S.ButtonContainer>
    </S.Navigation>
  );
}

export default Navigation;