import styled from "styled-components";

const TextAreaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 40px;
  padding-top: 0;
`

const TextArea = styled.textarea`
  display: block;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  width: calc(100% - 60px);
  resize: vertical;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  font-size: 16px;
  padding: 10px;
  
  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    resize: none;
  }
  
  &:focus {
    border: 2px solid ${props => props.primaryColor || "#2d7b45"};
  }
`

const FeedbackContainer = styled.div`
  padding: 20px 10px 20px 0;
  transition: all 0.3s ease-out;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContainerFinishButton = styled.div`
  button {
    position: absolute;
    right: 0;
    top: 10px;
    margin-right: 10px;
  }
`

const ContainedButton = styled.button`
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 30px;
  font-size: 12px;
  border: 0;
  font-family: Lato-Bold, serif;
  background: ${props => props.background || "#2d7b45"};
  color: ${props => props.color || "#fff"};
  border-radius: 30px;
  cursor: pointer;
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  &:focus {
    outline: 2px solid ${props => props.background || "#2d7b45"} !important;
    outline-offset: 2px !important;
  }
`

const TextButton = styled.button`
  transition: all 0.3s ease-out;
  padding: 6px;
  width: fit-content;
  display: block;
  margin: 40px auto;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.color || "#2d7b45"};
  
  &:hover {
    border: 1px dashed ${props => props.color || "#2d7b45"};
  }

  &:focus {
    outline: 2px solid ${props => props.color || "#2d7b45"} !important;
    outline-offset: 2px !important;
  }
`

const RemakeButtonContainer = styled.div`
  margin: 40px auto;
`

const RemakeButtonIcon = styled.span`
  width: 25px;
  height: 38px;
  display: block;
  margin: auto;
  background-size: 100%;
  text-align: center;
`

const RemakeButtonText = styled.span`
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
`

const NextButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 10px;

  button {
    margin: 0 10px 0 0;
    padding: 10px 20px;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;

    &:hover {
      border-color: transparent;
    }
  }
`

const PrevButtonContainer = styled.div`
  position: absolute;
  left: 0;
  top: 10px;

  button {
    margin: 0 0 0 10px;
    padding: 10px 20px;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;

    &:hover {
      border-color: transparent;
    }
  }
`

export {
  FeedbackContainer,
  TextAreaContainer,
  TextArea,
  ContainerFinishButton,
  ContainedButton,
  TextButton,
  RemakeButtonContainer,
  RemakeButtonIcon,
  RemakeButtonText,
  NextButtonContainer,
  PrevButtonContainer
}