import React from "react";
import * as S from "./quiz-card.styled";

function QuizCard(props) {
  const {
    children,
    ...rest
  } = props;

  return (
    <S.QuizCard
      className="rdp-element-quiz__quiz-card"
      {...rest}
    >
      {children}
    </S.QuizCard>
  )
}

export default QuizCard;