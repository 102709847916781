import axios from "axios";
import RDP_CONFIG from "../../config";
import { useConnectors } from "../../hooks/useConnectors";

const api = axios.create({
  baseURL: `https://${RDP_CONFIG.domain}/`,
});

var userData = JSON.parse(window.localStorage.getItem("rdp-composer-user-data"));

if (userData) {
  var type = userData.type;
  var accessToken = userData.access_token;
  api.interceptors.request.use(
    (config) => {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: type + " " + accessToken,
        "Project-Key": RDP_CONFIG.projectKey,
        "Api-Env": RDP_CONFIG.apiEnv,
      };
      return config;
    },
    (error) => Promise.reject(error)
  );
}

const getGroupTree = async (schemaUid) => {
  try {
    const response = await api.get(`/auth/v1.1/entity/schema/uid/${schemaUid}/items`);

    return response.data.sort((group, sort) => group.row - sort.row);
  } catch (err) {
    console.log(err);
  }
};

export const getConnectorSkillsDetail = async (connectorUid) => {
  try {
    const response = await api.get(`/auth/v1.1/connector/uid/${connectorUid}/management`);
    return response;
  } catch (err) {
    console.log(err);
  }
};

const getSchemas = async (schemasIds) => {
  try {
    const schemaPromises = [];
    schemasIds.forEach((uid) => {
      schemaPromises.push(getGroupTree(uid));
    });

    const entityList = [];

    await Promise.all(schemaPromises).then((res) => {
      if (res && res.length > 0) {
        res.forEach((entities) => entityList.push(...entities));
      }
    });

    return entityList || [];
  } catch (err) {
    return [];
  }
};

const CONNECTOR_PROFICIENCY_NAME = "IConnectorProficiencyManagement";

export const getSkills = async (context = "skills") => {
  const { getConnectorShouldHaveFeature } = useConnectors();

  const connector = getConnectorShouldHaveFeature(CONNECTOR_PROFICIENCY_NAME);

  try {
    const connectorDetails = await getConnectorSkillsDetail(connector.uid);

    if (!connectorDetails) return [];

    const { authorized_entity_target_schemas: proficiencyIds, authorized_entity_context_schemas: schemaSkillsIds } =
      connectorDetails.data.configuration;

    const skills = await getSchemas(context === "skills" ? schemaSkillsIds : proficiencyIds);
    return skills;
  } catch (err) {
    console.log(err);
  }
};
