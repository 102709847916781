import React, { Component } from 'react';
import uuidv1 from 'uuid/v1';
import { UserService } from '../../services/user';
import './element-tabs-properties.css';
import {
  RDPDamWidget, ColorPicker, FontPicker, RelToolbarQuill
} from '../../components';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

export default class ElementTabsProperties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "dam_widget_opened": false,
      "active_tab": "content",
      "rdp_dam_widget_type": null,
      "media_active_item": null
    }

    this.onMediaChange = this.onMediaChange.bind(this);
    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.onActiveTabColorChange = this.onActiveTabColorChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
  }

  onMediaChange(type, index) {
    this.setState({
      "rdp_dam_widget_type": type,
      "media_active_item": index
    }, function () {
      this.setState({
        "dam_widget_opened": true
      })
    })
  }

  onMediaRemove(index) {
    var content = this.state.content;
    var items = content.items;
    items[index].image = null;
    items[index].video = null;
    this.setState({
      "content": content
    });
  }

  onActiveTabColorChange(color) {
    var content = this.state.content;
    content.active_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange(color) {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange(asset) {
    var content = this.state.content;
    var items = content.items;
    var activeItem = this.state.media_active_item;
    items[activeItem].image = null;
    items[activeItem].video = null;
    items[this.state.media_active_item][this.state.rdp_dam_widget_type] = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onTitleChange(e, index) {
    var content = this.state.content;
    var items = content.items;
    items[index].title = e.currentTarget.value;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onTextChange(text, index) {
    var content = this.state.content;
    var items = content.items;
    items[index].text = text;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onRDPDamWidgetClose() {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget() {
    if (this.state.dam_widget_opened === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type={this.state.rdp_dam_widget_type}
        onClose={() => this.onRDPDamWidgetClose()}
        onAssetChange={(asset) => this.onAssetChange(asset)}
      />;
    }
  }

  getAssetUrl(asset) {
    var accessToken = UserService.getUser().access_token;
    return asset += "&access_token=" + accessToken;
  }

  onChangeTab(target) {
    this.setState({
      "active_tab": target
    })
  }

  onRemoveItem(index) {
    var content = this.state.content;
    var items = content.items;
    var temp = [];
    for (var i = 0; i < items.length; i++) {
      if (i !== index) {
        temp.push(items[i])
      }
    }
    content.items = temp;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    })
  }

  onUploadClick(index) {
    var content = this.state.content;
    var items = content.items;
    items[index].displayed_media_dropdown = true;
    this.setState({
      "content": content
    });
  }

  onAddItem() {
    var content = this.state.content;
    var items = content.items;
    items.push({
      "id": uuidv1(),
      "title": "Item " + (items.length + 1),
      "text": '<p><span style="font-size: 18px;">Lorem ipsum </span><strong style="font-size: 18px;">dolor sit amet</strong><span style="font-size: 18px;">, consectetur adipiscing elit. Curabitur tempor massa ut luctus dictum. Suspendisse consectetur ex cursus, tincidunt orci sit amet, cursus ligula. Quisque feugiat malesuada ligula, vitae iaculis tellus bibendum vel. Aenean et quam sollicitudin, sagittis nulla eget, blandit arcu. Etiam fermentum nunc nisl, in porttitor dui viverra quis. Etiam fringilla dolor id lobortis congue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec faucibus eu felis id suscipit.</span></p>',
      "image": null,
      "video": null
    });
    this.setState({
      "content": content
    })
  }

  renderItemMedia(item, index, labels) {
    if (item.image !== null) {
      return <div className="element-tabs-properties-item-image-container">
        <img className="element-tabs-properties-item-image" src={this.getAssetUrl(item.image)} />
        <span className="element-tabs-properties-item-image-remove" onClick={() => this.onMediaRemove(index)}>{labels.elements.settings.element_tabs.content_label_btn_remove_asset}</span>
      </div>;
    } else if (item.video !== null) {
      return <div className="element-tabs-properties-item-video-container">
        <video className="element-tabs-properties-item-video" src={this.getAssetUrl(item.video)} />
        <span className="element-tabs-properties-item-video-remove" onClick={() => this.onMediaRemove(index)}>{labels.elements.settings.element_tabs.content_label_btn_remove_asset}</span>
      </div>;
    } else {
      return <div className="element-tabs-properties-item-upload-media">
        <button className="element-tabs-properties-item-upload-image" onClick={() => this.onMediaChange("image", index)}>{labels.elements.settings.element_tabs.content_label_insert_image}</button>
        <button className="element-tabs-properties-item-upload-video" onClick={() => this.onMediaChange("video", index)}>{labels.elements.settings.element_tabs.content_label_insert_video}</button>
      </div>
    }
  }

  onFontChange(font) {
    var content = this.state.content;
    content.font_family = font;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  renderFont() {
    var content = this.state.content;
    var labels = this.props.labels;

    if (content.font_family) {
      return <FontPicker font={content.font_family} onFontChange={(font) => this.onFontChange(font)}/>
    } else {
      return <FontPicker hasFont={false} onFontChange={(font) => this.onFontChange(font)} noFontLabel={labels.elements.common_settings.label_no_has_font}/>
    }
  }

  renderContent() {
    var _this = this;
    var activeTab = this.state.active_tab;
    var content = this.state.content;
    var labels = this.props.labels;
    if (activeTab === "content") {
      var items = content.items;
      return <>
        <ul>
          {items.map(function (item, index) {
            return <li key={item.id} className="element-tabs-properties-item">
              <div className="element-tabs-properties-item-drag"></div>
              <span className="element-tabs-properties-item-remove" onClick={() => _this.onRemoveItem(index)}></span>
              <span className="element-tabs-properties-item-label">{labels.elements.settings.element_tabs.content_label_title}</span>
              <input type="text" defaultValue={item.title} onChange={(e) => _this.onTitleChange(e, index)} className="element-tabs-properties-item-title"></input>
              <span className="element-tabs-properties-item-label">{labels.elements.settings.element_tabs.content_label_description}</span>
              <RelToolbarQuill onChange={(e) => _this.onTextChange(e, index)} value={item.text} />
              {_this.renderItemMedia(item, index, labels)}
            </li>
          })}
        </ul>
        <button className="element-tabs-properties-item-new" onClick={() => this.onAddItem()}>{labels.elements.settings.element_tabs.content_label_btn_add_item}</button>
      </>
    } else if (activeTab === "configurations") {
      return <div className="element-tabs-properties-configurations">
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_tabs.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)} defaultValue={content.content_title}></input>
        
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_tabs.settings_label_background_color}</span>
        <ColorPicker labels={this.props.labels} color={content.background_color} onChange={this.onBackgroundColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_tabs.settings_label_active_tab_color}:</span>
        <ColorPicker labels={this.props.labels} color={content.active_color} onChange={this.onActiveTabColorChange} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_tabs.settings_label_margin_top}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_tabs.settings_label_margin_bottom}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_tabs.settings_label_label_font}:</span>
        {this.renderFont()}

        <PreferenceRequiredInteraction
          title={labels.elements.settings.preference_required_interaction.title}
          label={labels.elements.settings.preference_required_interaction.label}
          labels={labels}
          content={content}
          onContentChange={c => {
            this.setState({ content: c });
            this.props.onContentChange(c);
          }}
        />
      </div>
    }
  }

  render() {
    var content = this.state.content;
    var activeTab = this.state.active_tab;
    var labels = this.props.labels;

    return (<div className="rdp-element-tabs-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className={`rdp-composer-element-edit-tabs-item ${activeTab === "content" ? "active" : ""}`} onClick={() => this.onChangeTab("content")}>{labels.elements.common_settings.label_tab_content}</li>
        <li className={`rdp-composer-element-edit-tabs-item ${activeTab === "configurations" ? "active" : ""}`} onClick={() => this.onChangeTab("configurations")}>{labels.elements.common_settings.label_tab_settings}</li>
      </ul>
      <div className="rdp-composer-element-edit-body">
        {this.renderContent()}
      </div>
      {this.renderDAMWidget()}
    </div>);
  }
}
