import React from "react";
import * as S from "./quiz-text-input.styled";

function QuizTextInput(props) {
  const {
    disabled,
    primaryColor,
    onChange = (value) => {},
    value = "",
  } = props;
  return (
    <S.TextInputContainer>
      <S.TextInput
        tabIndex={1}
        value={value}
        onChange={event => {
          onChange(event.target.value);
        }}
        disabled={disabled}
        primaryColor={primaryColor}
      />
    </S.TextInputContainer>
  );
}

export default QuizTextInput;