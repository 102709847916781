import React from 'react';

function useIsChoiceSelected(activeChoices, choice, question) {
  switch (question.type) {
    case "quiz-1":
      return activeChoices[question.id] === choice.id;
    case "quiz-2":
      return activeChoices[question.id] &&  activeChoices[question.id].includes(choice.id);
    case "quiz-3":
      return String(activeChoices[question.id]).toLowerCase() === String(choice.text).toLowerCase();
    case "quiz-4":
      return String(activeChoices[question.id]) && (activeChoices[question.id].find(item => item.sourceId === choice.id && item.destinationId === choice.id) !== undefined);
    default:
      return null;
  }
}

export default useIsChoiceSelected;