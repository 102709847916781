import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;
  padding-top: ${(props) => props.paddingTop || 30}px;
  padding-bottom: ${(props) => props.paddingBottom || 30}px;

  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`};

  overflow: hidden;
  position: relative;

  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  :hover {
    border-top: 1px dashed #ccc;
    border-bottom: 1px dashed #ccc;
  }
`;
