import React, { Component } from 'react';
import { UserService } from '../../services/user';
import './element-tex-1-properties.css';
import {
  RDPDamWidget, ColorPicker
} from '../../components';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

export default class ElementTex1Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "dam_widget_opened": false
    }

    this.onImageChange = this.onImageChange.bind(this);
    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
    this.onZoomHeaderBackgroundColorChange = this.onZoomHeaderBackgroundColorChange.bind(this);
    this.onZoomIconsColorChange = this.onZoomIconsColorChange.bind(this);
  }

  onImageChange() {
    this.setState({
      "dam_widget_opened": true
    })
  }

  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }
  
  onBackgroundColorChange(color) {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onZoomHeaderBackgroundColorChange(color) {
    var content = this.state.content;
    content.zoom_header_background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onZoomIconsColorChange(color) {
    var content = this.state.content;
    content.zoom_icons_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onWidthChange(e) {
    var content = this.state.content;
    content.width = e.currentTarget.value !== "" ? e.currentTarget.value : content.width;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange(asset) {
    var content = this.state.content;
    content.image = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onZoomChange(zoom) {
    var content = this.state.content;
    content.zoom = zoom;
    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onZoomMaxChange(level) {
    var content = this.state.content;
    content.zoom_max_level = parseInt(level);
    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onRDPDamWidgetClose() {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget() {
    if (this.state.dam_widget_opened === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="image"
        onClose={() => this.onRDPDamWidgetClose()}
        onAssetChange={(asset) => this.onAssetChange(asset)}
      />;
    }
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  renderWidthInput(labels) {
    var content = this.state.content;
    var type = content.type;
    if (type === "image-1") {
      return <>
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_image_width}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onWidthChange(e)} defaultValue={content.width}></input>
      </>
    }
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;

    return (<div className="rdp-element-image-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className="rdp-composer-element-edit-tabs-item active">{labels.elements.common_settings.label_tab_settings}</li>
      </ul>
      <div className="rdp-composer-element-edit-body">
      <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_content_title}:</span>
        <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)} defaultValue={content.content_title}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_background_image}:</span>
        <img className="rdp-composer-element-image" onClick={() => this.onImageChange()} alt="Composer" src={this.getImageUrl(content.image)} />

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_background_color}</span>
        <ColorPicker labels={this.props.labels} color={content.background} onChange={this.onBackgroundColorChange} />

        {this.renderWidthInput(labels)}

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_margin_top}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_margin_bottom}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>


        <span className="rdp-composer-element-edit-label">Zoom</span>
        <select defaultValue={content.zoom || "disabled"} className="rdp-composer-element-edit-select" onChange={(e) => this.onZoomChange(e.target.value)}>
          <option value="enabled">Habilitado</option>
          <option value="disabled">Desabilitado</option>
        </select>

        {content.zoom === "enabled" &&
          <>
            <span className="rdp-composer-element-edit-label">Zoom: Nível máximo</span>
            <select defaultValue={content.zoom_max_level || "3"} className="rdp-composer-element-edit-select" onChange={(e) => this.onZoomMaxChange(e.target.value)}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>

            <span className="rdp-composer-element-edit-label">Zoom: Cor de fundo do cabeçalho</span>
            <ColorPicker labels={this.props.labels} color={content.zoom_header_background_color || "#FFFFFF"} onChange={this.onZoomHeaderBackgroundColorChange}/>

            <span className="rdp-composer-element-edit-label">Zoom: Cor dos ícones</span>
            <ColorPicker labels={this.props.labels} color={content.zoom_icons_color || "#000000"} onChange={this.onZoomIconsColorChange}/>
          </>
        }

        <PreferenceRequiredInteraction
          title={labels.elements.settings.preference_required_interaction.title}
          label={labels.elements.settings.preference_required_interaction.label}
          labels={labels}
          content={content}
          onContentChange={c => {
            this.setState({ content: c });
            this.props.onContentChange(c);
          }}
        />
      </div>
      {this.renderDAMWidget()}
    </div>);
  }
}
