import React, { Component } from 'react';
import './preloader.css';

export default class Preloader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var style = {
      "backgroundColor": `rgba(255, 255, 255, ${this.props.opacity})`,
      "top": `${this.props.top}px`,
      "bottom": `${this.props.bottom}px`,
      "left": `${this.props.left}px`,
      "right": `${this.props.right}px`
    }

    return (
      <div className="rdp-component-preloader" style={style}></div>
    );
  }
}
