import $ from 'jquery';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import RDP_CONFIG from '../../config';
import {DamService} from '../../services/dam';
import {UserService} from '../../services/user';
import { MessagesService } from '../../services/messages';
import './element-messages.css';

export default class ElementEdit extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getMessages = this.getMessages.bind(this);

    this.messageInputRef = React.createRef();

    this.myInterval = setInterval(this.getMessages, 500);

    this.state = {
      "content": this.props.content,
      "render_template_options": false,
      "render_template_new": false,
      "templates": null,
      "template_name": null,
      "saving_template": false,
      "render_template_save_success": false,
      "render_template_save_error": false,
      "render_templates_list": false,
      "applying_template": false,
      "template_search_term": "",
      "messages": null
    };
  }

  componentWillMount() {
    this.getMessages(true)
  }

  componentDidMount() {
    console.log(this.props)
    this.triggerSendInput()
  }

  triggerSendInput() {
    var _this = this;
    _this.messageInputRef.current.addEventListener("keyup", function(e) {
      var key = e.which || e.keyCode;
      if (key == 13) {
        _this.sendMessage()
      }
    });
  }

  onChange(type, data) {
    var content = this.state.content;
    this.props.onChange(content);
  }

  onContentChange(content) {
    this.setState({
      "content": content
    })
  }
  saveProperties() {
    this.props.onChange(this.state.content);
  }

  onAlertSuccessClose() {
    this.setState({
      "render_template_save_success": false
    })
  }

  onAlertErrorClose() {
    this.setState({
      "render_template_save_error": false
    })
  }

  openTemplatesList() {
    this.getDAMTemplates();

    this.setState({
      "render_template_options": false,
      "render_templates_list": true
    });
  }

  closeTemplatesList() {
    this.setState({
      "templates": null,
      "render_template_options": false,
      "render_templates_list": false
    });
  }

  async getMessages(handleScroll) {
    var messages = await MessagesService.getObjectMessages(this.props.object_uid);
    this.setState({
      "messages": messages
    })

    if (handleScroll) {
      var elem = document.querySelector(".rdp-composer-element-messages-items");
      elem.scrollTo(0, elem.scrollHeight);
    }
  }

  async sendMessage() {
    const {
      user_uid,
      content_uid,
      object_uid,
    } = this.props;

    var mess = this.messageInputRef.current.value;

    if (this.messageInputRef.current.value !== "") {
      this.messageInputRef.current.value = "";
      await MessagesService.postObjectMessage({
        "user_uid": user_uid,
        "composition_uid": content_uid,
        "object_uid": object_uid,
        "message": mess
      });
      this.getMessages(true)
    }

    // var messages = this.state.messages;
    // if (messages) {
    //   messages.push({
    //     "user_uid": user_uid,
    //     "composition_uid": content_uid,
    //     "object_uid": object_uid,
    //     "message": this.messageInputRef.current.value
    //   });
    //   this.setState({
    //     "messages": messages
    //   })
    // }
    
  }

  renderMessages() {
    var _this = this;
    var messages = this.state.messages;
    var users = this.props.users;
    var tempMessages = [];
    if (messages && users) {
      for (var i = 0; i < messages.length; i++) {
        if (messages[i].user_uid === _this.props.user_uid) {
          messages[i].my = true;
        };
        for (var j = 0; j < users.length; j++) {
          if (messages[i].user_uid === users[j].uid) {
            messages[i].user = users[j];
            tempMessages.push(messages[i]);
          };
        };
      };
    } else {
      return null;
    };

    return tempMessages;
  }

  getNameInitials(first, last) {
    return first.charAt(0) + last.charAt(0);
  }

  onClose() {
    clearInterval(this.myInterval);
    this.props.onClose()
  }

  render() {
    var _this = this;

    const {
      messages,
    } = this.state;

    return (
      <div className="rdp-composer-element-messages">
        <div className="rdp-composer-element-messages-overlay" onClick={() => this.props.onClose()}></div>
        <div className="rdp-composer-element-messages-modal">
          <div className="rdp-composer-element-messages-header">
            <span className="rdp-composer-element-messages-header-title">{this.props.labels.elements.messages.title}</span>
            <span className="rdp-composer-element-messages-header-close" onClick={() => this.onClose()}></span>
          </div>

          <ul className="rdp-composer-element-messages-items">
            {messages && this.props.users && this.renderMessages().map(function (item, index) {
              return <li key={index} className={`rdp-composer-element-messages-item ${item.my ? "me" : "another"}`}>
                <div className="rdp-composer-element-messages-item-avatar">
                  {_this.getNameInitials(item.user.detail.first_name, item.user.detail.last_name)}
                </div>
                <div className="rdp-composer-element-messages-item-data">
                  <span className="rdp-composer-element-messages-item-author">{item.user.detail.name}</span>
                  <span className="rdp-composer-element-messages-item-message">{item.message}</span>
                </div>
              </li>
            })}
          </ul>

          {!this.renderMessages() &&
            <div className="rdp-composer-element-messages-preloader-box">
              <div>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768">
                  <path fill="#2d7b45" d="M704 480v-320c0-26.496-10.784-50.56-28.128-67.872s-41.376-28.128-67.872-28.128h-448c-26.496 0-50.56 10.784-67.872 28.128s-28.128 41.376-28.128 67.872v512c0 8.192 3.136 16.384 9.376 22.624 12.512 12.512 32.768 12.512 45.248 0l118.624-118.624h370.752c26.496 0 50.56-10.784 67.872-28.128s28.128-41.376 28.128-67.872zM640 480c0 8.832-3.552 16.8-9.376 22.624s-13.792 9.376-22.624 9.376h-384c-8.832 0-16.832 3.584-22.624 9.376l-73.376 73.376v-434.752c0-8.832 3.552-16.8 9.376-22.624s13.792-9.376 22.624-9.376h448c8.832 0 16.8 3.552 22.624 9.376s9.376 13.792 9.376 22.624z"></path>
                </svg>
                <span className="rdp-composer-element-messages-preloader"></span>
                <span className="rdp-composer-element-messages-preloader-message">Sincronizando mensagens, aguarde...</span>
              </div>
            </div>
          }

          <div className="rdp-composer-element-messages-actions">
            <input ref={this.messageInputRef} className="rdp-composer-element-messages-actions-input" type="text"></input>
            <button className="rdp-composer-element-messages-actions-button" onClick={() => this.sendMessage()}></button>
          </div>
        </div>
      </div>
    );
  }
}
