import React, { Component } from 'react';
import {UserService} from '../../services/user';
import './element-flashcards-1.css';

export default class ElementFlashcard1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": [],
      "isFlipped": false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.content !== prevProps.content) {
      this.manipulateItems();
    }
  };

  componentWillMount() {
    this.manipulateItems();
  }

  manipulateItems() {
    var content = this.props.content;
    var items = content.items;
    for (var i = 0; i < items.length; i++) {
      items[i].opened = false;
    };
    content.items = items;
    this.setState({
      "content": content
    })
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
    image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  onToggleItem(index) {
    var content = this.state.content;
    var items = content.items;
    if(content.behavior === "close-all") {
      for (var i = 0; i < items.length; i++) {
        if (i !== index) {
          items[i].expanded = false;
        }
      };
    };
    items[index].expanded = !items[index].expanded;
    this.setState({
      "content": content
    })
  }

  getAssetUrl(asset) {
    var accessToken = UserService.getUser().access_token;
    if (asset !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg") {
      asset += "&access_token=" + accessToken;
    };
    return asset;
  }


  renderImage(item) {
    if (item.image !== null) {
      return <img className="element-flashcards-1-item-image" src={this.getAssetUrl(item.image)}/>
    }
  }

  renderVideo(item) {
    if (item.video !== null) {
      return <video className="element-flashcards-1-item-video" controls src={this.getAssetUrl(item.video)}/>
    }
  }

  onCardClick(e) {
    e.target.classList.toggle("flipped");
  }

  handleCenteredImageChange(img) {
    if (img.target.width > img.target.height) {
      img.target.style.width = "85%";
    } else {
      img.target.style.height = "85%";
    }
  }

  handleFullscreenImageChange(img) {
    img.target.style.objectFit = "cover";
    if (img.target.width > img.target.height) {
      img.target.style.height = "110%";
      img.target.style.width = "100%";
    } else {
      img.target.style.width = "110%";
    }
  }

  renderCardData(item, target, index) {
    var data = item[target];

    if (data.text !== null) {
      return <span className="element-flashcards-1-item-data-text" dangerouslySetInnerHTML={{__html: data.text}}/>
    };
    if (data.centered_image !== null) {
      return <img onLoad={(img) => this.handleCenteredImageChange(img)} className="element-flashcards-1-item-data-centered-image" src={this.getAssetUrl(data.centered_image)}/>
    };
    if (data.fullscreen_image !== null) {
      return <img onLoad={(img) => this.handleFullscreenImageChange(img)} className="element-flashcards-1-item-data-fullscreen-image" src={this.getAssetUrl(data.fullscreen_image)}/>
    };
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    var _this = this;
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    var accordionStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }
    var height = content.card_height || 240
    var width = content.card_width || 240
    var cardStyle = {
      maxHeight: height + "px",
      maxWidth: width + "px",
      aspectRatio: `${width / height}`,
      width: '100%'
    }
    var items = this.state.content.items;

    return <div className={`${this.props.className} ${this.getFont(content.font_family) || ""}`} style={accordionStyle}>
      {headerActionsTemplate}
      <ul className="element-flashcards-1-items">
        {items.map(function(item, index) {
          return <li key={index} className="element-flashcards-1-item" style={cardStyle}>
            <div className="element-flashcards-1-item-inner" onClick={(e) => _this.onCardClick(e)}>
              <div className="element-flashcards-1-item-front" style={{borderTopColor: _this.state.content.border_color}}>
                {_this.renderCardData(item, "front_card", index)}
              </div>
              <div className="element-flashcards-1-item-back">
                {_this.renderCardData(item, "back_card", index)}
              </div>
            </div>
          </li>
        })}
      </ul>
      {footerActionsTemplate}
      {/* <img src={this.getImageUrl(content.image)}/> */}
    </div>
  }
}
