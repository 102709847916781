import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {UserService} from '../../services/user';
import './element-3d.css';

export default class Element3d extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  getFileUrl(audio) {
    var accessToken = UserService.getUser().access_token;
    if (audio !== "https://pocs.digitalpages.com.br/rdpcomposer/media/3d/3d.glb") {
      audio += "&access_token=" + accessToken;
    };
    return audio;
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    var audioStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }

    return (<div className={this.props.className} style={audioStyle}>
      <Helmet>
        <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
      </Helmet>
      {headerActionsTemplate}
      <model-viewer class="element-3d-area" src={this.getFileUrl(content.file)} shadow-intensity="1" ar ar-modes="webxr scene-viewer quick-look" camera-controls touch-action="pan-y" alt="A 3D model carousel">
        <button slot="ar-button" id="ar-button">
          Visualizar no ambiente
        </button>
      </model-viewer>
      {footerActionsTemplate}
    </div>)
  }
}
