import React, { Component } from 'react';
import './popup.css';

export default class Popup extends Component {
  constructor(props) {
    super(props);
  }

  renderTitle() {
    if (this.props.message) {
      return <span className="rdp-component-preloader-message">{this.props.message}</span>
    }
  }

  render() {
    return (
      <div className="rdp-component-popup">
        {this.props.message}
      </div>
    );
  }
}
