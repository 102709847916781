import React, { Component } from 'react';
import './font-picker.css';

export default class FontPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "active_font": this.getActiveFont(),
      "opened": false
    };

    this.onToggleSelect = this.onToggleSelect.bind(this);
  }

  componentWillMount() {

  }

  onToggleSelect() {
    this.setState({
      "opened": !this.state.opened
    });
  }

  getFonts() {
    return [
      "Bradesco Sans",
      "Grueber",
      "Helvetica Neue",
      "Lato",
      "Lora",
      "Merriweather",
      "Montserrat",
      "Objective",
      "Open Sans",
      "Open Sans Condensed",
      "Prompt",
      "Proxima Nova",
      "Product Sans",
      "Roboto",
      "Roboto Condensed",
      "Roboto Slab",
      "Ubuntu",
      "Itau Display Pro",
      "Itau Text Pro",
      "Inter-Black",
      "Inter-SemiBold",
      "Inter-Bold",
      "Inter-Extrabold",
      "Inter-Medium",
      "Inter-Regular",
      "Inter-Light",
      "Inter-ExtraLight",
      "Inter-Thin",
      "Sharp Sans",
      "Sharp Sans Thin",
      "Sharp Sans Light",
      "Sharp Sans Medium",
      "Sharp Sans Semibold",
      "Sharp Sans ExtraBold",
      "Duplicate Ionic",
      "Duplicate Ionic Bold",
      "Duplicate Ionic Heavy",
      "Duplicate Ionic Italic",
      "Duplicate Ionic Light",
      "Duplicate Ionic Medium",
      "Duplicate Ionic Thin",
      "Flama",
      "Flama Bold",
      "Flama Condensed Bold",
      "Flama Condensed Light",
    ]
  }

  getActiveFont() {
    var fonts = this.getFonts();
    var font = "none";
    for (var i = 0; i < fonts.length; i++) {
      if (fonts[i] === this.props.font) {
        font = fonts[i];
      }
    }
    return font;
    // return this.getFonts()[0];
  }

  toLowerCase(str) {
    if (str) return str.replace(/\s/g, '-').toLowerCase()
    // return str
  }

  setFont(font) {
    this.setState({
      "active_font": font,
      "opened": false
    })
    this.props.onFontChange(font)
  }

  render() {
    var _this = this;
    var fonts = _this.getFonts();
    var active = _this.state.active_font;
    return <div className={`rdp-composer-font-picker-select ${_this.state.opened === true ? "expanded" : ""}`}>
      <span className={`rdp-composer-font-picker-select-item ${_this.toLowerCase(active)}`} onClick={() => _this.onToggleSelect()}>{active === "none" ? _this.props.noFontLabel : active}</span>
      <ul className="rdp-composer-font-picker-select-items">
        {fonts.map(function (font, index) {
          return <li key={index} className={`rdp-composer-font-picker-select-item ${_this.toLowerCase(font)}`} onClick={() => _this.setFont(font)}>{font}</li>
        })}
      </ul>
    </div>
  }
}
