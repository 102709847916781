import React, { Component } from 'react';
import {DamService} from '../../services/dam';
import {UserService} from '../../services/user';
import Autosuggest from 'react-autosuggest';
import './content-search.css';
import { getTheme } from '../../utils/getTheme';

export default class ContentSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "value": "",
      "suggestions": []
    };

    this.onChange = this.onChange.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
  }

  getSuggestionValue(suggestion) {
    return suggestion.text;
  }

  renderSuggestion(suggestion) {
    return <div onClick={(e) => this.onSearchSubmit(e)}>{suggestion.text}</div>
  };

  onChange(event, { newValue, method }) {
    var _this = this;
    this.setState({
      "value": newValue
    }, function() {
      if (method === "type" && newValue === "") {
        _this.onSearchSubmit(event);
      };
    });
  };

  onSuggestionsFetchRequested({value}) {
    var _this = this;
    var userData = UserService.getUser();
    var activeProject = UserService.getActiveProject();
    var type = userData.type;
    var accessToken = userData.access_token;
    var filter = "(project_uid eq '" + activeProject.uid + "')";
    
    DamService.getTags(filter, value, type, accessToken).then(function(data) {
      _this.setState({
        "suggestions": value.length === 0 ? [] : data.results.filter(item =>
          item.text.toLowerCase().slice(0, value.length) === value
        )
      });
    });
  };

  onSuggestionsClearRequested() {
    this.setState({
      "suggestions": []
    });
  }

  onSearchSubmit(e) {
    this.props.onSearchSubmit(this.state.value);
    e.preventDefault();
  }

  onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    var _this = this;
    this.setState({ 
      "value": suggestion.text
    }, function() {
      _this.props.onSearchSubmit(this.state.value);
    })
  }

  getStyle() {
    if (this.props.center === true) {
      return {
        left: "50%",
        right: "unset",
        marginLeft: "-140px"
      };
    } else {
      return {};
    }
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: this.props.labels.labels.search_placeholder,
      value,
      onChange: this.onChange
    };

    return <div className={`rdp-component-content-search rdp-component-content-search-theme-${getTheme()}`} style={this.getStyle()}>
      <form onSubmit={(e) => this.onSearchSubmit(e)}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
      </form>
      <span className="rdp-component-content-search-submit" onClick={(e) => this.onSearchSubmit(e)}></span>
    </div>
  }
}
