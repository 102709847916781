import React from "react";

export const IconLove = () => (
  <svg
	width="100px"
	height="100px"
    fill="red"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 288.397 288.397"
  >
    <path
      d="M203.524,12.832c-22.569,0-43.743,8.976-59.325,24.197c-15.582-15.222-36.756-24.197-59.326-24.197
	C38.074,12.832,0,50.906,0,97.706c0,37.748,13.759,71.203,43.298,105.287c18.269,21.079,59.21,50.817,81.989,66.669
	c5.655,3.936,12.284,5.903,18.912,5.903c6.627,0,13.254-1.967,18.909-5.901c22.777-15.849,63.717-45.585,81.992-66.671
	c29.539-34.084,43.297-67.54,43.297-105.287C288.397,50.906,250.323,12.832,203.524,12.832z"
    />
  </svg>
);
