import styled from "styled-components";

export const ElementAttachment1 = styled.div`
  background-color: ${props => props.backgroundColor || "#fff"};
  padding-top: ${props => props.paddingTop || "0"}px;
  padding-bottom: ${props => props.paddingBottom || "0"}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  /*region element max-width*/
  /*SM*/
  &.max-w-sm .rdp-element-attachment-1-item  {
    max-width: 760px;
  }
  /*MD*/
  &.max-w-md .rdp-element-attachment-1-item  {
    max-width: 1160px;
  }
  /*LG*/
  &.max-w-lg .rdp-element-attachment-1-item  {
    max-width: 1600px;
  }
  /*FULL*/
  &.max-w-full .rdp-element-attachment-1-item  {
    max-width: calc(100% - 60px);
  }
  /*endregion*/
`;

export const AttachmentList = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const AttachmentItem = styled.li`
  max-width: 600px;
  width: 100%;
  height: 120px;
`;

export const Attachment = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  background-color: white;

  &:hover, &:focus {
    text-decoration: underline;
    color: ${props => props.primaryColor || "#00643e"};
    border: 1px solid ${props => props.primaryColor || "#00643e"};
  }
  
  &:visited {
    color: initial;
  }
  
  &:focus {
    outline: 2px solid ${props => props.primaryColor || "#00643e"} !important;
    outline-offset: 2px;
  }
`;

export const Information = styled.div`
  display: block;
  color: inherit;
`;

export const FileName = styled.p`
  margin: 5px;
  font-weight: bold;
  max-width: 300px;
  color: inherit;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
`;

export const FileSize = styled.p`
  margin: 5px;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: inherit;
`;

export const AttachmentIcon = styled.svg`
  color: inherit;
`;

export const FileIcon = styled.svg`
  color: inherit;
  max-height: 48px;
  width: auto;
  
  @media screen and (max-width: 400px) {
    display: none;
  }
`;

export const LeftContainer = styled.div`
  flex: 4;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  color: inherit;
`;

export const RightContainer = styled.div`
  flex: 1;
  text-align: end;
  color: inherit;
`;


