import $ from 'jquery';
import React, { Component } from 'react';
import {DamService} from '../../services/dam';
import {UserService} from '../../services/user';
import {LoginService} from '../../services/login';
import RDP_CONFIG from '../../config';
import './configurations.css';

import {
  ColorPicker,
  FontPicker
} from '../../components';
import Checkbox from "../element-checkbox/checkbox";
import * as S from "../preference-required-interaction/preference-required-interaction.styled";

export default class Configurations extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      "composition": this.props.composition,
      "current_tab": "metadata",
      "loading": true
    };

    this.onTitleChange = this.onTitleChange.bind(this);
  }

  componentWillMount() {

  }

  onChange(type, asset) {
    this.props.onAssetChange(asset);
  }

  onTitleChange(e) {
    var composition = this.state.composition;
    composition.metadata.title = e.currentTarget.value !== "" ? e.currentTarget.value : composition.metadata.title;

    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onMetadataChange(e, prop) {
    var composition = this.state.composition;
    composition.metadata[prop] = e.currentTarget.value !== "" ? e.currentTarget.value : composition.metadata[prop];

    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onFontChange(font) {
    var composition = this.state.composition;
    composition.interface.font_family = font;

    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onGTMChange(container_id) {
    var composition = this.state.composition;
    composition.interface.gtm_container_id = container_id;

    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeLanguage(value) {
    var composition = this.state.composition;
    composition.interface.content_language = value;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeProgressElement(value) {
    var composition = this.state.composition;
    composition.interface.progress_element = value;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeProgressElementColor(value) {
    var composition = this.state.composition;
    composition.interface.progress_element_color = value;

    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeHeaderProgressElementColor(value) {
    var composition = this.state.composition;
    composition.interface.header_progress_element_color = value;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeModality(value) {
    var composition = this.state.composition;
    composition.modality = value;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }


  onChangeScormNotEvaluativeFinishStatus(value) {
    var composition = this.state.composition;
    composition.scorm_not_evaluative_finish_status = value;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeInteractionRequirements(event) {
    var composition = this.state.composition;
    composition.required_interaction = event.currentTarget.checked;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeHeaderSummaryProgress(event) {
    var composition = this.state.composition;
    composition.interface.show_summary_header_progress = event.currentTarget.checked;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeSummaryOption(value) {
    var composition = this.state.composition;
    composition.interface.show_summary = value;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeMenuPosition(value = "") {
    var composition = this.state.composition;
    composition.interface.summary_position = value !== "" ? value : composition.interface.summary_position;

    this.setState({
      "composition": composition
    }, function () {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeContinuousNavigation(value = "") {
    var composition = this.state.composition;
    composition.interface.continuous_navigation = value !== "" ? value : composition.interface.continuous_navigation;

    this.setState({
      "composition": composition
    }, function () {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeContentMaxWidthOption(value) {
    var composition = this.state.composition;
    composition.interface.content_max_width = value;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeFinishBtnOption(value) {
    var composition = this.state.composition;
    composition.interface.finish_btn = value;
    this.setState({
      "composition": composition
    }, function() {
      this.props.onMetadataChange(composition)
    });
  }

  onChangeTab(tab) {
    this.setState({
      "current_tab": tab
    })
  }

  render() {
    var composition = this.state.composition;
    var { current_tab } = this.state;

    return (
      <div className="rdp-component-configurations">
        <div className="rdp-component-configurations-modal">
          <div className="rdp-component-configurations-header">
            <span className="rdp-component-configurations-header-title">{this.props.labels.header.settings.title}</span>
            {/* <button onClick={() => this.uploadFile()}>Upload de arquivo</button> */}
            <span className="rdp-component-configurations-header-close" onClick={() => this.props.onClose()}></span>
          </div>
          <ul className="rdp-component-configurations-tabs">
            <li className={`rdp-component-configurations-tab ${current_tab === "metadata" ? "active" : ""}`} onClick={() => this.onChangeTab("metadata")}>{this.props.labels.header.settings.tabs.metadata_label}</li>
            <li className={`rdp-component-configurations-tab ${current_tab === "rules" ? "active" : ""}`} onClick={() => this.onChangeTab("rules")}>{this.props.labels.header.settings.tabs.rules_label}</li>
            <li className={`rdp-component-configurations-tab ${current_tab === "languages" ? "active" : ""}`} onClick={() => this.onChangeTab("languages")}>{this.props.labels.header.settings.tabs.language_label}</li>
            <li className={`rdp-component-configurations-tab ${current_tab === "interface" ? "active" : ""}`} onClick={() => this.onChangeTab("interface")}>{this.props.labels.header.settings.tabs.interface_label}</li>
            <li className={`rdp-component-configurations-tab ${current_tab === "gtm" ? "active" : ""}`} onClick={() => this.onChangeTab("gtm")}>{this.props.labels.header.settings.tabs.gtm_label}</li>
          </ul>
          <div className="rdp-component-configurations-body">
            {current_tab === "metadata" &&
              <>
              <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_title}:</span>
              <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onMetadataChange(e, "title")} defaultValue={composition.metadata.title}></input>

              <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_description}:</span>
              <textarea className="rdp-composer-element-edit-textarea" onChange={(e) => this.onMetadataChange(e, "description")} defaultValue={composition.metadata.description}></textarea>
              </>
            }

            {current_tab === "rules" &&
              <>
                <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_modality}:</span>
                <select defaultValue={composition.modality ? composition.modality : "non-evaluative"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeModality(e.target.value)}>
                  <option value="non-evaluative">{this.props.labels.header.settings.options_modality.non_evaluative}</option>
                  <option value="evaluative">{this.props.labels.header.settings.options_modality.evaluative}</option>
                </select>

                <span className="rdp-composer-element-edit-label">{this.props.labels.elements.settings.preference_required_interaction.title}:</span>
                <S.Label>
                  <Checkbox
                    checked={composition.required_interaction !== undefined ? (composition.required_interaction ? "on" : "") : ""}
                    onChange={this.onChangeInteractionRequirements.bind(this)}
                    padding={false}
                  />
                  <span>
                    {this.props.labels.elements.settings.preference_required_interaction.label}
                  </span>
                </S.Label>


                <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_scorm_not_evaluative_complete_status}:</span>
                <select defaultValue={composition.scorm_not_evaluative_finish_status ? composition.scorm_not_evaluative_finish_status : "completed"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeScormNotEvaluativeFinishStatus(e.target.value)}>
                  <option value="completed">completed</option>
                  <option value="passed">passed</option>
                </select>
              </>
            }

            {current_tab === "languages" &&
              <>
                <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_document_language}:</span>
                <select defaultValue={composition.interface.content_language ? composition.interface.content_language : RDP_CONFIG.language} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeLanguage(e.target.value)}>
                  <option value="en">{this.props.labels.header.settings.options_document_languages.en}</option>
                  <option value="pt_br">{this.props.labels.header.settings.options_document_languages.pt_br}</option>
                </select>
              </>
            }

            {current_tab === "interface" &&
              <>
                <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_max_width}:</span>
                <select defaultValue={composition.interface.content_max_width || "760px"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeContentMaxWidthOption(e.target.value)}>
                  <option value="760px">760px</option>
                  <option value="1160px">1160px</option>
                  <option value="1600px">1600px</option>
                  <option value="100%">100%</option>
                </select>

                <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_document_font}:</span>
                <FontPicker font={composition.interface.font_family} onFontChange={(font) => this.onFontChange(font)}/>

                <span className="rdp-component-configurations-divider"></span>

                <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_tree_enable}:</span>
                <select defaultValue={composition.interface.show_summary || "disabled"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeSummaryOption(e.target.value)}>
                  <option value="enabled">{this.props.labels.header.settings.tree_enable.enabled}</option>
                  <option value="disabled">{this.props.labels.header.settings.tree_enable.disabled}</option>
                </select>

                {composition.interface.show_summary === "enabled" && (
                  <>
                    <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.settings_label_menu_position}:</span>
                    <select defaultValue={composition.interface.summary_position || "right"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeMenuPosition(e.target.value)}>
                      <option value="right">{this.props.labels.header.settings.settings_label_menu_position_right}</option>
                      <option value="left">{this.props.labels.header.settings.settings_label_menu_position_left}</option>
                    </select>

                    <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.settings_label_continuous_navigation}:</span>
                    <select defaultValue={composition.interface.continuous_navigation || "disabled"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeContinuousNavigation(e.target.value)}>
                      <option value="disabled">{this.props.labels.header.settings.settings_label_continuous_navigation_disabled}</option>
                      <option value="enabled">{this.props.labels.header.settings.settings_label_continuous_navigation_enabled}</option>
                    </select>

                    <S.Label>
                      <Checkbox
                        checked={composition.interface.show_summary_header_progress !== undefined ? (composition.interface.show_summary_header_progress ? "on" : "") : ""}
                        onChange={this.onChangeHeaderSummaryProgress.bind(this)}
                        padding={false}
                      />
                      <span>
                        {this.props.labels.header.settings.settings_label_header_progress}
                      </span>
                    </S.Label>

                    <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_progress_element_color}:</span>
                    <ColorPicker
                      labels={this.props.labels}
                      color={composition.interface.header_progress_element_color || "#000000"}
                      onChange={(color) => this.onChangeHeaderProgressElementColor(color)}
                    />
                    {(!composition.interface.continuous_navigation || composition.interface.continuous_navigation === 'disabled') && (
                      <>
                        <span className="rdp-component-configurations-divider"></span>
                        <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_finish_btn_enable}:</span>
                        <select defaultValue={composition.interface.finish_btn || "disabled"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeFinishBtnOption(e.target.value)}>
                          <option value="enabled">{this.props.labels.header.settings.finish_btn.enabled}</option>
                          <option value="disabled">{this.props.labels.header.settings.finish_btn.disabled}</option>
                        </select>
                      </>
                    )}
                  </>
                )}
                {/* {(composition.interface.continuous_navigation || composition.interface.continuous_navigation === 'enabled') && (
                  <span className="rdp-component-configurations-divider"></span>
                )} */}
                <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_progress_element}:</span>
                <select defaultValue={composition.interface.progress_element || "none"} className="rdp-composer-element-edit-select" onChange={(e) => this.onChangeProgressElement(e.target.value)}>
                  <option value="none">{this.props.labels.header.settings.options_progress_element.none}</option>
                  <option value="circular_bottom_center">{this.props.labels.header.settings.options_progress_element.circular_bottom_center}</option>
                  <option value="circular_bottom_left">{this.props.labels.header.settings.options_progress_element.circular_bottom_left}</option>
                  <option value="circular_bottom_right">{this.props.labels.header.settings.options_progress_element.circular_bottom_right}</option>
                </select>

                {composition.interface.progress_element && composition.interface.progress_element !== "none" && (
                  <>
                    <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_progress_element_color}:</span>
                    <ColorPicker
                      labels={this.props.labels}
                      color={composition.interface.progress_element_color || "#000000"}
                      onChange={(color) => this.onChangeProgressElementColor(color)}
                    />
                  </>
                )}
              </>
            }

            {current_tab === "gtm" &&
              <>
                <span className="rdp-composer-element-edit-label">{this.props.labels.header.settings.form_label_gtm}:</span>
                <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onGTMChange(e.target.value)} defaultValue={composition.interface.gtm_container_id || ""}></input>
              </>
            }
          </div>
          <div className="rdp-component-configurations-footer">
            <button className="rdp-component-configurations-submit" onClick={() => this.props.onClose()}>{this.props.labels.header.settings.save_btn}</button>
          </div>
        </div>
      </div>
    );
  }
}
