import React, {Component} from 'react';
import * as S from "./element-sorted-activity-1.styled";
import ItemCard from "./item-card";


class ElementSortedActivity1 extends Component {
  state = {
    language: this.props.contentLanguage || "pt_br",
    movedItems: [],
    incorrectMovedItems: [],
    pileItems: [],
  }

  getLabels = () => {
    const language = this.state.language;
    const labels = {
      pt_br: {
        remake: "Refazer",
        moved_correctly: "Movidos corretamente",
      },
      en: {
        remake: "Remake",
        moved_correctly: "Moved correctly",
      }
    }

    return labels[language] || labels.pt_br;
  }

  handleDrop = (e) => {
    const {
      content
    } = this.props;
    const {
      items = [],
    } = content;

    e.preventDefault();

    const pileElement = e.target;
    const pileId = pileElement.id;

    const data = e.dataTransfer.getData("text");
    let pileItems = [...this.state.pileItems];
    const item = items.find(item => item.id === data);

    if (!pileItems[pileId]) pileItems[pileId] = [];

    pileItems[pileId].push(item);

    let movedItems = [...this.state.movedItems];
    let incorrectMovedItems = [...this.state.incorrectMovedItems];

    if (pileId === item.pileId) {
      movedItems = [...this.state.movedItems, data];
    } else {
      incorrectMovedItems = incorrectMovedItems.filter(id => id !== data);
      incorrectMovedItems.push(data);
    }

    this.setState({
      movedItems,
      pileItems,
      incorrectMovedItems,
    }, () => {
      if (pileId !== item.pileId) {
        setTimeout(() => {
          pileItems[pileId] = pileItems[pileId].filter(item => item.id !== data);
          this.setState({
            pileItems
          });
        }, 1000);
      }
    });
  }

  handleDragOver = (e) => {
    e.preventDefault();
  }

  getNotMovedItems = () => {
    const {
      content
    } = this.props;
    const {
      items = [],
    } = content;
    const {
      movedItems,
    } = this.state;

    return items.filter(item => !movedItems.includes(item.id));
  }

  resetState = () => {
    this.setState({
      movedItems: [],
      incorrectMovedItems: [],
      pileItems: []
    });
  }

  render() {
    const {
      headerActionsTemplate,
      footerActionsTemplate,
      className,
      content
    } = this.props;
    const {
      items,
      piles = [],
      primary_color,
      background_color,
      pile_color,
      pile_text_color,
      item_color,
      item_text_color,
      padding_top,
      padding_bottom,
    } = content;
    const {
      pileItems,
      movedItems,
      incorrectMovedItems,
    } = this.state;

    return (
      <S.ElementSortedActivity1
        className={className}
        backgroundColor={background_color}
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
      >
        {headerActionsTemplate}

        <S.Container>
          {
            movedItems.length === items.length ? (
              <S.ItemCardList>
                <S.ResultItem>
                  <S.ResultPlacar>
                    {(items.length - incorrectMovedItems.length)}/{items.length} {this.getLabels().moved_correctly}
                  </S.ResultPlacar>

                  <S.ReloadButton
                    onClick={this.resetState}
                  >
                    {this.getLabels().remake}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="currentColor" d="M2 12C2 16.97 6.03 21 11 21C13.39 21 15.68 20.06 17.4 18.4L15.9 16.9C14.63 18.25 12.86 19 11 19C4.76 19 1.64 11.46 6.05 7.05C10.46 2.64 18 5.77 18 12H15L19 16H19.1L23 12H20C20 7.03 15.97 3 11 3C6.03 3 2 7.03 2 12Z" />
                    </svg>
                  </S.ReloadButton>
                </S.ResultItem>
              </S.ItemCardList>
            ) : (
              <S.ItemCardList>
                {
                  this.getNotMovedItems().map((item) => (
                    <ItemCard
                      draggable
                      id={item.id}
                      key={item.id}
                      primaryColor={primary_color}
                      backgroundColor={item_color}
                      color={item_text_color}
                      title={item.title}
                    />
                  ))
                }
              </S.ItemCardList>
            )
          }

          <S.DropPileList>
            {
              piles.map((pile) => (
                <S.DropPile
                  id={pile.id}
                  key={pile.id}
                  onDrop={this.handleDrop}
                  onDragOver={this.handleDragOver}
                  primaryColor={primary_color}
                  title={pile.title}
                >
                  <S.DropPileItemCardList
                    backgroundColor={pile_color}
                    color={pile_text_color}
                    borderColor={primary_color}
                  >
                    <p>
                      {pile.title}
                    </p>
                    {
                      pileItems[pile.id] &&
                      pileItems[pile.id].map((item) => (
                        <ItemCard
                          id={item.id}
                          key={item.id}
                          primaryColor={primary_color}
                          backgroundColor={item_color}
                          color={item_text_color}
                          title={item.title}
                          isCorrect={item.pileId === pile.id}
                          draggable={false}
                        />
                      ))
                    }
                  </S.DropPileItemCardList>
                </S.DropPile>
              ))
            }
          </S.DropPileList>
        </S.Container>

        {footerActionsTemplate}
      </S.ElementSortedActivity1>
    );
  }
}

export default ElementSortedActivity1;