import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {UserService} from '../../services/user';
import './element-steps-1-properties.css';
import {
  RDPDamWidget, ColorPicker, RelToolbarQuill
} from '../../components';
import * as S from "./element-steps-1-properties.styled";
import uuidv1 from "uuid/v1";
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

const tabs = {
  "settings": "settings",
  "items": "items",
}

export default class ElementSteps1Properties extends Component {
  state = {
    content: this.props.content,
    dam_widget_opened: false,
    active_tab: tabs.items,
    expanded_item_id: null,
    item_image_modal_open: false,
    item_audio_modal_open: false
  }

  onImageChange = () => {
    this.setState({
      "dam_widget_opened": true
    })
  }

  onAlternativeTextChange = (e) => {
    var content = this.state.content;
    content.image_alt = e.currentTarget.value !== "" ? e.currentTarget.value : content.image_alt;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotTitleChange = (e, id) => {
    const content = this.state.content;

    content.items = content.items.map(item => {
      if (item.id === id) {
        item.title = e.currentTarget.value !== "" ? e.currentTarget.value : item.title;
      }
      return item;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotDescriptionChange = (text, id) => {
    const content = this.state.content;

    content.items = content.items.map(item => {
      if (item.id === id) {
        item.description = text;
      }
      return item;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange = (color) => {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPrimaryColorChange = (color) => {
    var content = this.state.content;
    content.primary_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onWidthChange = (e) => {
    var content = this.state.content;
    content.width = e.currentTarget.value;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange = (e) => {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange = (e) => {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange = (asset) => {
    var content = this.state.content;
    content.image = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onRDPDamWidgetClose = () => {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget = () => {
    if (this.state.dam_widget_opened === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="image"
        onClose={() => this.onRDPDamWidgetClose()}
        onAssetChange={(asset) => this.onAssetChange(asset)}
      />;
    } else if (this.state.item_image_modal_open === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="image"
        onClose={() => this.onSpotImageModalClose()}
        onAssetChange={(asset) => this.onSpotImageChange(asset)}
      />;
    } else if (this.state.item_audio_modal_open === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="audio"
        onClose={() => this.onSpotAudioModalClose()}
        onAssetChange={(asset) => this.onSpotAudioChange(asset)}
      />;
    }
  }

  onSpotImageModalClose = () => {
    this.setState({
      "item_image_modal_open": false
    })
  }

  onSpotAudioModalClose = () => {
    this.setState({
      "item_audio_modal_open": false
    })
  }

  onSpotImageRemove = (id) => {
    var content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === id) {
        item.image = null;
      }
      return item;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotAudioRemove = (id) => {
    var content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === id) {
        item.audio = null;
      }
      return item;
    });

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotImageChange = (asset) => {
    var content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === this.state.expanded_item_id) {
        item.image = asset;
      }
      return item;
    });

    this.setState({
      "content": content,
      "item_image_modal_open": false
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onSpotAudioChange = (asset) => {
    var content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === this.state.expanded_item_id) {
        item.audio = asset;
      }
      return item;
    });

    this.setState({
      "content": content,
      "item_audio_modal_open": false
    }, function () {
      this.props.onContentChange(content)
    });
  }

  getImageUrl = (image) => {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    }
    return image;
  }

  getAudioUrl = (audio) => {
    const accessToken = UserService.getUser().access_token;
    audio += "&access_token=" + accessToken;
    return audio;
  }

  handleDeleteSpot = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    var content = this.state.content;
    content.items = content.items.filter(item => item.id !== id);
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  handleHiddenItem = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const content = this.state.content;
    content.items = content.items.map(item => {
      if (item.id === id) {
        item.isHidden = !item.isHidden;
      }
      return item;
    });
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onChangeTab(target) {
    this.setState({
      "active_tab": target
    })
  }

  handleExpandSpot = (id) => {
    if (this.state.expanded_item_id === id) {
      this.setState({
        "expanded_item_id": null
      })
    } else {
      this.setState({
        "expanded_item_id": id
      })
    }
  }

  handleOnDragEndSpots = (result, items = []) => {
    if (!result.destination) return;

    // previne que o item seja movido acima da intro
    if (result.destination.index === 0) {
      return;
    }

    // previne que o item seja movido abaixo do sumário
    if (result.destination.index === items.length - 1) {
      return;
    }

    const itemsCpy = Array.from(items);

    const [reorderedItem] = itemsCpy.splice(result.source.index, 1);
    let destinationIndex = result.destination.index;

    itemsCpy.splice(destinationIndex, 0, reorderedItem);

    const content = this.state.content;

    content.items = itemsCpy
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  };

  onAddItem() {
    var content = this.state.content;
    var items = content.items;
    const summary = items.find(item => item.type === "summary");
    let itemsWithoutSummary = items.filter(item => item.type !== "summary");

    itemsWithoutSummary.push({
      "id": uuidv1(),
      "type": "step",
      "title": "Lorem ipsum dolor sit amet",
      "isHidden": false,
      "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At aut impedit inventore nihil reiciendis. Ad alias amet aspernatur dignissimos exercitationem reiciendis? Amet eos incidunt labore vel veniam? Explicabo, hic, sed. Accusantium amet cum cumque deleniti doloribus earum eligendi, eum exercitationem harum hic illum ipsa itaque labore libero magni minus neque nesciunt nihil non odio quam repellendus tempore velit, veniam veritatis? Aspernatur aut beatae blanditiis corporis dolorem eius harum incidunt ipsa, itaque iure labore laboriosam laborum magni maiores molestiae natus omnis quae reiciendis reprehenderit sed similique sit tempora tenetur velit veniam! Aliquid autem debitis dolores ducimus eaque est facilis incidunt ipsa ipsum laboriosam modi non perspiciatis placeat, quaerat quas quis totam vel velit? Deleniti deserunt expedita laudantium molestias porro, quas veritatis? Architecto dolor, provident. A accusantium architecto consequatur illum itaque necessitatibus perspiciatis reprehenderit sapiente veritatis vero. Consequatur doloremque ducimus ex molestias non. Dolore, harum ipsa ipsam laudantium nostrum tenetur ut voluptates!",
    });

    if (summary) {
      itemsWithoutSummary.push(summary);
    }

    content.items = itemsWithoutSummary;

    this.setState({
      "content": content
    })
  }

  render() {
    var content = this.state.content;
    var labels = this.props.labels;

    return (
      <div className="rdp-element-image-properties">
        <ul className="rdp-composer-element-edit-tabs">
          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.items ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.items)}
          >
            {labels.elements.settings.element_steps_1.settings_label_tab_items}
          </li>

          <li
            className={[
              "rdp-composer-element-edit-tabs-item",
              this.state.active_tab === tabs.settings ? "active" : ""
            ].join(" ")}
            onClick={() => this.onChangeTab(tabs.settings)}
          >
            {labels.elements.common_settings.label_tab_settings}
          </li>
        </ul>

        <div className="rdp-composer-element-edit-body">
          {this.state.active_tab === tabs.settings && (
            <>
              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_steps_1.settings_label_background_color}</span>
              <ColorPicker labels={this.props.labels} color={content.background_color}
                           onChange={this.onBackgroundColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_steps_1.settings_label_item_color}</span>
              <ColorPicker labels={this.props.labels} color={content.primary_color} onChange={this.onPrimaryColorChange}/>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_steps_1.settings_label_margin_top}</span>
              <input type="number" className="rdp-composer-element-edit-input px"
                     onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

              <span
                className="rdp-composer-element-edit-label">{labels.elements.settings.element_steps_1.settings_label_margin_bottom}</span>
              <input type="number" className="rdp-composer-element-edit-input px"
                     onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

              <PreferenceRequiredInteraction
                label={labels.elements.settings.preference_required_interaction.label}
                labels={labels}
                content={content}
                onContentChange={c => {
                  this.setState({ content: c });
                  this.props.onContentChange(c);
                }}
              />
            </>
          )}

          {this.state.active_tab === tabs.items && (
            <>
              <DragDropContext
                onDragEnd={(result) => {
                  this.handleOnDragEndSpots(result, this.state.content.items);
                }}
              >
                <Droppable droppableId="items">
                  {(provided) => (
                    <ol ref={provided.innerRef} {...provided.droppableProps}>
                      {
                        content.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            isDragDisabled={item.type === "summary" || item.type === "intro"}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <S.SpotItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <S.SpotHead
                                    tabIndex="1"
                                    role="button"
                                    onClick={() => this.handleExpandSpot(item.id)}
                                  >
                                    <S.SpotItemHeadLeft>
                                      {
                                        (item.type !== "summary" && item.type !== "intro") && (
                                          <S.MoveButton
                                            {...provided.dragHandleProps}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}
                                          >
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                            >
                                              <path fill="currentColor"
                                                    d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z"/>
                                            </svg>
                                          </S.MoveButton>
                                        )
                                      }

                                      {
                                        item.type === "intro" && (
                                          <>
                                            {labels.elements.settings.element_steps_1.settings_label_intro}
                                          </>
                                        )
                                      }
                                      {
                                        item.type === "step" && (
                                          <>
                                            {labels.elements.settings.element_steps_1.settings_label_item} {index}
                                          </>
                                        )
                                      }
                                      {
                                        item.type === "summary" && (
                                          <>
                                            {labels.elements.settings.element_steps_1.settings_label_summary}
                                          </>
                                        )
                                      }
                                    </S.SpotItemHeadLeft>

                                    <S.SpotItemHeadRight>
                                      {
                                        item.type === "intro" || item.type === "summary" ? (
                                          <S.IconRemove
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            onClick={(e) => this.handleHiddenItem(e, item.id)}
                                          >
                                            {
                                              item.isHidden ? (
                                                <path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
                                              ) : (
                                                <path fill="currentColor" d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
                                              )
                                            }
                                          </S.IconRemove>
                                        ) : (
                                          <S.IconRemove
                                            width="18"
                                            height="18"
                                            viewBox="0 0 768 768"
                                            onClick={(e) => this.handleDeleteSpot(e, item.id)}
                                          >
                                            <path fill="currentColor"
                                                  d="M607.5 127.5v64.5h-447v-64.5h111l33-31.5h159l33 31.5h111zM192 607.5v-384h384v384c0 34.5-30 64.5-64.5 64.5h-255c-34.5 0-64.5-30-64.5-64.5z"></path>
                                          </S.IconRemove>
                                        )
                                      }

                                      <S.IconDown
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        isExpanded={this.state.expanded_item_id === item.id}
                                      >
                                        <path fill="currentColor"
                                              d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                                      </S.IconDown>
                                    </S.SpotItemHeadRight>
                                  </S.SpotHead>

                                  <S.SpotItemBody
                                    show={this.state.expanded_item_id === item.id}
                                  >
                                    <span
                                      className="rdp-composer-element-edit-label">{labels.elements.settings.element_steps_1.settings_label_title}:</span>
                                    <input
                                      type="text"
                                      className="rdp-composer-element-edit-input"
                                      onChange={(e) => this.onSpotTitleChange(e, item.id)}
                                      defaultValue={item.title}
                                    />

                                    <span
                                      className="rdp-composer-element-edit-label">
                                          {labels.elements.settings.element_steps_1.settings_label_description}:
                                        </span>
                                    <div style={{marginTop: "10px", marginBottom: "25px"}}>
                                      <RelToolbarQuill
                                        onChange={(text) => this.onSpotDescriptionChange(text, item.id)}
                                        value={item.description}
                                      />
                                    </div>

                                    <S.FieldContainer>
                                      <span
                                        className="rdp-composer-element-edit-label"
                                      >
                                        {labels.elements.settings.element_steps_1.settings_label_item_image}:
                                      </span>
                                      {
                                        item.image ? (
                                          <>
                                            <img
                                              className="rdp-composer-element-image"
                                              onClick={() => this.onImageChange()}
                                              alt="item image"
                                              src={this.getImageUrl(item.image)}
                                            />
                                            <S.SpotImageRemoveButton
                                              onClick={() => this.onSpotImageRemove(item.id)}
                                            >
                                              {labels.elements.settings.element_steps_1.settings_button_remove_image}
                                            </S.SpotImageRemoveButton>
                                          </>
                                        ) : (
                                          <S.SpotImageUpload
                                            tabIndex="1"
                                            type="button"
                                            onClick={() => {
                                              this.setState({
                                                item_image_modal_open: true,
                                              });
                                            }}
                                          >
                                            {labels.elements.settings.element_steps_1.settings_button_upload_image}

                                            <svg width="48" height="48" viewBox="0 0 24 24">
                                              <path fill="currentColor" d="M4,5H7L9,3H15L17,5H20A2,2 0 0,1 22,7V19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19V7A2,2 0 0,1 4,5M13.09,9.45L11.05,12.18L12.6,14.25L11.73,14.91L9.27,11.64L6,16H18L13.09,9.45Z" />
                                            </svg>
                                          </S.SpotImageUpload>
                                        )
                                      }
                                    </S.FieldContainer>

                                    <S.FieldContainer>
                                      <span
                                        className="rdp-composer-element-edit-label"
                                      >
                                        {labels.elements.settings.element_steps_1.settings_label_item_audio}:
                                      </span>
                                      {
                                        item.audio ? (
                                          <>
                                            <S.SpotAudioPreview src={this.getAudioUrl(item.audio)} controls>
                                              O seu navegador não suporta o elemento <code>audio</code>.
                                            </S.SpotAudioPreview>

                                            <S.SpotImageRemoveButton
                                              onClick={() => this.onSpotAudioRemove(item.id)}
                                            >
                                              {labels.elements.settings.element_steps_1.settings_button_remove_audio}
                                            </S.SpotImageRemoveButton>
                                          </>
                                        ) : (
                                          <S.SpotImageUpload
                                            tabIndex="1"
                                            type="button"
                                            onClick={() => {
                                              this.setState({
                                                item_audio_modal_open: true,
                                              });
                                            }}
                                          >
                                            {labels.elements.settings.element_steps_1.settings_button_upload_audio}

                                            <svg width="48" height="48" viewBox="0 0 24 24">
                                              <path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13,13H11V18A2,2 0 0,1 9,20A2,2 0 0,1 7,18A2,2 0 0,1 9,16C9.4,16 9.7,16.1 10,16.3V11H13V13M13,9V3.5L18.5,9H13Z" />
                                            </svg>
                                          </S.SpotImageUpload>
                                        )
                                      }
                                    </S.FieldContainer>
                                  </S.SpotItemBody>
                                </S.SpotItem>
                              </>
                            )}
                          </Draggable>
                        ))
                      }
                    </ol>
                  )}
                </Droppable>
              </DragDropContext>

              <S.AddButton onClick={() => this.onAddItem()}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 640 640">
                  <path fill="currentColor" d="M352 288h128v64h-128v128h-64v-128h-128v-64h128v-128h64v128zM320 640c-176.731 0-320-143.269-320-320s143.269-320 320-320v0c176.731 0 320 143.269 320 320s-143.269 320-320 320v0zM320 576c141.385 0 256-114.615 256-256s-114.615-256-256-256v0c-141.385 0-256 114.615-256 256s114.615 256 256 256v0z"></path>
                </svg>
                {labels.elements.settings.element_timeline_1.content_label_btn_add_item}
              </S.AddButton>
            </>
          )}
        </div>
        {this.renderDAMWidget()}
      </div>
    );
  }
}
