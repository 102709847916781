import styled from "styled-components";

export const TextAreaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 40px;
  padding-top: 0;
`;

export const TextArea = styled.textarea`
  display: block;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  width: calc(100% - 60px);
  resize: vertical;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  font-size: 16px;
  padding: 10px;
  
  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    resize: none;
  }
  
  &:focus {
    border: 2px solid ${props => props.primaryColor || "#2d7b45"};
  }
`;