import React, { Component } from 'react';
import { Quill } from '../../components';
import 'animate.css/animate.min.css';
import './element-text.css';
import HtmlString from "../../components/html-string/html-string";

export default class ElementText extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(text) {
    var content = this.props.content;
    content.text = text;
    this.props.onChange(content);
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;

    var textStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    };

    return (
      <div className={`${className} rdp-element-text-container ${this.getFont(content.font_family) || ""}`} style={textStyle}>
        {this.props.editable && headerActionsTemplate}
        <div className="rdp-element-text" >
          {this.props.editable ? (
            <Quill
              value={content.text}
              onChange={(text) => this.handleChange(text)}
            />
          ) : (
            <HtmlString
              className="editor"
              value={content.text}
            />
          )}
        </div>
        {this.props.editable && footerActionsTemplate}
      </div>
    );
  }
}
