import styled from "styled-components";


export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const Button = styled.button`
  background: transparent;
  color: ${props => props.primaryColor || "#2d7b45"};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  border: 0;
  padding: 10px 20px;
  border-radius: 2px;
  cursor: pointer;
  font-family: Lato-Bold, serif;

  &:disabled,
  &[disabled="true"],
  &[disabled="disabled"] {
    color: #A1A1A1;
    cursor: default;
  }
`;

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${props => props.justify || "middle"};
`;

export const ContainedButton = styled(Button)`
  background: ${props => props.primaryColor || "#2d7b45"};
  color: ${props => props.color || "#fff"};
  border-radius: 30px;
  cursor: pointer;
  margin-right: 20px;

  &:disabled,
  &[disabled="true"],
  &[disabled="disabled"] {
    cursor: not-allowed;
    opacity: 0.5;
    color: white;
  }
  
  &:focus {
    outline: 2px solid ${props => props.primaryColor || "#2d7b45"} !important;
    outline-offset: 2px !important;
  }
`
