import React, { Component } from 'react'
import './header-preview.css';

export default class HeaderPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "active_device": "mobile"
    }

    this.changeDevice = this.changeDevice.bind(this);
    this.back = this.back.bind(this);
  }

  changeDevice(device) {
    this.setState({
      "active_device": device
    });
    this.props.onDeviceChange(device);
  }

  back() {
    // window.location = window.location.origin + "/#/";
    window.location = this.props.backUrl;
    // window.location = window.location.origin + "/#/composer/" + response[0].metadatas.content_uid;
  }

  render() {
    return (
      <header className="rdp-composer-header-preview">
        {this.props.isEmbed &&
          <span onClick={() => this.back()} className="rdp-composer-header-preview-back-embed">Voltar</span>
        }
        {!this.props.isEmbed &&
          <span onClick={() => this.back()} className="rdp-composer-header-preview-back">Voltar</span>
        }
        <ul>
          <li onClick={() => this.changeDevice("mobile")} className={`rdp-composer-header-preview-mobile-icon ${this.props.isEmbed ? "embed" : ""} ${this.state.active_device === "mobile" ? "active" : ""}`}></li>
          <li onClick={() => this.changeDevice("tablet")} className={`rdp-composer-header-preview-tablet-icon ${this.props.isEmbed ? "embed" : ""} ${this.state.active_device === "tablet" ? "active" : ""}`}></li>
          <li onClick={() => this.changeDevice("desktop")} className={`rdp-composer-header-preview-desktop-icon ${this.props.isEmbed ? "embed" : ""} ${this.state.active_device === "desktop" ? "active" : ""}`}></li>
        </ul>
      </header>
    );
  }
}