import styled from "styled-components";


export const Radio = styled.span`
  --primary-color: ${props => props.primaryColor || "#00643e"};
  
  width: 60px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Input = styled.input`
  cursor: inherit;
  appearance: none;
  background-color: transparent;
  margin: 0;

  font: inherit;
  color: transparent;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
  
  
  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: var(--primary-color);
  }
  
  &:checked:before,
  &[checked=true]:before,
  &[checked=checked]:before {
    transform: scale(1);
  }
`;