import React from "react";

export const LineHeightIconString = `
      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
      <path d="M48 0H0V48H48V0Z" fill="white" fill-opacity="0.01"/>
      <path d="M16 35L10 41L4 35" stroke="#606060" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 13L10 7L4 13" stroke="#606060" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10 7V41" stroke="#606060" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M44 9H22" stroke="#606060" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M36 19H22" stroke="#606060" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M44 29H22" stroke="#606060" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M36 39H22" stroke="#606060" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
`;

export const LineHeightIcon = () => (
  <svg width="800px" height="800px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={48} height={48} fill="white" fillOpacity={0.01} />
    <path d="M48 0H0V48H48V0Z" fill="white" fillOpacity={0.01} />
    <path d="M16 35L10 41L4 35" stroke="#606060" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 13L10 7L4 13" stroke="#606060" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 7V41" stroke="#606060" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M44 9H22" stroke="#606060" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36 19H22" stroke="#606060" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M44 29H22" stroke="#606060" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36 39H22" stroke="#606060" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
