import React, { Component } from 'react';
import {UserService} from '../../services/user';
import 'animate.css/animate.min.css';
import './element-forum-1.css';

export default class ElementForum1 extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      "language": this.props.contentLanguage || "pt_br"
    }
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  getFormatDate() {
    var language = this.state.language;

    var date = new Date();
    var day = date.getDate().toString();
    var dayLabel = (day.length == 1) ? "0" + day : day;
    var month  = (date.getMonth() + 1).toString();
    var monthLabel = (month.length == 1) ? "0" + month : month;
    var yearLabel = date.getFullYear();

    if (language === "en") {
      return `${monthLabel}/${dayLabel}/${yearLabel}`;
    } else {
      return `${dayLabel}/${monthLabel}/${yearLabel}`;
    };
  }

  getByLabel() {
    var language = this.state.language;
    var label = "Por";

    if (language === "en") label = "By";
    if (language === "pt_br") label = "Por";

    return label;
  }

  getReplyLabel() {
    var language = this.state.language;
    var label = "Responder";

    if (language === "en") label = "Reply";
    if (language === "pt_br") label = "Responder";

    return label;
  }

  getRemoveLabel() {
    var language = this.state.language;
    var label = "Excluir";

    if (language === "en") label = "Remove";
    if (language === "pt_br") label = "Excluir";

    return label;
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    var forumStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }

    // "confirm_button_text_color": "#FFFFFF",
    // "confirm_button_background_color": "#00643e",
    // "cancel_button_text_color": "#FFFFFF",
    // "cancel_button_background_color": "#8f8f8f",
    // "topic_button_color": "#00643e",
    // "primary_topic_background_color": "#e1f1eb",
    // "primary_topic_author_color": "#A8A8A8",
    // "primary_topic_text_color": "#727272",
    // "secondary_topic_background_color": "#f1f1f1",
    // "secondary_topic_author_color": "#A8A8A8",
    // "secondary_topic_text_color": "#727272"

    return <div className={this.props.className} style={forumStyle}>
    {headerActionsTemplate}
      <div className="element-forum-content">
        <div className="element-forum-content-message" style={{background: content.primary_topic_background_color}}>
          <span className="element-forum-content-message-author" style={{color: content.primary_topic_author_color}}>{`${this.getByLabel()}: John Doe - ${this.getFormatDate()}`}</span>
          <div className="element-forum-content-message-message" style={{color: content.primary_topic_text_color}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</div>
          <ul className="element-forum-content-message-actions">
            <li className="element-forum-content-message-action" style={{color: content.topic_button_color}}>{this.getReplyLabel()}</li>
            <li className="element-forum-content-message-action" style={{color: content.topic_button_color}}>{this.getRemoveLabel()}</li>
          </ul>
        </div>
        <div className="element-forum-content-message-children">
          <div className="element-forum-content-message-child" style={{background: content.secondary_topic_background_color}}>
            <span className="element-forum-content-message-author" style={{color: content.secondary_topic_author_color}}>{`${this.getByLabel()}: John Doe - ${this.getFormatDate()}`}</span>
            <div className="element-forum-content-message-message" style={{color: content.secondary_topic_text_color}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</div>
            <ul className="element-forum-content-message-actions">
              <li className="element-forum-content-message-action" style={{color: content.topic_button_color}}>{this.getReplyLabel()}</li>
              <li className="element-forum-content-message-action" style={{color: content.topic_button_color}}>{this.getRemoveLabel()}</li>
            </ul>
          </div>
          <div className="element-forum-content-message-child" style={{background: content.secondary_topic_background_color}}>
            <span className="element-forum-content-message-author" style={{color: content.secondary_topic_author_color}}>{`${this.getByLabel()}: John Doe - ${this.getFormatDate()}`}</span>
            <div className="element-forum-content-message-message" style={{color: content.secondary_topic_text_color}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</div>
            <ul className="element-forum-content-message-actions">
              <li className="element-forum-content-message-action" style={{color: content.topic_button_color}}>{this.getReplyLabel()}</li>
              <li className="element-forum-content-message-action" style={{color: content.topic_button_color}}>{this.getRemoveLabel()}</li>
            </ul>
          </div>
        </div>
      </div>
    {footerActionsTemplate}
  </div>
  }
}