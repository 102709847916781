import React, { useEffect, useState } from 'react';
import uuidv1 from 'uuid/v1';
import { UserService } from '../../services/user';
import {
  RDPDamWidget, ColorPicker
} from '../../components';
import Img3 from '../../containers/compositions/image-3.jpg'
import Img4 from '../../containers/compositions/image-4.jpg'
import * as S from './styles';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

const ElementImagesGalleryProperties = ({ content, onContentChange, labels }) => {
  const [contentState, setContentState] = useState(content);
  const [dam_widget_opened, setDam_widget_opened] = useState(false);
  const [imageBeingChanged, setImageBeingChanged] = useState(0);
  const [images, setImages] = useState(content.images);

  const onImageChange = (index) => {
    setDam_widget_opened(true);
    setImageBeingChanged(index);
  }

  const onContentTitleChange = (e) => {
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    onContentChange(content);
  }

  const onBackgroundColorChange = (color) => {
    content.background_color = color;

    onContentChange(content);
  }

  const onTextColorChange = (color) => {
    content.text_color = color;

    onContentChange(content);
  }

  const onWidthChange = (e) => {
    content.width = e.currentTarget.value !== "" ? e.currentTarget.value : content.width;

    onContentChange(content);
  }

  const onPaddingTopChange = (e) => {
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    onContentChange(content);
  }

  const onPaddingBottomChange = (e) => {
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    onContentChange(content);
  }

  const onAssetChange = (asset) => {
    images[imageBeingChanged].src = asset;
    setImages([...images]);
    setDam_widget_opened(false);
  }

  const onRDPDamWidgetClose = () => {
    setDam_widget_opened(false);
  }

  const renderDAMWidget = () => {
    if (dam_widget_opened === true) {
      return <RDPDamWidget
        labels={labels} settings_label_text_color
        type="image"
        onClose={() => onRDPDamWidgetClose()}
        onAssetChange={(asset) => onAssetChange(asset)}
      />;
    }
  }

  const getImageUrl = (image) => {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg" &&
      image.slice(0, '/static/media/'.length) !== '/static/media/') {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  const renderWidthInput = (labels) => {
    var type = content.type;
    if (type === "image-1") {
      return <>
        <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_image_width}</span>
        <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => onWidthChange(e)} defaultValue={content.width}></input>
      </>
    }
  }

  const addNewImage = () => {
    const Image = images.length === 2 ? Img3 : Img4;
    setImages([...images, {src: Image, caption: '', id: uuidv1()}]);
  }

  const removeImage = (i) => {
    setImages([...images.filter((image, index) => index !== i)]);
  }
  
  const onSubtitleChange = (i, e) => {
    e.stopPropagation();
    images[i].caption = e.target.value;
    setImages([...images]);
  }

  useEffect(() => {
    content.images = images;
  }, [images]);

    return (
      <div className="rdp-element-image-properties">
        <ul className="rdp-composer-element-edit-tabs">
          <li className="rdp-composer-element-edit-tabs-item active">{labels.elements.common_settings.label_tab_settings}</li>
        </ul>
        <div className="rdp-composer-element-edit-body">
          <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_content_title}:</span>
          <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => onContentTitleChange(e)} defaultValue={content.content_title}></input>

          <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_images_gallery.settings_label_images}:</span>
          {images.map((image, i) => {
            return (
              <S.ImageContainer key={i}>
                {images.length > 2 &&
                  <S.RemoveButton className="element-accordion-properties-item-remove" onClick={() => { removeImage(i) }}></S.RemoveButton>
                }
                <img 
                  className="rdp-composer-element-image" 
                  onClick={() => onImageChange(i)} 
                  alt="Composer" 
                  src={getImageUrl(image.src)}
                  key={image.src}
                />
                <input 
                  key={image.id}
                  type="text"
                  className="rdp-composer-element-edit-input"
                  defaultValue={image.caption}
                  placeholder={labels.elements.settings.element_images_gallery.settings_label_caption}
                  onChange={(e) => onSubtitleChange(i, e)}
                />
              </S.ImageContainer>
            )
          })}
          {images.length < 4 &&
            <button className="element-accordion-properties-choice-new" onClick={addNewImage} type="button">Adicionar imagem</button>
          }

          <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_background_color}</span>
          <ColorPicker labels={labels} color={content.background} onChange={onBackgroundColorChange} />

          <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_images_gallery.settings_label_text_color}</span>
          <ColorPicker labels={labels} color={content.color} onChange={onTextColorChange} />

          {renderWidthInput(labels)}

          <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_margin_top}</span>
          <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

          <span className="rdp-composer-element-edit-label">{labels.elements.settings.element_image.settings_label_margin_bottom}</span>
          <input type="number" className="rdp-composer-element-edit-input px" onChange={(e) => onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

          <PreferenceRequiredInteraction
            label={labels.elements.settings.preference_required_interaction.label}
            labels={labels}
            content={contentState}
            onContentChange={c => {
              setContentState(c);
              onContentChange(c);
            }}
          />
        </div>
        {renderDAMWidget()}
      </div>
    );
}

export default ElementImagesGalleryProperties;