import React, {Component} from 'react';
import {UserService} from '../../services/user';
import './element-forum-1-properties.css';
import {
  RDPDamWidget, ColorPicker
} from '../../components';
import PreferenceRequiredInteraction
  from "../../components/preference-required-interaction/preference-required-interaction";

export default class ElementForum1Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "dam_widget_opened": false,
      "active_tab": "settings",
      "user_roles": ["reviewer", "reviewadmin", "reviewcontributor", "viewer"]
    }

    this.onImageChange = this.onImageChange.bind(this);
    this.onContentTitleChange = this.onContentTitleChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.onPaddingTopChange = this.onPaddingTopChange.bind(this);
    this.onPaddingBottomChange = this.onPaddingBottomChange.bind(this);
    this.onCheckPermission = this.onCheckPermission.bind(this);
    this.onGradeNumeralStepsChange = this.onGradeNumeralStepsChange.bind(this);
  }

  onImageChange() {
    this.setState({
      "dam_widget_opened": true
    })
  }

  onContentTitleChange(e) {
    var content = this.state.content;
    content.content_title = e.currentTarget.value !== "" ? e.currentTarget.value : content.content_title;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onBackgroundColorChange(color) {
    var content = this.state.content;
    content.background_color = color;
    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onWidthChange(e) {
    var content = this.state.content;
    content.width = e.currentTarget.value !== "" ? e.currentTarget.value : content.width;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingTopChange(e) {
    var content = this.state.content;
    content.padding_top = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_top;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPaddingBottomChange(e) {
    var content = this.state.content;
    content.padding_bottom = e.currentTarget.value !== "" ? e.currentTarget.value : content.padding_bottom;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onPropChange(value, prop) {
    var content = this.state.content;
    content[prop] = value;

    this.setState({
      "content": content
    }, function () {
      this.props.onContentChange(content)
    });
  }

  onAssetChange(asset) {
    var content = this.state.content;
    content.image = asset;

    this.setState({
      "content": content,
      "dam_widget_opened": false
    })
  }

  onRDPDamWidgetClose() {
    this.setState({
      "dam_widget_opened": false
    })
  }

  renderDAMWidget() {
    if (this.state.dam_widget_opened === true) {
      return <RDPDamWidget
        labels={this.props.labels}
        type="image"
        onClose={() => this.onRDPDamWidgetClose()}
        onAssetChange={(asset) => this.onAssetChange(asset)}
      />;
    }
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    }
    return image;
  }

  onChangeTab(target) {
    this.setState({
      "active_tab": target
    })
  }

  onChangeGradeType(gradeType) {
    var content = this.state.content;
    content.grade_type = gradeType;
    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onGradeNumeralMinChange(value) {
    const {content} = this.state;
    const {grade_numeral = {}} = content;
    grade_numeral.min = parseInt(value);

    content.grade_numeral = grade_numeral;

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  checkGradeNumeralMinValue() {
    const {content} = this.state;
    const {grade_numeral = {}} = content;

    if (isNaN(grade_numeral.min)) {
      grade_numeral.min = 0;
    }

    if (grade_numeral.min >= grade_numeral.max) {
      grade_numeral.max = grade_numeral.min + 1;
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onGradeNumeralMaxChange(value) {
    const {content} = this.state;
    const {grade_numeral = {}} = content;
    grade_numeral.max = parseInt(value);

    content.grade_numeral = grade_numeral;

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  checkGradeNumeralMaxValue() {
    const {content} = this.state;
    const {grade_numeral = {}} = content;

    if (isNaN(grade_numeral.max)) {
      grade_numeral.max = 1;
    }

    if (grade_numeral.max <= grade_numeral.min) {
      grade_numeral.min = grade_numeral.max - 1;
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onGradeNumeralStepsChange(value) {
    const {content} = this.state;
    const {grade_numeral = {}} = content;
    grade_numeral.steps = parseInt(value);
    content.grade_numeral = grade_numeral;

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  checkGradeNumeralStepsValue() {
    const {content} = this.state;
    const {grade_numeral = {}} = content;

    if (isNaN(grade_numeral.steps)) {
      grade_numeral.steps = 10;
    }

    if (grade_numeral.steps > grade_numeral.max) {
      grade_numeral.steps = grade_numeral.max;
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  onCheckPermission(event) {
    const {content} = this.state;
    const {enable_new_topics = []} = content;
    const checked = event.currentTarget.checked;
    const value = event.currentTarget.value;

    if (checked) {
      content.enable_new_topics = [...enable_new_topics, value];
    } else {
      content.enable_new_topics = enable_new_topics.filter(item => item !== value);
    }

    this.setState({
      "content": content
    }, () => {
      this.props.onContentChange(content)
    });
  }

  render() {
    const {user_roles = []} = this.state;
    var content = this.state.content;
    var labels = this.props.labels;
    var activeTab = this.state.active_tab;

    const {grade_numeral = {}} = content;

    const {
      min = 0,
      max = 100,
      steps = 10
    } = grade_numeral;

    return (<div className="rdp-element-image-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className={`rdp-composer-element-edit-tabs-item ${activeTab === "settings" ? "active" : ""}`}
            onClick={() => this.onChangeTab("settings")}>{labels.elements.common_settings.label_tab_settings}</li>
        <li className={`rdp-composer-element-edit-tabs-item ${activeTab === "rules" ? "active" : ""}`}
            onClick={() => this.onChangeTab("rules")}>{labels.elements.common_settings.label_tab_rules}</li>
      </ul>

      {activeTab === "settings" &&
        <div className="rdp-composer-element-edit-body">
          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_content_title}:</span>
          <input type="text" className="rdp-composer-element-edit-input" onChange={(e) => this.onContentTitleChange(e)}
                 defaultValue={content.content_title}></input>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_margin_top}</span>
          <input type="number" className="rdp-composer-element-edit-input px"
                 onChange={(e) => this.onPaddingTopChange(e)} defaultValue={content.padding_top}></input>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_margin_bottom}</span>
          <input type="number" className="rdp-composer-element-edit-input px"
                 onChange={(e) => this.onPaddingBottomChange(e)} defaultValue={content.padding_bottom}></input>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_background_color}</span>
          <ColorPicker labels={this.props.labels} color={content.background} onChange={this.onBackgroundColorChange}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_confirm_button_text_color}</span>
          <ColorPicker labels={this.props.labels} color={content.confirm_button_text_color}
                       onChange={(color) => this.onPropChange(color, "confirm_button_text_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_confirm_button_background_color}</span>
          <ColorPicker labels={this.props.labels} color={content.confirm_button_background_color}
                       onChange={(color) => this.onPropChange(color, "confirm_button_background_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_cancel_button_text_color}</span>
          <ColorPicker labels={this.props.labels} color={content.cancel_button_text_color}
                       onChange={(color) => this.onPropChange(color, "cancel_button_text_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_cancel_button_background_color}</span>
          <ColorPicker labels={this.props.labels} color={content.cancel_button_background_color}
                       onChange={(color) => this.onPropChange(color, "cancel_button_background_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_topic_button_color}</span>
          <ColorPicker labels={this.props.labels} color={content.topic_button_color}
                       onChange={(color) => this.onPropChange(color, "topic_button_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_primary_topic_background_color}</span>
          <ColorPicker labels={this.props.labels} color={content.primary_topic_background_color}
                       onChange={(color) => this.onPropChange(color, "primary_topic_background_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_primary_topic_author_color}</span>
          <ColorPicker labels={this.props.labels} color={content.primary_topic_author_color}
                       onChange={(color) => this.onPropChange(color, "primary_topic_author_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_primary_topic_text_color}</span>
          <ColorPicker labels={this.props.labels} color={content.primary_topic_text_color}
                       onChange={(color) => this.onPropChange(color, "primary_topic_text_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_secondary_topic_background_color}</span>
          <ColorPicker labels={this.props.labels} color={content.secondary_topic_background_color}
                       onChange={(color) => this.onPropChange(color, "secondary_topic_background_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_secondary_topic_author_color}</span>
          <ColorPicker labels={this.props.labels} color={content.secondary_topic_author_color}
                       onChange={(color) => this.onPropChange(color, "secondary_topic_author_color")}/>

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.settings_label_secondary_topic_text_color}</span>
          <ColorPicker labels={this.props.labels} color={content.secondary_topic_text_color}
                       onChange={(color) => this.onPropChange(color, "secondary_topic_text_color")}/>

        </div>
      }

      {activeTab === "rules" &&
        <div className="rdp-composer-element-edit-body">
          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.rules_label_grade_type}</span>

          <select defaultValue={content.grade_type} className="rdp-composer-element-edit-select"
                  onChange={(e) => this.onChangeGradeType(e.target.value)}>
            <option value="numeral">{labels.elements.settings.element_forum_1.rules_grade_type_numeral}</option>
          </select>

          {(content.grade_type === "numeral") &&
            <>
              <span
                className="rdp-composer-element-edit-label">
                {labels.elements.settings.element_forum_1.rules_label_grade_numeral_min}:
              </span>
              <input
                type="number"
                className="rdp-composer-element-edit-input"
                max={String(max)}
                onChange={(e) => this.onGradeNumeralMinChange(e.target.value)}
                onBlur={(e) => this.checkGradeNumeralMinValue()}
                value={String(min)}
              />

              <span
                className="rdp-composer-element-edit-label">
                {labels.elements.settings.element_forum_1.rules_label_grade_numeral_max}:
              </span>
              <input
                type="number"
                className="rdp-composer-element-edit-input"
                onChange={(e) => this.onGradeNumeralMaxChange(e.target.value)}
                onBlur={(e) => this.checkGradeNumeralMaxValue()}
                value={String(max)}
              />

              <span
                className="rdp-composer-element-edit-label">
                {labels.elements.settings.element_forum_1.rules_label_grade_numeral_steps}:
              </span>
              <input
                type="number"
                className="rdp-composer-element-edit-input"
                min={1}
                max={String(max)}
                onChange={(e) => this.onGradeNumeralStepsChange(e.target.value)}
                onBlur={(e) => this.checkGradeNumeralStepsValue()}
                value={steps || 10}
              />

              <PreferenceRequiredInteraction
                label={labels.elements.settings.preference_required_interaction.label}
                labels={labels}
                content={content}
                onContentChange={c => {
                  this.setState({ content: c });
                  this.props.onContentChange(c);
                }}
              />
            </>
          }

          <span
            className="rdp-composer-element-edit-label">{labels.elements.settings.element_forum_1.rules_label_permission}</span>

          <ul className="rdp-composer-element-edit-permission-list">
            {user_roles.map((role) => {
              const {
                content: {
                  enable_new_topics = []
                }
              } = this.state;

              return (
                <li
                  key={role}
                  className="rdp-composer-element-edit-permission-list-item"
                >
                  <label
                    className="rdp-composer-element-edit-permission-checkbox-label"
                  >
                    <input
                      className="rdp-composer-element-edit-permission-checkbox"
                      type="checkbox"
                      value={role}
                      onChange={this.onCheckPermission}
                      checked={enable_new_topics.includes(role)}
                    />
                    <span className="rdp-composer-element-edit-permission-checkbox-checkmark"/>
                    <span className="rdp-composer-element-edit-permission-checkbox-text">
                      {labels.elements.settings.element_forum_1[`rules_permission_${role}`]}
                    </span>
                  </label>
                </li>
              )
            })}
          </ul>

        </div>
      }

      {this.renderDAMWidget()}
    </div>);
  }
}
