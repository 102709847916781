import React, { useEffect, useState } from "react";
import uuidv1 from "uuid/v1";
import { OPERATORS } from "./constants";
import { useStructure } from "../../../hooks/context/structure";

export const ElementConfigVisibility = () => {
  const { structure, getElementsPerType, element, onContentChange } = useStructure();
  const [currentElement, setCurrentElement] = useState(element);
  const quizList = getElementsPerType("quiz", true, ['quiz-5']);
  const { show } = currentElement;

  const hasRules = show && show.rules;

  const addNewRule = () => {
    const newRule = {
      id: uuidv1(),
      operator: "",
      value: null,
    };
    if (hasRules) {
      setCurrentElement((prev) => ({
        ...prev,
        show: {
          ...prev.show,
          rules: [...prev.show.rules, newRule],
        },
      }));
      return;
    }

    setCurrentElement({
      ...currentElement,
      show: {
        id: uuidv1(),
        rules: [newRule],
        reference_uid: null,
      },
    });
  };

  const onChangeRule = (rule) => {
    setCurrentElement((prev) => ({
      ...prev,
      show: {
        ...prev.show,
        rules: prev.show.rules.map((item) => {
          if (item.id !== rule.id) return item;
          return rule;
        }),
      },
    }));
  };

  const onChangeObjectReference = (e) => {
    if (!e.target.value) return;

    setCurrentElement((prev) => ({
      ...prev,
      show: {
        ...prev.show,
        reference_uid: e.target.value,
      },
    }));
  };

  const rules = show ? show.rules : [];

  useEffect(() => {
    onContentChange(currentElement);
  }, [currentElement]);

  return (
    <>
      <span className="rdp-composer-element-edit-label">Regras de visualização</span>

      <div
        style={{
          padding: "20px 20px 10px",
          display: "flex",
          flexDirection: "column",
          background: "#f6f6f6",
          margin: "1rem 0",
        }}
      >
        {hasRules && (
          <>
            <span className="rdp-composer-element-edit-label">Questionário referência</span>
            <select className="rdp-composer-element-edit-select open-popper" onChange={onChangeObjectReference}>
              <option label="Selecione" style={{ display: "none" }} />
              {quizList.map((quiz, index) => (
                <option
                  selected={quiz.id === show.reference_uid}
                  key={quiz.id}
                  label={quiz.content_title || `Questionário ${index + 1}`}
                  value={quiz.id}
                />
              ))}
            </select>

            <span className="rdp-composer-element-edit-label">Desempenho requerido</span>

            <p style={{ marginTop: "5px" }}>Adicione um operador e um número para criar o critério requerido</p>
          </>
        )}

        {rules.map((rule) => (
          <div style={{ display: "flex", gap: "10px" }} key={rule.id}>
            <select
              style={{ marginBottom: 0 }}
              className="rdp-composer-element-edit-select open-popper"
              onChange={(e) => onChangeRule({ ...rule, operator: e.target.value })}
            >
              <option label="A pontuação deve ser" style={{ display: "none" }} />
              {OPERATORS.map((operator) => (
                <option label={operator.label} value={operator.value} selected={operator.value === rule.operator} />
              ))}
            </select>
            <input
              onChange={(e) => onChangeRule({ ...rule, value: e.target.value })}
              style={{ marginBottom: 0 }}
              className="rdp-composer-element-edit-input"
              type="number"
              placeholder="Pontuação"
              value={rule.value}
            />
          </div>
        ))}

        <div style={{ marginTop: "10px" }}>
          <button onClick={addNewRule} className="element-accordion-properties-choice-new">
            {!hasRules ? "Criar regra" : "Adicionar critério"}
          </button>
        </div>
      </div>
    </>
  );
};
