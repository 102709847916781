import $ from "jquery";
import {GeneralService} from './general';
import RDP_CONFIG from '../config';

var loginService = function() {
  this.login = function(login, password) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/v1.0/signin/uid`,
        type: "POST",
        dataType: "json",
        headers: {
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        },
        data: JSON.stringify({
          "login": login,
          "password": password
        })
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getProjectKey = function (userLogin)
  {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/v1.0/project/key`,
        type: "POST",
        data : userLogin
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    });
  }

  this.getUserInfo = function(type, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/v1.0/user/me`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getProjectsList = function(type, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/v1.0/projects`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key" : RDP_CONFIG.projectKey,
          "Api-Env" : RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.logout = function() {
    return new Promise(function(resolve, reject) {
      window.localStorage.removeItem("rdp-composer-user-data")
      window.localStorage.removeItem("rdp-composer-projects")
      window.localStorage.removeItem("rdp-composer-active-project")
      window.localStorage.removeItem("rdp-composer-data")
      window.localStorage.removeItem("rdp-composer-user-uuid")

      for (const key in localStorage){
        if (key.indexOf("rdp-composer-editing-data-") > -1) {
          window.localStorage.removeItem(key);
        }
      }

      resolve({"success": "ok"})
    })
  }

  return this;
}

var LoginService = new loginService();

export {
  LoginService
};
