import React, {Component} from "react";
import * as S from "./element-hotspots-1.styled";
import Spot from "../../components/element-spot/spot";
import uuidv1 from "uuid/v1";
import {UserService} from "../../services/user";


class ElementHotspots1 extends Component {
  stageRef = React.createRef();
  timeoutMoveSpot = null;
  state = {
    editable: this.props.editable !== undefined ? this.props.editable : true,
    movingSpotId: null,
    disableAddSpot: false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.movingSpotId !== null) {
      this.moveSpot(this.state.movingSpotId);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutMoveSpot);
  }

  createSpot = () => {
    return ({
      id: uuidv1(),
      x: "0",
      y: "0",
      icon: "01",
      title: "Lorem ipsum dolor sit amet",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      hasMedia: false,
      isActive: false,
      image: null,
      audio: null,
    })
  }

  getCoordinatesInPorcentage = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const width = event.target.clientWidth;
    const height = event.target.clientHeight;

    const posX = ((x * 100) / width).toString();
    const posY = ((y * 100) / height).toString();

    return {
      x: posX,
      y: posY
    }
  }

  addSpot = e => {
    if (this.state.disableAddSpot) {
      this.setState({
        disableAddSpot: false,
      })
      return;
    }

    if (this.state.movingSpotId !== null) {
      this.setState({
        movingSpotId: null,
      })
      return;
    }

    if (this.state.editable) {
      let {content} = this.props;
      const {spots = []} = content;
      const coord = this.getCoordinatesInPorcentage(e);

      let newSpot = this.createSpot();
      newSpot.x = coord.x;
      newSpot.y = coord.y;

      content.spots = [...spots, newSpot];

      this.props.onChange(content);
    }
  }

  moveSpot = (e, info = {}) => {
    if (this.timeoutMoveSpot) {
      clearTimeout(this.timeoutMoveSpot);
    }

    this.timeoutMoveSpot = setTimeout(() => {
      if (this.state.movingSpotId !== null) {
        const {id, x, y} = info;
        const {content} = this.props;
        const {spots = []} = content;

        let spot = spots.find(spot => spot.id === id);

        if (spot) {
          const rect = this.stageRef.current.getBoundingClientRect();
          const parentX = rect.left;
          const parentY = rect.top;

          const width = this.stageRef.current.clientWidth;
          const height = this.stageRef.current.clientHeight;

          const posX = (((x - parentX) * 100) / width).toFixed(2);
          const posY = (((y - parentY) * 100) / height).toFixed(2);

          if (posX >= 0 && posX <= 100) spot.x = posX;
          if (posY >= 0 && posY <= 100) spot.y = posY;

          const originalSpot = spots.find(spot => spot.id === this.state.movingSpotId);
          const originalSpotIndex = spots.indexOf(originalSpot);
          spots[originalSpotIndex] = spot;
          content.spots = [...spots];

          this.props.onChange(content);
        }

        clearTimeout(this.timeoutMoveSpot);
      }
    }, 5);
  }

  handleRemoveSpot = (id) => {
    let {content} = this.props;
    const {spots = []} = content;

    content.spots = spots.filter(spot => spot.id !== id);

    this.props.onChange(content);
  }

  handleStartMoveSpot = (id) => {
    const {
      movingSpotId
    } = this.state;

    if (movingSpotId === null || movingSpotId !== id) {
      this.setState({
        movingSpotId: id
      });
    }
  }

  handleStopMoveSpot = (id) => {
    this.setState({
      disableAddSpot: true,
    });
  }

  handleNextSpotFocus = (e, id) => {
    const {content} = this.props;
    const {spots = []} = content;

    const currentSpot = spots.find(spot => spot.id === id);
    const currentSpotIndex = spots.indexOf(currentSpot);
    const nextSpot = spots[currentSpotIndex + 1] || spots[0];

    if (nextSpot) {
      const spotElement = document.getElementById(nextSpot.id);
      spotElement.focus();
    }
  }

  handlePrevSpotFocus = (e, id) => {
    const {content} = this.props;
    const {spots = []} = content;

    const currentSpot = spots.find(spot => spot.id === id);
    const currentSpotIndex = spots.indexOf(currentSpot);
    const prevSpot = spots[currentSpotIndex - 1] || spots[spots.length - 1];

    if (prevSpot) {
      const spotElement = document.getElementById(prevSpot.id);
      spotElement.focus();
    }
  }

  getImageUrl = (image) => {
    const accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg" &&
      image.search("https://images.articulate.com/") === -1) {
      image += "&access_token=" + accessToken;
    }
    return image;
  }

  getWidth = (width) => {
    switch (width) {
      case "full":
        return "100%";
      case "small":
        return "800px";
      case "medium":
        return "900px";
      case "page_width":
        return "page_width";
      default:
        return undefined;
    }
  }

  render() {
    const {
      headerActionsTemplate,
      footerActionsTemplate,
      className,
      content
    } = this.props;
    const {
      image,
      spots = [],
      spot_color,
      background_color,
      padding_top,
      padding_bottom,
      image_alt,
      width,
      font_family_title,
      font_family_text
    } = content;
    const {
      disableAddSpot,
      editable,
    } = this.state;

    return (
      <S.ElementHotspots1
        className={className}
        marginTop={""}
        marginBottom={""}
        backgroundColor={background_color}
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
      >
        {headerActionsTemplate}

        <S.Container>
          <S.Stage
            ref={this.stageRef}
            onClick={this.addSpot}
            width={this.getWidth(width)}
            className="element-hotspots-1__stage"
          >
            <S.Img
              src={this.getImageUrl(image)}
              alt={image_alt}
              showCursor={!disableAddSpot && editable}
            />

            <ol>
              {spots.map((spot) => (
                <li key={spot.id}>
                  <Spot
                    className="hotspots-1-spot"
                    editable={editable}
                    id={spot.id}
                    posY={spot.y}
                    posX={spot.x}
                    image={spot.image}
                    audio={spot.audio}
                    title={spot.title}
                    description={spot.description}
                    icon={spot.icon}
                    onClickRemove={this.handleRemoveSpot}
                    onGrab={this.handleStartMoveSpot}
                    onMove={this.moveSpot}
                    onRelease={this.handleStopMoveSpot}
                    onNext={this.handleNextSpotFocus}
                    onPrev={this.handlePrevSpotFocus}
                    onFocus={e => this.setState({disableAddSpot: true})}
                    color={spot_color}
                    fontFamilyTitle={font_family_title}
                    fontFamilyText={font_family_text}
                  />
                </li>
              ))}
            </ol>
          </S.Stage>
        </S.Container>

        {footerActionsTemplate}
      </S.ElementHotspots1>
    );
  }
}

export default ElementHotspots1;