import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex: ${({ flex = 0 }) => flex};
  align-items: ${({ align }) => align || "unset"};
  margin: ${({ margin }) => margin || "unset"};
  padding: ${({ padding }) => padding || "unset"};
  justify-content: ${({ justify }) => justify || "unset"};
  ${(props) => props.fullWidth && "width: 100%;"}
`;

export const Col = styled(Row)`
  flex-direction: column;
`;

export const Space = styled.div`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`;
