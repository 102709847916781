import React, { Component } from 'react';
import { Quill } from '../../components';
import { UserService } from '../../services/user';
import './element-quiz-2.css';

export default class ElementQuiz2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "activeChoices": [],
      "current_question": 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.content !== prevProps.content) {
      this.manipulateItems();
    }
  };

  manipulateItems() {
    this.setState({
      "content": this.sortQuestions(),
      "contentBkp": this.props.content,
      // "current_question": 0,
      // "activeChoices": [],
    }, () => this.defineAnswered(0, true))
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  getAssetUrl(asset) {
    if (asset.slice(0, '/static/media/'.length) === '/static/media/') return asset;
    var accessToken = UserService.getUser().access_token;
    return asset += "&access_token=" + accessToken;
  }

  renderImage(item) {
    const style = item.media_max_width ? { maxWidth: item.media_max_width.toString() + 'px' } : {};
    if (item.image !== null) {
      return <img className="element-quiz-2-image" src={this.getImageUrl(item.image)} style={style} />
    }
  }

  renderVideo(item) {
    const style = item.media_max_width ? { maxWidth: item.media_max_width.toString() + 'px' } : {};
    if (item.video !== null) {
      return <video className="element-quiz-2-video" controls src={this.getAssetUrl(item.video)} style={style} />
    }
  }

  handleChangeQuestion(questionChanged, text) {
    let contentClone = this.state.content;
    contentClone.questions = contentClone.questions.map(question => {
      if (question.id === questionChanged.id) {
        question.question = text;
      }
      return question;
    });

    this.setState({
      content: contentClone
    }, () => {
      this.props.onChange(contentClone);
    });
  }

  getLabels() {
    var language = this.props.contentLanguage;
    if (language === undefined) language = "pt_br";
    if (language === "en") {
      return {
        "confirm": "Confirm",
        "question": "Question",
        "back": "Back",
        "retry": "Try again",
        "previous": "Previous",
        "next": "Next",
        "finish": "Finish",
        "incorrect": "Incorrect",
        "right": "Right!",
        "finish_quiz_message": "You have completed this quiz."
      }
    } else if (language === "pt_br") {
      return {
        "confirm": "Confirmar",
        "question": "Questão",
        "retry": "Refazer atividade",
        "previous": "Anterior",
        "next": "Próximo",
        "finish": "Finalizar",
        "incorrect": "Incorreto",
        "right": "Correto!",
        "finish_quiz_message": "Você finalizou este questionário."
      }
    }
  }

  renderQuestionQuill = (question) => {
    var modules = this.state.modules;
    var formats = this.state.formats;
    if (this.props.editable) {
      return (
        <div className="element-quiz-2-question">
          <Quill
            key={question.id}
            value={question.question}
            onChange={(text) => this.handleChangeQuestion(question, text)}
          />
        </div>
      );
    } else {
      return (
        <div className="element-quiz-2-question">
          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: question.question }}></div>
        </div>
      );
    }
  }

  renderChoiceRadio(question, choice) {
    const { activeChoices } = this.state;
    if (activeChoices[question.id] && activeChoices[question.id].includes(choice.id)) {
      return <div className="element-quiz-2-choice-radio">
        <svg className="element-quiz-2-correct-choice" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="12px" height="9px" viewBox="0 0 12 9" enable-background="new 0 0 12 9" space="preserve">
          <polygon fill-rule="evenodd" clip-rule="evenodd" fill={this.state.content.primary_color} points="10.15,0.062 4.505,5.782 1.595,2.9 0.032,4.431   4.635,8.98 11.973,1.909 " />
        </svg>
      </div >
    } else {
      return <div className="element-quiz-2-choice-radio"></div>
    }
  }

  onChoiceClick(question, choice) {
    let activeChoices = this.state.activeChoices;
    if (activeChoices[question.id]) {
      if (activeChoices[question.id].includes(choice.id)) {
        activeChoices[question.id] = activeChoices[question.id].filter(selected => {
          if (selected !== choice.id) return selected;
        });
      } else {
        activeChoices[question.id].push(choice.id);
      }
    } else {
      activeChoices[question.id] = [];
      activeChoices[question.id].push(choice.id);
    }

    this.setState({
      activeChoices: activeChoices
    })
  }

  handleChangeChoice(question, choice, text) {
    let contentClone = this.state.content;
    contentClone.questions = contentClone.questions.map(qtion => {
      if (qtion.id === question.id)
        qtion.choices = qtion.choices.map(ch => {
          if (ch.id === choice.id) {
            ch.text = text;
          }
          return ch;
        })
      return qtion;
    });

    this.setState({
      content: contentClone
    }, () => {
      this.props.onChange(contentClone);
    });

  }

  renderChoiceQuill(question, choice, index) {
    let { modules, formats, content, activeChoices } = this.state;
    let classSelected = null;
    let colorChoice = content.primary_color;
    if (activeChoices[question.id] && activeChoices[question.id].includes(choice.id)) {
      classSelected = "choice-selected";
      if (question.answered) {
        let correctAnswer = true;
        let totalCorrects = 0;
        question.choices.map(ch => {
          if (ch.correct) totalCorrects++;
          if (!ch.correct && activeChoices[question.id].includes(ch.id)) {
            correctAnswer = false;
          }
        });
        if (!activeChoices[question.id] || totalCorrects !== activeChoices[question.id].length) correctAnswer = false;
        colorChoice = correctAnswer ? content.correct_color : content.incorrect_color;
        classSelected = "choice-answered";
      }
    }

    if (this.props.editable) {
      return (
        <div key={choice.id} style={classSelected && { borderColor: colorChoice ? colorChoice : content.primary_color }}
          className={`element-quiz-2-choice ${classSelected}`}
          onClick={() => !question.answered && this.onChoiceClick(question, choice)}
        >
          {this.renderChoiceRadio(question, choice)}
          <Quill
            value={choice.text}
            onChange={(text) => this.handleChangeChoice(question, choice, text)}
          />
          {(choice.image && this.renderImage(choice)) || (choice.video && this.renderVideo(choice))}
        </div>
      );
    } else {
      return (
        <div key={choice.id} style={classSelected && { borderColor: colorChoice ? colorChoice : content.primary_color }}
          className={`element-quiz-2-choice ${classSelected}`}
          onClick={() => !question.answered && this.onChoiceClick(question, choice)}
        >
          {this.renderChoiceRadio(question, choice)}
          <div className="element-quiz-2-choice-text" dangerouslySetInnerHTML={{ __html: choice.text }}></div>
          {(choice.image && this.renderImage(choice)) || (choice.video && this.renderVideo(choice))}
        </div>
      )
    }
  }

  renderChoices(question) {
    var _this = this;
    var choices = question.choices;

    return <ul className="element-quiz-2-choices">
      {choices.map(function (choice, index) {
        return _this.renderChoiceQuill(question, choice, index)
      })}
    </ul>
  }

  renderPreviousQuestionBtn() {
    const { primary_color } = this.state.content;
    let current_question = this.state.current_question;
    if (current_question !== 0) {
      return <button style={{ color: primary_color }}
        onClick={() => this.setState({ current_question: current_question - 1 })}
        className="rdp-react-quiz-navigation-previous-btn">{this.getLabels().previous}</button>
    }
  }

  renderNextQuestionBtn() {
    const { current_question, content } = this.state;
    const { questions, primary_color } = content;
    var questions_count = questions.length;
    if (questions_count !== (current_question + 1)) {
      return <button style={{ color: primary_color }}
        onClick={() => this.setState({ current_question: current_question + 1 })}
        className="rdp-react-quiz-navigation-next-btn">{this.getLabels().next}</button>
    }
  }

  renderFinishBtn() {
    const { current_question, content, activeChoices } = this.state;
    const { questions, primary_color } = content;
    let current = current_question + 1;
    let questions_count = questions.length;
    let answeredQuestions = 0;
    questions.map(q => {
      if (activeChoices[q.id] && q.answered) {
        answeredQuestions++;
      }
    })
    let disabledBtn = questions_count !== answeredQuestions;
    if (current === questions_count) {
      return <button style={{ color: !disabledBtn ? primary_color : "#A1A1A1", cursor: !disabledBtn ? "pointer" : "default" }}
        onClick={() => !disabledBtn && this.onFinishClick()} className="rdp-react-quiz-navigation-finish-btn">{this.getLabels().finish}</button>
    }
  }

  renderProgress() {
    const { current_question, content } = this.state;
    const { questions, primary_color } = content;
    var current = current_question + 1;
    var total = questions.length;
    var percentage = (100 / total * current) + "%";
    return <div className="rdp-react-quiz-navigation-progress">
      <div className="rdp-react-quiz-navigation-progress-bar-placeholder">
        <div style={{ width: percentage, background: primary_color }} className="rdp-react-quiz-navigation-progress-bar"></div>
      </div>
      <span style={{ color: primary_color }} className="rdp-react-quiz-navigation-progress-count">{current}/{total}</span>
    </div>
  }

  renderNavigation() {
    return (
      <div className="rdp-react-quiz-navigation">
        {this.renderPreviousQuestionBtn()}
        {this.renderProgress()}
        {this.renderNextQuestionBtn()}
        {this.renderFinishBtn()}
      </div>
    );
  }

  defineAnswered = (idQuestion, resetAll = false) => {
    let cloneContent = this.state.content;
    let changedQuestions = this.state.content.questions.map(question => {
      if (question.id === idQuestion) {
        question.answered = true;
      }
      if (resetAll === true) {
        question.answered = false;
      }
      return question;
    });
    cloneContent.questions = changedQuestions;
    this.setState({
      content: cloneContent
    });
  }

  renderCustomMsg = (question) => {
    const msg = question.feedback_default.text;
    const image = question.feedback_default.image;
    const video = question.feedback_default.video;
    const media_max_width = question.feedback_default.media_max_width;

    return (
      <div>
        {(image && this.renderImage({ image, media_max_width })) || (video && this.renderVideo({ video, media_max_width }))}
        {msg && <p>{msg}</p>}
      </div>
    )
  }

  renderCorrectMsg = (question) => {
    const { content } = this.state;
    let msg = this.getLabels().right;
    let image = null, video = null, media_max_width = null;
    if (content.feedback.type === "correct_incorrect") {
      msg = question.feedback_correct.text ? question.feedback_correct.text : msg;
      image = question.feedback_correct.image;
      video = question.feedback_correct.video;
      media_max_width = question.feedback_correct.media_max_width;
    } else {
      msg = question.feedback_default.text ? question.feedback_default.text : msg;
      image = question.feedback_default.image;
      video = question.feedback_default.video;
      media_max_width = question.feedback_default.media_max_width;
    }

    return (
      <div>
        {!image && !video && (
          <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="56px" height="56px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" space="preserve">
            <path fill={content.correct_color ? content.correct_color : content.primary_color} d="M221.41,346.64c-5.42,0-10.81-2.11-14.87-6.28l-77.5-79.56c-7.99-8.21-7.82-21.35,0.39-29.35  c8.22-8,21.35-7.82,29.35,0.39l63.21,64.89L354.5,171.06c8.32-7.9,21.46-7.53,29.34,0.78c7.9,8.31,7.54,21.45-0.77,29.34  L235.69,340.94C231.67,344.74,226.54,346.64,221.41,346.64z" />
            <path fill={content.correct_color ? content.correct_color : content.primary_color} fillRule="evenodd" clipRule="evenodd" d="M256,41.51C137.73,41.51,41.51,137.73,41.51,256  S137.73,470.49,256,470.49S470.49,374.27,470.49,256S374.27,41.51,256,41.51z M256,512C114.84,512,0,397.16,0,256S114.84,0,256,0  s256,114.84,256,256S397.16,512,256,512z" />
          </svg>
        )}
        {(image && this.renderImage({ image, media_max_width })) || (video && this.renderVideo({ video, media_max_width }))}
        <p>{msg}</p>
      </div>
    );
  }

  renderIncorrectMsg = (question) => {
    const { content } = this.state;
    let msg = this.getLabels().incorrect;
    let image = null, video = null, media_max_width = null;
    if (content.feedback.type === "correct_incorrect") {
      msg = question.feedback_incorrect.text ? question.feedback_incorrect.text : msg;
      image = question.feedback_incorrect.image;
      video = question.feedback_incorrect.video;
      media_max_width = question.feedback_incorrect.media_max_width;
    } else {
      msg = question.feedback_default.text ? question.feedback_default.text : msg;
      image = question.feedback_default.image;
      video = question.feedback_default.video;
      media_max_width = question.feedback_default.media_max_width;
    }

    return (
      <div>
        {!image && !video && (<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="56px" height="56px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" space="preserve">
          <path fill={content.incorrect_color ? content.incorrect_color : content.primary_color} fillRule="evenodd" clipRule="evenodd" d="M256,36.57C135,36.57,36.57,135,36.57,256S135,475.43,256,475.43  S475.43,377,475.43,256S377,36.57,256,36.57z M256,512C114.84,512,0,397.16,0,256S114.84,0,256,0s256,114.84,256,256  S397.16,512,256,512z" />
          <path fill={content.incorrect_color ? content.incorrect_color : content.primary_color} fillRule="evenodd" clipRule="evenodd" d="M170,342c7.13,7.13,18.68,7.13,25.8,0l60.2-60.2l60.2,60.2  c7.13,7.13,18.68,7.12,25.8,0c7.13-7.13,7.13-18.67,0-25.8L281.8,256l60.2-60.2c7.13-7.12,7.13-18.67,0-25.8  c-7.12-7.12-18.67-7.12-25.8,0L256,230.2L195.8,170c-7.12-7.13-18.67-7.13-25.8,0c-7.12,7.12-7.13,18.68,0,25.8l60.2,60.2L170,316.2  C162.88,323.32,162.88,334.88,170,342z" />
        </svg>)}
        {(image && this.renderImage({ image, media_max_width })) || (video && this.renderVideo({ video, media_max_width }))}
        <p>{msg}</p>
      </div>
    );
  }

  verifyAnswer = (question) => {
    const { activeChoices } = this.state;
    const { feedback } = this.state.content;

    let correctAnswer = true;
    let totalCorrects = 0;
    question.choices.map(choice => {
      if (choice.correct) totalCorrects++;
      if (!choice.correct && activeChoices[question.id] && activeChoices[question.id].includes(choice.id)) {
        correctAnswer = false;
      }
    });
    if (!activeChoices[question.id] || totalCorrects !== activeChoices[question.id].length) correctAnswer = false;

    const no_correct_answer = question.no_correct_answer;

    if (feedback.type !== "disabled" && !question.no_feedback) {
      return (
        <div className="element-quiz-2-feedback-msg">
          {(correctAnswer && !no_correct_answer)
            ? this.renderCorrectMsg(question)
            : (!no_correct_answer)
              ? this.renderIncorrectMsg(question)
              : this.renderCustomMsg(question)
          }
        </div>
      );
    };

  }

  renderFeedback(question) {
    const { content, activeChoices } = this.state;
    const { primary_color, } = content;

    return (
      <div className="element-quiz-2-feedback-container">
        {question.answered ?
          this.verifyAnswer(question)
          :
          <button onClick={() => activeChoices[question.id] && this.defineAnswered(question.id)}
            style={{ background: activeChoices[question.id] ? primary_color : "#F1F1F1" }} className="element-quiz-2-btn-confirm">{this.getLabels().confirm}</button>
        }
      </div>
    );

  }

  renderQuestionare(question) {
    const { current_question, content } = this.state;
    const { questions, primary_color, } = content;
    return (
      <div>
        <div className="element-quiz-2-header">
          <span style={{ color: primary_color }}>{this.getLabels().question} {current_question + 1}</span>
          <span style={{ color: primary_color }}>{current_question + 1}/{questions.length}</span>
        </div>
        {this.renderQuestionQuill(question)}
        {this.renderImage(question)}
        {this.renderVideo(question)}
        {this.renderChoices(question)}
        {this.renderFeedback(question)}
        {this.renderNavigation()}
      </div>
    );
  }

  renderContent() {
    const { current_question, content, show_result } = this.state;
    const { questions } = content;
    if (questions) {
      let question = questions[current_question];
      return (
        <div className="element-quiz-2-container">
          {question && !show_result ? this.renderQuestionare(question) : this.renderResult()}
        </div>
      )
    };
  }


  onFinishClick() {
    const { content, activeChoices } = this.state;
    const { questions } = content;
    let hits = 0;
    let total = questions.length;
    questions.map(question => {
      let totalCorrects = 0;
      let correctsAnswered = 0;
      if (question.answered) {
        question.choices.map(choice => {
          if (choice.correct) {
            totalCorrects++;
            if (activeChoices[question.id].includes(choice.id)) {
              correctsAnswered++;
            }
          }
        });
        if (totalCorrects === correctsAnswered && correctsAnswered === activeChoices[question.id].length) {
          hits++;
        }
      }
    });

    var resume = {
      "rdp_html_content": {
        "questions": questions,
        "hits": hits,
        "total": total,
        "percentage_average": (100 / total * hits)
      }
    };

    this.setState({
      grades: resume,
      show_result: true,
    })
  }

  onRemakeQuestionnaire() {
    this.defineAnswered(0, true);
    this.setState({
      answered: false,
      grades: null,
      show_result: false,
      current_question: 0,
      activeChoices: []
    })

    this.sortQuestions(true);
  }

  sortQuestions(isRemake = false) {
    const contentCopy = { ...this.props.content };
    var splitQuestions = contentCopy.number_of_questions_to_display !== undefined && contentCopy.number_of_questions_to_display > 0;
    var randomizeOnRemake = contentCopy.random_on_remake !== undefined && contentCopy.random_on_remake.enabled === true;
    var sortQuestions = contentCopy.random_question_order !== undefined && contentCopy.random_question_order.enabled === true;

    if ((randomizeOnRemake && isRemake) || !isRemake) {
      const backup = this.props.content.questions;
      var newQuestions = this.props.content.questions;

      if (sortQuestions) {
        newQuestions.sort((a, b) => {
          return Math.floor(Math.random() * (1 - -1)) + -1;
        });
      }

      if (splitQuestions) {
        newQuestions = newQuestions.slice(0, contentCopy.number_of_questions_to_display);
      }

      contentCopy.questions = newQuestions;

      this.props.content.questions = backup;
      this.setState({
        content: contentCopy
      })
    }

    return contentCopy;
  }

  getResultLabel(hits, total, percentage) {
    var language = this.props.contentLanguage;
    if (language === "en") {
      return `You got ${hits} of ${total} questions right, equivalent to ${percentage} of the quiz.`
    } else if (language === "pt_br") {
      return `Você acertou ${hits} de ${total} questões, o equivalente a ${percentage} do questionário.`
    };
  }
  

  renderFinalMessage(grades) {
    var feedback = this.state.content.feedback.type;
    if (feedback === "disabled") {
      return <span className="rdp-react-quiz-result-message">{this.getLabels().finish_quiz_message}</span>
    } else {
      return <span className="rdp-react-quiz-result-message">{this.getResultLabel(grades.hits, grades.total, `${parseFloat(grades.percentage_average).toFixed(0)}%`)}</span>;
    }
  }

  renderResult() {
    const { content, show_result } = this.state;
    var feedback = this.state.content.feedback.type;
    if (show_result === true) {
      let grades = this.state.grades.rdp_html_content;
      return (
        <div className="rdp-react-quiz-result">
          <span className="rdp-react-quiz-result-icon"></span>
          {this.renderFinalMessage(grades)}
          {content.remake == "enable" && <div className="rdp-react-quiz-remake-btn" onClick={() => this.onRemakeQuestionnaire()}>
            <span className="rdp-react-quiz-remake-btn-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" space="preserve">
                <g>
                  <path fill={content.primary_color ? content.primary_color : "#111111"} d="M126.97,328.84c-5.84,0-11.63-2.42-15.78-7.15L5.17,200.31c-6.89-7.88-6.89-19.64,0-27.53L111.19,51.41   c7.61-8.7,20.83-9.59,29.53-1.99c8.71,7.6,9.59,20.82,2,29.52l-94,107.61l94,107.6c7.6,8.7,6.71,21.92-2,29.52   C136.75,327.14,131.85,328.84,126.97,328.84z" />
                  <path fill={content.primary_color ? content.primary_color : "#111111"} d="M359.93,469.75H48.83c-11.56,0-20.93-9.37-20.93-20.93s9.37-20.93,20.93-20.93h311.1   c60.78,0,110.22-49.44,110.22-110.21s-49.44-110.21-110.22-110.21h-339C9.37,207.47,0,198.1,0,186.54   c0-11.55,9.37-20.92,20.93-20.92h339c83.85,0,152.07,68.22,152.07,152.06C512,401.53,443.77,469.75,359.93,469.75z" />
                </g>
              </svg>
            </span>
            {/* <span className="rdp-react-quiz-remake-btn-text">{feedback === "disabled" ? "Refazer" : "Refazer atividade"}</span> */}
            <span className="rdp-react-quiz-remake-btn-text">{this.getLabels().retry}</span>
          </div>}
        </div >
      );
    }
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    let accordionStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }
    return <div className={`${this.props.className} ${this.getFont(content.font_family) || ""}`} style={accordionStyle}>
      {headerActionsTemplate}
      {this.renderContent()}
      {footerActionsTemplate}
    </div>
  }

}
