import React, { Component } from 'react'
import './element-header.css';

export default class ElementHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var up = (<button className="rdp-composer-element-header-up-btn" onClick={() => this.props.onUp()}>
      <span></span>
    </button>);
    var down = (<button className="rdp-composer-element-header-down-btn" onClick={() => this.props.onDown()}>
      <span></span>
    </button>);


    if (this.props.index === 0) {
      up = "";
      down = (<button className="rdp-composer-element-header-down-btn alone" onClick={() => this.props.onDown()}>
        <span></span>
      </button>);
    };
    if (((this.props.index === this.props.length - 1) && this.props.length > 1) || this.props.length === 1) {
      down = "";
    };

    return (
      <div className="rdp-composer-element-header">
        {up}
        {down}
        <button className="rdp-composer-element-header-remove-btn" onClick={() => this.props.onRemove()}>
          <span></span>
        </button>
        <button className="rdp-composer-element-header-edit-btn" onClick={() => this.props.onEdit()}>
          <span></span>
        </button>
        <button className="rdp-composer-element-header-copy-btn" onClick={() => this.props.onCopy()}>
          <span></span>
        </button>
        {this.props.renderObjectMessages &&
          <button className="rdp-composer-element-header-message-btn" onClick={() => this.props.onOpenMessages()}>
            <span></span>
          </button>
        }
      </div>
    );
  }
}
