export default function replaceObjectKeyValue(obj, regex, cb = () => {}) {
  if (obj && typeof obj === "object") {
    Object.keys(obj).forEach(function (k) {
      if (obj[k] && typeof obj[k] !== "object" && regex.exec(k)) {
        obj[k] = cb(k, obj[k]);
      } else if (obj[k] && typeof obj[k] === "object") {
        replaceObjectKeyValue(obj[k], regex, cb);
      }
    });
  }
  return obj;
}


