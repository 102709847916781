import React from "react";

export const IconLike = () => (
  <svg
    fill="#000000"
    width="100px"
    height="100px"
    viewBox="0 0 24 24"
    id="like"
    data-name="Line Color"
    xmlns="http://www.w3.org/2000/svg"
    className="icon line-color"
  >
    <path
      id="primary"
      d="M7.25,20.12a9.47,9.47,0,0,0,4,.88h4.2a4,4,0,0,0,3.88-3l1.62-6.48A2,2,0,0,0,19,9H16a31.13,31.13,0,0,0,.12-3.77A2.28,2.28,0,0,0,13.82,3h-.05a2.2,2.2,0,0,0-2.15,1.72c-.36,1.5-.74,2.83-1.11,3.92A2,2,0,0,1,8.62,10H7.2"
      style={{
        fill: "none",
        stroke: "rgb(0, 0, 0)",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
    <rect
      id="secondary"
      x={3}
      y={9}
      width={4}
      height={12}
      style={{
        fill: "none",
        stroke: "rgb(44, 169, 188)",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
  </svg>
);
