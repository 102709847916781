import styled from 'styled-components';


export const PileList = styled.ul`
  width: 100%;
  padding: 10px;
`;

export const Pile = styled.li`
  width: 100%;
  background: #e1e1e1;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
`;

export const PileHeader = styled.div`
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`;

export const ItemList = styled.ul`
  width: 100%;
`;

export const Item = styled.li`
  background: white;
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 15px 30px;
  margin-bottom: 10px;
  box-shadow: 0 0 1px 1px #8f8f8f;
  position: relative;
`;

export const AddButton = styled.li`
  width: 100%;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  color: white;
  background: #2d7b45;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const PileTextArea = styled.textarea`
  width: 100%;
  border: none;
  color: black;
  user-select: text;
  background: transparent;
  height: auto;
`;

export const ItemTextArea = styled.textarea`
  width: 100%;
  border: none;
  color: black;
  user-select: text;
  background: transparent;
  height: auto;
`;

export const RemoveButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
`;