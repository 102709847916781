import React, {Component} from 'react';
import * as S from './element-code-snippet-1.styled';
import copyIcon from './copy.svg';
import checkCircleIcon from './check-circle.svg';

class ElementCodeSnippet1 extends Component {
  codeRef = React.createRef();
  state = {
    copied: false,
  }

  getCaptionAriaProps = (item) => {
    const {
      id,
    } = item;

    return {
      'aria-labelledby': `caption-${id}`,
      'aria-describedby': `caption-${id}`,
    }
  }

  getLabels = (label) => {
    const {
      contentLanguage
    } = this.props;
    const labels = {
      "pt_br": {
        "copy": 'Copiar',
      },
      "en": {
        "copy": 'Copy',
      }
    }

    const localizedLabels = labels[contentLanguage.toLowerCase()] || {};

    return localizedLabels[label] || label;
  }

  handleCopyItemCodeText = async (evt) => {
    evt.preventDefault();

    if (navigator && navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(this.codeRef.current.innerText);
        this.setState({
          copied: true,
        });
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    }
  }

  getFont = font => font ? font.replace(/\s/g, '-').toLowerCase() : "";

  render() {
    const {
      headerActionsTemplate,
      footerActionsTemplate,
      content,
      className,
    } = this.props;
    const {
      background_color,
      primary_color,
      padding_top,
      padding_bottom,
      snippet_background_color,
      font_family,
      items = [],
    } = content;
    const {
      copied,
    } = this.state;

    return (
      <S.ElementCodeSnippet1
        className={`${className} ${this.getFont(font_family)}`}
        backgroundColor={background_color}
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
        primaryColor={primary_color}
      >
        {headerActionsTemplate}
        <S.SnippetList>
          {items.map(item => (
            <S.SnippetItem
              key={item.id}
              className="element-snippet-1__snippet-item"
            >
              <S.CopyButton
                tabIndex="1"
                type="button"
                onClick={this.handleCopyItemCodeText}
                title={this.getLabels('copy')}
              >
                <S.CopyButtonIcon
                  src={copied ? checkCircleIcon : copyIcon}
                  width="24"
                  height="24"
                />
              </S.CopyButton>

              <S.Figure
                id={`figure-${item.id}`}
                backgroundColor={snippet_background_color}
                {...this.getCaptionAriaProps(item)}
              >
                <S.Pre
                  ref={this.codeRef}
                  dangerouslySetInnerHTML={{__html: item.code}}
                />
              </S.Figure>
              {
                item.caption.length > 0 && (
                  <S.FigCaption
                    id={`caption-${item.id}`}
                    dangerouslySetInnerHTML={{__html: item.caption}}
                  />
                )
              }
            </S.SnippetItem>
          ))}
        </S.SnippetList>
        {footerActionsTemplate}
      </S.ElementCodeSnippet1>
    );
  }
}

export default ElementCodeSnippet1;