import React from "react";
import * as S from "./quiz-header.styled";


function QuizHeader(props) {
  const {
    primaryColor,
    labels,
    current,
    total,
  } = props;

  return (
    <S.QuizHeader>
      <S.CurrentQuestionText
        primaryColor={primaryColor}
      >
        {labels.question} {current + 1}
      </S.CurrentQuestionText>

      <S.ProgressText
        primaryColor={primaryColor}
      >
        {current + 1}/{total}
      </S.ProgressText>
    </S.QuizHeader>
  )
}

export default QuizHeader;