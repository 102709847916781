import React, {Component} from 'react';
import * as S from "./element-timeline-1.styled";
import {UserService} from "../../services/user";
import { getTheme } from '../../utils/getTheme';


class ElementTimeline1 extends Component {

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
    return "";
  }

  getAssetUrl(assetUrl) {
    const publicAssetsArr = [
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg",
      "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg",
    ];
    if (false === publicAssetsArr.includes(assetUrl)) {
      assetUrl = `${assetUrl}&access_token=${UserService.getUser().access_token}`;
    }
    return assetUrl;
  }

  render() {
    const {
      className,
      content,
      headerActionsTemplate,
      footerActionsTemplate,
    } = this.props;
    const {
      background_color,
      primary_color,
      padding_top,
      padding_bottom,
      items = [],
    } = content;

    return (
      <S.ElementTimeline1
        className={`${className} ${this.getFont()}`}
        backgroundColor={background_color}
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
      >
        {headerActionsTemplate}

        <S.TimelineList >
          {items.map((item) => (
            <S.TimelineItem
              key={item.id}
              primaryColor={primary_color}
            >
              <S.TimelineDate
                primaryColor={primary_color}
              >
                {item.date}
              </S.TimelineDate>

              <S.TimelineTitle
                dangerouslySetInnerHTML={{__html: item.title}}
              />

              {item.audio && (
                <S.TimelineAudio
                  src={this.getAssetUrl(item.audio)}
                  controls
                />
              )}

              <S.TimelineDescription
                height={item.height && `${item.height}px`}
                dangerouslySetInnerHTML={{__html: item.description}}
              />

              {item.image && (
                <S.TimelineImage
                  src={this.getAssetUrl(item.image)}
                />
              )}
            </S.TimelineItem>
          ))}
        </S.TimelineList>

        {footerActionsTemplate}
      </S.ElementTimeline1>
    );
  }
}

export default ElementTimeline1;