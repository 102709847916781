import React, { useState } from "react";

import { Typography, Checkbox } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import RefreshIcon from "@material-ui/icons/Refresh";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import * as S from "./styles";
import { Row } from "../Flex";

const Quiz = ({ open, onComplete, question, options }) => {
  const [checkedOption, setCheckedOption] = useState();
  const [finished, setFinished] = useState(false);
  const correctItem = options.find(item => item.correct);
  const correctOption = correctItem && correctItem.id;
  const success = checkedOption === correctOption;

  if (!open) return <></>;

  return (
    <S.Background>
      <S.Container>
        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
          {question}
        </Typography>

        {options.map((option, i) => (
          <S.CheckRow
            checked={option.id === checkedOption}
            correct={correctOption === option.id}
            finished={finished}
            onClick={() => setCheckedOption(option.id)}
          >
            <Checkbox
              checked={checkedOption === option.id}
              checkedIcon={
                !finished ? (
                  <RadioButtonCheckedIcon />
                ) : correctOption === option.id ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )
              }
              icon={
                !finished ? (
                  <RadioButtonUncheckedIcon />
                ) : correctOption === option.id ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )
              }
            />
            <span>{option.text}</span>
          </S.CheckRow>
        ))}

        <Row>
          <S.ButtonCheck onClick={() => setFinished(true)}>
            <CheckIcon />
            Confirmar
          </S.ButtonCheck>

          {finished && (
            <S.ButtonRefresh
              onClick={() => {
                setFinished(false);
                setCheckedOption(undefined);
              }}
            >
              <RefreshIcon />
            </S.ButtonRefresh>
          )}

          {finished && success && (
            <S.ButtonRefresh
              onClick={onComplete}
            >
              <PlayArrowIcon />
            </S.ButtonRefresh>
          )}
        </Row>
      </S.Container>
    </S.Background>
  );
};

export default Quiz;
