import React, { Component } from 'react';
import './element-divider-numbered.css';

export default class ElementDividerNumbered extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(content) {
    this.props.onChange(content);
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;
    var dividerContainerStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }

    var lineStyle = {
      background: content.divider_primary_color
    }

    var circleStyle = {
      background: content.divider_primary_color,
      color: content.divider_secondary_color
    }

    return (
      <div className={`${className}`} style={dividerContainerStyle}>
        {headerActionsTemplate}
        <div className="element-divider-numbered-content">
          <div className="element-divider-numbered-line" style={lineStyle}></div>
          <span className="element-divider-numbered-circle" style={circleStyle}>{this.props.objectIndex + 1}</span>
        </div>
        {footerActionsTemplate}
      </div>
    )
  }
}
