import { Quill } from "react-quill";

var Clipboard = Quill.import("modules/clipboard");
var Delta = Quill.import("delta");

export default class ClipboardHandler extends Clipboard {


  clipboardHeader(currentFormats, range, text) {
    const [leaf] = this.quill.getLeaf(range.index);
    const element = leaf ? leaf.domNode : null;

    if (element && currentFormats && currentFormats.header) {
      let delta = new Delta().retain(range.index).delete(range.length);

      const lines = text.replaceAll("\r", "").split("\n");

      lines.forEach((line, index) => {
        if (index > 0) {
          delta = delta.insert("\n", { header: currentFormats.header });
        }
        delta = delta.insert(line, { ...currentFormats, header: currentFormats.header });
      });

      this.quill.updateContents(delta, Quill.sources.USER);
      // this.quill.setSelection(range.index + text.length);

      return true;
    }

    return false;
  }
  
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    if (range == null) return;

    let text = (e.clipboardData || window.clipboardData).getData("text");
    let html = (e.clipboardData || window.clipboardData).getData("text/html");

    let currentFormats = this.quill.getFormat(range.index);

    let lines = text.replaceAll("\r", "").split("\n");
    let delta = new Delta().retain(range.index).delete(range.length);
    const formats = !html ? { size: "18px" } : currentFormats;

    const isHeader = this.clipboardHeader(currentFormats, range, text, lines);

    if(isHeader) return;

    lines.forEach((line, index) => {
      if (index > 0) delta.insert("\r");
      delta = delta.insert(line, formats);
    });

    this.quill.updateContents(delta, Quill.sources.USER);

    this.quill.setSelection(range.index + text.length);
  }

  formatsToCSS(formats) {
    let style = '';
  
    if (formats.bold) {
      style += 'font-weight: bold; ';
    }
    if (formats.italic) {
      style += 'font-style: italic; ';
    }
    if (formats.underline) {
      style += 'text-decoration: underline; ';
    }
    if (formats.size) {
      style += `font-size: ${formats.size}; `;
    }
    if (formats.color) {
      style += `color: ${formats.color}; `;
    }
  
    return style;
  }
}

Quill.register("modules/clipboard", ClipboardHandler, true);
