import {RDP_TRANSLATE_EN} from './en';
import {RDP_TRANSLATE_PT_BR} from './pt-br';

var rdpTranslate = function() {
  this.en = function() {
    return RDP_TRANSLATE_EN;
  }

  this.pt_br = function() {
    return RDP_TRANSLATE_PT_BR;
  }

  return this;
}

var RDP_TRANSLATE = new rdpTranslate();

export {
  RDP_TRANSLATE
};