import styled, {css} from "styled-components";


export const Checkbox = styled.span`
  --primary-color: ${props => props.primaryColor || "#00643e"};
  
  ${({padding}) => padding && css`
    width: 60px;
    height: 45px;
  `}
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Input = styled.input`
  cursor: inherit;
  appearance: none;
  background-color: transparent;
  margin: 0;

  font: inherit;
  color: transparent;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  &:before {
    position: absolute;
    top: 11px;
    left: 7px;
    content: "";
    width: 3px;
    height: 0;
    transform: rotate(140deg);
    transition: all 0.2s ease-out;
    background: var(--primary-color);
    opacity: 0;
  }

  &:checked:before,
  &[checked=true]:before,
  &[checked=checked]:before {
    top: 7px;
    left: 4px;
    height: 7px;
    opacity: 1;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 11px;
    left: 6px;
    width: 3px;
    height: 0;
    transform: rotate(50deg);
    transition: all 0.2s ease-in;
    transition-delay: 50ms;
    background: var(--primary-color);
    opacity: 0;
  }
  
  &:checked:after,
  &[checked=true]:after,
  &[checked=checked]:after {
    top: 3px;
    left: 10px;
    height: 10px;
    opacity: 1;
  }

  @keyframes grow-after {
    0% {
      height: 0;
    }
    100% {
      height: 10px;
    }
  }
`;