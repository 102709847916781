import React, { Component } from "react";
import "./sidebar-btn.css";
import RDP_CONFIG from "../../config";
import { getTheme } from "../../utils/getTheme";
export default class SidebarBtn extends Component {
  render() {
    const isThemeDark = getTheme() === "dark";
    return (
      <li className="rdp-composer-sidebar-btn" onClick={() => this.props.click()}>
        <span
          className={`rdp-composer-sidebar-btn-icon ${this.props.icon}`}
          style={{
            background: "#5d5d5d",
            ...(isThemeDark && {
              background: "#fff",
            }),
          }}
        ></span>
        <span className="rdp-composer-sidebar-btn-label">{this.props.label}</span>
      </li>
    );
  }
}
