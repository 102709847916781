import $ from 'jquery';
import React, { Component } from 'react';
import {DamService} from '../../services/dam';
import Composition from './composition.rdpcomposer';
import './subheader.css';
import { ContentSearch } from '../../components';
import RDP_CONFIG from '../../config';

export default class Subheader extends Component {

  newComposition() {
    window.location = window.location.origin + window.location.pathname + "#/composer";
  }

  blobToFile(theBlob, fileName){
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  getFile() {
    var composition = {
      "metadata": {
        "title": "Sem título",
        "description": ""
      },
      "interface": {
        "font_family": "Montserrat-Regular"
      },
      "structure": []
    }
    var myBlob = new Blob([JSON.stringify(composition)], {type : "digitalpages/json"});
    return this.blobToFile(myBlob, "composition.rdpcomposer");
  }

  uploadFile() {
    var uid = JSON.parse(window.localStorage.getItem("rdp-composer-active-project")).connectors[0].uid;
    var url = `https://${RDP_CONFIG.domain}/storage/v1.0/upload/connector/uid/${uid}`;
    var _this = this;

    var fd = new FormData();
    var files = this.getFile();
    fd.append('file', files);

    var userData = JSON.parse(window.localStorage.getItem("rdp-composer-user-data"));
    var type = userData.type;
    var accessToken = userData.access_token;

    $.ajax({
      url: url,
      type: "POST",
      data: fd,
      contentType: false,
      processData: false,
      headers: {
        "Authorization": type + " " + accessToken,
        "Project-Key" : RDP_CONFIG.projectKey,
        "Api-Env" : RDP_CONFIG.apiEnv
      },
      xhr: function(){
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener("progress", function(evt){
          if (evt.lengthComputable) {
            var percentComplete = parseInt((evt.loaded / evt.total) * 100);
          }
        }, false);
        return xhr;
      },
      success: function(response) {
        window.location = window.location.origin + "/#/composer/" + response[0].metadatas.content_uid;
      }
    });
  }
  
  render() {
    return (
      <div className="rdp-composer-subheader" style={{top: this.props.isEmbed ? "0px" : "80px"}}>
        <button className="rdp-composer-subheader-new" onClick={() => this.newComposition()}>{this.props.labels.buttons.new_composition}</button>
        <ContentSearch onSearchSubmit={(term) => this.props.onSearchSubmit(term)} labels={this.props.labels}/>
      </div>
    );
  }
}
