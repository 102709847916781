let devOpsApiEnv = "{{DevOps_ApiEnv}}";
devOpsApiEnv = devOpsApiEnv.indexOf("{{") != 0 ? devOpsApiEnv : "production";

let devOpsApiProjectKey = "{{DevOps_ApiProjectKey}}";
devOpsApiProjectKey = devOpsApiProjectKey.indexOf("{{") != 0 ? devOpsApiProjectKey : "";

let devOpsApiProjectUid = "{{DevOps_ApiProjectUid}}";
devOpsApiProjectUid = devOpsApiProjectUid.indexOf("{{") != 0 ? devOpsApiProjectUid : "";

let devOpsApiDirectoryUid = "{{DevOps_ApiDirectoryUid}}";
devOpsApiDirectoryUid = devOpsApiDirectoryUid.indexOf("{{") != 0 ? devOpsApiDirectoryUid : "";

let devOpsCustomLoginBackground = "{{DevOps_CustomLoginBackground}}";
devOpsCustomLoginBackground = devOpsCustomLoginBackground.indexOf("{{") != 0 ? devOpsCustomLoginBackground : "";

let devOpsCustomBrand= "{{DevOps_CustomBrand}}";
devOpsCustomBrand = devOpsCustomBrand.indexOf("{{") != 0 ? devOpsCustomBrand : "";

let RDP_CONFIG = {};

RDP_CONFIG.domain = "api.digitalpages.com.br";
RDP_CONFIG.apiVersion = "v1.0";
RDP_CONFIG.directoryUid = devOpsApiDirectoryUid;
RDP_CONFIG.projectUid = devOpsApiProjectUid;
RDP_CONFIG.projectKey = devOpsApiProjectKey;
RDP_CONFIG.apiEnv = devOpsApiEnv;
RDP_CONFIG.customLoginBackground = devOpsCustomLoginBackground;
RDP_CONFIG.customBrand = devOpsCustomBrand;

RDP_CONFIG.configBase64 = null;
RDP_CONFIG.config = null;
RDP_CONFIG.theme = 'light';

export default RDP_CONFIG;