import React, { Component } from 'react';
import './virtual-element-index-1.css';

export default class VirtualElementIndex1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;

    return (<div className={this.props.className}>
      {headerActionsTemplate}
      <span className="virtual-element-index-1-title">{content.title}</span>
      <span className="virtual-element-index-1-description">(Este marcador ficará visível como um botão de navegação pelo conteúdo)</span>
      {footerActionsTemplate}
    </div>)
  }
}
